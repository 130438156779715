import { Grid, makeStyles } from '@material-ui/core'
import { themeContext } from '@smartb/archetypes-ui-components'
import React, { useContext } from 'react'
import { ArrowLeft } from '../../Icons'
import { Button } from '../Button'
import { CustomList } from './CustomList'

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto'
  },
  icon: {
    width: '20px',
    height: '20px'
  },
  rightIcon: {
    transform: 'rotate(180deg)'
  },
  buttonContainer: {
    height: '100px'
  }
}))

export type GenericWithId<T> = { id: string | number } & T

export type GenericGroup<T> = {
  list: GenericWithId<T>[]
  name: string
  id: string | number
}

interface TransferListProps<T> {
  leftGroupsList: GenericGroup<T>[]
  rightGroupsList: GenericGroup<T>[]
  leftHeader?: React.ReactNode
  rightHeader?: React.ReactNode
  onSwitchAllToLeft?: () => void
  onSwitchToLeft?: (item: GenericWithId<T>) => void
  switchAlltoLeftLabel?: string
  onSwitchAllToRight?: () => void
  onSwitchToRight?: (item: GenericWithId<T>) => void
  getItemTitle: (item: GenericWithId<T>) => string
  switchAlltoRightLabel?: string
  isLoading?: boolean
}

export const TransferList = function <T>(props: TransferListProps<T>) {
  const {
    leftGroupsList,
    rightGroupsList,
    isLoading,
    leftHeader,
    onSwitchAllToLeft,
    onSwitchAllToRight,
    onSwitchToLeft,
    onSwitchToRight,
    rightHeader,
    switchAlltoLeftLabel,
    switchAlltoRightLabel,
    getItemTitle
  } = props
  const theme = useContext(themeContext)
  const classes = useStyles()
  return (
    <Grid
      container
      spacing={2}
      justify='center'
      alignItems='center'
      className={classes.root}
    >
      <Grid item>
        <CustomList
          groups={leftGroupsList}
          header={leftHeader}
          getItemTitle={getItemTitle}
          itemIcon={
            <ArrowLeft className={`${classes.icon} ${classes.rightIcon}`} />
          }
          isLoading={isLoading}
          onSwitch={onSwitchToRight}
          orientation='left'
        />
      </Grid>
      <Grid item>
        <Grid
          className={classes.buttonContainer}
          container
          direction='column'
          justify='space-around'
        >
          {onSwitchAllToLeft && (
            <Button
              variant='outlined'
              onClick={onSwitchAllToLeft}
              icon={
                <ArrowLeft
                  className={classes.icon}
                  color={theme.secondaryColor}
                />
              }
            >
              {switchAlltoLeftLabel}
            </Button>
          )}
          {onSwitchAllToRight && (
            <Button variant='outlined' onClick={onSwitchAllToRight}>
              {switchAlltoRightLabel}
              <ArrowLeft
                className={`${classes.icon} ${classes.rightIcon}`}
                color={theme.secondaryColor}
              />
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <CustomList
          groups={rightGroupsList}
          header={rightHeader}
          orientation='right'
          getItemTitle={getItemTitle}
          itemIcon={<ArrowLeft className={classes.icon} />}
          isLoading={isLoading}
          onSwitch={onSwitchToLeft}
        />
      </Grid>
    </Grid>
  )
}
