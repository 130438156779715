import React, { useEffect, useState } from 'react'
import { makeStyles, Box } from '@material-ui/core'
import * as R from 'ramda'

const useStyles = (tabsNumber: number, tabsTitleWidth?: number) =>
  makeStyles(() => ({
    container: {
      width: '100%',
      position: 'relative'
    },
    header: {
      width: '100%',
      position: 'relative'
    },
    body: {
      width: 'calc(100% - 30px)',
      position: 'relative',
      minHeight: '200px',
      //background: 'white',
      borderRadius: '0px 0px 5px 5px',
      padding: '15px',
      overflow: 'auto'
    },
    button: {
      width: tabsTitleWidth
        ? `${tabsTitleWidth}px`
        : `calc(100% / ${tabsNumber})`,
      border: '1px solid rgba(209,202,203,1)',
      color: '#9a9a9a',
      fontSize: '14px',
      borderRadius: '5px 5px 0px 0px',
      cursor: 'pointer',
      background: 'rgb(237, 237, 237)',
      padding: '15px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:hover': {
        background: '#dedede'
      },
      '&:focus': {
        outline: 'none'
      }
    },
    selected: {
      width: tabsTitleWidth
        ? `${tabsTitleWidth}px`
        : `calc(100% / ${tabsNumber})`,
      borderRadius: '5px 5px 0px 0px',
      border: '1px solid white',
      color: '#9a9a9a',
      fontSize: '14px',
      //background: 'white',
      padding: '15px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:focus': {
        outline: 'none'
      }
    }
  }))

export interface TabsClasses {
  header?: string
  body?: string
  tabsTitle?: string
  tabsTitleSelected?: string
}

export interface TabsStyles {
  header?: React.CSSProperties
  body?: React.CSSProperties
  tabsTitle?: React.CSSProperties
  tabsTitleSelected?: React.CSSProperties
}

export interface Tab {
  id: string
  header: React.ReactNode
  body: React.ReactNode
  onClick?: () => void
}

type TabsMap = { [id: string]: Tab }
type indexById = (tabs: Tab[]) => TabsMap
const indexById: indexById = R.indexBy(R.prop('id'))

interface TabsProps {
  tabs: Tab[]
  selected: string
  onClick: (tabId: string) => void
  className?: string
  style?: React.CSSProperties
  classes?: TabsClasses
  styles?: TabsStyles
  tabsTitleWidth?: number
}

export const Tabs = (props: TabsProps) => {
  const {
    tabs,
    selected,
    onClick,
    className,
    style,
    classes,
    styles,
    tabsTitleWidth
  } = props
  const defaultClasses = useStyles(tabs.length, tabsTitleWidth)()
  const [tabsMap, setTabsMap] = useState(indexById(tabs))

  useEffect(() => {
    setTabsMap(indexById(tabs))
  }, [tabs])

  const handleClick = (tab: Tab) => {
    !!tab.onClick ? tab.onClick() : onClick(tab.id)
  }
  return (
    <div className={`${className} ${defaultClasses.container}`} style={style}>
      <Box
        display='flex'
        flexDirection='row'
        className={`${classes && classes.header} ${defaultClasses.header}`}
        style={styles && styles.header}
      >
        {tabs.map((tab, index) =>
          tab.id === selected ? (
            <button
              className={`${classes && classes.tabsTitleSelected} ${
                defaultClasses.selected
              }`}
              style={styles && styles.tabsTitleSelected}
              key={index}
            >
              {tab.header}
            </button>
          ) : (
            <button
              className={`${classes && classes.tabsTitle} ${
                defaultClasses.button
              }`}
              style={styles && styles.tabsTitle}
              onClick={() => handleClick(tab)}
              key={index}
            >
              {tab.header}
            </button>
          )
        )}
      </Box>
      <div
        className={`${classes && classes?.body} ${defaultClasses.body}`}
        style={styles && styles?.body}
      >
        {tabsMap[selected].body}
      </div>
    </div>
  )
}
