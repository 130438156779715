import { Rule } from "api/project/model";
import "date-fns";
import React, { useEffect, useState } from "react";
import { RulesFormValidity } from "./Model";
import RuleComponent from "./Rule";

interface CampaignFormProps {
  rules: Rule[];
  validity: RulesFormValidity[];
  onRulesChange: (rule: Rule[], validation: RulesFormValidity[]) => void;
  readonly: boolean;
}

const RulesRows = (props: CampaignFormProps) => {
  const { rules, validity, onRulesChange, readonly } = props;
  const [validityState, setValidity] = useState(validity);

  const onChangeBonus = (typeInput: string, position: number, value: any) => {
    const copy = [...rules];
    copy[position] = { ...copy[position], [`${typeInput}`]: value };
    onRulesChange(copy, validityState);
  };
  useEffect(() => {
    setValidity(validity);
  }, [validity]);
  const onBonusDeleted = (position: number): void => {
    const copy = [...rules];
    const bonusValidationCopy = [...validityState];
    copy.splice(position, 1);
    bonusValidationCopy.splice(position, 1);
    onRulesChange(copy, bonusValidationCopy);
  };

  return (
    <>
      {rules.map((el, index) => (
        <RuleComponent
          rule={el}
          onChangeRule={onChangeBonus}
          onRuleDeleted={onBonusDeleted}
          ruleValidation={validityState[index]}
          key={index}
          position={index}
          readOnly={readonly}
        />
      ))}
    </>
  );
};

export default RulesRows;
