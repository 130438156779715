import React from 'react'
import {
  makeStyles,
  Snackbar as MuiSnackbar,
  SnackbarCloseReason
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { Severity } from './AlertHandler'

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 20,
    color: 'white',
    position: 'absolute',
    right: '10px',
    top: '14px',
    cursor: 'pointer'
  },
  message: {
    width: '100%',
    marginRight: '20px'
  }
}))

export interface SnackbarProps {
  className?: string
  message?: string
  onClose?: () => void
  severity: Severity
  open?: boolean
}

export const Snackbar = (props: SnackbarProps) => {
  const {
    severity = 'info',
    className,
    message,
    onClose,
    open = true,
    ...other
  } = props
  const classes = useStyles()
  const onSnackbarClose = (
    event: React.SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') return
    onClose && onClose()
  }

  return (
    <MuiSnackbar onClose={onSnackbarClose} autoHideDuration={5000} open={open}>
      <Alert
        className={className}
        aria-describedby='client-snackbar'
        severity={severity}
        elevation={6}
        variant='filled'
        classes={{ message: classes.message }}
        {...other}
      >
        {message}
        <Close onClick={onClose} className={classes.icon} />
      </Alert>
    </MuiSnackbar>
  )
}
