import { queries } from "./bonuspayment.queries";
import { actions } from "./bonuspayment.actions";
import { commands } from "./bonuspayment.command";
import { selectors } from "./bonuspayment.selectors";
export const bonusPayment = {
  actions: actions,
  queries: queries,
  commands: commands,
  selectors: selectors,
};
