import { buildBonusGroupApiUrl } from "api/configuration";
import { city } from "plateform-bonus-group-domain";
import { http } from "../../http";

export interface ExportBonusGroupInvoiceQueryBase
  extends city.colisactiv.plateform.bonus.group.model.features
    .ExportBonusGroupInvoiceQueryBase {}

export const exportBonusGroupInvoices = async (
  bonusGroupIdsPerExport: number[][]
): Promise<string | null> => {
  const query: ExportBonusGroupInvoiceQueryBase = {
    payload: {
      bonusGroupIds: bonusGroupIdsPerExport
    }
  }
  return http.fetchDownloadableFile(
    buildBonusGroupApiUrl(`/bonusgroup/invoice/export`),
    "Une erreur est survenue lors de l'export des factures",
    "post",
    JSON.stringify(query)
  );
};

