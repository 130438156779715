import { connect } from "react-redux";
import { State } from "store";
import { title } from "store/title";
import { profile } from "store/profile";
import { report } from "store/report";
import { router } from "store/router";
import { project } from "store/project";
import { global } from "store/global";

const mapStateToProps = (state: State) => ({
  currentUser: profile.selectors.User(state),
  carriers: global.selectors.carrierRefs(state),
  currentReport: report.selectors.report(state),
  availableTerritories: project.selectors.territories(state),
});

const mapDispatchToProps = {
  setHeadBar: title.actions.setHeadBar,
  createCarrierReport: report.commands.createCarrierReport,
  gotoReports: router.goto.report.reportsList,
  fetchTerritories: project.queries.fetchUnfinishedTerritories,
};

export default connect(mapStateToProps, mapDispatchToProps);
