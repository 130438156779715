import { Roles } from "./index";

type RoutesAccessRightsMap = { [route: string]: Roles[] };

export const routesAccessRights: RoutesAccessRightsMap = {
  "/dashboard": [
    "admin",
    "super_admin",
    "operator_admin",
    "funder_admin",
    "operator_user",
    "funder_user",
    "carrier_admin",
    "carrier_user",
  ],
  "/dashboard?operatorFilter": [
    "admin",
    "super_admin",
    "funder_admin",
    "funder_user",
    "carrier_admin",
    "carrier_user",
  ],
  "/dashboard?carrierFilter": [
    "admin",
    "super_admin",
    "operator_admin",
    "operator_user",
  ],
  "/bonus": ["admin", "super_admin", "operator_admin", "operator_user"],
  "/bonusPayment": ["admin", "user"],
  "/projects": ["admin", "super_admin"],
  "/organizations": ["admin", "super_admin"],
  "/organizations/:orgnizationId": [],
  "/organizations/add": [
    "admin",
    "super_admin",
    "operator_admin",
    "funder_admin",
    "carrier_admin",
  ],
  "/organizations/edit": [
    "admin",
    "super_admin",
    "operator_admin",
    "funder_admin",
    "carrier_admin",
  ],
  "/organizations/users/add": [
    "admin",
    "super_admin",
    "operator_admin",
    "funder_admin",
    "carrier_admin",
  ],
  "/organizations/users/edit": [
    "admin",
    "super_admin",
    "operator_admin",
    "funder_admin",
    "carrier_admin",
  ],
  "/organizations/documents/convention": [
    "admin",
    "super_admin",
    "operator_admin",
    "funder_admin",
    "carrier_admin",
  ],
  "/organizations/documents/convention/sign": [
    "operator_admin",
    "funder_admin",
    "carrier_admin",
  ],
  "/organizations/documents": [],
  "/notifications": [],
  "/notificationRouter": [],
  "/archives": [],
  "/api": ["operator_admin"],
  "/reports": ["user", "admin", "carrier_admin", "carrier_user"],
  "/campaignDelivery": ["admin"],
};
