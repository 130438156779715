import { buildOrganizationApiUrl } from "api/configuration";
import { DeliveryTourPage } from "..";
import { http } from "../../http";
import { DateInterval } from "api/report";

interface GetRejectedToursCommand {
  type: string
  payload: {
    areaCode?: string
    operatorId?: string
    pageNumber: number
    pageSize: number
    period?: DateInterval
  },
}

const getRejectedDeliveryToursCommand = (
  page: number,
  size: number,
  operatorId?: string,
  period?: DateInterval,
  areaCode?: string
): GetRejectedToursCommand => {
  return {
    type: "GET_REJECTED_DELIVERY_TOURS",
    payload: {
      areaCode: areaCode,
      operatorId: operatorId,
      pageNumber: page,
      pageSize: size,
      period: period,
    },
  };
};

export const getRejectedDeliveryTours = (
  page: number,
  size: number,
  operatorId?: string,
  period?: DateInterval,
  areaCode?: string
): Promise<DeliveryTourPage> => {
  return http.get(
    buildOrganizationApiUrl(`/campaignDelivery/tours?page=${page}&size=${size}${operatorId ? `&operatorId=${operatorId}` : ""}${areaCode ? `&areaCode=${areaCode}` : ""}${period ? `&startDate=${period.startDate}&endDate=${period.endDate}` : ""}`),
    "Les livraisons n'ont pas pu être récupéré"
  );
};
