import { geoZoneClient, Territory } from "api/geoZone";
import { AutoComplete } from "components";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface BonusFormTerritoryInputProps {
  className: string;
  inputClassName: string;
  value: Territory;
  error: boolean;
  readonly?: boolean;
  onChange: (territory: Territory | Territory[]) => void;
}

const BonusFormTerritoryInput = (props: BonusFormTerritoryInputProps) => {
  const { className, error, inputClassName, readonly, value, onChange } = props;
  const { t } = useTranslation();

  const [autocompleteText, setAutocompleteText] = useState(
    "Rechercher un territoire"
  );
  const [autocompleteOptions, setAutocompleteOptions] = useState<Territory[]>(
    []
  );

  const handleSearchTerritory = useCallback(async (search: string) => {
    if (search.trim().length === 0) {
      setAutocompleteOptions([]);
      setAutocompleteText("Rechercher un territoire");
      return;
    }

    try {
      const response = await geoZoneClient.getTerritories(0, 5, search.trim());
      setAutocompleteOptions(response.list);
      if (response.list.length === 0) {
        setAutocompleteText("Aucun territoire trouvé");
      }
    } catch (err) {
      console.error(err);
      setAutocompleteOptions([]);
      setAutocompleteText("Une erreur est survenue, veuillez réessayer");
    }
  }, []);

  const getTerritoryName = useCallback((territory: Territory) => {
    return territory?.name ?? "";
  }, []);

  return (
    <AutoComplete<Territory>
      id="bonus-territory"
      label={t("app_components_bonus_form_territory_code")}
      className={className}
      textFieldClassName={inputClassName}
      options={autocompleteOptions}
      defaultValue={value}
      noOptionsText={autocompleteText}
      onChangeSelectedElement={onChange}
      onSearch={handleSearchTerritory}
      getOptionLabel={getTerritoryName}
      readonly={readonly}
      error={error}
      errorMessage={t("app_components_bonus_form_territory_code_error")}
    />
  );
};

export default BonusFormTerritoryInput;
