import { createActionCreator } from "deox";

const setUnSeenNumber= createActionCreator(
  "SET_NOTIF_UNSEEN_NUMBER",
  (resolve) => (unSeenNumber: number) => resolve({ unSeenNumber: unSeenNumber })
);

export const actions = {
  setUnSeenNumber: setUnSeenNumber,
};
