import { CarrierReport } from "../../../api/report";
export interface CarrierReportFormState {
  carrierReport: CarrierReport;
  honorConfirmation: boolean;
  validity: CarrierReportFormValidity;
}
export interface CarrierReportFormValidity {
  carrierId: boolean;
  territory: boolean;
  //activeModekmNumber: boolean;
  activeModepackNumber: boolean;
  // electricalModekmNumber: boolean;
  electricalModepackNumber: boolean;
  // thermalModekmNumber: boolean;
  thermalModepackNumber: boolean;
  honorConfirmation: boolean;
}

export const defautlCarrierReportFormValidity: CarrierReportFormValidity = {
  carrierId: true,
  territory: true,
  // activeModekmNumber: true,
  activeModepackNumber: true,
  //electricalModekmNumber: true,
  electricalModepackNumber: true,
  //thermalModekmNumber: true,
  thermalModepackNumber: true,
  honorConfirmation: true,
};
const date = new Date();
export const defaultCarrierReportFormState: CarrierReportFormState = {
  carrierReport: {
    activeMode: {
      kmNumber: "",
      packNumber: "",
    },
    period: {
      startDate: Date.UTC(date.getFullYear(), date.getMonth(), 1),
      endDate: Date.UTC(date.getFullYear(), date.getMonth() + 1, 1) - 1,
    },
    carrierId: "",
    territory: "",
    electricMode: {
      kmNumber: "",
      packNumber: "",
    },
    id: "",
    thermalMode: {
      kmNumber: "",
      packNumber: "",
    },
    status: { value: "" },
    incidents: [],
    creationDate: "",
  },
  honorConfirmation: false,
  validity: defautlCarrierReportFormValidity,
};
