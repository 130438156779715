import { buildOrganizationApiUrl } from "api/configuration";
import { http } from "../../http";

export const fetchConventionFile = (
  organizationId: string
): Promise<null | Blob> => {
  return http.fetchBlob(
    buildOrganizationApiUrl(`/organizations/${organizationId}/convention/file`),
    "La récupération des conventions n'a pas pu être effectuée"
  );
};
