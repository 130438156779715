import { Convention, Organization } from "api/organization";
import { createReducer } from "deox";
import * as R from "ramda";
import { actions } from "./organization.actions";

export interface organizationState {
  organization: Organization | null;
  convention: Convention | null;
}

export const initialState: organizationState = {
  organization: null,
  convention: null,
};

export type OrganizationStateTransformer = (
  state: organizationState
) => organizationState;

const setOrganization = (
  organization: Organization
): OrganizationStateTransformer => {
  return R.assoc("organization", organization);
};

const setConvention = (
  convention: Convention
): OrganizationStateTransformer => {
  return R.assoc("convention", convention);
};

const setOrganizationToNull = (): OrganizationStateTransformer => {
  return (state: organizationState) => {
    return initialState
  };
}

export const organizationReducer = createReducer(
  initialState,
  (handleAction) => [
    handleAction(
      actions.fetchedOrganization,
      (state: organizationState, action) =>
        setOrganization(action.payload.organization)(state)
    ),
    handleAction(
      actions.fetchedConvention,
      (state: organizationState, action) =>
        setConvention(action.payload.convention)(state)
    ),
    handleAction(
      actions.createdOrganization,
      (state: organizationState, action) =>
        setOrganization(action.payload.organization)(state)
    ),
    handleAction(
      actions.updatedOrganization,
      (state: organizationState, action) =>
        setOrganization(action.payload.organization)(state)
    ),
    handleAction(
      actions.setOrganizationToNull,
      (state: organizationState) =>
      setOrganizationToNull()(state)
    ),
  ]
);
