import { buildOrganizationApiUrl } from "api/configuration";
import { DeliveryTourPage } from "..";
import { http } from "../../http";
import { city as citydel } from "plateform-campaign-delivery-domain";

interface ForceAcceptDeliveryToursCommand
  extends citydel.colisactiv.platform.campaign.delivery.model.features
    .ForceAcceptDeliveryToursCommand { }

interface DeliveryToursForceAcceptedEventBase
  extends citydel.colisactiv.platform.campaign.delivery.model.features
    .DeliveryToursForceAcceptedEventBase { }

const forceAcceptDeliveryToursCommand = (
  tourIds: string[]
): ForceAcceptDeliveryToursCommand => {
  return {
    type: "ACCEPT_DELIVERY_TOURS",
    payload: {
      tourIds: tourIds,
    },
  };
};

export const forceAcceptDeliveryTours = (
  tourIds: string[]
): Promise<DeliveryToursForceAcceptedEventBase[]> => {
  return http.post(
    buildOrganizationApiUrl(`/campaignDelivery/tours/forceAccept`),
    JSON.stringify(forceAcceptDeliveryToursCommand(tourIds)),
    `${tourIds.length > 1 ? "les" : "la"} livraison${tourIds.length > 1 ? "s" : ""} ${tourIds.length > 1 ? "ont" : "a"} bien été validée${tourIds.length > 1 ? "s" : ""}`
  );
};
