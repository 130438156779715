import { city } from "plateform-project-domain";
import { city as cityPlat } from "plateform-plateform-commons";
import { city as cityStats } from "plateform-project-stats-domain";
import { GeoZone } from "api/geoZone";

export interface ProjectFunder
  extends city.colisactiv.project.model.ProjectFunder {}
export interface Project extends city.colisactiv.project.model.Project {}

export interface ProjectStats
  extends cityStats.colisactiv.plateform.project.stats.model.ProjectStats {}

export interface ProjectDetails
  extends city.colisactiv.project.model.ProjectDetails {}

export interface CreateProjectCommandDTO
  extends city.colisactiv.project.model.features.CreateProjectCommandDTO {}

export interface ProjectFunder
  extends city.colisactiv.project.model.ProjectFunder {}

export interface ProjectFunders extends Array<ProjectFunder> {}

export interface ProjectPage
  extends cityPlat.colisactiv.platform.commons.Page<Project> {}

export interface ProjectStatsPage
  extends cityPlat.colisactiv.platform.commons.Page<ProjectStats> {}

export interface Campaign extends city.colisactiv.project.model.Campaign {
  bonusPrices: BonusPrice[];
}

export interface CampaignStats
  extends cityStats.colisactiv.plateform.project.stats.model.CampaignStats {
  bonusPrices: BonusPrice[];
}

export interface Campaign extends city.colisactiv.project.model.Campaign {
  bonusPrices: BonusPrice[];
}

export interface BonusPrice extends city.colisactiv.project.model.BonusPrice {
  operator: string[];
  areas: GeoZone[];
}

export interface CampaignFunder
  extends city.colisactiv.project.model.CampaignFunder {}

export interface Rule extends city.colisactiv.project.model.Rule {}

export class RuleTypePerHourMaxRate extends city.colisactiv.project.model
  .RuleTypePerHourMaxRate {}

export interface CampaignDetails
  extends city.colisactiv.project.model.CampaignDetails {}

export interface ProjectRef
  extends city.colisactiv.project.model.references.ProjectRef {}
