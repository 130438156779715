import { makeStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { OrganizationRef } from "api/organization";
import "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CampaignFunder, ProjectStats } from "../../../api/project/model";
import CampaignFunderRow from "./CampaignFunderRow";
import { CampaignFormModel, CampaignFunderValidity } from "./Model";
import {
  CampaignValidation,
  defaultCamapaignFunderValidity,
} from "./validation";

const useStyles = makeStyles(() => ({
  addBonusRule: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "fit-content",
    color: "#007DCE",
    fontSize: "14px",
  },
  addCircleIcon: {
    marginRight: 5,
    width: "14px",
    height: "29px",
  },
}));

interface CampaignFundersRowsProps {
  campaignFunders: CampaignFunder[];
  validity: CampaignFunderValidity[];
  onCampaignFundersChange: (
    campaignFunders: CampaignFunder[],
    validation: CampaignFunderValidity[]
  ) => void;
  readonly: boolean;
  project: ProjectStats | null;
  funderRefs: Map<string, OrganizationRef>;
}

const CampaignFundersRows = (props: CampaignFundersRowsProps) => {
  const {
    campaignFunders,
    onCampaignFundersChange,
    validity,
    readonly,
    project,
    funderRefs,
  } = props;
  const classes = useStyles();
  const [validityState, setValidity] = useState(validity);
  const { t } = useTranslation();

  const addCampaignFunder = useCallback(() => {
    let [isValid, validation] = CampaignValidation.CampaignFundersValidation(
      campaignFunders
    );
    if (!isValid) {
      return setValidity(validation);
    }
    onCampaignFundersChange(
      [...campaignFunders, CampaignFormModel.defaultCampaignFunder],
      [...validation, defaultCamapaignFunderValidity]
    );
  }, [campaignFunders, onCampaignFundersChange]);

  useEffect(() => {
    setValidity(validity);
  }, [validity]);

  const onChangeCampaignFunder = useCallback(
    (typeInput: keyof CampaignFunder, position: number, value: any) => {
      const campaignFundersCopy = [...campaignFunders];
      campaignFundersCopy[position] = {
        ...campaignFundersCopy[position],
        [typeInput]: value,
      };
      onCampaignFundersChange(campaignFundersCopy, validityState);
    },
    [campaignFunders, onCampaignFundersChange]
  );

  const onCampaignFunderDeleted = useCallback(
    (position: number): void => {
      const campaignFundersCopy = [...campaignFunders];
      const campaignFundersValidationCopy = [...validityState];
      campaignFundersCopy.splice(position, 1);
      campaignFundersValidationCopy.splice(position, 1);
      onCampaignFundersChange(
        campaignFundersCopy,
        campaignFundersValidationCopy
      );
    },
    [campaignFunders, onCampaignFundersChange]
  );

  const alreadyAddedFunders = useMemo(
    () => campaignFunders.map((el) => el.funderId),
    [campaignFunders]
  );

  const campaignFundersRows = useMemo(
    () =>
      campaignFunders.map((el, index) => {
        const addedFunders = alreadyAddedFunders.filter(
          (funderId) => funderId !== el.funderId
        );
        return (
          <CampaignFunderRow
            onCampaignFunderDeleted={onCampaignFunderDeleted}
            onChangeCampaignFunder={onChangeCampaignFunder}
            campaignFunder={el}
            campaignFunderValidation={
              validityState[index] || defaultCamapaignFunderValidity
            }
            campaignFunderLength={campaignFunders.length}
            key={"campaignFunders: " + index}
            position={index}
            readOnly={readonly}
            project={project}
            funderRefs={funderRefs}
            alreadyAddedFunders={addedFunders}
          />
        );
      }),
    [
      campaignFunders,
      validityState,
      project,
      funderRefs,
      readonly,
      onCampaignFunderDeleted,
      onChangeCampaignFunder,
      alreadyAddedFunders,
    ]
  );

  return (
    <>
      {campaignFundersRows}
      {!readonly && (
        <div className={classes.addBonusRule} onClick={addCampaignFunder}>
          <AddCircleIcon className={classes.addCircleIcon} />
          {t("app_campaign_form_campaignFunders_new_row")}
        </div>
      )}
    </>
  );
};

export default CampaignFundersRows;
