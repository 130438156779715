import { Box, makeStyles } from "@material-ui/core";
import { OrganizationDetails } from "../../../api/organization";
import { Button, InputForm } from "components";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { getInformationsBySiret } from "../../../api/organization/query/fetchOrganizationDetailsBySiret";
import i18n from "i18n";

const useStyles = makeStyles(() => ({
  input: {
    width: "50%",
  },
  text: {
    width: "35%",
  },
  inputForm: {
    marginTop: "30px",
  },
  button: {
    marginLeft: "16px",
    height: "50px",
  },
  buttonDisabled: {
    backgroundColor: "#98A5AE",
    marginLeft: "16px",
    height: "50px",
  },
}));
export const NO_ERROR = undefined;

interface SirenAutocompleteProps {
  readonly: boolean;
  onSiretChange: (siret: string) => void;
  siret: string;
  onSiretFound: (details: OrganizationDetails) => void;
  error?: string;
}

const SirenAutocomplete = (props: SirenAutocompleteProps) => {
  const { onSiretChange, siret, readonly, onSiretFound, error } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [siretApiError, setError] = useState<string | undefined>(NO_ERROR);
  const [success, setSuccess] = useState(false);

  const searchDetailsBySiret = useCallback(
    async (siret: string) => {
      setSuccess(false);
      if (siret.length === 14) {
        const data = await getInformationsBySiret(siret);
        if (data !== null) {
          onSiretFound(data);
          setSuccess(true);
        } else {
          setError(i18n.t("app_organization_form_siret_no_data_found"));
        }
      } else {
        setError(i18n.t("app_organization_form_please_insert_a_correct_siret"));
      }
    },
    [onSiretFound]
  );

  const onChangeSiret = (siret: string) => {
    setError(NO_ERROR);
    setSuccess(false);
    onSiretChange(siret);
  };

  return (
    <Box display="flex" alignItems="flex-end">
      <Box flex={1}>
        <InputForm
          value={siret}
          onChange={onChangeSiret}
          id="organisation-add-selectOrganisationSiret"
          error={error !== NO_ERROR || siretApiError !== NO_ERROR}
          errorMessage={error || siretApiError}
          inputType="textField"
          label={t("app_organization_form_siret")}
          placeHolder="Ex: 1234403292"
          textFieldType="number"
          inputClassName={readonly ? classes.text : classes.input}
          className={classes.inputForm}
          readonly={readonly}
        />
      </Box>
      {!readonly &&
        <Box flexShrink={1}>
          <Button
            className={readonly ? classes.buttonDisabled : classes.button}
            onClick={() => searchDetailsBySiret(siret)}
            disabled={readonly}
            fail={error !== NO_ERROR || siretApiError !== NO_ERROR}
            success={success}
          >
            {t("app_organization_form_search_siret")}
          </Button>
        </Box>}
    </Box>
  );
};

export default SirenAutocomplete;
