import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  HeadBarProps,
  SectionForm,
  FormButtons,
  HeadBarView,
} from "components";
import { CampaignValidation } from "app/components/CampaingForm/validation";
import { useParams } from "react-router-dom";
import CampaignForm from "app/components/CampaingForm";
import { Campaign, ProjectFunder, ProjectStats } from "api/project/model";
import {
  CampaignFormModel,
  CampaignFormState,
} from "app/components/CampaingForm/Model";
import { OrganizationRef } from "api/organization";
import withConnect from "./withConnect";
import { CampaignStatusUtils } from "api/project/model/CampaignStatus";
import { useTranslation } from "react-i18next";

interface CampaignEditPageProps {
  project: ProjectStats | null;
  campaign: Campaign | null;
  operatorRefs: Map<string, OrganizationRef>;
  funderRefs: Map<string, OrganizationRef>;
  setHeadBar: (title: HeadBarProps) => void;
  fetchCampaign: (projectId: string, campaignId: string) => void;
  gotoProjectView: (projectId: string) => void;
  updateCampaign: (campaign: CampaignFormState) => void;
}

const CampaignEditPage = (props: CampaignEditPageProps) => {
  const {
    project,
    setHeadBar,
    operatorRefs,
    fetchCampaign,
    gotoProjectView,
    campaign,
    updateCampaign,
    funderRefs,
  } = props;
  const { projectId, campaignId } = useParams();
  const { t } = useTranslation();

  const projectBudget: number | undefined = useMemo(() => {
    if (!project) return;
    const totalBudget = project.funders.reduce(
      (accumulator: number, funder: ProjectFunder) => {
        if (funder.budget) return accumulator + funder.budget;
        return accumulator;
      },
      0
    );
    if (!project.campaigns) return totalBudget;
    const campaignsBudget = project.campaigns.reduce(
      (accumulator: number, campaign: Campaign) => {
        if (campaign.id === campaignId) return accumulator;
        if (campaign.budget) return accumulator + campaign.budget;
        return accumulator;
      },
      0
    );
    return totalBudget - campaignsBudget;
  }, [project?.funders, project?.campaigns, campaignId]);

  useEffect(() => {
    project &&
      campaign &&
      setHeadBar({
        title: `${campaign.details.name}`,
        component: (
          <HeadBarView
            title={campaign.details.name}
            secondaryTitle={project.details.name}
            status={CampaignStatusUtils.getLabelStyleStatusTags(
              campaign.status
            )}
            startDate={campaign.details.period.startDate}
            endDate={campaign.details.period.endDate}
          />
        ),
      });
  }, [project?.id, campaign?.id]);
  const [campaignForm, setCampaignForm] = useState(
    CampaignFormModel.defaultCampaignForm
  );

  useEffect(() => {
    if (campaign) {
      setCampaignForm({
        ...campaignForm,
        ...campaign,
        projectId: projectId,
        validation: CampaignValidation.campaignFormValidation(
          campaign,
          projectBudget
        )[1],
      });
    }
  }, [campaign]);

  useEffect(() => {
    fetchCampaign(projectId, campaignId);
  }, [projectId, campaignId]);

  const handleCampaignUpdate = useCallback(async () => {
    const [
      isFormValid,
      validationDetails,
    ] = CampaignValidation.campaignFormValidation(campaignForm, projectBudget);
    if (!isFormValid) {
      return setCampaignForm({
        ...campaignForm,
        validation: validationDetails,
      });
    }
    updateCampaign(campaignForm);
    gotoProjectView(projectId);
  }, [campaignForm, projectBudget, updateCampaign, gotoProjectView]);

  return (
    <SectionForm withGoBack>
      <CampaignForm
        project={project}
        campaign={campaignForm}
        projectBudget={projectBudget}
        onCampaignChange={setCampaignForm}
        readonly={false}
        operatorRefs={operatorRefs}
        funderRefs={funderRefs}
      />
      <FormButtons>
        <Button onClick={() => handleCampaignUpdate()}>
          {t("app_project_campaign_detail_save")}
        </Button>
      </FormButtons>
    </SectionForm>
  );
};

export default withConnect(CampaignEditPage);
