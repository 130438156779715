import React, { useCallback, useEffect, useState } from "react";
import { HeadBar, HeadBarProps, NotificationButton } from "components";
import { makeStyles, Menu as MuiMenu } from "@material-ui/core";
import withConnect from "./withConnect";
import NotificationsContainer from "../../components/NotificationsContainer";
import { notificationClient } from "api/notification";
import { User } from "api/organization/users";

const useStyles = makeStyles(() => ({
  notificationsMenu: {
    maxHeight: "500px",
    padding: "15px",
    paddingRight: "30px",
    width: "400px",
    top: "60px !important",
    "& ul": {
      paddingTop: "0px",
    },
    "& .notification-mainContainer": {
      minHeight: "60px",
      boxShadow: "none",
      borderBottom: "#98A5AE 1px solid",
    },
    "& .notification-actionContainer": {
      display: "none",
    },
  },
  notifications: {
    borderRadius: "0px !important",
    marginBottom: "3px",
  },
  notificationButton: {
    color: "#353945",
    width: "25px",
    height: "25px",
  },
}));

export interface AppProps {
  currentLocationHeadBar: HeadBarProps;
  unSeenNumber: number;
  setUnSeenNumber: (unseenNumber: number) => void;
  currentUser: User | null;
}

const AdminHeadBar = (props: AppProps) => {
  const {
    currentLocationHeadBar,
    unSeenNumber,
    setUnSeenNumber,
    currentUser,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notificationPageNumber, setNotificationPageNumber] = useState<number>(
    1
  );
  useEffect(() => {
    currentUser &&
      notificationClient.query
        .getUnseenNumber(currentUser.details.organizationId)
        .then((unSeenNumber) => {
          unSeenNumber && setUnSeenNumber(unSeenNumber);
        });
  }, [setUnSeenNumber, currentUser]);

  useEffect(() => {
    document.title = currentLocationHeadBar.title;
  }, [currentLocationHeadBar]);

  const handleOpenNotifications = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
      if (unSeenNumber > 0 && currentUser) {
        notificationClient.markAllAsSeen(currentUser.details.organizationId);
      }
    },
    [unSeenNumber, currentUser]
  );

  const handleCloseNotifications = useCallback(() => {
    setAnchorEl(null);
    setUnSeenNumber(0);
  }, [setUnSeenNumber]);

  const handlePageNumberChange = useCallback((page: number) => {
    setNotificationPageNumber(page);
  }, []);

  return (
    <>
      <HeadBar {...currentLocationHeadBar} />
      <NotificationButton
        onClick={handleOpenNotifications}
        buttonStyle={{ marginRight: "20px" }}
        notifNumber={unSeenNumber}
        iconClassName={classes.notificationButton}
      />
      <MuiMenu
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl != null}
        onClose={handleCloseNotifications}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.notificationsMenu }}
        MenuListProps={{ variant: "menu" }}
      >
        <NotificationsContainer
          pageNumber={notificationPageNumber}
          onPageNumberChange={handlePageNumberChange}
          className={classes.notifications}
          id="notificationShortCut"
        />
      </MuiMenu>
    </>
  );
};

export default withConnect(AdminHeadBar);
