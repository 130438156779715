import { Typography } from "@material-ui/core";
import { BonusGroupStatusValues } from "api/bonus";
import {
  BonusGroup,
  BonusGroupIncident,
  BonusGroupIncidentStatusValues,
  BonusGroupStateMachine,
} from "api/bonusGroup";
import { BonusGroupStatusUtils } from "api/bonusGroup/model/bonusStatus";
import { OrganizationRef } from "api/organization";
import {
  BonusIncidentFormState,
  BonusIncidentPopup,
  BonusRejectionFormState,
  BonusRejectionPopup,
  Button,
  PopUpConfirmation,
} from "components";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBonusActionsStyle } from "./BonusActionsStyle";

interface BonusActionsButtonsProps {
  bonus: BonusGroup;
  carrier: OrganizationRef | undefined;
  rejectBonus: (bonusGroupId: string, reasons: string) => void;
  validateBonus: (bonusGroupId: string) => void;
  createBonusIncident: (bonusGroupId: string, description: string) => void;
  resolveBonusIncident: (bonusGroupId: string, incidentId: string) => void;
  deduceBonusGroup: (bonusGroupId: string) => void;
  checkDocumentsValidity: () => Promise<boolean>;
}

export const BonusActionsAdminButtons = (props: BonusActionsButtonsProps) => {
  const {
    bonus,
    carrier,
    rejectBonus,
    validateBonus,
    createBonusIncident,
    resolveBonusIncident,
    checkDocumentsValidity,
    deduceBonusGroup,
  } = props;

  const classes = useBonusActionsStyle();
  const { t } = useTranslation();

  const [
    incidentPopupForm,
    setIncidentPopupForm,
  ] = useState<BonusIncidentFormState | null>(null);

  const [
    rejectionPopupForm,
    setRejectionPopupForm,
  ] = useState<BonusRejectionFormState | null>(null);

  const [validationPopupText, setValidationPopupText] = useState<string | null>(
    null
  );

  const [openDeducePopup, setOpenDeducePopup] = useState<boolean>(false);

  const availableAction = BonusGroupStateMachine.getAvailableActions(
    bonus.status.value,
    true
  );
  const hasIncident = BonusGroupStatusValues.isInvalid(bonus.status.value);

  const handleRejectionPopup = useCallback(() => {
    setRejectionPopupForm({
      description: "",
    });
  }, []);

  const handleRejection = useCallback(
    (form: BonusRejectionFormState) => {
      rejectBonus(bonus.id, form.description);
      setRejectionPopupForm(null);
    },
    [bonus, rejectBonus]
  );

  const handleIncidentCreatePopup = useCallback(() => {
    setIncidentPopupForm({
      description: "",
    });
  }, []);

  const handleIncidentCreate = useCallback(
    (form: BonusIncidentFormState) => {
      createBonusIncident(bonus.id, form.description);
      setIncidentPopupForm(null);
    },
    [bonus, createBonusIncident]
  );

  const handleIncidentResolve = useCallback(() => {
    bonus.incidents.forEach((incident: BonusGroupIncident) => {
      if (BonusGroupIncidentStatusValues.isCreated(incident.status.value)) {
        resolveBonusIncident(bonus.id, incident.id);
      }
    });
  }, [bonus, resolveBonusIncident]);

  const handleOpenValidationPopup = useCallback(async () => {
    const carrierName = carrier?.displayName;
    const defaultText = t("app_bonus_view_admin_actions_defaultText", {
      carrierName: carrierName,
    });
    const incidentText = t("app_bonus_view_admin_actions_incidentText");
    const unpublishedText = t("app_bonus_view_admin_actions_unpublishedText");
    const rejectedText = t("app_bonus_view_admin_actions_rejectedText");

    (await checkDocumentsValidity()) &&
      setValidationPopupText(
        BonusGroupStatusValues.isRejected(bonus.status.value)
          ? rejectedText
          : hasIncident
          ? incidentText
          : BonusGroupStatusValues.isCreated(bonus.status.value)
          ? unpublishedText
          : defaultText
      );
  }, [checkDocumentsValidity, bonus, carrier, hasIncident]);

  const handleCloseValidationPopup = useCallback(() => {
    setValidationPopupText(null);
  }, []);

  const handleValidation = useCallback(() => {
    validateBonus(bonus.id);
    handleCloseValidationPopup();
  }, [bonus.id, validateBonus, handleCloseValidationPopup]);

  const toggleDeducePopUp = useCallback(() => {
    setOpenDeducePopup((open) => !open);
  }, []);

  const handleDeduce = useCallback(() => deduceBonusGroup(bonus.id), [
    bonus.id,
    deduceBonusGroup,
  ]);

  if (BonusGroupStatusUtils.isReported(bonus.status)) {
    return (
      <>
        <Button
          className={classes.button}
          onClick={toggleDeducePopUp}
          disabled={!availableAction.canDeduce}
        >
          Passer à déduire
        </Button>
        <PopUpConfirmation
          open={openDeducePopup}
          title="Passer la prime à déduire"
          onClose={toggleDeducePopUp}
          onConfirm={handleDeduce}
        >
          <Typography variant="body1">
            {t("app_bonus_view_admin_actions_reportedText")}
          </Typography>
        </PopUpConfirmation>
      </>
    );
  }

  return (
    <>
      <Button
        className={classes.button}
        variant="outlined"
        fail
        onClick={handleRejectionPopup}
        disabled={!availableAction.canReject}
      >
        Rejeter
      </Button>
      {availableAction.canAddIncident && (
        <Button
          className={classes.button}
          variant="outlined"
          advertissement
          onClick={handleIncidentCreatePopup}
          disabled={!availableAction.canAddIncident}
        >
          Créer un incident
        </Button>
      )}
      {availableAction.canResolve && (
        <Button
          className={classes.button}
          variant="outlined"
          advertissement
          onClick={handleIncidentResolve}
          disabled={!availableAction.canResolve}
        >
          Clore l'incident
        </Button>
      )}
      <Button
        className={classes.button}
        onClick={handleOpenValidationPopup}
        disabled={!availableAction.canApprove}
      >
        Valider
      </Button>
      {incidentPopupForm && (
        <BonusIncidentPopup
          open
          onCancel={() => setIncidentPopupForm(null)}
          form={incidentPopupForm}
          onChange={(form: BonusIncidentFormState) =>
            setIncidentPopupForm(form)
          }
          onValidate={(form: BonusIncidentFormState) =>
            handleIncidentCreate(form)
          }
        />
      )}
      {rejectionPopupForm && (
        <BonusRejectionPopup
          open
          onCancel={() => setRejectionPopupForm(null)}
          form={rejectionPopupForm}
          onChange={(form: BonusRejectionFormState) =>
            setRejectionPopupForm(form)
          }
          onValidate={(form: BonusRejectionFormState) => handleRejection(form)}
        />
      )}
      {validationPopupText && (
        <PopUpConfirmation
          open
          title="Valider la prime"
          onClose={handleCloseValidationPopup}
          onConfirm={handleValidation}
        >
          <Typography variant="body1">{validationPopupText}</Typography>
        </PopUpConfirmation>
      )}
    </>
  );
};
