import { Period, PeriodPicker } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { wholeMonthOf } from "utils";

interface BonusFormPeriodPickerProps {
  inputClassName: string;
  value?: Period;
  readonly?: boolean;
  onChange: (period: Period) => void;
}

const BonusFormPeriodPicker = (props: BonusFormPeriodPickerProps) => {
  const { inputClassName, value, onChange, readonly } = props;
  const { t } = useTranslation();

  const now = new Date();
  const nowPeriod = wholeMonthOf(now.getTime());

  const [period, setPeriod] = useState(value);

  useEffect(() => {
    if (!value) {
      handlePeriodChange(nowPeriod);
    }
  }, []);

  const handlePeriodChange = useCallback(
    (period?: Period) => {
      if (period == null) {
        return;
      }
      setPeriod(period);
      onChange(period);
    },
    [onChange]
  );

  return (
    <PeriodPicker
      id="bonus-form-period-picker"
      label={t("app_report_form_choose_the_period")}
      period={period}
      onDateChange={handlePeriodChange}
      inputClassName={inputClassName}
      readonly={readonly}
    />
  );
};

export default BonusFormPeriodPicker;
