import React from 'react'
import colisActivSrc from './colisactiv.png'
import colisActivWhiteSrc from './colisactivWhite.png'
import { MergeReactElementProps } from '../types'

export type ColisActivLogoPngColor = 'multi' | 'white'

interface ColisActivLogoPngProps {
  height?: number | string
  width?: number | string
  color?: ColisActivLogoPngColor
}

type Props = MergeReactElementProps<'svg',ColisActivLogoPngProps>

export const ColisActivLogoPngSrc: string = colisActivSrc

export const getLogoSrc = (color?: ColisActivLogoPngColor) => {
  if (color == 'white') return colisActivWhiteSrc

  return colisActivSrc
}

export const ColisActivLogoPng = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { height = 150, width = 150, color = 'multi' } = props
    const src = getLogoSrc(color)
    return <img height={height} width={width} src={src} />
  }
)
