import { CampaignStatus, CampaignStatusUtils } from "./CampaignStatus";
import i18n from "i18n";
import { Campaign, CampaignFunder } from "./index";
import { MenuItemAction } from "components";

export interface ActionLink {
  key: string;
  label: string;
  action?: () => void;
  icon?: JSX.Element;
  confirmMsg?: string;
}

export type CampaignActions =
  | "VIEW"
  | "PUBLISH"
  | "DUPLICATE"
  | "EDIT"
  | "DELETE"
  | "PAUSE"
  | "ARCHIVE";

const getActions = (campaignStatus?: CampaignStatus): CampaignActions[] => {
  if (!campaignStatus) {
    return [];
  }
  if (CampaignStatusUtils.isCreated(campaignStatus)) {
    return ["VIEW", "PUBLISH", "EDIT", "DELETE", "DUPLICATE"];
  } else if (CampaignStatusUtils.isStarted(campaignStatus)) {
    return ["VIEW", "PAUSE", "DUPLICATE", "EDIT"];
  } else if (CampaignStatusUtils.isDone(campaignStatus)) {
    return ["VIEW", "ARCHIVE", "DUPLICATE"];
  }
  return ["VIEW"];
};

export interface CampaignsActionProps {
  gotoCampaignView: (projectId: string, campaignId: string) => void;
  gotoCampaignEdit: (projectId: string, campaignId: string) => void;
  publishCampaign: (projectId: string, campaignId: string) => void;
  duplicateCampaign: (projectId: string, campaignId: string) => void;
  archiveCampaign: (projectId: string, campaignId: string) => void;
  deleteCampaign: (projectId: string, campaignId: string) => void;
  pauseCampaign: (projectId: string, campaignId: string) => void;
  gotoProjectView: (projectId: string) => void;
}

const getMenuItems = (
  row: Campaign,
  props: CampaignsActionProps,
  ignore: CampaignActions[] = []
): MenuItemAction[] => {
  const totalFunderPercentage = row.funders.reduce(
    (acc: number, funder: CampaignFunder) => funder.budgetPercentage + acc,
    0
  );
  const campaignActions = getActions(row.status);
  return campaignActions
    .filter((it: CampaignActions) => !ignore.includes(it))
    .map((action) =>
      getMenuItem(row.id, row.projectId, action, props, totalFunderPercentage)
    );
};

const getActionLink = (
  campaignId: string,
  projectId: string,
  action: CampaignActions,
  props: CampaignsActionProps,
  totalFunderPercentage: number
): ActionLink => {
  switch (action) {
    case "PUBLISH":
      return {
        key: `publish-${campaignId}`,
        label: i18n.t("app_project_campaign_detail_publish"),
        action:
          totalFunderPercentage === 100
            ? () => props.publishCampaign(projectId, campaignId)
            : undefined,
        confirmMsg:
          totalFunderPercentage === 100
            ? i18n.t("app_project_campaign_publish_confirmation")
            : i18n.t("app_project_cant_publish_campaign"),
      };
    case "EDIT":
      return {
        key: `edit-${campaignId}`,
        label: i18n.t("app_project_campaign_detail_edit"),
        action: () => props.gotoCampaignEdit(projectId, campaignId),
      };
    case "ARCHIVE":
      return {
        key: `archive-${campaignId}`,
        label: i18n.t("app_project_campaign_detail_archive"),
        action: () => props.archiveCampaign(projectId, campaignId),
      };
    case "DELETE":
      return {
        key: `delete-${campaignId}`,
        label: i18n.t("app_project_campaign_detail_delete"),
        action: () => {
          props.deleteCampaign(projectId, campaignId);
          props.gotoProjectView(projectId);
        },
        confirmMsg: i18n.t("app_project_campaign_delete_confirmation"),
      };
    case "PAUSE":
      return {
        key: `pause-${campaignId}`,
        label: i18n.t("app_project_campaign_detail_pause"),
        action: () => props.pauseCampaign(projectId, campaignId),
      };
    case "VIEW":
      return {
        key: `view-${campaignId}`,
        label: i18n.t("app_project_campaign_detail_view"),
        action: () => props.gotoCampaignView(projectId, campaignId),
      };
    case "DUPLICATE":
      return {
        key: `duplicate-${campaignId}`,
        label: i18n.t("app_project_campaign_detail_duplicate"),
        action: () => {
          props.duplicateCampaign(projectId, campaignId);
        },
      };
  }
};
const getMenuItem = (
  campaignId: string,
  projectId: string,
  action: CampaignActions,
  props: CampaignsActionProps,
  totalFunderPercentage: number
): MenuItemAction => {
  const actionLink = getActionLink(
    campaignId,
    projectId,
    action,
    props,
    totalFunderPercentage
  );
  return {
    key: actionLink.key,
    label: actionLink.label,
    goto: actionLink.action,
    confirmMsg: actionLink.confirmMsg,
    icon: actionLink.icon,
  };
};

export const CampaignActionsUtils = {
  getActions: getActions,
  getMenuItems: getMenuItems,
};
