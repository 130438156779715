import { connect } from "react-redux";
import { State } from "../../store";
import { title } from "../../store/title";

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {
  setHeadBar: title.actions.setHeadBar,
};

export default connect(mapStateToProps, mapDispatchToProps);
