import { KeycloakProvider } from "@react-keycloak/web";
import { CAI18NThemeContextProvider } from "components";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { keycloakClient } from "./api/keyclaok";
import App from "./app";
import StoreInit from "./app/init";
// import i18n (needs to be bundled ;))
import i18next from "./i18n";
import * as serviceWorker from "./serviceWorker";
import store, { history } from "./store";
import Loading from "./app/components/Loading";

ReactDOM.render(
  <KeycloakProvider
    keycloak={keycloakClient.instance}
    LoadingComponent={<Loading />}
    initConfig={keycloakClient.initConfig}
  >
    <React.StrictMode>
      <Provider store={store}>
        <CAI18NThemeContextProvider i18n={i18next} loading={<Loading />}>
          <ConnectedRouter history={history}>
            <StoreInit>
              <App />
            </StoreInit>
          </ConnectedRouter>
        </CAI18NThemeContextProvider>
      </Provider>
    </React.StrictMode>
  </KeycloakProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
