import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = (aligned: boolean, reverse: boolean) =>
  makeStyles(() =>
    createStyles({
      header: {
        color: '#353945',
        fontWeight: 600,
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      headerBig: {
        fontSize: '17px',
        lineHeight: '24px'
      },
      headerMedium: {
        fontSize: '15px',
        lineHeight: '22px'
      },
      headerSmall: {
        fontSize: '13px',
        lineHeight: '20px'
      },
      content: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: reverse && aligned ? '10px' : '',
        marginLeft: !reverse && aligned ? '10px' : ''
      },
      contentBig: {
        fontSize: '14px',
        lineHeight: '17px',
        color: '#353945'
      },
      contentMedium: {
        fontSize: '12px',
        lineHeight: '15px',
        color: '#828282'
      },
      contentSmall: {
        fontSize: '10px',
        lineHeight: '13px',
        color: '#828282'
      }
    })
  )

export type HeadlineBoxSize = 'big' | 'medium' | 'small'

interface HeadlineBoxProps {
  header?: React.ReactNode
  children?: React.ReactNode
  nodeHeader?: boolean
  nodeChildren?: boolean
  className?: string
  style?: React.CSSProperties
  size?: HeadlineBoxSize
  reverse?: boolean
  aligned?: boolean
}

export const HeadlineBox = (props: HeadlineBoxProps) => {
  const {
    header,
    children,
    className,
    style,
    nodeHeader = false,
    nodeChildren = false,
    aligned = false,
    reverse = false,
    size = 'medium'
  } = props
  const defaultClasses = useStyles(aligned, reverse)()
  return (
    <Box
      className={clsx(className)}
      style={style}
      display='flex'
      flexDirection={!aligned ? 'column' : 'row'}
      alignItems={aligned ? 'center' : undefined}
    >
      {reverse && !!children ? (
        !nodeChildren ? (
          <Typography
            variant='body1'
            className={`${
              size === 'big'
                ? defaultClasses.contentBig
                : size === 'medium'
                ? defaultClasses.contentMedium
                : defaultClasses.contentSmall
            } ${defaultClasses.content} colisactivHeadlineBox-content`}
          >
            {children}
          </Typography>
        ) : (
          children
        )
      ) : undefined}

      {!nodeHeader ? (
        <Typography
          variant='body1'
          className={`${
            size === 'big'
              ? defaultClasses.headerBig
              : size === 'medium'
              ? defaultClasses.headerMedium
              : defaultClasses.headerSmall
          } ${defaultClasses.header} colisactivHeadlineBox-header`}
        >
          {header}
        </Typography>
      ) : (
        header
      )}
      {!reverse && !!children ? (
        !nodeChildren ? (
          <Typography
            variant='body1'
            className={`${
              size === 'big'
                ? defaultClasses.contentBig
                : size === 'medium'
                ? defaultClasses.contentMedium
                : defaultClasses.contentSmall
            } ${defaultClasses.content} colisactivHeadlineBox-content`}
          >
            {children}
          </Typography>
        ) : (
          children
        )
      ) : undefined}
    </Box>
  )
}
