import { Box } from '@material-ui/core'
import React from 'react'
import { InputForm } from '../../Component/InputForm'

interface BonusRejectionFormState {
  description: string
}

interface BonusRejectionFormProps {
  form: BonusRejectionFormState
  onChange: (form: BonusRejectionFormState) => void
}

export const BonusRejectionForm = (props: BonusRejectionFormProps) => {
  const { form, onChange } = props
  const handleChange = (
    field: keyof BonusRejectionFormState,
    value: string
  ) => {
    onChange({
      ...form,
      [`${field}`]: value
    })
  }
  return (
    <Box>
      <InputForm
        label='Raisons'
        inputType='textField'
        id={'bonus-rejection-description'}
        value={form.description}
        onChange={(description: string) =>
          handleChange('description', description)
        }
      />
    </Box>
  )
}
