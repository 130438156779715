import { city as cityDom } from 'plateform-organization-domain'
import React, { useMemo } from 'react'
import { PrimaryTypography, Table } from '../../Component/Table'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { city } from 'plateform-poi-dashboard-domain'
import { city as cityBonusGroup } from 'plateform-bonus-group-domain'
import { city as cityBonus } from 'plateform-bonus-domain'
import { InfoBox } from '../../Component/InfoBox'
import { displayNumber, formatDate } from '../../utils'
import { StatusTags, StatusTagsFormProps } from '../../Component/StatusTags'
import i18n from 'i18next'

interface BonusStats
  extends city.colisactiv.plateform.poi.dashboard.domain.BonusStats {}

interface OrganizationRef
  extends cityDom.colisactiv.organization.model.references.OrganizationRef {}

export interface BonusGroupFunder
  extends cityBonusGroup.colisactiv.plateform.bonus.group.model
    .BonusGroupFunder {}

export interface BonusGroupStatus
  extends cityBonus.colisactiv.plateform.bonus.model.BonusGroupStatus {}

export interface FunderRepartitionRatio {
  funderId: string
  funderName: string
  fundedAmount: number
}

interface DashboardTableRow {
  date: string
  carrier: string
  operator: string
  activePackCount: number
  bonusTotal: number
  bonusGroupStatus: BonusGroupStatus
  area: string
  funderRepartition: FunderRepartitionRatio[]
}

interface DashboardTableProps {
  organizationRef: Map<string, OrganizationRef>
  stats: BonusStats[]
  onRowClicked?: (row: DashboardTableRow) => void
  totalPages?: number
  page?: number
  handlePageChange?: (page: number) => void
  getStatus: (bonusGroupStatus: BonusGroupStatus) => StatusTagsFormProps
  isLoading?: boolean
  isOperator: boolean
  isCarrier: boolean
}

const useStyles = makeStyles(() => ({
  container: {
    '& .rdt_TableRow .rdt_TableCell': {
      minWidth: '100px'
    },
    '& .rdt_TableCol': {
      minWidth: '100px'
    },
    '& .rdt_TableRow .rdt_TableCell:first-child': {
      minWidth: '130px',
      maxWidth: '130px'
    },
    '& .rdt_TableCol:first-child': {
      minWidth: '130px',
      maxWidth: '130px'
    },
    '& .rdt_TableRow .rdt_TableCell:last-child': {
      minWidth: '200px',
      maxWidth: '200px'
    },
    '& .rdt_TableCol:last-child': {
      minWidth: '200px',
      maxWidth: '200px'
    }
  },
  dateStyle: {
    display: 'flex'
  },
  imgDateStyle: {
    width: 25,
    height: 25,
    marginRight: 5
  },
  infoIcon: {
    width: '15px',
    height: '15px',
    margin: '5px'
  },
  infoText: {
    fontSize: '13px',
    color: '#98A5AE',
    display: 'flex',
    alignItems: 'center'
  },
  internText: {
    fontSize: '12px',
    color: '#828282'
  }
}))

export const computeFunderRepartition = (
  bonusStats: BonusStats,
  organizationRef: Map<string, OrganizationRef>
): FunderRepartitionRatio[] => {
  return bonusStats.funderRepartition.map((funderRepartition: any) => {
    return {
      funderId: funderRepartition.id,
      funderName: organizationRef.get(funderRepartition.id)!!.displayName,
      fundedAmount: funderRepartition.fundedAmount
    }
  })
}

export const DashboardTable = (props: DashboardTableProps) => {
  const {
    organizationRef,
    stats,
    onRowClicked,
    totalPages,
    handlePageChange,
    page,
    isLoading,
    isOperator,
    isCarrier,
    getStatus
  } = props

  const classes = useStyles()

  const { t } = useTranslation()

  const data: DashboardTableRow[] = useMemo(
    () =>
      stats.map(
        (line): DashboardTableRow => {
          return {
            date: formatDate(line.period.startDate),
            operator: line.operatorId,
            carrier: line.carrierId,
            activePackCount: line.activePackCount,
            bonusTotal: line.bonusTotal,
            bonusGroupStatus: line.status as BonusGroupStatus,
            area: line.areaName,
            funderRepartition: computeFunderRepartition(line, organizationRef)
          }
        }
      ),
    [stats, organizationRef]
  )

  const columns = useMemo(
    () => [
      {
        name: `${t('app_domain_date')} `,
        cell: (row: DashboardTableRow) => (
          <PrimaryTypography
            data-tag='___react-data-table-allow-propagation___'
            align='left'
          >
            {row.date}
          </PrimaryTypography>
        )
      },
      ...(!isOperator
        ? [
            {
              name: `${t('app_domain_bonus_operateur')} `,
              cell: (row: DashboardTableRow) => (
                <PrimaryTypography
                  data-tag='___react-data-table-allow-propagation___'
                  align='left'
                >
                  {organizationRef.get(row.operator)?.displayName}
                </PrimaryTypography>
              )
            }
          ]
        : []),
      ...(!isCarrier
        ? [
            {
              name: `${t('app_domain_bonus_chargeur')} `,
              cell: (row: DashboardTableRow) => (
                <PrimaryTypography
                  data-tag='___react-data-table-allow-propagation___'
                  align='left'
                >
                  {organizationRef.get(row.carrier)?.displayName}
                </PrimaryTypography>
              )
            }
          ]
        : []),
      {
        name: `${t('app_dashboard_territory')} `,
        cell: (row: DashboardTableRow) => (
          <PrimaryTypography
            data-tag='___react-data-table-allow-propagation___'
            align='left'
          >
            {row.area}
          </PrimaryTypography>
        )
      },
      {
        name: `${t('app_domain_organization_financeur')} `,
        cell: (row: DashboardTableRow) => {
          const firstPart = row.funderRepartition
            .slice(0, 2)
            .map((funder) => funder.funderName)
            .join(', ')
          const secondPart = row.funderRepartition
            .slice(2)
            .map((funder) => funder.funderName)
          const secondPartExist = secondPart.length > 0
          return (
            <PrimaryTypography
              data-tag='___react-data-table-allow-propagation___'
              align='left'
            >
              <Box display='flex' flexDirection='column' alignItems='center'>
                {secondPartExist ? (
                  <>
                    {firstPart}
                    <span
                      className={classes.infoText}
                      data-tag='___react-data-table-allow-propagation___'
                    >
                      Plus {secondPart.length}
                      <InfoBox
                        className={classes.infoIcon}
                        content={
                          <Typography
                            variant='body2'
                            className={classes.internText}
                            data-tag='___react-data-table-allow-propagation___'
                          >
                            {secondPart.join(', ')}
                          </Typography>
                        }
                      />
                    </span>
                  </>
                ) : (
                  firstPart
                )}
              </Box>
            </PrimaryTypography>
          )
        }
      },
      {
        name: `${t('app_domain_package_count')} `,
        cell: (row: DashboardTableRow) => (
          <PrimaryTypography
            data-tag='___react-data-table-allow-propagation___'
            align='left'
          >
            {row.activePackCount}
          </PrimaryTypography>
        )
      },
      {
        name: `${t('app_domain_bonus_total')} `,
        cell: (row: DashboardTableRow) => (
          <PrimaryTypography
            data-tag='___react-data-table-allow-propagation___'
            align='left'
          >
            <Box
              display='flex'
              alignItems='center'
              data-tag='___react-data-table-allow-propagation___'
            >
              <InfoBox
                className={classes.infoIcon}
                content={
                  <Box
                    display='flex'
                    flexDirection='column'
                    data-tag='___react-data-table-allow-propagation___'
                  >
                    {row.funderRepartition.map((funderRepar) => (
                      <Typography
                        variant='body2'
                        className={classes.internText}
                        key={'funderRepartiotion-' + funderRepar.funderId}
                        data-tag='___react-data-table-allow-propagation___'
                      >
                        {displayNumber(funderRepar.fundedAmount)} € financé par{' '}
                        {funderRepar.funderName}
                      </Typography>
                    ))}
                  </Box>
                }
              />
              {displayNumber(row.bonusTotal)} €
            </Box>
          </PrimaryTypography>
        )
      },
      {
        name: t('app_domain_bonus-targeted_status'),
        cell: (row: DashboardTableRow) => (
          <StatusTags {...getStatus(row.bonusGroupStatus)} />
        )
      }
    ],
    []
  )

  return (
    <Table
      data={data}
      columns={columns}
      className={classes.container}
      onRowClicked={onRowClicked}
      handlePageChange={handlePageChange}
      totalPages={totalPages}
      isLoading={isLoading}
      page={page}
      noDataMessage={t('app_no_data_bonus_table')}
    />
  )
}
