import { makeStyles, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { TitleHeadBar } from "components";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
  help: {
    marginLeft: 10,
    marginTop: 5,
  },
});

const useTooltipStyles = makeStyles({
  arrow: {
    color: "#007DCE",
  },
  tooltip: {
    backgroundColor: "#007DCE",
    fontSize: 14,
    minWidth: 450,
    fontFamily: '"Montserrat", Arial !important',
  },
});

interface BonusEditHeadBarProps {
  bonusId: string;
}

const BonusEditHeadBar = (props: BonusEditHeadBarProps) => {
  const { bonusId } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();

  return (
    <div className={classes.container}>
      <TitleHeadBar title={t("app_bonus_page_edit_title", { bonusId })} />
      <Tooltip
        title={t("app_bonus_page_edit_tooltip") ?? ""}
        classes={tooltipClasses}
        arrow
      >
        <Help />
      </Tooltip>
    </div>
  );
};

const Help = React.forwardRef((props: any, ref: any) => {
  const classes = useStyles();

  return (
    <div {...props} className={classes.help} ref={ref}>
      <Info style={{ color: "rgb(33, 150, 243)" }} />
    </div>
  );
});

export default BonusEditHeadBar;
