import { OrganizationDetailsFormValidity } from "../../../../components/OrganizationDetailsForm/model";
import { PaymentInfoFormValidity } from "../../../../components/PaymentInfoForm/model";
import { OrganizationFormState } from "./model";
import {
  OrganizationDetailsValidation,
  defaultOrganizationDetailsFormValidity,
} from "../../../../components/OrganizationDetailsForm/validation";
import {
  defaultPaymentInfoFormValidity,
  PaymentInfoValidation,
} from "../../../../components/PaymentInfoForm/validation";

const isOrganizationValid = (
  detailsValidation: OrganizationDetailsFormValidity,
  paymentValidation: PaymentInfoFormValidity,
  showPayment: boolean
) => {
  return (
    OrganizationDetailsValidation.isFormValid(detailsValidation) &&
    (PaymentInfoValidation.isFormValid(paymentValidation) || !showPayment)
  );
};

export const isOrganizationFormValid = (
  showPayment: boolean,
  validDetails: boolean,
  organizationForm: OrganizationFormState
): OrganizationValidation => {
  const { details, type } = organizationForm.details;
  const { payment } = organizationForm;

  const detailsValidation = validDetails
    ? OrganizationDetailsValidation.validation(details, type)
    : defaultOrganizationDetailsFormValidity;
  const paymentValidation = showPayment
    ? PaymentInfoValidation.validation(payment)
    : defaultPaymentInfoFormValidity;

  const isValid = isOrganizationValid(
    detailsValidation,
    paymentValidation,
    showPayment
  );

  return {
    details: detailsValidation,
    payment: paymentValidation,
    isValid: isValid,
  };
};

export interface OrganizationValidation {
  details: OrganizationDetailsFormValidity;
  payment: PaymentInfoFormValidity;
  isValid: boolean;
}
