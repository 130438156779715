import store from "store";
import { alert } from "store/alert";
import { Alert } from "../organization";
import { getAlertSeverity } from "./AlertSeverity";

export const errorsHandler = (errorMessage: string, status: number) => {
  let source = "à cause d'un problème venant du serveur";
  if (status > 400 && status < 500)
    source = "à cause d'un problème venant de votre machine";
  if (status === 522) source = "à cause d'un problème de connexion";
  const alertobj: Alert = {
    date: new Date().toDateString(),
    description: `${errorMessage} ${source}`,
    payload: {},
    severity: getAlertSeverity("error"),
    type: "",
  };
  store.dispatch(alert.actions.add(alertobj));
};

export const customErrorHandler = (errorMessage: string) => {
  const alertobj: Alert = {
    date: new Date().toDateString(),
    description: `${errorMessage}`,
    payload: {},
    severity: getAlertSeverity("error"),
    type: "",
  };
  store.dispatch(alert.actions.add(alertobj));
};

export const successHandler = (successMessage?: string) => {
  const alertobj: Alert = {
    date: new Date().toDateString(),
    description: successMessage ? successMessage : "",
    payload: {},
    severity: getAlertSeverity("success"),
    type: "",
  };
  store.dispatch(alert.actions.add(alertobj));
};
