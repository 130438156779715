import { createActionCreator } from "deox";
import { CarrierReport } from "../../api/report";

const createCarrierReport = createActionCreator(
  "CARRIER_REPORT_CREATE",
  (resolve) => (report: CarrierReport) => resolve({ report })
);
const updateCarrierReport = createActionCreator(
  "CARRIER_REPORT_UPDATE",
  (resolve) => (report: CarrierReport) => resolve({ report })
);


const resetCarrierReport = createActionCreator("CARRIER_REPORT_RESET");

const fetchReport = createActionCreator(
  "PROJECT_FETCH",
  (resolve) => (reportId: string) => resolve({ reportId: reportId })
);
const fetchedReport = createActionCreator(
  "PROJECT_FETCHED",
  (resolve) => (carrierReport: CarrierReport) =>
    resolve({ carrierReport: carrierReport })
);
export const actions = {
  createCarrierReport: createCarrierReport,
  resetCarrierReport: resetCarrierReport,
  fetchReport: fetchReport,
  fetchedReport: fetchedReport,
  updateCarrierReport: updateCarrierReport
};
