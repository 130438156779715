import React from 'react'
import { ReactComponent } from './circleBicycleIcon.svg'
import { MergeReactElementProps } from '../../types'

interface CircleBicycleIconProps {
  color?: string
}

type Props = MergeReactElementProps<'svg', CircleBicycleIconProps>

export const CircleBicycleIcon = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { color = '#9a9a9a' } = props
    return <ReactComponent stroke={color} ref={ref} {...props} />
  }
)
