import { buildBonusGroupApiUrl } from "api/configuration";
import { city } from "plateform-bonus-group-domain";
import { http } from "../../http";

export interface BonusGroupStatusUpdatedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupStatusUpdatedEvent {
  type: string;
}
export const publishBonusGroup = async (
  bonusGroupId: string
): Promise<BonusGroupStatusUpdatedEvent> => {
  return http.put(
    buildBonusGroupApiUrl(`/bonusgroup/${bonusGroupId}/publish`),
    null,
    "La prime a bien été soumise pour validation"
  );
};
