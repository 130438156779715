import { Bonus } from "api/bonus";
import { city as cityBonusGroup } from "plateform-bonus-group-domain";
import { city as cityPlat } from "plateform-plateform-commons";
import { createBonusGroupIncident } from "./command/createBonusGroupIncident";
import { createInvoice } from "./command/createInvoice";
import { deduceBonusGroup } from "./command/deduceBonusGroup";
import { deleteInvoice } from "./command/deleteInvoice";
import { publishBonusGroup } from "./command/publishBonusGroup";
import { rejectBonusGroup } from "./command/rejectBonusGroup";
import { resolveBonusGroupIncident } from "./command/resolveBonusGroupIncident";
import { updateInvoice } from "./command/updateInvoice";
import { updateInvoiceDetails } from "./command/updateInvoiceDetails";
import { validateBonusGroup } from "./command/validateBonusGroup";
import { fetchBonusGroupFundersQuery } from "./query/fetchBonusFundersQuery";
import { fetchBonusGroupPageQuery } from "./query/fetchBonusPageQuery";
import { fetchBonusGroupQuery } from "./query/fetchBonusQuery";

export interface BonusGroupPage
  extends cityPlat.colisactiv.platform.commons.Page<BonusGroup> {}

export interface BonusGroup
  extends cityBonusGroup.colisactiv.plateform.bonus.group.model.BonusGroup {
  bonuses: Bonus[];
}

export interface BonusGroupIncident
  extends cityBonusGroup.colisactiv.plateform.bonus.group.model
    .BonusGroupIncident {}

export interface BonusGroupIncidentStatus
  extends cityBonusGroup.colisactiv.plateform.bonus.group.model
    .BonusGroupIncidentStatus {}

export const BonusGroupIncidentStatusValues = new cityBonusGroup.colisactiv.plateform.bonus.group.model.BonusGroupIncidentStatusValues();

export interface BonusGroupIncidentType
  extends cityBonusGroup.colisactiv.plateform.bonus.group.model
    .BonusGroupIncidentType {}
export const BonusGroupIncidentTypeValues = new cityBonusGroup.colisactiv.plateform.bonus.group.model.BonusGroupIncidentTypeValues();

export interface BonusGroupAction
  extends cityBonusGroup.colisactiv.plateform.bonus.group.model
    .BonusGroupAction {}

export const BonusGroupStateMachine = new cityBonusGroup.colisactiv.plateform.bonus.group.model.BonusGroupStateMachine();

export interface BonusGroupInvoice
  extends cityBonusGroup.colisactiv.plateform.bonus.group.model
    .BonusGroupInvoice {}

export class BonusGroupInvoiceTypeValues extends cityBonusGroup.colisactiv
  .plateform.bonus.group.model.BonusGroupInvoiceTypeValues {}

export interface BonusGroupInvoiceType
  extends cityBonusGroup.colisactiv.plateform.bonus.group.model
    .BonusGroupInvoiceType {}

export interface BonusGroupFunder
  extends cityBonusGroup.colisactiv.plateform.bonus.group.model
    .BonusGroupFunder {}

export interface BonusGroupFunderInvoiceDetails
  extends cityBonusGroup.colisactiv.plateform.bonus.group.model
    .BonusGroupFunderInvoiceDetails {}

export const bonusGroupClient = {
  createInvoice: createInvoice,
  updateInvoice: updateInvoice,
  updateInvoiceDetails: updateInvoiceDetails,
  deleteInvoice: deleteInvoice,
  createBonusGroupIncident: createBonusGroupIncident,
  resolveBonusGroupIncident: resolveBonusGroupIncident,
  publishBonusGroup: publishBonusGroup,
  rejectBonusGroup: rejectBonusGroup,
  validateBonusGroup: validateBonusGroup,
  deduceBonusGroup: deduceBonusGroup,
  query: {
    fetchBonusGroup: fetchBonusGroupQuery,
    fetchBonusGroupPage: fetchBonusGroupPageQuery,
    fetchBonusGroupFunders: fetchBonusGroupFundersQuery,
  },
};
