import React, { useEffect, useState } from "react";
import {
  CampaignActionButton,
  FormButtons,
  HeadBarProps,
  HeadBarView,
  MenuItemAction,
  SectionForm,
} from "components";
import { makeStyles } from "@material-ui/core";
import {
  CampaignValidation,
  defaultCampaignFormValidity,
} from "app/components/CampaingForm/validation";
import { useParams } from "react-router-dom";
import CampaignForm from "app/components/CampaingForm";
import { Campaign, ProjectStats } from "api/project/model";
import { OrganizationRef } from "api/organization";
import withConnect from "./withConnect";
import { CampaignStatusUtils } from "api/project/model/CampaignStatus";
import {
  CampaignActionsUtils,
  CampaignsActionProps,
} from "api/project/model/CampaignActions";
import { CampaignFormModel } from "app/components/CampaingForm/Model";

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: 8,
  },
}));

interface CampaignViewPageProps extends CampaignsActionProps {
  project: ProjectStats | null;
  campaign: Campaign | null;
  operatorRefs: Map<string, OrganizationRef>;
  funderRefs: Map<string, OrganizationRef>;
  setHeadBar: (title: HeadBarProps) => void;
  fetchCampaign: (projectId: string, campaignId: string) => void;
}

const CampaignViewPage = (props: CampaignViewPageProps) => {
  const {
    project,
    setHeadBar,
    operatorRefs,
    fetchCampaign,
    campaign,
    funderRefs,
  } = props;
  const { projectId, campaignId } = useParams();

  useEffect(() => {
    project &&
      campaign &&
      setHeadBar({
        title: `${campaign.details.name}`,
        component: (
          <HeadBarView
            title={campaign.details.name}
            secondaryTitle={project.details.name}
            status={CampaignStatusUtils.getLabelStyleStatusTags(
              campaign.status
            )}
            startDate={campaign.details.period.startDate}
            endDate={campaign.details.period.endDate}
          />
        ),
      });
  }, [campaign, projectId, campaignId]);
  const classes = useStyles();
  const [campaignForm, setCampaignForm] = useState(
    CampaignFormModel.defaultCampaignForm
  );

  useEffect(() => {
    campaign &&
      setCampaignForm({
        ...campaignForm,
        ...campaign,
        projectId: projectId,
        validation: {
          validity: defaultCampaignFormValidity,
          campaignFundersValidation: [],
          bonusPriceValidation: [],
          rulesValidation: [],
        },
      });
  }, [campaign, projectId, campaignId]);

  useEffect(() => {
    fetchCampaign(projectId, campaignId);
  }, [projectId, campaignId]);

  const actionsLink = !!campaign
    ? CampaignActionsUtils.getMenuItems(campaign, props, ["VIEW"])
    : [];
  return (
    <SectionForm withGoBack>
      <CampaignForm
        project={project}
        campaign={campaignForm}
        onCampaignChange={setCampaignForm}
        readonly={true}
        operatorRefs={operatorRefs}
        funderRefs={funderRefs}
      />
      <FormButtons>
        {actionsLink.map((action: MenuItemAction) => (
          <CampaignActionButton action={action} className={classes.button} />
        ))}
      </FormButtons>
    </SectionForm>
  );
};

export default withConnect(CampaignViewPage);
