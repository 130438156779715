import { Box, makeStyles } from "@material-ui/core";
import { OrganizationRef } from "api/organization";
import {
  defaultProjectFormState,
  ProjectFormState,
} from "app/components/ProjectForm/model";
import { ProjectValidation } from "app/components/ProjectForm/validation";
import {
  BackButton,
  Button,
  CancelButton,
  HeadBarProps,
  PageForm,
} from "components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProjectForm from "../../../components/ProjectForm";
import withConnect from "./withConnect";

const useStyles = makeStyles(() => ({
  button: {
    margin: "0 10px",
  },
  form: {
    position: "relative",
    paddingBottom: 100,
    marginLeft: "0px",
  },
  buttons: {
    position: "absolute",
    right: 50,
    marginTop: 50,
  },
}));

interface ProjectCreatePageProps {
  setHeadBar: (headBar: HeadBarProps) => void;
  createProject: (form: ProjectFormState) => void;
  funderRefs: Map<string, OrganizationRef>;
  gotoProjectsView: (
    page: number,
    size: number,
    name?: string,
    status?: string
  ) => void;
}

const ProjectCreatePage = (props: ProjectCreatePageProps) => {
  const { setHeadBar, createProject, funderRefs, gotoProjectsView } = props;
  const classes = useStyles();

  const [projectState, setProject] = useState<ProjectFormState>(
    defaultProjectFormState
  );

  const handleProjectCreation = async () => {
    const [isValid, validation] = ProjectValidation.getFormValidation(
      projectState
    );
    if (!isValid) {
      return setProject({ ...projectState, validity: validation });
    }
    await createProject(projectState);
  };
  const { t } = useTranslation();
  return (
    <PageForm headBar={{ title: "Créer un projet" }} setHeadBar={setHeadBar}>
      <>
        <BackButton />
        <ProjectForm
          project={projectState}
          readOnly={false}
          onProjectChange={setProject}
          funderRefs={funderRefs}
          mode={"add"}
        />
        <Box className={classes.buttons}>
          <CancelButton onClick={() => gotoProjectsView(0, 10)}>
            {t("app_project_create_cancel")}
          </CancelButton>
          <Button className={classes.button} onClick={handleProjectCreation}>
            {t("app_project_create_create")}
          </Button>
        </Box>
      </>
    </PageForm>
  );
};

export default withConnect(ProjectCreatePage);
