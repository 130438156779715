import { push } from "connected-react-router";

export type UserSection = "general" | "documents";
export type UsersSectionMode = "list" | "view" | "edit" | "add";

const userSection = (
  userId: string,
  section: UserSection,
  mode: UsersSectionMode
) => push(`/users/${userId}/${section}/${mode}`);

const userSectionView = (userId: string, section: UserSection) =>
  userSection(userId, section, "view");

const userView = (userId: string) => userSection(userId, "general", "view");

const userEdit = (userId: string) => userSection(userId, "general", "edit");

export const gotoUser = {
  userSectionView: userSectionView,
  userSection: userSection,
  usersView: userView,
  userEdit: userEdit,
};
