import { connect } from "react-redux";
import { router } from "store/router";
import { State } from "../../../../store";
import { report } from "store/report";
import { profile } from "store/profile";
import { global } from "store/global";

const mapStateToProps = (state: State) => ({
  currentUser: profile.selectors.User(state),
  carriers: global.selectors.carrierRefs(state),
  organizationRefs: global.selectors.organizationRefs(state),
  territories: global.selectors.projectTerritories(state),
});

const mapDispatchToProps = {
  addCarrierReport: router.goto.report.reportAdd,
  resetReport: report.commands.resetReport,
  gotoReportView: router.goto.report.reportView,
  gotoReportsList: router.goto.report.reportsList,
};

export default connect(mapStateToProps, mapDispatchToProps);
