import React from 'react'
import { Paper, makeStyles, Box, IconButton, Badge } from '@material-ui/core'
import * as R from 'ramda'
import { HeadlineBox } from '../HeadlineBox'
import { TextButton } from '../TextButton'
import { PinIcon, UnPinIcon } from '../../Icons'
import { useTranslation } from 'react-i18next'
import { Close } from '../../Icons/Close'

const useStyles = makeStyles(() => ({
  container: {
    background: 'white',
    width: '100%',
    borderRadius: '5px',
    position: 'relative',
    display: 'flex'
  },
  clickableContainer: {
    width: 'calc(100% - 10px)',
    minHeight: 'calc(100% - 20px)',
    cursor: 'pointer',
    padding: '5px',
    paddingLeft: '5px',
    borderRadius: '5px',
    '&:hover': {
      background: '#F9FAFC'
    },
    '&:hover p': {
      whiteSpace: 'normal',
      overflow: 'auto'
    }
  },
  children: {
    minWidth: '0',
    flexGrow: 1,
    minHeight: '100%',
    padding: '8px'
  },
  severityBar: {
    width: '3px',
    minWidth: '3px',
    borderRadius: '30px',
    height: '100%',
    marginRight: '5px'
  },
  headlineBox: {
    maxWidth: '100%',
    '& .colisactivHeadlineBox-header': {
      fontWeight: 400,
      lineHeight: '20px'
    },
    '& .colisactivHeadlineBox-content': {
      color: '#98A5AE'
    }
  }
}))

export type NotificationSeverity =
  | 'important'
  | 'warning'
  | 'information'
  | 'success'
  | 'unset'

interface NotificationProps {
  children: React.ReactNode
  icon: React.ReactNode
  onClick?: () => void
  onRemove?: () => void
  onPin?: () => void
  onUnpin?: () => void
  isPinned?: boolean
  className?: string
  date?: string
  style?: React.CSSProperties
  id?: string
  severity?: NotificationSeverity
  unSeen?: boolean
  backgroundColor?: string
}

const propsAreEqual = (
  prevProps: NotificationProps,
  nextProps: NotificationProps
) => {
  return (
    R.equals(prevProps.onClick, nextProps.onClick) &&
    R.equals(prevProps.onRemove, nextProps.onRemove) &&
    R.equals(prevProps.onPin, nextProps.onPin) &&
    R.equals(prevProps.onUnpin, nextProps.onUnpin) &&
    R.equals(prevProps.isPinned, nextProps.isPinned) &&
    R.equals(prevProps.className, nextProps.className) &&
    R.equals(prevProps.className, nextProps.className) &&
    R.equals(prevProps.style, nextProps.style) &&
    R.equals(prevProps.id, nextProps.id) &&
    R.equals(prevProps.severity, nextProps.severity) &&
    R.equals(prevProps.children, nextProps.children)
  )
}

export const Notification = React.memo(
  React.forwardRef((props: NotificationProps, forwardedRef) => {
    const {
      children,
      icon,
      onRemove,
      onClick,
      className,
      style,
      onPin,
      onUnpin,
      isPinned,
      id,
      severity = 'unset',
      date,
      unSeen = false,
      backgroundColor
    } = props
    const classes = useStyles()
    const { t } = useTranslation()

    const background =
      backgroundColor ?? severity !== 'unset'
        ? `${
            severity === 'important'
              ? 'rgb(244, 67, 54)'
              : severity === 'information'
              ? 'rgb(33, 150, 243)'
              : severity === 'success'
              ? 'rgb(76, 175, 80)'
              : 'rgb(255, 152, 0)'
          }`
        : ''

    return (
      <Paper
        onClick={onClick}
        className={`${classes.container} ${className} notification-mainContainer`}
        ref={forwardedRef}
        style={style}
        id={id}
      >
        <Box
          display='flex'
          alignItems='center'
          className={`${classes.clickableContainer} notification-bodyContainer`}
        >
          <div
            className={classes.severityBar}
            style={{
              background: background
            }}
          ></div>
          {icon && (
            <Box
              height='100%'
              display='flex'
              width='50px'
              minWidth='50px'
              alignItems='center'
              justifyContent='center'
            >
              <Badge color='error' variant='dot' invisible={!unSeen}>
                {icon}
              </Badge>
            </Box>
          )}
          <Box display='flex' alignItems='center' className={classes.children}>
            <HeadlineBox
              header={children}
              className={classes.headlineBox}
              size='medium'
            >
              {date}
            </HeadlineBox>
          </Box>
          <Box
            display='flex'
            right='10px'
            className='notification-actionContainer'
          >
            {isPinned
              ? onUnpin && (
                  <TextButton
                    onClick={(event) => {
                      event.stopPropagation()
                      onUnpin()
                    }}
                  >
                    <UnPinIcon
                      style={{
                        marginRight: '4px',
                        width: '25px',
                        height: '25px'
                      }}
                      color='#98A5AE'
                    />
                    {t('app_notification_unpin')}
                  </TextButton>
                )
              : onPin && (
                  <TextButton
                    onClick={(event) => {
                      event.stopPropagation()
                      onPin()
                    }}
                  >
                    <PinIcon
                      style={{
                        marginRight: '4px',
                        width: '20px',
                        height: '20px'
                      }}
                      color='#98A5AE'
                    />
                    {t('app_notification_pin')}
                  </TextButton>
                )}
            {onRemove && (
              <IconButton
                style={{ marginLeft: '4px' }}
                onClick={(event) => {
                  event.stopPropagation()
                  onRemove()
                }}
              >
                <Close
                  style={{ width: '17px', height: '17px' }}
                  color='#98A5AE'
                />
              </IconButton>
            )}
          </Box>
        </Box>
      </Paper>
    )
  }),
  propsAreEqual
)
