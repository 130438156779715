import { GeoLocation } from "api/geoLocation";
import pickupIcon from "components/src/Icons/Pickup/icon-pickup.svg";
import { FullscreenControl, GeoJSONSourceRaw } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as React from "react";
import ReactMapboxGl, { Layer, Source } from "react-mapbox-gl";
import { MapEvent } from "react-mapbox-gl/lib/map-events";
import { apiMapboxAccessToken } from "../../../api/configuration";
import { DeliveryLocation } from "../../../api/poidashboard";
import {
  deliveryLocationToFeatureCollection,
  geoLocationToFeatureCollection,
  getBounds,
} from "./utils";

const styles = {
  londonCycle: "mapbox://styles/mapbox/light-v9",
  light: "mapbox://styles/mapbox/light-v9",
  dark: "mapbox://styles/mapbox/dark-v10",
  basic: "mapbox://styles/mapbox/basic-v9",
  outdoor: "mapbox://styles/mapbox/outdoors-v10",
  street: "mapbox://styles/mapbox/streets-v11",
};

const Map = ReactMapboxGl({
  accessToken: apiMapboxAccessToken,
});

const mapStyle = {
  width: "500px",
  height: "282px",
  marginLeft: "30px",
};

interface HeatmapProps {
  onStyleLoad?: (map: any) => any;
  pickupPoints: GeoLocation[];
  areaGeolocations?: GeoLocation[];
  deliveryPoints: DeliveryLocation[];
}

const layerPaint = {
  "heatmap-weight": ["interpolate", ["linear"], ["get", "nbColis"], 0, 0, 6, 1],
  "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 3],
  "heatmap-color": [
    "interpolate",
    ["linear"],
    ["heatmap-density"],
    0,
    "rgba(33,102,172,0)",
    0.1,
    "rgb(103,169,207)",
    0.3,
    "rgb(209,229,240)",
    0.5,
    "rgb(253,219,199)",
    0.7,
    "rgb(239,138,98)",
    1,
    "rgb(178,24,43)",
  ],
  "heatmap-radius": {
    stops: [
      [0, 4],
      [4, 8],
    ],
  },
  "heatmap-opacity": 0.7,
};

export const Heatmap = (props: HeatmapProps) => {
  const { pickupPoints, deliveryPoints, areaGeolocations } = props;

  const allLocations: GeoLocation[] = [];
  deliveryPoints.map((deliveryPoint) => {
    allLocations.push(deliveryPoint.location);
  });

  const bounds =
    pickupPoints.length <= 0 &&
    allLocations.length <= 0 &&
    areaGeolocations &&
    areaGeolocations.length > 0
      ? getBounds(areaGeolocations)
      : getBounds(allLocations.concat(pickupPoints));

  const pickupPointsSource: GeoJSONSourceRaw = {
    data: geoLocationToFeatureCollection(pickupPoints),
    type: "geojson",
  };

  const deliveryPointsSource: GeoJSONSourceRaw = {
    data: deliveryLocationToFeatureCollection(deliveryPoints),
    type: "geojson",
  };

  const pickupImg = new Image(25, 25);
  pickupImg.src = pickupIcon;

  const onStyleLoad: MapEvent = (map) => {
    const { onStyleLoad } = props;

    map.addImage("pickup-point", pickupImg);
    map.addControl(new FullscreenControl());

    return onStyleLoad && onStyleLoad(map);
  };

  return (
    <Map
      style={styles.street}
      fitBounds={[bounds.southWest, bounds.northEast]}
      fitBoundsOptions={{ padding: 15 }}
      containerStyle={mapStyle}
      onStyleLoad={onStyleLoad}
    >
      <Source id="heatmap" geoJsonSource={deliveryPointsSource} />
      <Layer type="heatmap" sourceId="heatmap" paint={layerPaint} />
      <Source id="pickup" geoJsonSource={pickupPointsSource} />
      <Layer
        type="symbol"
        layout={{ "icon-image": "pickup-point" }}
        sourceId="pickup"
      />
    </Map>
  );
};
