import React, { ChangeEvent, useContext } from 'react'

import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { themeContext, Theme } from '@smartb/archetypes-ui-components'

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    root: {
      borderRadius: 20
    },
    container: {
      marginLeft: '0px',
      marginRight: '0px',
      '& .MuiTypography-root': {
        fontSize: '14px'
      },
      '& .MuiButtonBase-root:hover': {
        background: 'transparent'
      }
    },
    icon: {
      color: theme.primaryColor
    },
    iconSize: {
      width: '22px',
      height: '22px'
    }
  }))

interface CheckBoxProps {
  checked: boolean
  text?: string
  disabled?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  value?: string
  className?: string
  style?: React.CSSProperties
  uncheckedIconClassName?: string
  checkedIconClassName?: string
  checkedIconStyle?: React.CSSProperties
}

export const CheckBox = (props: CheckBoxProps) => {
  const {
    checked = false,
    text = '',
    disabled = false,
    onChange,
    value = '',
    className,
    style,
    uncheckedIconClassName,
    checkedIconClassName,
    checkedIconStyle
  } = props
  const theme = useContext(themeContext)
  const classes = useStyles(theme)()

  return (
    <FormControlLabel
      value={value}
      className={`${classes.container} ${className}`}
      style={style}
      control={
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          className={`${classes.root}`}
          style={{ cursor: !onChange ? 'initial' : '' }}
          disableRipple
          icon={
            <CheckBoxOutlineBlankIcon
              className={`${uncheckedIconClassName} ${classes.iconSize}`}
            />
          }
          checkedIcon={
            <CheckBoxIcon
              className={`${classes.icon} ${classes.iconSize} ${checkedIconClassName}`}
              style={checkedIconStyle}
            />
          }
        />
      }
      label={text}
      labelPlacement='end'
    />
  )
}
