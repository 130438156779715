import { makeStyles } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { TFunction } from 'i18next'
import React, { Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Option } from '../../Component/Select'
import { InputForm } from '../../Component/InputForm'

const useStyles = makeStyles(() => ({
  filter: {
    width: '200px',
    marginRight: '10px',
    ['@media (max-width: 800px)']: {
      width: '100% !important',
      marginRight: '0 !important'
    },
    '& .MuiOutlinedInput-root': {
      height: '44px !important'
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
      marginTop: '0px'
    },
    '& .MuiInputLabel-filled ': {
      transform: ' translate(12px, 25px) scale(1)'
    }
  },
  icon: {
    color: '#9a9a9a',
    cursor: 'pointer'
  }
}))

const statusOptions = (t: TFunction): Option[] => [
  {
    label: t('app_campaign_status_created') as string,
    value: 'CREATED'
  },
  {
    label: t('app_campaign_status_published') as string,
    value: 'PUBLISHED'
  },
  {
    label: t('app_campaign_status_started') as string,
    value: 'STARTED'
  },
  {
    label: t('app_campaign_status_paused') as string,
    value: 'PAUSED'
  },
  {
    label: t('app_campaign_status_done') as string,
    value: 'DONE'
  },
  {
    label: t('app_campaign_status_archived') as string,
    value: 'ARCHIVED'
  }
]

interface CampaignFiltersProps {
  searchCampaign: string
  setSearchCampaign: (value: string) => void
  campaignStatus: string
  setCampaignStatus: (value: string) => void
}

export const CampaignFilters = (props: CampaignFiltersProps) => {
  const {
    searchCampaign,
    setSearchCampaign,
    campaignStatus,
    setCampaignStatus
  } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const onSearchCampaign = useCallback(
    (value: string) => setSearchCampaign(value),
    [setSearchCampaign]
  )

  const onRemoveCampaign = useCallback(() => setSearchCampaign(''), [
    setSearchCampaign
  ])

  const onRemoveCampaignStatus = useCallback(() => setCampaignStatus(''), [
    setCampaignStatus
  ])

  const onChangeCampaignStatus = useCallback(
    (value) => setCampaignStatus(value),
    [setCampaignStatus]
  )

  return (
    <Fragment>
      <InputForm
        id='project-filters-name'
        value={searchCampaign}
        placeHolder={t('app_domain_project_search')}
        inputType={'textField'}
        onChange={onSearchCampaign}
        className={`${classes.filter}`}
        inputIcon={<Search className={classes.icon} />}
        onRemoveValue={onRemoveCampaign}
      />
      <InputForm
        inputType={'select'}
        onChange={onChangeCampaignStatus}
        value={campaignStatus}
        selectOptions={statusOptions(t)}
        placeHolder={t('app_campaign_details_form_status')}
        className={`${classes.filter} project-filters`}
        id='project-filters-status'
        onRemoveValue={onRemoveCampaignStatus}
      />
    </Fragment>
  )
}

export default CampaignFilters
