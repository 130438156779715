import { createSelector } from "reselect";
import { State } from "store";
import { OrganizationRef } from "api/organization";
import * as R from "ramda";
import { Campaign } from "api/project/model";
import { Territory } from "api/geoZone";

const getOrganizationState = (state: State) => state.global;

export const getCampaigns = createSelector([getOrganizationState], (state) => {
  return !!state.campaigns ? state.campaigns : ([] as Campaign[]);
});

export const getAllTerritoriesProjects = createSelector(
  [getOrganizationState],
  (state) => {
    return !!state.projectTerritories
      ? state.projectTerritories
      : ([] as Territory[]);
  }
);

export const getOrganizationRefs = createSelector(
  [getOrganizationState],
  (state) => {
    return !!state.organizationRefs
      ? state.organizationRefs
      : new Map<string, OrganizationRef>();
  }
);

export const getFunderRefs = createSelector([getOrganizationState], (state) => {
  return !!state.funderRefs
    ? state.funderRefs
    : new Map<string, OrganizationRef>();
});

export const getOperatorRefs = createSelector(
  [getOrganizationState],
  (state) => {
    return !!state.operatorRefs
      ? state.operatorRefs
      : new Map<string, OrganizationRef>();
  }
);

export const getCarrier = createSelector([getOrganizationState], (state) => {
  return !!state.carrierRefs
    ? state.carrierRefs
    : new Map<string, OrganizationRef>();
});

export const selectors = {
  campaigns: getCampaigns,
  projectTerritories: getAllTerritoriesProjects,
  organizationRefs: getOrganizationRefs,
  funderRefs: getFunderRefs,
  operatorRefs: getOperatorRefs,
  carrierRefs: getCarrier,
};
