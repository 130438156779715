import {
  buildOrganizationDetailsForm,
  OrganizationDetailsFormState,
} from "../../../../components/OrganizationDetailsForm/model";
import {
  buildPaymentInfoForm,
  PaymentInfoFormState,
} from "../../../../components/PaymentInfoForm/model";
import { defaultOrganizationDetailsFormState } from "../../../../components/OrganizationDetailsForm/validation";
import { defaultPaymentInfoFormState } from "../../../../components/PaymentInfoForm/validation";
import { Organization } from "../../../../../api/organization";

export interface OrganizationFormState {
  details: OrganizationDetailsFormState;
  payment: PaymentInfoFormState;
}

export const defaultOrganizationFormState: OrganizationFormState = {
  details: defaultOrganizationDetailsFormState,
  payment: defaultPaymentInfoFormState,
};

export const buildOrganizationForm = (
  organization: Organization
): OrganizationFormState => {
  const organizationForm = buildOrganizationDetailsForm(organization);
  const paymentForm = buildPaymentInfoForm(organization);
  return {
    details: organizationForm,
    payment: paymentForm,
  };
};
