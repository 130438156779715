import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import { GenericWithId } from './TransferList'

const useStyles = makeStyles(() => ({
  listIcon: {
    minWidth: '35px'
  },
  listItemIconRight: {
    justifyContent: 'flex-end'
  }
}))

interface CustomItemProps<T> {
  item: GenericWithId<T>
  onSwitch?: (item: GenericWithId<T>) => void
  orientation: 'left' | 'right'
  itemIcon: React.ReactNode
  getItemTitle: (item: GenericWithId<T>) => string
}

export const CustomItem = function <T>(props: CustomItemProps<T>) {
  const { item, onSwitch, itemIcon, getItemTitle, orientation } = props
  const classes = useStyles()
  const title = useMemo(() => getItemTitle(item), [getItemTitle, item])
  const onSwitchMemoized = useCallback(() => onSwitch && onSwitch(item), [
    item,
    onSwitch
  ])
  return (
    <ListItem role='listitem' button onClick={onSwitchMemoized}>
      {onSwitch && orientation === 'right' && (
        <ListItemIcon className={classes.listIcon}>{itemIcon}</ListItemIcon>
      )}
      <ListItemText primary={title} />
      {onSwitch && orientation === 'left' && (
        <ListItemIcon
          className={`${classes.listIcon} ${classes.listItemIconRight}`}
        >
          {itemIcon}
        </ListItemIcon>
      )}
    </ListItem>
  )
}
