import { buildBonusGroupApiUrl } from "api/configuration";
import { city } from "plateform-bonus-group-domain";
import { http } from "../../http";

export interface CreateBonusGroupIncidentCommand
  extends city.colisactiv.plateform.bonus.group.model.features
    .CreateBonusGroupIncidentCommand {}

export interface CreateBonusGroupIncidentCommandDTO
  extends city.colisactiv.plateform.bonus.group.model.features
    .CreateBonusGroupIncidentCommandDTO {}

export interface BonusGroupIncidentCreatedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupIncidentCreatedEvent {}

const createBonusGroupIncidentCommand = (
  dto: CreateBonusGroupIncidentCommandDTO
): CreateBonusGroupIncidentCommand => {
  return {
    type: "UPDATE_BONUS_GROUP_STATUS",
    payload: dto,
  };
};

export const createBonusGroupIncident = (
  command: CreateBonusGroupIncidentCommandDTO
): Promise<BonusGroupIncidentCreatedEvent> => {
  const url = buildBonusGroupApiUrl(
    `/bonusgroup/${command.bonusGroupId}/incidents`
  );
  const body = JSON.stringify(createBonusGroupIncidentCommand(command));
  return http.post(url, body);
};
