import { BonusGroup } from "api/bonusGroup";
import { OrganizationRef } from "api/organization";
import BonusForm from "app/components/Bonus/BonusForm";
import {
  BonusFormDetailsState,
  BonusFormState,
  toForm,
} from "app/components/Bonus/BonusForm/model";
import { BonusValidation } from "app/components/Bonus/BonusForm/validation";
import {
  Button,
  CancelButton,
  FormButtons,
  HeadBarProps,
  SectionForm,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BonusEditHeadBar from "./BonusEditHeadBar";
import withConnect from "./withConnect";

interface BonusEditPageFormProps {
  bonus: BonusGroup | null;
  carriers: Map<string, OrganizationRef>;
  operators: Map<string, OrganizationRef>;
  fetchBonus: (bonusId: string, force?: boolean) => void;
  setHeadBar: (props: HeadBarProps) => void;
  updateBonus: (bonus: BonusFormDetailsState) => void;
  gotoBonusView: (bonusId: string) => void;
}

const BonusEditPage = (props: BonusEditPageFormProps) => {
  const {
    bonus,
    carriers,
    operators,
    fetchBonus,
    setHeadBar,
    updateBonus,
    gotoBonusView,
  } = props;
  const { t } = useTranslation();
  const [bonusState, setBonusState] = useState<BonusFormState>();

  const { bonusId } = useParams();

  useEffect(() => {
    fetchBonus(bonusId, false);
  }, [bonusId]);

  useEffect(() => {
    setHeadBar({
      title: "",
      component: <BonusEditHeadBar bonusId={bonusId} />,
    });
  }, []);

  useEffect(() => {
    if (!bonus) {
      return;
    }

    const bonusForm = toForm(bonus);
    const [, validation] = BonusValidation.validateForm(bonusForm);

    setBonusState((bonusState) => ({
      ...bonusState,
      ...bonusForm,
      validity: validation,
    }));
  }, [bonus]);

  const handleRedirection = useCallback(() => {
    gotoBonusView(bonusId);
  }, [bonusId]);

  const handleBonusUpdate = useCallback(async () => {
    const [isValid, validation] = BonusValidation.validateForm(bonusState!);

    if (!isValid) {
      return setBonusState({ ...bonusState!, validity: validation });
    }

    await updateBonus(bonusState!.details);
    handleRedirection();
  }, [bonusState]);

  if (!bonusState) {
    return null;
  }

  return (
    <SectionForm withGoBack>
      <BonusForm
        carriers={carriers}
        operators={operators}
        bonusState={bonusState}
        onBonusChange={setBonusState}
        mode="update"
      />

      <FormButtons>
        <CancelButton onClick={handleRedirection}>
          {t("app_page_bonus_create_cancel")}
        </CancelButton>
        <Button onClick={handleBonusUpdate}>Valider</Button>
      </FormButtons>
    </SectionForm>
  );
};

export default withConnect(BonusEditPage);
