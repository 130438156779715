import { DateInterval, Mode, CarrierReportStatus } from "../index";
import { buildCarrierReportApiUrl } from "../../configuration";
import { city } from "plateform-carrier-report-domain";
import { http } from "../../http";

export interface UpdateCarrierReportCommand
  extends city.colisactiv.platform.carrier.report.domain.features
    .UpdateCarrierReportCommand {}

export interface CarrierReportUpdatedEvent
  extends city.colisactiv.platform.carrier.report.domain.features
    .CarrierReportUpdatedEvent {}

export const CarrierReportUpdateCommand = (
  reportId: string,
  carrierId: string,
  period: DateInterval,
  territoryCode: string,
  activeMode: Mode,
  electricMode: Mode,
  thermalMode: Mode,
  status: CarrierReportStatus
): UpdateCarrierReportCommand => {
  return {
    type: "CREATE-CARRIER-REPORT",
    payload: {
      carrierReportId: reportId,
      carrierId: carrierId,
      period: period,
      territoryCode: territoryCode,
      activeMode: activeMode,
      electricMode: electricMode,
      thermalMode: thermalMode,
      status: status,
    },
  };
};

export const updateCarrierReportCommand = (
  reportId: string,
  carrierId: string,
  period: DateInterval,
  territoryCode: string,
  activeMode: Mode,
  electricMode: Mode,
  thermalMode: Mode,
  status: CarrierReportStatus
): Promise<CarrierReportUpdatedEvent> => {
  const statusMsg =
    status.value === "PUBLISHED"
      ? "Reporting crée avec succés"
      : "Le reporting à été enregistrer comme brouillon";
  return http.post(
    buildCarrierReportApiUrl(`/carrierReports/${reportId}`),
    JSON.stringify(
      CarrierReportUpdateCommand(
        reportId,
        carrierId,
        period,
        territoryCode,
        activeMode,
        thermalMode,
        electricMode,
        status
      )
    ),
    statusMsg
  );
};
