import { city as cityOrga } from "plateform-organization-domain";
import { createOrganization, updateOrganization } from "./service";
import { createApplication } from "./command/createApplication";
import { city as cityPlat } from "plateform-plateform-commons";
import { fetchOrganizationsPage } from "./query/getOrganizationsPageQuery";
import { updatePaymentInfo } from "./command/updatePaymentInfo";
import { getSecret } from "./query/getSecret";
import { updateApplicationSecret } from "./command/updateSecret";
import { getOrganization } from "./query/getOrganizationQuery";
import { fetchDocuSignUrl } from "./query/fetchDocuSignUrl";
import { fetchOrganizationsReferences } from "./query/getOrganizationsReferences";
import { deleteOrganizationCommand } from "./command/deleteOrganizationCommand";
import { prepareSignConvention } from "./command/prepareSignConvention";
import { fetchConventionFile } from "./query/fetchConventionFile";
import { fetchConvention } from "./query/fetchConvention";
import { resetConvention } from "./command/resetConvention";

export interface OrganizationPage
  extends cityPlat.colisactiv.platform.commons.Page<Organization> {}

export interface Alert extends cityPlat.colisactiv.platform.commons.Alert<{}> {}

export interface Organization
  extends cityOrga.colisactiv.organization.model.Organization {}

export interface OrganizationRef
  extends cityOrga.colisactiv.organization.model.references.OrganizationRef {
  profileImgUrl: string;
}

export interface OrganizationDetails
  extends cityOrga.colisactiv.organization.model.OrganizationDetails {}

export interface OrganizationType
  extends cityOrga.colisactiv.organization.model.OrganizationType {}

export const OrganizationTypeValues = new cityOrga.colisactiv.organization.model.OrganizationTypeValues();

export interface PaymentDetails
  extends cityOrga.colisactiv.organization.model.PaymentDetails {}

export interface Convention
  extends cityOrga.colisactiv.organization.model.Convention {}

export interface ConventionStatus
  extends cityOrga.colisactiv.organization.model.ConventionStatus {}

export const OrganizationStateMachine = new cityOrga.colisactiv.organization.model.OrganizationStateMachine();
export interface ConventionActions
  extends cityOrga.colisactiv.organization.model.ConventionActions {}
export const ConventionStatusValues = new cityOrga.colisactiv.organization.model.ConventionStatusValues();
export const ConventionStateMachine = new cityOrga.colisactiv.organization.model.ConventionStateMachine();

export const organizationClient = {
  createOrganization: createOrganization,
  updateOrganization: updateOrganization,
  updatePaymentInfo: updatePaymentInfo,
  createApplication: createApplication,
  updateApplicationSecret: updateApplicationSecret,
  prepareSignConvention: prepareSignConvention,
  resetConvention: resetConvention,
  deleteOrganizationCommand: deleteOrganizationCommand,
  query: {
    getOrganization: getOrganization,
    fetchOrganizationsPage: fetchOrganizationsPage,
    fetchOrganizationRefs: fetchOrganizationsReferences,
    getSecret: getSecret,
    fetchConventionFile: fetchConventionFile,
    fetchConvention: fetchConvention,
    fetchDocuSignUrl: fetchDocuSignUrl,
  },
};
