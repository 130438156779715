import React from "react";
import { AppRouter } from "./routes";
import withConnect from "./withConnect";
import AdminAppLayout from "./layout/AdminAppLayout";
import AdminHeadBar from "./layout/AdminHeadBar";
import AdminAlertHandler from "./layout/AdminAlertHandler";
import { useAuth } from "../auth/useAuth";
import Loading from "./components/Loading";

const App = () => {
  const [authService, keycloak] = useAuth();

  if (!authService.isAuthenticated(keycloak)) {
    return <Loading />;
  }

  return (
    <AdminAppLayout navBarContent={<AdminHeadBar />}>
      <>
        <AdminAlertHandler />
        <AppRouter />
      </>
    </AdminAppLayout>
  );
};

export default withConnect(App);
