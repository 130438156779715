import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { InputForm } from '../InputForm'
import { Search } from '@material-ui/icons'
import { Option } from '../Select'
import { themeContext, Theme } from '@smartb/archetypes-ui-components'
import { Territory } from './TerritorySelectorDialog'
import { useTranslation } from 'react-i18next'

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    icon: {
      color: 'rgba(0, 0, 0, 0.54)',
      cursor: 'pointer'
    },
    title: {
      fontWeight: 600,
      color: theme.secondaryColor,
      fontSize: '16px'
    }
  }))

interface ListHeaderProps {
  territories: Territory[]
  itemsNumber: number
  id: string
  onChangeName: (name: string) => void
  territoryId: string
  onChangeTerritory: (territory?: Territory) => void
  title: string
  loading: boolean
}

export const ListHeader = (props: ListHeaderProps) => {
  const {
    itemsNumber,
    id,
    onChangeTerritory,
    territories,
    territoryId,
    onChangeName,
    title,
    loading
  } = props
  const theme = useContext(themeContext)
  const classes = useStyles(theme)()
  const [name, setName] = useState('')
  const { t } = useTranslation()

  const onSearchName = useCallback(() => onChangeName(name), [
    onChangeName,
    name
  ])

  const onRemoveSearch = useCallback(() => {
    setName('')
    onChangeName('')
  }, [])

  const onRemoveTerritory = useCallback(() => onChangeTerritory(), [])

  const onChangeTerritoryMemoized = useCallback((territoryId: string) => {
    const territory = territories.find(
      (territory) => territory.id === territoryId
    )
    if (territory) onChangeTerritory(territory)
  }, [])

  const upHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onSearchName()
      }
    },
    [onSearchName]
  )

  useEffect(() => {
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keyup', upHandler)
    }
  }, [upHandler])

  const territoryOptions: Option[] = useMemo(
    () =>
      territories.map(
        (territory): Option => ({
          label: territory.name,
          value: territory.id
        })
      ),
    [territories]
  )

  return (
    <Box
      display='flex'
      flexDirection='column'
      padding='15px'
      height='180px'
      justifyContent='space-between'
    >
      <Typography className={classes.title} align='center' variant='h6'>
        {title}
      </Typography>
      <InputForm
        inputType='textField'
        id={`${id}-list-header-search-namey`}
        onChange={setName}
        value={name}
        placeHolder={t('app_campaign_form_bonus_price_row_search_zone_name')}
        inputIcon={<Search className={classes.icon} onClick={onSearchName} />}
        onRemoveValue={onRemoveSearch}
        readonly={loading}
      />
      <InputForm
        inputType='select'
        id={`${id}-list-header-select-territory`}
        selectOptions={territoryOptions}
        onChange={onChangeTerritoryMemoized}
        value={territoryId}
        placeHolder={t('app_campaign_form_bonus_price_row_search_territories')}
        onRemoveValue={onRemoveTerritory}
        readonly={loading}
      />
      <Typography variant='body2' align='right'>
        {t('app_campaign_form_bonus_price_row_zone_number', {
          zoneNumber: itemsNumber
        })}
      </Typography>
    </Box>
  )
}
