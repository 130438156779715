import { Button, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ArrowLeft } from '../../Icons'

const useStyles = makeStyles(() => ({
  pageSelectorContainer: {
    background: 'rgba(0, 0, 0, 0.6)',
    width: '155px',
    height: '40px',
    borderRadius: '5px',
    transition: '0.2s',
    zIndex: 1
  },
  pageSelectorButton: {
    display: 'inline',
    width: '45px',
    minWidth: '45px',
    height: '100%',
    color: 'white',
    borderRadius: '0px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.4)'
    }
  },
  pageSelectortext: {
    display: 'inline-block',
    width: '65px',
    height: '100%',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '20px',
    color: 'white'
  },
  pageSelectorArrow: {
   width:"100%",
   height:"100%"
  }
}))

interface PageSelectorProps {
  pageNumber: number
  setPageNumber: (newPageNumber: number) => void
  totalPages: number
  className?: string
  style?: React.CSSProperties
}

export const PageSelector = (props: PageSelectorProps) => {
  const { pageNumber, setPageNumber, totalPages, className, style } = props
  const classes = useStyles()
  if (totalPages <= 1) return <div> </div>
  return (
    <div
      className={`${className} ${classes.pageSelectorContainer}`}
      style={style}
    >
      <Button
        className={classes.pageSelectorButton}
        onClick={() => setPageNumber(pageNumber - 1)}
        disabled={pageNumber <= 1}
      >
        {pageNumber > 1 && (
          <ArrowLeft className={classes.pageSelectorArrow} color='white' />
        )}
      </Button>
      <Typography
        className={classes.pageSelectortext}
        variant='body1'
        align='center'
      >{`${pageNumber} sur ${totalPages}`}</Typography>
      <Button
        className={classes.pageSelectorButton}
        onClick={() => setPageNumber(pageNumber + 1)}
        disabled={pageNumber >= totalPages}
      >
        {pageNumber < totalPages && (
          <ArrowLeft
            className={classes.pageSelectorArrow}
            color='white'
            style={{ transform: 'rotate(180deg)' }}
          />
        )}
      </Button>
    </div>
  )
}
