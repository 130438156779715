import React from 'react'
import { ReactComponent } from './single-man.svg'
import { MergeReactElementProps } from '../types'

interface UserIconProps {
  color?: string
}

type Props = MergeReactElementProps<'svg', UserIconProps>

export const UserIcon = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { color = '#FFFFFF' } = props
    return <ReactComponent stroke={color} fill="none" ref={ref} {...props} />
  }
)
