import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 16,
    "& .MuiButton-root": {
      marginRight:"10px"
    }
  }
}))

interface FormButtonsProps {
  children?: React.ReactNode
  className?: string
}

export const FormButtons = (props: FormButtonsProps) => {
  const { children, className } = props
  const classes = useStyles()
  return (
    <Box
      display='flex'
      justifyContent='flex-end'
      className={`${className} ${classes.root}`}
    >
      {children}
    </Box>
  )
}
