import React, { useState, Fragment, useCallback } from 'react'
import { Button } from '../../../Component/Button'
import { PopUpConfirmation } from '../../../Component/PopUp'
import { MenuItemAction } from '../../../Component/MoreOptions'

export interface CampaignActionButtonProps {
  action: MenuItemAction
  className: string
}

export const CampaignActionButton = (props: CampaignActionButtonProps) => {
  const { action, className } = props
  const [open, setOpen] = useState<boolean>(false)

  const onClose = useCallback(() => setOpen(false), [])

  const onClick = useCallback(
    () => (!!action.confirmMsg ? setOpen(true) : action.goto && action.goto()),
    [action.confirmMsg]
  )

  return (
    <Fragment>
      <Button key={action.key} className={className} onClick={onClick}>
        {action.label}
      </Button>
      {!!action.confirmMsg && (
        <PopUpConfirmation
          title={action.confirmMsg}
          onClose={onClose}
          onConfirm={action.goto}
          open={open}
        />
      )}
    </Fragment>
  )
}
