import { keycloakClient } from "../keyclaok";
import { errorsHandler, successHandler, customErrorHandler } from "../error";

const put = <T>(
  url: string,
  boby?: BodyInit | null,
  successMessage?: string,
  noContentType?: boolean,
  noResponse?: boolean
): Promise<T> => {
  return fetch(url, {
    method: "put",
    headers: {
      Authorization: `Bearer ${keycloakClient.instance.token}`,
      ...(!noContentType
        ? {
            "Content-Type": "application/json",
          }
        : {}),
    },
    body: boby,
  })
    .then((response) => {
      successMessage && successHandler(successMessage);
      if (!noResponse) return response.json();
      else return;
    })
    .catch((error) => {
      console.log(error);
      errorsHandler("Une erreur est survenue", 522);
    });
};

const post = <T>(
  url: string,
  boby?: string,
  successMessage?: string,
  errorMessage?: string,
  showErrorAlert: Boolean = true
): Promise<T> => {
  return fetch(url, {
    method: "post",
    headers: {
      Authorization: `Bearer ${keycloakClient.instance.token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: boby,
  })
    .then((response) => {
      if (response.ok) successMessage && successHandler(successMessage);
      else {
        if (!showErrorAlert) return response.json();
        if (errorMessage == null) errorsHandler("Une erreur est survenue", 522);
        else customErrorHandler(errorMessage);
      }
      return response.json();
    })
    .catch((error) => {
      console.log(error);
      errorsHandler("Une erreur est survenue", 522);
    });
};

const get = <T>(url: string, errorMessage: string): Promise<T> => {
  return fetch(url, {
    method: "get",
    headers: {
      Authorization: `Bearer ${keycloakClient.instance.token}`,
    },
  })
    .then((response) => {
      if (!response.ok) errorsHandler(errorMessage, response.status);
      return response.json();
    })
    .catch((error) => {
      console.log(error);
      errorsHandler(errorMessage, 522);
    });
};

const fetchBlob = (url: string, error: string, method: string = "get", body?: string, headers?: Record<string, string>): Promise<null | Blob> => {
  return fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${keycloakClient.instance.token}`,
      ...headers
    },
    body
  })
    .then((response) => {
      if (!response.ok) errorsHandler(error, response.status);
      return response.blob();
    })
    .catch((error) => {
      errorsHandler(error, 522);
      return null
    });
};

const fetchDownloadableFile = async (url: string, error: string, method: string = "get", body?: string): Promise<string | null> => {
  const blob = await fetchBlob(url, error, method, body, { Accept: "application/octet-stream", "Content-Type": "application/json" })

  return (blob && blob.size > 0)
    ? window.URL.createObjectURL(blob)
    : null
}

const getText = <T>(url: string, error: string): Promise<void | string> => {
  return fetch(url, {
    method: "get",
    headers: {
      Authorization: `Bearer ${keycloakClient.instance.token}`,
    },
  })
    .then((response) => {
      if (!response.ok) errorsHandler(error, response.status);
      return response.text();
    })
    .catch((error) => {
      errorsHandler(error, 522);
    });
};

const del = <T>(
  url: string,
  successMessage: string,
  noResponse?: boolean
): Promise<T> => {
  return fetch(url, {
    method: "delete",
    headers: {
      Authorization: `Bearer ${keycloakClient.instance.token}`,
    },
  })
    .then((response) => {
      if (!response.ok)
        errorsHandler("Une erreur est survenue", response.status);
      successMessage && successHandler(successMessage);
      if (!noResponse) return response.json();
      return;
    })
    .catch((error) => {
      errorsHandler(error, 522);
    });
};

const patch = <T>(url: string, successMessage: string): Promise<T> => {
  return fetch(url, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${keycloakClient.instance.token}`,
    },
  })
    .then((response) => {
      if (!response.ok)
        errorsHandler("Une erreur est survenue", response.status);
      successMessage && successHandler(successMessage);
      return response.json();
    })
    .catch((error) => {
      errorsHandler(error, 522);
    });
};

export const http = {
  put: put,
  post: post,
  get: get,
  fetchBlob: fetchBlob,
  fetchDownloadableFile: fetchDownloadableFile,
  getText: getText,
  delete: del,
  patch: patch,
};
