import React, { useContext, useRef, useState } from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { useInputStyles } from '../../Theme'
import { themeContext } from '@smartb/archetypes-ui-components'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { SelectIcon } from '../../Icons/SelectIcon'

export type Option = {
  value: string | number
  label: string | number
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiFilledInput-input': {
      margin: '0px 5px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      height: 48,
      borderRadius: '5px'
    } 
  },
  disabledStyle: {
    '& .MuiSelect-root': {
      fontSize: "14px",
      color: 'rgba(0, 0, 0, 0.35)'
    }
  },
  clear: {
    position: 'absolute',
    right: '35px',
    top: '50%',
    marginTop: "-12px",
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)'
  }
}))

interface SelectProps {
  value?: string | number
  onChange: (value: string) => void
  label?: string
  options: Option[]
  id: string
  className?: string
  placeHolder?: string
  style?: React.CSSProperties
  error?: boolean
  errorMessage?: string
  baseClassName?: string
  onRemoveValue?: () => void
  disabled?: boolean
  onClick?: (event: React.MouseEvent) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export const Select = React.forwardRef((props: SelectProps, ref) => {
  const {
    value = '',
    onChange,
    label = '',
    options,
    className,
    placeHolder,
    baseClassName = "",
    style,
    id,
    error = false,
    errorMessage = '',
    onRemoveValue,
    disabled = false,
    onClick,
    onBlur
  } = props

  const theme = useContext(themeContext)
  const classes = useInputStyles(theme, disabled)()
  const classesLocal = useStyles()

  return (
    <FormControl
      variant='filled'
      className={`${className} ${classes.input}`}
      style={style}
      error={error}
    >
      {label ? (
        <InputLabel htmlFor={id} className={classes.label}>
          {label}
        </InputLabel>
      ) : null}
      <MuiSelect
        ref={ref}
        onClick={onClick}
        onBlur={onBlur}
        className={`${classesLocal.root} ${value === '' && placeHolder ? classesLocal.disabledStyle : ""} ${baseClassName}`}
        id={id}
        variant={'filled'}
        native
        value={value}
        IconComponent={(props) => (
          <SelectIcon
            {...props}
            color='#98A5AE'
            style={{
              width: '20px',
              height: "20px",
              right: '10px',
              top: 'calc(50% - 10px)'
            }}
          />
        )}
        onChange={(
          e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
        ) => onChange(e.target.value as string)}
        inputProps={{
          name: label,
          style: {
            paddingRight: value !== '' && onRemoveValue ? "49px" : "26px",
            textOverflow: "ellipsis"
          }
        }}
        style={{
          color: '#98A5AE',
          fontWeight: 500
        }}
        disabled={disabled ? disabled : false}
      >
        {value === '' && placeHolder ? 
        <option aria-label='Placeholder' value="" disabled>{placeHolder}</option>
      : value === '' && !placeHolder && <option aria-label='None' value=''/>
      }
        {options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.label}
          </option>
        ))}
      </MuiSelect>
      {value !== '' && onRemoveValue && (
        <Clear onClick={onRemoveValue} className={classesLocal.clear} />
      )}
      {errorMessage !== '' && error && (
        <FormHelperText className={classes.helperText}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
})
