import { connect } from "react-redux";
import { State } from "../../../../store";
import { title } from "../../../../store/title";
import { project } from "../../../../store/project";
import { router } from "store/router";

const mapStateToProps = (state: State) => ({
  projectRefs: project.selectors.projectRefs(state),
  project: project.selectors.project(state),
});

const mapDispatchToProps = {
  setHeadBar: title.actions.setHeadBar,
  gotoCreateCampaign: router.goto.project.campaignCreate,
  fetchProject: project.queries.fetchProject,
  createProject: project.commands.createProject,
};

export default connect(mapStateToProps, mapDispatchToProps);
