import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { theme } from '../../Theme'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '25px',
      color: theme.secondaryColor,
      fontWeight: 600
    }
  })
)

interface TitleHeadBarProps {
  title?: string
}

export const TitleHeadBar = (props: TitleHeadBarProps) => {
  const { title } = props
  const classes = useStyles()
  return <div className={classes.title}>{title}</div>
}
