import React, { Fragment } from 'react'
import { ProfileLogo } from '../../Component/ProfileLogo'
import { Box, makeStyles, Typography } from '@material-ui/core'
import {
  CampaignDeliveryRow,
  OrganizationType,
  CarrierInfo
} from './CampaignDeliveryTable'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles(() => ({
  icon: {
    width: '35px',
    marginRight: '10px'
  },
  bonusContainer: {
    padding: '5px 20px',
    width: '90%',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0,0,0,0.05)'
    }
  },
  separator: {
    height: '1px',
    width: 'calc(90% + 40px)',
    marginTop: '-0.5px',
    background: 'rgba(0,0,0,0.2)'
  },
  carrierName: {
    textDecoration: 'underline',
    color: '#007DCE'
  }
}))

export interface CampaignDeliveryCarrierTableProps {
  onClickCarrier: (carrierId: string) => void
  getDefaultOrganizationLogo: (
    type: OrganizationType | undefined,
    small: boolean
  ) => JSX.Element
  data?: CampaignDeliveryRow
}

export const CampaignDeliveryCarrierTable = (
  props: CampaignDeliveryCarrierTableProps
) => {
  const { onClickCarrier, data, getDefaultOrganizationLogo } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const carriers: CarrierInfo[] = data?.carriers || []

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      {carriers.map((carrier, index) => (
        <Fragment>
          <Box
            key={carrier.organization.organizationId}
            display='flex'
            alignItems='center'
            className={classes.bonusContainer}
            onClick={() => onClickCarrier(carrier.organization.organizationId)}
          >
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100px'
              marginRight='30px'
            >
              {carrier.organization.hasLogo ? (
                <ProfileLogo
                  alt={carrier.organization.displayName}
                  src={carrier.organization.profileImgUrl}
                  className={classes.icon}
                />
              ) : (
                getDefaultOrganizationLogo(
                  carrier.organization.organizationType,
                  true
                )
              )}
            </Box>
            <Typography variant='body2'>
              {t('app_domain_campaign_delivery_carrierPackages', {
                packCount: carrier.packCount
              })}
              <span className={classes.carrierName}>
                {carrier.organization.displayName}
              </span>
            </Typography>
          </Box>
          {index !== carriers.length - 1 && (
            <div className={classes.separator} />
          )}
        </Fragment>
      ))}
    </Box>
  )
}
