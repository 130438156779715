import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core'
import { InputForm } from '../../Component/InputForm'

const useStyles = makeStyles(() => ({
  filter: {
    width: '160px',
    marginRight: '10px'
  }
}))

export const UsersFilters = () => {
  const classes = useStyles()
  return (
    <Fragment>
      <InputForm
        label={'Térritoires'}
        onChange={() => {}}
        selectOptions={[]}
        inputType={'select'}
        placeHolder='Térritoires'
        className={`${classes.filter} users-filters`}
        id='users-filters-territories'
      />
      <InputForm
        label='Organisations'
        onChange={() => {}}
        selectOptions={[]}
        inputType={'select'}
        placeHolder='Organisations'
        className={`${classes.filter} users-filters`}
        id='users-filters-operators'
      />
      <InputForm
        label='Types'
        onChange={() => {}}
        selectOptions={[]}
        inputType={'select'}
        placeHolder='Types'
        className={`${classes.filter} users-filters`}
        id='users-filters-carriers'
      />
      <InputForm
        label='Status'
        onChange={() => {}}
        selectOptions={[]}
        inputType={'select'}
        placeHolder='Status'
        className={`${classes.filter} users-filters`}
        style={{ marginRight: '0' }}
        id='users-filters-status'
      />
    </Fragment>
  )
}

export default UsersFilters
