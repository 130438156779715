import { city } from "plateform-organization-domain";
import { createUser } from "./commands/createOrganizationUserCommand";
import { city as cityPlat } from "plateform-plateform-commons";
import { logoCreateCommand } from "./commands/logoCreateCommand";
import { fetchOrganizationUser } from "./query/fetchOrganizationUser";
import { fetchOrganizationUsersPage } from "./query/fetchOrganizationUsers";
import { userLogoUrl } from "./query/userLogoUrl";
import { updateUser } from "./commands/UpdateOrganizationUserCommand";
import { changePassword } from "./commands/changePassword";
import { deleteUser } from "./commands/deleteUser";
import { disableUser } from "./commands/disableUser";
import { enableUser } from "./commands/enableUser";

export interface User extends city.colisactiv.organization.model.user.User {}

export interface UserDetails
  extends city.colisactiv.organization.model.user.UserDetails {}

export interface UserPage
  extends cityPlat.colisactiv.platform.commons.Page<
    city.colisactiv.organization.model.user.UserBase
  > {}

export const usersClient = {
  createUser: createUser,
  uploadUserLogo: logoCreateCommand,
  updateUser: updateUser,
  changeUserPassword: changePassword,
  deleteUser: deleteUser,
  disableUser: disableUser,
  enableUser: enableUser,
  queries: {
    fetchUser: fetchOrganizationUser,
    fetchPageUsers: fetchOrganizationUsersPage,
    userLogoUrl: userLogoUrl,
  },
};
