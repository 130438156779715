import { buildProjectApiUrl } from "api/configuration";
import { ProjectRef } from "api/project/model";
import { http } from "api/http";

export const getProjectsReferences = (): Promise<ProjectRef[]> => {
  return http.get(
    buildProjectApiUrl(`/projectRefs`),
    "la récupération des réferences du projets n'a pas pu être effectuée"
  );
};
