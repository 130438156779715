import { buildBonusPaymentApiUrl } from "api/configuration";
import { BonusPayment, BonusPaymentId } from "../../index";
import { http } from "../../../http";

export const fetchBonusPaymentQuery = (
  id: BonusPaymentId
): Promise<BonusPayment> => {
  return http.get(
    buildBonusPaymentApiUrl(`/bonuspayments/${id}`),
    "La récupération du réglement n'a pas pu être effectué"
  );
};
