import React, { useEffect } from 'react'
import { Box, makeStyles, Paper } from '@material-ui/core'
import { HeadBarProps } from '../HeadBar'

const useStyles = (columnSwitchWidth: number, switchedHeaderHeight: number) =>
  makeStyles(() => ({
    filterContainer: {
      boxShadow: 'none !important',
      borderRadius: '5px',
      padding: '10px 15px',
      [`@media (max-width: ${columnSwitchWidth}px)`]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: `${switchedHeaderHeight}px`
      }
    },
    body: {
      background: 'transparent !important',
      borderRadius: '5px',
      boxShadow: 'none !important',
      padding: '5px 15px'
    }
  }))

export interface PageProps {
  headBar: HeadBarProps
  setHeadBar: (headBar: HeadBarProps) => void
  header: React.ReactNode
  children?: React.ReactNode
  columnSwitchWidth: number
  switchedHeaderHeight: number
  className?: string
  style?: React.CSSProperties
}

export const Page = (props: PageProps) => {
  const {
    setHeadBar,
    headBar,
    header,
    children,
    columnSwitchWidth,
    switchedHeaderHeight,
    className,
    style
  } = props
  const classes = useStyles(columnSwitchWidth, switchedHeaderHeight)()
  useEffect(() => {
    setHeadBar(headBar)
  }, [setHeadBar, headBar])
  return (
    <React.Fragment>
      <Box
        display='flex'
        justifyContent='flex-end'
        className={classes.filterContainer}
      >
        {header}
      </Box>
      <Paper
        className={`${classes.body} ${className} colisactiv-organizationBody`}
        style={style}
      >
        {children}
      </Paper>
    </React.Fragment>
  )
}
