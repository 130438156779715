import React, { useEffect } from 'react'
import { Container, makeStyles, Paper } from '@material-ui/core'
import { HeadBarProps } from '../HeadBar'

const useStyles = makeStyles(() => ({
  container: {
    width: 'calc(100% - 40px)',
    height: 'calc(100vh - 175px)',
    padding: '30px 20px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'auto'
  },
  button: {
    position: 'absolute',
    right: '30px',
    bottom: '3px'
  },
  form: {
    position: 'relative',
    paddingBottom: 100,
    marginLeft: '0px'
  }
}))

export interface PageFormProps {
  children: React.ReactElement
  headBar: HeadBarProps
  setHeadBar: (headBar: HeadBarProps) => void
}

export const PageForm = (props: PageFormProps) => {
  const { children, headBar, setHeadBar } = props
  useEffect(() => {
    setHeadBar(headBar)
  }, [setHeadBar, headBar])
  const classes = useStyles()
  return (
    <Paper className={classes.container}>
      <Container className={classes.form}>{children}</Container>
    </Paper>
  )
}
