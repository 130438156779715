import * as R from "ramda";
import { createReducer } from "deox";
import { actions } from "./user.actions";
import { User } from "api/organization/users";

export interface UserState {
  user: User | null;
}

export const initialState: UserState = {
  user: null,
};

export type UserStateTransformer = (state: UserState) => UserState;

const setOrganization = (user: User): UserStateTransformer => {
  return R.assoc("user", user);
};

export const userReducer = createReducer(initialState, (handleAction) => [
  handleAction(actions.fetchedUser, (state: UserState, action) =>
    setOrganization(action.payload.user)(state)
  ),
]);
