import { Box, makeStyles, InputLabel } from '@material-ui/core'
import React, { useContext } from 'react'
import { Option } from '../Select'
import { MultiSelect } from './MultiSelect'
import { themeContext } from '@smartb/archetypes-ui-components'
import { useInputStyles } from '../../Theme'

const useStyles = makeStyles(() => ({
  text: {
    fontWeight: 600,
    color: '#828282 !important'
  },
  noIcon: {
    '& .MuiSelect-icon.Mui-disabled': {
      display: 'none'
    }
  }
}))

interface MultiSelectBoxProps {
  options?: Option[]
  values?: string[]
  label?: string
  onChange?: (value: string[]) => void
  className?: string
  style?: React.CSSProperties
  inputClassName?: string
  inputStyle?: React.CSSProperties
  onClick?: () => void
  id: string
  placeHolder?: string
  readonly?: boolean
  noMenu?: boolean
  error?: boolean
  errorMessage?: string
}

export const MultiSelectBox = (props: MultiSelectBoxProps) => {
  const {
    onChange,
    className,
    inputClassName,
    label,
    inputStyle,
    options,
    onClick,
    style,
    values = [],
    id,
    placeHolder = '',
    readonly = false,
    error = false,
    errorMessage = '',
    noMenu
  } = props
  const classesLocal = useStyles()
  const theme = useContext(themeContext)
  const classes = useInputStyles(theme, readonly)()
  return (
    <Box className={className} style={style}>
      {label ? (
        <InputLabel htmlFor={id} className={classes.label}>
          {label}
        </InputLabel>
      ) : null}
      {readonly ? (
        <MultiSelect
          id={id}
          values={values}
          options={options}
          onClick={onClick}
          className={`${inputClassName} ${classesLocal.noIcon}`}
          style={inputStyle}
          noMenu={noMenu}
          disabled={true}
        />
      ) : (
        <MultiSelect
          id={id}
          values={values}
          onChange={onChange}
          onClick={onClick}
          placeHolder={placeHolder}
          options={options}
          className={inputClassName}
          style={inputStyle}
          error={error}
          noMenu={noMenu}
          errorMessage={error ? errorMessage : undefined}
        />
      )}
    </Box>
  )
}
