import { makeStyles } from '@material-ui/core'
import React, { ChangeEvent, Fragment } from 'react'
import { PopUp } from './PopUp'
import { Button } from '../Button'

const useStyles = makeStyles(() => ({
  popup: {
    '& .MuiDialog-paper': {
      width: 750,
      maxWidth: 750
    }
  },
  button: {
    margin: 8
  }
}))

export interface BonusIncidentFormState {
  description: string
}

export interface PopUpFormProps<FormState> {
  form: FormState
  open: boolean
  title: string
  children?: React.ReactNode
  onValidate: (form: FormState) => void
  onCancel: (event: ChangeEvent<{}>) => void

  className?: string
  style?: React.CSSProperties
}

export const PopUpForm = <FormState,>(props: PopUpFormProps<FormState>) => {
  const { form, open, title, children, className, onValidate, onCancel } = props

  const classes = useStyles()
  const footer = (
    <Fragment>
      <Button
        onClick={(event: ChangeEvent<{}>) => {
          onCancel(event)
        }}
        className={classes.button}
      >
        Annuler
      </Button>
      <Button
        onClick={() => {
          onValidate(form)
        }}
        className={classes.button}
      >
        Confirmer
      </Button>
    </Fragment>
  )
  return (
    <PopUp
      title={title}
      footer={footer}
      onClose={onCancel}
      open={open}
      className={`${classes.popup} ${className || ''}`}
    >
      {children}
    </PopUp>
  )
}
