import { Organization } from "../index";
import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "../../http";

export const getOrganization = (
  organizationId: string
): Promise<Organization> => {
  return http.get(
    buildOrganizationApiUrl(`/organizations/${organizationId}`),
    "la récupération des données de l'organization n'a pas pu être effectuée"
  );
};
