// @ts-ignore
export const apiUrl = window._env_.API_URL;
// @ts-ignore
export const apiConfigUrlPath = window._env_.API_URL_CONFIG;
// @ts-ignore
export const apiOrganizationUrlPath = window._env_.API_URL_ORGANIZATION;
// @ts-ignore
export const apiProjectUrlPath = window._env_.API_URL_PROJECT;
// @ts-ignore
export const apiCarrierReportUrlPath = window._env_.API_URL_CARRIER_REPORT;
// @ts-ignore
export const apiBonusUrlPath = window._env_.API_URL_BONUS;
// @ts-ignore
export const apiBonusGroupUrlPath = window._env_.API_URL_BONUS_GROUP;
// @ts-ignore
export const apiBonusPaymentUrlPath = window._env_.API_URL_BONUS_PAYMENT;
// @ts-ignore
export const apiDashboardPoiUrlPath = window._env_.API_URL_DASHBOARD_POI;
// @ts-ignore
export const apiNotificationUrlPath = window._env_.API_URL_NOTIFICATION;
// @ts-ignore
export const apiInseeBearerToken = window._env_.API_INSEE_BEARER_TOKEN;
// @ts-ignore
export const apiMapboxAccessToken = window._env_.API_MAPBOX_ACCESS_TOKEN;

export const buildConfigApiUrl = (path: String) => {
  return `${apiUrl}${apiConfigUrlPath}${path}`;
};

export const buildOrganizationApiUrl = (path: String) => {
  return `${apiUrl}${apiOrganizationUrlPath}${path}`;
};

export const buildBonusApiUrl = (path: String) => {
  return `${apiUrl}${apiBonusUrlPath}${path}`;
};

export const buildBonusGroupApiUrl = (path: String) => {
  return `${apiUrl}${apiBonusGroupUrlPath}${path}`;
};

export const buildBonusPaymentApiUrl = (path: String) => {
  return `${apiUrl}${apiBonusPaymentUrlPath}${path}`;
};

export const buildProjectApiUrl = (path: String) => {
  return `${apiUrl}${apiProjectUrlPath}${path}`;
};
export const buildCarrierReportApiUrl = (path: String) => {
  return `${apiUrl}${apiCarrierReportUrlPath}${path}`;
};
export const buildDashboardPoiApiUrl = (path: String) => {
  return `${apiUrl}${apiDashboardPoiUrlPath}${path}`;
};
export const buildNotificationApiUrl = (path: String) => {
  return `${apiUrl}${apiNotificationUrlPath}${path}`;
};
export const getURLParameter = (name: string): string => {
  const parameter = (RegExp(name + "=" + "(.+?)(&|$)").exec(
    window.location.search
  ) || [, null])[1];
  return decodeURI(parameter ? parameter : "");
};
