import {
  NotificationsNoneOutlined,
  ReportProblemOutlined,
} from "@material-ui/icons";
import { User } from "api/organization/users";
import {
  BusinessCenter,
  CreditCard,
  EditDocument,
  UserIcon,
  SignDocument,
} from "components";
import React from "react";
import {
  Notification,
  NotificationStatusValues,
  NotificationTypeValues,
} from "../../../api/notification";

export interface NotificationData {
  id: string;
  url?: string;
  messageKey?: string;
  messageDefault: string;
  messageParams: Record<string, string>;
  icon: React.ReactNode;
  canUserSee: (user: User) => boolean;
  organizationId: string;
  pinned: boolean;
  changeable: boolean;
  creationDate: string;
  severity: string;
  label?: React.ReactNode;
  show?: boolean;
  unSeen?: boolean;
}

const getConventionSignMapping = (notif: Notification): NotificationData => {
  const organizationId = notif.organizationId;
  return {
    ...getDefault(notif),
    url: `/organizations/${organizationId}/documents/view`,
    messageKey: "app_notification_convention_sign",
    icon: (
      <SignDocument color="#98A5AE" style={{ width: "25px", height: "25px" }} />
    ),
  };
};

const getConventionResetedMapping = (notif: Notification): NotificationData => {
  const organizationId = notif.organizationId;
  return {
    ...getDefault(notif),
    url: `/organizations/${organizationId}/documents/view`,
    messageKey: "app_notification_convention_reset",
    icon: (
      <SignDocument color="#98A5AE" style={{ width: "25px", height: "25px" }} />
    ),
  };
};

const getOperatorPaymentInformationsMapping = (
  notif: Notification
): NotificationData => {
  const organizationId = notif.organizationId;
  return {
    ...getDefault(notif),
    url: `/organizations/${organizationId}/general/view`,
    messageKey: "app_notification_payment_informations",
    icon: (
      <ReportProblemOutlined
        style={{ width: "25px", height: "25px", color: "#ff9900" }}
      />
    ),
  };
};

const getConventionSignedMapping = (notif: Notification): NotificationData => {
  const organizationId = notif.properties["operatorId"];
  return {
    ...getDefault(notif),
    url: `/organizations/${organizationId}/documents/view`,
    messageKey: "app_notification_convention_signed",
    icon: (
      <SignDocument color="#98A5AE" style={{ width: "25px", height: "25px" }} />
    ),
  };
};

const getBonusCreatedMapping = (notif: Notification): NotificationData => {
  const bonusId = notif.objRef.id;
  return {
    ...getDefault(notif),
    url: `/bonus/${bonusId}/view`,
    messageKey: "app_notification_bonus_created",
    icon: (
      <EditDocument color="#98A5AE" style={{ width: "25px", height: "25px" }} />
    ),
  };
};

const getBonusIncidentCreatedMapping = (
  notif: Notification
): NotificationData => {
  const bonusId = notif.objRef.id;
  return {
    ...getDefault(notif),
    url: `/bonus/${bonusId}/view`,
    messageKey: "app_notification_bonus_incident_created",
    icon: (
      <EditDocument color="#98A5AE" style={{ width: "25px", height: "25px" }} />
    ),
  };
};

const getBonusPublishedMapping = (notif: Notification): NotificationData => {
  const bonusId = notif.objRef.id;
  return {
    ...getDefault(notif),
    url: `/bonus/${bonusId}/view`,
    messageKey: "app_notification_bonus_published",
    icon: (
      <CreditCard color="#98A5AE" style={{ width: "25px", height: "25px" }} />
    ),
  };
};

const getBonusInvoiceCreatedMapping = (
  notif: Notification
): NotificationData => {
  const bonusId = notif.objRef.id;
  return {
    ...getDefault(notif),
    url: `/bonus/${bonusId}/view`,
    messageKey: "app_notification_bonus_invoice_created",
    icon: (
      <CreditCard color="#98A5AE" style={{ width: "25px", height: "25px" }} />
    ),
  };
};

const getBonusRejectedMapping = (notif: Notification): NotificationData => {
  const bonusId = notif.objRef.id;
  return {
    ...getDefault(notif),
    url: `/bonus/${bonusId}/view`,
    messageKey: "app_notification_bonus_rejected",
    icon: (
      <CreditCard color="#98A5AE" style={{ width: "25px", height: "25px" }} />
    ),
  };
};

const getBonusApprovedMapping = (notif: Notification): NotificationData => {
  const bonusId = notif.objRef.id;
  return {
    ...getDefault(notif),
    url: `/bonus/${bonusId}/view`,
    messageKey: "app_notification_bonus_approved",
    icon: (
      <CreditCard color="#98A5AE" style={{ width: "25px", height: "25px" }} />
    ),
  };
};

const getCampaignPublishedMapping = (notif: Notification): NotificationData => {
  const campaignId = notif.objRef.id;
  const projectId = notif.properties.projectId;

  return {
    ...getDefault(notif),
    url: `/projects/${projectId}/campaign/view/${campaignId}`,
    messageKey: "app_notification_campaign_published",
    icon: (
      <BusinessCenter
        color="#98A5AE"
        style={{ width: "25px", height: "25px" }}
      />
    ),
  };
};

const getDeliveryRejectedMapping = (notif: Notification): NotificationData => {
  return {
    ...getDefault(notif),
    url: `/campaignDelivery`,
    messageKey: "app_notification_delivery_rejected",
  };
};

const getUserAddedMapping = (notif: Notification): NotificationData => {
  const organizationId = notif.properties.organizationId;
  const userId = notif.objRef.id;

  return {
    ...getDefault(notif),
    url: `/organizations/${organizationId}/users/view/${userId}`,
    messageKey: "app_notification_user_added",
    icon: (
      <UserIcon color="#98A5AE" style={{ width: "25px", height: "25px" }} />
    ),
    canUserSee: (user: User) =>
      user.details.role === "admin" &&
      user.userId !== userId &&
      user.userId !== notif.properties["inviter"],
  };
};

const getDefault = (notif: Notification): NotificationData => {
  return {
    messageDefault: notif.message,
    messageParams: notif.properties,
    organizationId: notif.organizationId,
    changeable: notif.changeable,
    creationDate: notif.creationDate,
    id: notif.id,
    pinned: notif.pinned,
    severity: notif.severity.value,
    unSeen: new NotificationStatusValues().isUnSeen(notif.status.value),
    canUserSee: () => true,
    icon: (
      <NotificationsNoneOutlined
        style={{ color: "#98A5AE", width: "25px", height: "25px" }}
      />
    ),
  };
};

const getterMap = {
  [NotificationTypeValues.BONUS_CREATED.value]: getBonusCreatedMapping,
  [NotificationTypeValues.BONUS_INCIDENT_CREATED
    .value]: getBonusIncidentCreatedMapping,
  [NotificationTypeValues.BONUS_PUBLISHED.value]: getBonusPublishedMapping,
  [NotificationTypeValues.BONUS_INVOICE_CREATED
    .value]: getBonusInvoiceCreatedMapping,
  [NotificationTypeValues.BONUS_REJECTED.value]: getBonusRejectedMapping,
  [NotificationTypeValues.BONUS_APPROVED.value]: getBonusApprovedMapping,
  [NotificationTypeValues.CAMPAIGN_PUBLISHED
    .value]: getCampaignPublishedMapping,
  [NotificationTypeValues.CONVENTION_SIGN.value]: getConventionSignMapping,
  [NotificationTypeValues.CONVENTION_SIGNED.value]: getConventionSignedMapping,
  [NotificationTypeValues.CONVENTION_RESET.value]: getConventionResetedMapping,
  [NotificationTypeValues.DELIVERY_REJECTED.value]: getDeliveryRejectedMapping,
  [NotificationTypeValues.USER_ADDED.value]: getUserAddedMapping,
  [NotificationTypeValues.OPERATOR_ADDED_WITHOUT_PAYMENT_INFORMATIONS
    .value]: getOperatorPaymentInformationsMapping,
};

const get = (notif: Notification): NotificationData => {
  const getMapping = getterMap[notif.type.value] ?? getDefault;
  return getMapping(notif);
};

export const notificationMapping = {
  get,
};
