import { buildOrganizationApiUrl } from "api/configuration";
import { http } from "../../http";

export const getSecret = (
  organizationId: string,
  keycloackId: string
): Promise<string | void> => {
  return http.getText(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/applications/${keycloackId}`
    ),
    "La récupération de la clé secrète n'a pas pu être effectuée"
  );
};

export const getOrganizationLogo = (organizationId: string) => {
  return buildOrganizationApiUrl(`/organizations/${organizationId}/logo`);
};
