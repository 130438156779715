import React from 'react'
import { TextButton } from '../TextButton'

interface CancelButtonProps {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  onClick: (event: React.ChangeEvent<{}>) => void
  disabled?: boolean
}

export const CancelButton = (props: CancelButtonProps) => {
  const { children, className, style, onClick, disabled = false } = props

  return (
    <TextButton
      onClick={onClick}
      className={className}
      disabled={disabled}
      style={style}
    >
      {children}
    </TextButton>
  )
}
