import { Box, makeStyles } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import { city } from 'plateform-project-domain'
import { city as cityStats } from 'plateform-project-stats-domain'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItemAction, MoreOptions } from '../../Component/MoreOptions'
import { ProgressBar } from '../../Component/ProgressBar'
import { StatusTags, StatusTagsFormProps } from '../../Component/StatusTags'
import { PrimaryTypography, Table } from '../../Component/Table'
import { displayNumber, UTCToTimedZone } from '../../utils'
export interface Campaign extends city.colisactiv.project.model.Campaign {}
export interface CampaignStats
  extends cityStats.colisactiv.plateform.project.stats.model.CampaignStats {}
interface BonusPrice extends city.colisactiv.project.model.BonusPrice {}

const useStyles = makeStyles(() => ({
  areasContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    align: 'left'
  },
  chip: {
    margin: 2,
    width: 'fit-content'
  },
  container: {
    '& .rdt_TableRow .rdt_TableCell:nth-child(2n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(2n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(3n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(3n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(4n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(4n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(5n)': {
      minWidth: '150px'
    },
    '& .rdt_TableCol:nth-child(5n)': {
      minWidth: '150px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(6n)': {
      minWidth: '140px'
    },
    '& .rdt_TableCol:nth-child(6n)': {
      minWidth: '140px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(7n)': {
      minWidth: '150px'
    },
    '& .rdt_TableCol:nth-child(7n)': {
      minWidth: '150px'
    },
    '& .rdt_TableRow .rdt_TableCell:last-child': {
      minWidth: '80px',
      maxWidth: '80px'
    },
    '& .rdt_TableCol:last-child': {
      minWidth: '80px',
      maxWidth: '80px'
    },
    '& .rdt_TableRow .rdt_TableCell:first-child': {
      minWidth: '200px'
    },
    '& .rdt_TableCol:first-child': {
      minWidth: '200px'
    }
  }
}))

export interface CampaignRow {
  id: string
  name: string
  consumedBudget: number
  activeDeliveries: number
  totalBudget: number
  progressionPourcentage: number
  period: string
  status: StatusTagsFormProps
  actions: MenuItemAction[]
}

interface CampaignTableProps {
  campaigns: CampaignStats[]
  onRowClicked?: (row: CampaignRow) => void
  totalPages?: number
  page?: number
  handlePageChange?: (page: number) => void
  isLoading?: boolean
  getStatus: (row: CampaignStats) => StatusTagsFormProps
  getActions?: (row: CampaignStats) => MenuItemAction[]
}

export const CampaignTable = (props: CampaignTableProps) => {
  const {
    campaigns,
    onRowClicked,
    totalPages,
    handlePageChange,
    page,
    isLoading,
    getStatus,
    getActions = () => []
  } = props

  const { t } = useTranslation()
  const formatDate = (startDate: number, endDate: number): string => {
    return `Du ${UTCToTimedZone(
      startDate
    ).toLocaleDateString()} au ${UTCToTimedZone(endDate).toLocaleDateString()}`
  }

  const classes = useStyles()
  const data: CampaignRow[] = campaigns.map(
    (campaign): CampaignRow => {
      const consumedBudget = campaign.totalBonusAmount
      return {
        id: campaign.id,
        name: campaign.details.name,
        activeDeliveries: campaign.activeDeliveryCount,
        consumedBudget: campaign.totalBonusAmount,
        totalBudget: campaign.budget,
        progressionPourcentage:
          campaign.budget !== 0
            ? (consumedBudget * 100) / campaign.budget
            : 100,
        period: formatDate(
          campaign.details.period.startDate,
          campaign.details.period.endDate
        ),
        status: getStatus(campaign),
        actions: getActions(campaign)
      }
    }
  )

  const columns = [
    {
      name: 'Nom',
      cell: (row: CampaignRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.name}
        </PrimaryTypography>
      )
    },
    {
      name: 'Budget total',
      cell: (row: CampaignRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >{`${displayNumber(row.totalBudget)} €`}</PrimaryTypography>
      )
    },
    {
      name: 'Budget consommé',
      cell: (row: CampaignRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {displayNumber(row.consumedBudget)}
        </PrimaryTypography>
      )
    },
    {
      name: 'Livraisons actives',
      cell: (row: CampaignRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {displayNumber(row.activeDeliveries)}
        </PrimaryTypography>
      )
    },
    {
      name: 'Période',
      cell: (row: CampaignRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.period}
        </PrimaryTypography>
      )
    },
    {
      name: 'Progression',
      cell: (row: CampaignRow) => (
        <ProgressBar
          withLabel
          progressionPourcentage={Math.round(row.progressionPourcentage)}
        />
      )
    },
    {
      name: 'Statut',
      cell: (row: CampaignRow) => {
        const status = row.status
        return <StatusTags {...status} />
      }
    },
    {
      name: 'Action',
      cell: (row: CampaignRow) => {
        const actions = row.actions
        return (
          <Box>
            <MoreOptions
              handleClose={() => {}}
              options={!!actions ? actions : []}
            />
          </Box>
        )
      }
    }
  ]
  return (
    <Table
      data={data}
      columns={columns}
      className={classes.container}
      onRowClicked={onRowClicked}
      handlePageChange={handlePageChange}
      totalPages={totalPages}
      page={page}
      isLoading={isLoading}
      keyField='id'
      noDataMessage={t('app_no_data_campaign_table')}
    />
  )
}
