import { connect } from "react-redux";
import { State } from "../../../../store";
import { router } from "../../../../store/router";
import { title } from "../../../../store/title";
import { organization } from "../../../../store/organization";

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {
  gotoOrganizationsList: router.goto.organization.organizationsList,
  setHeadBar: title.actions.setHeadBar,
  createOrganization: organization.commands.createOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps);
