import { Dispatch } from "redux";
import { State } from "../index";
import { actions } from "./bonuspayment.actions";
import { bonusPaymentClient } from "../../api/bonusPayment";

const fetchBonusPayment: (bonusId: string, force?: boolean) => void = (
  bonusId: string,
  force?: boolean
) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const doRequest = force === undefined ? true : force;
    try {
      dispatch(actions.fetchBonusPayment(bonusId));

      const fetched = await bonusPaymentClient.query.fetchBonusPaymentQuery(
        bonusId
      );
      dispatch(actions.fetchedBonusPayment(fetched));
    } catch (error) {
      // dispatch(addTodo.error(error))
    }
  };
};

export const queries = {
  fetchBonusPayment: fetchBonusPayment,
};
