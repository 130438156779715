import { CarrierReport } from "api/report";
import { CarrierReportFormValidity, CarrierReportFormState } from "./model";
import * as R from "ramda";

export const getValidation = (
  carrierReaport: CarrierReport,
  honorConfirmation: boolean
): CarrierReportFormValidity => {
  return {
    carrierId: carrierReaport.carrierId !== "",
    territory: carrierReaport.territory !== "",
    // activeModekmNumber: carrierReaport.activeMode.kmNumber !== "",
    activeModepackNumber: carrierReaport.activeMode.packNumber !== "",
    // electricalModekmNumber: carrierReaport.electricMode.kmNumber !== "",
    electricalModepackNumber: carrierReaport.electricMode.packNumber !== "",
    // thermalModekmNumber: carrierReaport.thermalMode.kmNumber !== "",
    thermalModepackNumber: carrierReaport.thermalMode.packNumber !== "",
    honorConfirmation: honorConfirmation === true,
  };
};

export const isCarrierReportValid = (
  newValidity: CarrierReportFormValidity
): boolean => {
  return R.values(newValidity).reduce(
    (previousValue, currentValue) => previousValue && currentValue
  );
};

const getFormValidation = (
  form: CarrierReportFormState
): [boolean, CarrierReportFormValidity] => {
  const validation = getValidation(form.carrierReport, form.honorConfirmation);
  const isValid = isCarrierReportValid(validation);
  const carrierReportValidation = validation;
  return [isValid, carrierReportValidation];
};

export const ReportValidation = {
  getFormValidation: getFormValidation,
};
