import { makeStyles } from "@material-ui/core";
import { Territory } from "api/geoZone";
import { OrganizationRef } from "api/organization";
import { User } from "api/organization/users";
import { CarrierReport } from "api/report";
import ReportForm from "app/components/ReportForm";
import {
  CarrierReportFormState,
  defaultCarrierReportFormState,
} from "app/components/ReportForm/model";
import {
  Button,
  CancelButton,
  FormButtons,
  HeadBarProps,
  SectionForm,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportValidation } from "../../../components/ReportForm/validation";
import withConnect from "./withConnect";

const useStyles = makeStyles(() => ({
  button: {
    margin: "0 10px",
  },
  form: {
    position: "relative",
    paddingBottom: 100,
    marginLeft: "0px",
  },
  buttonDraft: {
    background: "#61D4A6 !important",
    "&:hover": {
      background: "#61D4A6 !important",
      opacity: "0.9",
    },
  },
}));
interface ReportCreatePageFormProps {
  setHeadBar: (title: HeadBarProps) => void;
  currentUser: User | null;
  carriers: Map<string, OrganizationRef>;
  createCarrierReport: (form: CarrierReportFormState) => void;
  currentReport: CarrierReport | null;
  gotoReports: (page: number, size: number) => void;
  fetchTerritories: () => void;
  availableTerritories: Territory[];
}

const ReportCreatePage = (props: ReportCreatePageFormProps) => {
  const classes = useStyles();
  const {
    setHeadBar,
    currentUser,
    carriers,
    createCarrierReport,
    currentReport,
    gotoReports,
    fetchTerritories,
    availableTerritories,
  } = props;
  const [carrierReportState, setCarrierReportState] = useState<
    CarrierReportFormState
  >(defaultCarrierReportFormState);
  const { t } = useTranslation();

  useEffect(() => {
    setHeadBar({ title: `${t("app_reports_create_monthly_report")} ` });
  }, [setHeadBar, t]);

  useEffect(() => {
    if (currentReport?.id) {
      gotoReports(0, 10);
    }
  }, [currentReport, gotoReports]);

  useEffect(() => {
    if (
      carrierReportState.carrierReport.status.value === "CREATED" ||
      carrierReportState.carrierReport.status.value === "PUBLISHED"
    ) {
      createCarrierReport(carrierReportState);
    }
  }, [carrierReportState, createCarrierReport]);

  useEffect(() => {
    fetchTerritories();
  }, [fetchTerritories]);

  const handleCarrierReportCreation = useCallback(async () => {
    const [
      isValid,
      carrierReportValidation,
    ] = ReportValidation.getFormValidation(carrierReportState);

    if (!isValid) {
      return setCarrierReportState({
        ...carrierReportState,
        validity: carrierReportValidation,
      });
    }

    setCarrierReportState({
      ...carrierReportState,
      carrierReport: {
        ...carrierReportState.carrierReport,
        status: { value: "PUBLISHED" },
      },
    });
  }, [carrierReportState]);

  const handleCarrierReportScarmblingCreation = useCallback(async () => {
    setCarrierReportState({
      ...carrierReportState,
      carrierReport: {
        ...carrierReportState.carrierReport,
        status: { value: "CREATED" },
      },
    });
  }, [carrierReportState]);

  return (
    <SectionForm withGoBack>
      <ReportForm
        currentUser={currentUser}
        carriers={carriers}
        carrierReportState={carrierReportState}
        onCarrierReportChange={setCarrierReportState}
        availableTerritories={availableTerritories}
      />
      <FormButtons>
        <CancelButton onClick={() => gotoReports(0, 10)}>
          {t("app_reports_create_cancel")}
        </CancelButton>
        <Button
          className={classes.buttonDraft}
          onClick={() => handleCarrierReportScarmblingCreation()}
        >
          {t("app_reports_create_save")}
        </Button>
        <Button
          className={classes.button}
          onClick={() => handleCarrierReportCreation()}
        >
          {t("app_reports_create_submit_for_validation")}
        </Button>
      </FormButtons>
    </SectionForm>
  );
};

export default withConnect(ReportCreatePage);
