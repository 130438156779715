import { city } from "plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../../configuration";
import { http } from "../../../http";

export interface DeletedUserEvent
  extends city.colisactiv.organization.model.features.DeletedUserEvent {}

export const deleteUser = (
  organizationId: string,
  userId: string
): Promise<DeletedUserEvent> => {
  return http.delete(
    buildOrganizationApiUrl(`/organizations/${organizationId}/users/${userId}`),
    "L'utilisateur a bien été supprimé"
  );
};
