import React from 'react'
import { Button as MuiButton, makeStyles } from '@material-ui/core'
import { ArrowLeft } from '../../Icons/ArrowLeft'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  back: {
    textTransform: 'none',
    marginBottom: '20px',
    width: '100px',
    '& span': {
      color: '#828282',
      fontSize: '14px'
    }
  },
  arrow: {
    marginLeft: '-8px',
    marginRight: '8px',
    marginTop: '-1px',
    width: '20px',
    height: '20px'
  }
}))

interface BackButtonProps {
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}

export const BackButton = (props: BackButtonProps) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { className, style, children } = props
  return (
    <MuiButton
      className={`${classes.back} ${className}`}
      onClick={() => history.goBack()}
      style={{ ...style }}
    >
      <ArrowLeft className={classes.arrow} />
      {children ?? t('app_components_backbutton_return')}
    </MuiButton>
  )
}
