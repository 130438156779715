import { buildOrganizationApiUrl } from "../../../configuration";
import { UserPage } from "../index";
import { http } from "../../../http";

export const fetchOrganizationUsersPage = (
  organizationId: string,
  page: number,
  size: number,
  emailOrFirstnameOrLastname?: string,
  userRole?: string,
  userStatus?: string
): Promise<UserPage> => {
  return http.get(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/users?page=${page}&size=${size}&emailOrFirstnameOrLastname=${emailOrFirstnameOrLastname}&userRole=${userRole}&userStatus=${userStatus}`
    ),
    "la récupération des utilisateurs n'a pas pu être effectuée"
  );
};
