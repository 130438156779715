import { Box, makeStyles } from '@material-ui/core'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import React from 'react'
const useStyles = makeStyles(() => ({
  root: {
    '& .MuiBadge-colorPrimary': {
      backgroundColor: '#48d7ae'
    },

    '& span': {
      color: 'white'
    }
  },
  container: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center'
  },
  message: {
    marginTop: 2,
    marginLeft: 5,
    color: '#ff9900'
  }
}))

interface WarningMessageProps {
  message: string
  className?: string
  style?: React.CSSProperties
}

export const WarningMessage = (props: WarningMessageProps) => {
  const { message, className, style } = props
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <ReportProblemOutlinedIcon
        fontSize='small'
        style={{
          color: '#ff9900'
        }}
      />
      <div className={classes.message}>{message}</div>
    </Box>
  )
}
