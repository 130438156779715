import { connect } from "react-redux";
import { State } from "../../../../../../store";
import { router } from "../../../../../../store/router";
import { organization } from "../../../../../../store/organization";
import { profile } from "store/profile";

const mapStateToProps = (state: State) => ({
  organization: organization.selectors.organization(state),
  currentUser: profile.selectors.User(state),
});

const mapDispatchToProps = {
  gotoUserAdd: router.goto.organization.organizationUsersAdd,
  gotoOrganizationUser: router.goto.organization.organizationUsersView,
  gotoUserEdit: router.goto.organization.organizationUsersEdit,
  gotoUserView: router.goto.user.usersView,
  gotoOrganizationView: router.goto.organization.organizationView,
  gotoOrganizationUsersFilteredList:
    router.goto.organization.organizationUsersFilteredList,
};

export default connect(mapStateToProps, mapDispatchToProps);
