import { buildProjectApiUrl } from "../../../configuration";
import { ProjectPage } from "api/project/model";
import { http } from "../../../http";

export const fetchBonusPaymentPage = (
  page: number,
  size: number,
  operatorId?: string,
  startDate?: number,
  endDate?: number,
  status?: string
): Promise<ProjectPage> => {
  let path = `/bonuspayments?page=${page}&size=${size}`;
  if (operatorId) path = `${path}&operatorId=${operatorId}`;
  if (startDate) path = `${path}&startDate=${startDate}`;
  if (endDate) path = `${path}&endDate=${endDate}`;
  if (status) path = `${path}&status=${status}`;
  return http.get(
    buildProjectApiUrl(path),
    "la récupération des paiements n'a pas pu être effectuée"
  );
};
