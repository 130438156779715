import { push } from "connected-react-router";

export type BonusMode = "view" | "edit";

const bonusList = (
  page: number,
  size: number,
  startDate?: number,
  endDate?: number,
  operatorId?: string,
  carrierId?: string,
  area?: string,
  status?: string
) => {
  let path = `/bonus?page=${page}&size=${size}`;
  if (startDate) path = `${path}&startDate=${startDate}`;
  if (endDate) path = `${path}&endDate=${endDate}`;
  if (operatorId) path = `${path}&operatorId=${operatorId}`;
  if (carrierId) path = `${path}&carrierId=${carrierId}`;
  if (area) path = `${path}&area=${area}`;
  if (status) path = `${path}&status=${status}`;
  return push(path);
};

const bonusAdd = () => push("bonus/add");

const bonusSectionView = (reportId: string, mode: BonusMode) =>
  push(`/bonus/${reportId}/${mode}`);

const bonusView = (bonusId: string) => bonusSectionView(bonusId, "view");
const bonusEdit = (bonusId: string) => bonusSectionView(bonusId, "edit");

export const gotoBonus = {
  bonusList: bonusList,
  bonusView: bonusView,
  bonusAdd: bonusAdd,
  bonusEdit: bonusEdit,
};
