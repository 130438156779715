import { fetchGeoZone } from "./query/fetchGeoZoneQuery";
import { city as cityPlat } from "plateform-plateform-commons";
import { city as cityGeo } from "plateform-geozone-domain";
import { fetchTerritories } from "./query/fetchTerritoriesQuery";

export interface GeoZonePage
  extends cityPlat.colisactiv.platform.commons.Page<GeoZone> {}

export interface GeoZone
  extends cityGeo.colisactiv.platform.geozone.model.GeoZone {}

export interface Territory
  extends cityGeo.colisactiv.platform.geozone.model.Territory {}

export interface TerritoryPage
  extends cityPlat.colisactiv.platform.commons.Page<Territory> {}

export const geoZoneClient = {
  getGeoZones: fetchGeoZone,
  getTerritories: fetchTerritories,
};
