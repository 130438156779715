import { connect } from "react-redux";
import { State } from "store";
import { router } from "store/router";
import { alert } from "store/alert";
import { profile } from "store/profile";
import { notification } from "store/notification";

const mapStateToProps = (state: State) => ({
  currentUser: profile.selectors.User(state),
  unSeenNumber: notification.selectors.unSeenNumber(state),
});

const mapDispatchToProps = {
  gotoDashboard: router.goto.dashboard,
  gotoApi: router.goto.api,
  gotoReports: router.goto.report.reportsList,
  gotoBonus: router.goto.bonus.bonusList,
  gotoNotifications: router.goto.notifications,
  gotoProjectsList: router.goto.project.projectsList,
  gotoOrganizationsList: router.goto.organization.organizationsList,
  gotoOrganization: router.goto.organization.organizationView,
  onRemoveAlert: alert.actions.remove,
  fetchProfile: profile.queries.fetchProfile,
  usersView: router.goto.user.usersView,
  gotoBonusPayments: router.goto.bonusPayment.bonusPaymentsList,
  gotoCampaignDelivery: router.goto.campaignDelivery,
};

export default connect(mapStateToProps, mapDispatchToProps);
