import { makeStyles } from '@material-ui/core'
import React, { Fragment, useCallback, useState } from 'react'
import { InputForm } from '../../Component/InputForm'
import { AutoComplete } from '../../Component/AutoComplete'
import { PeriodPicker, Period } from '../../Component/PeriodPicker'
import { city as cityGeo } from 'plateform-geozone-domain'
import { city as cityPlat } from 'plateform-plateform-commons'

const useStyles = makeStyles(() => ({
  filter: {
    width: '200px',
    marginRight: '10px',
    ['@media (max-width: 800px)']: {
      width: '100% !important',
      marginRight: '0 !important'
    },
    '& .MuiOutlinedInput-root': {
      height: '44px !important'
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
      marginTop: '0px'
    },
    '& .MuiInputLabel-filled ': {
      transform: ' translate(12px, 25px) scale(1)'
    }
  },
  icon: {
    color: '#9a9a9a',
    cursor: 'pointer'
  },
  container: {
    display: 'flex'
  }
}))

export interface Territory
  extends cityGeo.colisactiv.platform.geozone.model.Territory {}
export interface TerritoryPage
  extends cityPlat.colisactiv.platform.commons.Page<Territory> {}

export interface DashboardFiltersModel {
  period?: Period
  status?: string
  operatorId?: string
  carrierId?: string
  territory?: Territory
}

export type Options = {
  label: string
  value: string
}

interface DashboardFiltersProps {
  operators: Options[]
  carriers: Options[]
  filterValues: DashboardFiltersModel
  getTerritories: (
    page: number,
    size: number,
    name: string
  ) => Promise<TerritoryPage>
  onFilterChange: (values: DashboardFiltersModel) => void
  canSeeOperatorFilter: boolean
  canSeeCarrierFilter: boolean
}

export const DashboardFilters = (props: DashboardFiltersProps) => {
  const {
    filterValues,
    onFilterChange,
    carriers,
    operators,
    getTerritories,
    canSeeCarrierFilter,
    canSeeOperatorFilter
  } = props
  const classes = useStyles()
  const [selectedPeriod, setSelectedPeriod] = useState<Period | undefined>(
    filterValues?.period?.startDate == null
      ? undefined
      : {
          startDate: filterValues.period.startDate!,
          endDate: filterValues.period.startDate!
        }
  )

  const handlePeriodChange = useCallback(
    (period: Period | undefined) => {
      setSelectedPeriod(period)

      onFilterChange({
        period: period,
        operatorId: filterValues.operatorId,
        carrierId: filterValues.carrierId,
        status: filterValues.status,
        territory: filterValues.territory
      })
    },
    [onFilterChange, filterValues]
  )

  const handleOperatorChange = useCallback(
    (operator?: string) => {
      onFilterChange({
        period: selectedPeriod,
        operatorId: operator,
        carrierId: filterValues.carrierId,
        status: filterValues.status,
        territory: filterValues.territory
      })
    },
    [onFilterChange, filterValues]
  )

  const handleCarrierChange = useCallback(
    (carrier?: string) => {
      onFilterChange({
        period: selectedPeriod,
        operatorId: filterValues.operatorId,
        carrierId: carrier,
        status: filterValues.status,
        territory: filterValues.territory
      })
    },
    [onFilterChange, filterValues]
  )

  const handleTerritoryChange = useCallback(
    (territory: Territory | Territory[]) => {
      const singleTerritory = Array.isArray(territory)
        ? territory[0]
        : territory

      onFilterChange({
        period: selectedPeriod,
        operatorId: filterValues.operatorId,
        carrierId: filterValues.carrierId,
        status: filterValues.status,
        territory: singleTerritory
      })
    },
    [onFilterChange, filterValues]
  )

  const [autocompleteText, setAutocompleteText] = useState(
    'Rechercher un territoire'
  )
  const [autocompleteOptions, setAutocompleteOptions] = useState<Territory[]>(
    []
  )

  const handleSearchTerritory = useCallback(async (search: string) => {
    if (search.trim().length === 0) {
      setAutocompleteOptions([])
      setAutocompleteText('Rechercher un territoire')
      return
    }

    try {
      const response = await getTerritories(0, 5, search.trim())
      setAutocompleteOptions(response.list)
      if (response.list.length === 0) {
        setAutocompleteText('Aucun territoire trouvé')
      }
    } catch (err) {
      console.error(err)
      setAutocompleteOptions([])
      setAutocompleteText('Une erreur est survenue, veuillez réessayer')
    }
  }, [])

  const getTerritoryName = useCallback((territory: Territory) => {
    return territory.name
  }, [])

  return (
    <Fragment>
      <PeriodPicker
        id='filterPeriod'
        placeholder={'Période'}
        period={selectedPeriod}
        onDateChange={handlePeriodChange}
        inputClassName={classes.filter}
        clearable
      />
      {canSeeOperatorFilter && (
        <InputForm
          inputType={'select'}
          onChange={handleOperatorChange}
          selectOptions={operators}
          value={filterValues.operatorId}
          onRemoveValue={() => handleOperatorChange(undefined)}
          label={''}
          placeHolder={'Opérateur'}
          className={`${classes.filter} project-filters`}
          id='dashboard-filters-operator'
        />
      )}
      {canSeeCarrierFilter && (
        <InputForm
          inputType={'select'}
          onChange={handleCarrierChange}
          selectOptions={carriers}
          value={filterValues.carrierId}
          onRemoveValue={() => handleCarrierChange(undefined)}
          label={''}
          placeHolder={'Transporteur'}
          className={`${classes.filter} project-filters`}
          id='dashboard-filters-carrier'
        />
      )}
      <AutoComplete<Territory>
        id='dashboard-filters-territory'
        onChangeSelectedElement={handleTerritoryChange}
        options={autocompleteOptions}
        defaultValue={filterValues.territory}
        label=''
        onSearch={handleSearchTerritory}
        noOptionsText={autocompleteText}
        getOptionLabel={getTerritoryName}
        placeholder='Territoire'
        className={classes.filter}
      />
    </Fragment>
  )
}
