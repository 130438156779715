import { buildBonusGroupApiUrl } from "api/configuration";
import { city } from "plateform-bonus-group-domain";
import { http } from "../../http";

export interface BonusGroupInvoiceDeletedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupInvoiceDeletedEvent {
  type: string;
}
export const deleteInvoice = (
  bonusGroupId: string,
  invoiceId: string
): Promise<BonusGroupInvoiceDeletedEvent> => {
  return http.delete(
    buildBonusGroupApiUrl(`/bonusgroup/${bonusGroupId}/invoice/${invoiceId}`),
    "La facture a bien été supprimée",
    true
  );
};
