import { createSelector } from "reselect";
import { State } from "..";

const getAlertState = (state: State) => state.alert;

export const getAlerts = createSelector(
  [getAlertState],
  (state) => state.alerts
);

export const selectors = {
  alerts: getAlerts,
};
