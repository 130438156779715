import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { UTCToTimedZone } from '../../utils'

export interface DateIntervalLabelProps {
  start: number
  end: number
}

export const DateIntervalLabel = (props: DateIntervalLabelProps) => {
  const { start, end } = props
  return (
    <Box
      display='flex'
      justifyContent='center'
      width='100%'
      alignItems='center'
      flexWrap='wrap'
    >
      <Typography variant='body2' style={{ marginRight: 10 }}>
        Du{' '}
        <p style={{ fontWeight: 600, display: 'inline' }}>
          {UTCToTimedZone(start).toLocaleDateString()}
        </p>
      </Typography>
      <Typography variant='body2'>
        Au{' '}
        <p style={{ fontWeight: 600, display: 'inline' }}>
          {UTCToTimedZone(end).toLocaleDateString()}
        </p>
      </Typography>
    </Box>
  )
}
