import { Dispatch } from "redux";
import { actions } from "./organization.actions";
import { State } from "../index";
import { organizationClient } from "../../api/organization";
import { selectors } from "./organization.selectors";

const fetchOrganization: (organizationId: string) => void = (
  organizationId: string
) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      const state = getState();
      const organization = selectors.organization(state);
      if (!organization || organization.id !== organizationId) {
        dispatch(actions.fetchOrganization(organizationId));

        const newOrganization = await organizationClient.query.getOrganization(
          organizationId
        );
        dispatch(actions.fetchedOrganization(newOrganization ?? null));
      }
    } catch (error) {
      // dispatch(addTodo.error(error))
    }
  };
};
const fetchConvention: (organizationId: string) => void = (
  organizationId: string
) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(actions.fetchConvention(organizationId));

    const convention = await organizationClient.query.fetchConvention(
      organizationId
    );
    dispatch(actions.fetchedConvention(convention || null));
  };
};

export const queries = {
  fetchOrganization: fetchOrganization,
  fetchConvention: fetchConvention,
};
