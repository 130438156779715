import { queries } from "./project.queries";
import { actions } from "./project.actions";
import { commands } from "./project.command";
import { selectors } from "./project.selectors";
export const project = {
  actions: actions,
  queries: queries,
  commands: commands,
  selectors: selectors,
};
