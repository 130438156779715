import { push } from "connected-react-router";

const bonusPaymentsList = (
  page: number,
  size: number,
  operatorId?: String,
  startDate?: Number,
  endDate?: Number,
  status?: string
) => {
  let path = `/bonusPayments?page=${page}&size=${size}`;
  if (operatorId) path = `${path}&operatorId=${operatorId}`;
  if (startDate) path = `${path}&startDate=${startDate}`;
  if (endDate) path = `${path}&endDate=${endDate}`;
  if (status) path = `${path}&status=${status}`;
  return push(path);
};
export const gotoBonusPayment = {
  bonusPaymentsList: bonusPaymentsList,
};
