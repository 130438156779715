import {
  CircleBicycleIcon,
  CircleBuildingIcon,
  CircleTruckIcon,
  DropPicture,
  ProfileLogo,
} from "components";
import React from "react";
import {
  Organization,
  OrganizationType,
  OrganizationTypeValues,
} from "api/organization";
import { OrganizationDetailsFormState } from "./model";
import { getOrganizationLogoUrl } from "api/organization/utils";

export interface OrganizationDropLogoProps {
  organization: OrganizationDetailsFormState;
  readOnly: boolean;
  setLogo: (logo?: File) => void;
}

const OrganizationDropLogo = (props: OrganizationDropLogoProps) => {
  const { organization, readOnly, setLogo } = props;

  const defaultLogo = getDefaultOrganizationLogo(organization.type);
  return (
    <DropPicture
      onPictureDroped={(picture) => setLogo(picture)}
      onRemovePicture={() => setLogo(undefined)}
      readonly={readOnly}
      src={
        organization.logo && organization.logo.src ? organization.logo.src : ""
      }
      defaultLogoComponent={defaultLogo}
    />
  );
};

export const getOrganizationLogo = (org: Organization): JSX.Element => {
  if (org.hasLogo) {
    return (
      <ProfileLogo
        data-tag="___react-data-table-allow-propagation___"
        alt="Le logo de l'entreprise"
        src={getOrganizationLogoUrl(org.id)}
        style={{ width: "50px", marginTop: "3px", borderRadius: "5px" }}
      />
    );
  }
  return getDefaultOrganizationLogo(org.organizationType, true);
};

export const getDefaultOrganizationLogo = (
  type: OrganizationType | undefined,
  small?: boolean
): JSX.Element => {
  if (type !== undefined && OrganizationTypeValues.isCarrier(type.type)) {
    return (
      <CircleTruckIcon
        style={
          small
            ? {
                width: "50px",
                marginTop: "3px",
                height: "50px",
                marginRight: 10,
              }
            : undefined
        }
        data-tag="___react-data-table-allow-propagation___"
      />
    );
  }

  if (type !== undefined && OrganizationTypeValues.isOperator(type.type)) {
    return (
      <CircleBicycleIcon
        style={
          small
            ? {
                width: "50px",
                marginTop: "3px",
                height: "50px",
                marginRight: 10,
              }
            : undefined
        }
        data-tag="___react-data-table-allow-propagation___"
      />
    );
  }

  return (
    <CircleBuildingIcon
      style={
        small
          ? { width: "50px", marginTop: "3px", height: "50px", marginRight: 10 }
          : undefined
      }
      data-tag="___react-data-table-allow-propagation___"
    />
  );
};

export default OrganizationDropLogo;
