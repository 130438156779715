import { Campaign } from "api/project/model";
import { DateInterval } from "api/report";
import { city } from "plateform-project-domain";
import { buildProjectApiUrl } from "../../configuration";
import { http } from "../../http";
import { CampaignStatus } from "../model/CampaignStatus";

interface GetCampaignFilter
  extends city.colisactiv.project.model.features.query.GetCampaignFilter {}

export const fetchCampaignsFiltered = (
  operatorId: string,
  territoryCode: string,
  period: DateInterval,
  status: CampaignStatus
): Promise<Campaign[]> => {
  const body: GetCampaignFilter = {
    operatorId: operatorId,
    territoryCode: territoryCode,
    period: period,
    status: status,
  };
  return http.post(buildProjectApiUrl(`/campaigns`), JSON.stringify(body));
};
