import { BonusGroup } from "api/bonusGroup";
import { createActionCreator } from "deox";

const fetchedBonus = createActionCreator(
  "BONUS_FETCHED",
  (resolve) => (bonus: BonusGroup) => resolve({ bonus })
);
const createBonus = createActionCreator(
  "BONUS_CREATE",
  (resolve) => (bonus: BonusGroup) => resolve({ bonus })
);

const updatedBonus = createActionCreator(
  "BONUS_UPDATED",
  (resolve) => (bonus: BonusGroup) => resolve({ bonus })
);

const resetBonus = createActionCreator("CARRIER_REPORT_RESET");

export const actions = {
  fetchedBonus: fetchedBonus,
  createBonus: createBonus,
  updatedBonus: updatedBonus,
  resetBonus: resetBonus,
};
