import React, { useState } from 'react'
import { Menu, IconButton, makeStyles } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { MenuItemActionButton } from './MenuItemActionButton'

const ITEM_HEIGHT = 48

const useStyles = makeStyles(() => ({
  menu: {
    maxWidth: '300px',
    '& ul': {
      padding: '0'
    }
  }
}))

export interface MenuItemAction {
  key: string
  label: string
  goto?: () => void
  icon?: JSX.Element
  confirmMsg?: string
}

interface MoreOptionsProps {
  options: MenuItemAction[]
  handleClose: () => void
}

export const MoreOptions = (props: MoreOptionsProps) => {
  const { options, handleClose } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const classes = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const close = () => {
    handleClose()
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={close}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5
          },
          className: classes.menu
        }}
      >
        {options.map((option) => (
          <MenuItemActionButton item={option} key={option.key}/>
        ))}
      </Menu>
    </div>
  )
}
