import { connect } from "react-redux";
import { router } from "store/router";
import { State } from "../../../../store";
import { global } from "store/global";
import { bonusPayment } from "store/bonuspayment";
const mapStateToProps = (state: State) => ({
  organizationRefs: global.selectors.organizationRefs(state),
  operatorRefs: global.selectors.operatorRefs(state),
});

const mapDispatchToProps = {
  gotoBonus: router.goto.bonus.bonusView,
  gotoBonusPaymentList: router.goto.bonusPayment.bonusPaymentsList,
  payBonusPayment: bonusPayment.commands.payBonusPayment,
};

export default connect(mapStateToProps, mapDispatchToProps);
