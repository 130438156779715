import {
  BonusPrice,
  Campaign,
  CampaignDetails,
  CampaignFunder,
  Rule,
} from "api/project/model";
import * as R from "ramda";
import {
  BonusPriceFormValidity,
  CampaignFormState,
  CampaignFormValidation,
  CampaignFormValidity,
  CampaignFunderValidity,
  RulesFormValidity,
} from "./Model";

export const getValidation = (
  details: CampaignDetails,
  projectBudget?: number,
  budget?: number
): CampaignFormValidity => {
  return {
    name: details.name !== "",
    budget: budget != undefined && (!projectBudget || budget <= projectBudget),
    startDate: details.period.startDate !== "",
    endDate: details.period.endDate !== "",
  };
};

export const getValidationBonusPrice = (
  bonusPrice: BonusPrice[]
): [Boolean, BonusPriceFormValidity[]] => {
  const validity = bonusPrice.map((el: BonusPrice) => {
    let value =
      el.value !== undefined && el.value !== 0.0 && el.value.toString() !== "";
    let areas = el.areas !== undefined && el.areas.length !== 0;
    let operator = el.operator.length !== 0;
    return {
      value: value,
      areas: areas,
      operator: operator,
    };
  });
  const isValid = isBonusPriceValid(validity);
  return [isValid, validity];
};

export const getValidationCampaignFunders = (
  campaignFunders: CampaignFunder[]
): [Boolean, CampaignFunderValidity[]] => {
  const validity = campaignFunders.map(
    (el: CampaignFunder): CampaignFunderValidity => {
      return {
        funderId: el.funderId !== undefined && el.funderId !== "",
        budgetPercentage: el.budgetPercentage > 0 && el.budgetPercentage <= 100,
      };
    }
  );
  const isValid = isCampaignFunderValid(validity);
  return [isValid, validity];
};

export const getValidationDetails = (
  details: CampaignDetails,
  projectBudget: number,
  budget?: number
): [Boolean, CampaignFormValidity] => {
  const validity: CampaignFormValidity = {
    name: details.name !== "",
    budget: budget != undefined && budget <= projectBudget,
    startDate: details.period.startDate !== "",
    endDate: details.period.endDate !== "",
  };
  const isValid = isCampaignFormValid(validity);
  return [isValid, validity];
};

export const getValidationRules = (
  rules: Rule[]
): [Boolean, RulesFormValidity[]] => {
  const validity = rules.map((el: Rule) => {
    return {
      value: el.value !== "",
      type: el.rule !== null,
    };
  });
  const isValid = isRulesValid(validity);
  return [isValid, validity];
};

export const defaultCampaignFormValidity: CampaignFormValidity = {
  name: true,
  budget: true,
  startDate: true,
  endDate: true,
};

export const defaultBonusPriceFormValidity: BonusPriceFormValidity = {
  areas: true,
  operator: true,
  value: true,
};

export const defaultCamapaignFunderValidity: CampaignFunderValidity = {
  budgetPercentage: true,
  funderId: true,
};

export const defaultRuleFormValidity: RulesFormValidity = {
  value: true,
  type: true,
};

const campaignFormValidation = (
  campaignForm: CampaignFormState | Campaign,
  projectBudget?: number
): [Boolean, CampaignFormValidation] => {
  const campaignFormValidation: CampaignFormValidation = {
    validity: CampaignValidation.detailsValidation(
      campaignForm.details,
      projectBudget,
      campaignForm.budget
    ),
    bonusPriceValidation: CampaignValidation.bonusPriceValidation(
      campaignForm.bonusPrices
    )[1],
    campaignFundersValidation: CampaignValidation.CampaignFundersValidation(
      campaignForm.funders
    )[1],
    rulesValidation: CampaignValidation.rulesValidation(campaignForm.rules)[1],
  };
  const isValid = isFormValid(campaignFormValidation);
  return [isValid, campaignFormValidation];
};

export const isFormValid = (validation: CampaignFormValidation): Boolean => {
  const detailsIsValid = isCampaignFormValid(validation.validity);
  const bonusValidation = isBonusPriceValid(validation.bonusPriceValidation);
  const rulesValidation = isRulesValid(validation.rulesValidation);
  return detailsIsValid && bonusValidation && rulesValidation;
};

const isCampaignFormValid = (
  newValidity:
    | CampaignFormValidity
    | BonusPriceFormValidity
    | RulesFormValidity
    | CampaignFunderValidity
): boolean => {
  return R.values(newValidity).reduce(
    (previousValue, currentValue) => previousValue && currentValue
  );
};

const isBonusPriceValid = (values: BonusPriceFormValidity[]): Boolean => {
  const bonusValidation = values.map(isCampaignFormValid);
  return areValid(bonusValidation);
};

const isCampaignFunderValid = (values: CampaignFunderValidity[]): Boolean => {
  const bonusValidation = values.map(isCampaignFormValid);
  return areValid(bonusValidation);
};

const isRulesValid = (values: RulesFormValidity[]): Boolean => {
  const rulesValidation = values.map(isCampaignFormValid);
  return areValid(rulesValidation);
};

const areValid = (values: Boolean[]): Boolean => {
  return values.reduce(
    (previousValue, currentValue) => previousValue && currentValue
  );
};

export const CampaignValidation = {
  detailsValidation: getValidation,
  bonusPriceValidation: getValidationBonusPrice,
  CampaignFundersValidation: getValidationCampaignFunders,
  rulesValidation: getValidationRules,
  campaignFormValidation: campaignFormValidation,
};
