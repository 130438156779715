import { buildProjectApiUrl } from "api/configuration";
import { Territory } from "api/geoZone";
import { http } from "api/http";

export const getTerritoriesOfAllProjects = (): Promise<Territory[]> => {
  return http.get(
    buildProjectApiUrl(`/projects/territories`),
    "Une erreur est survenue"
  );
};
