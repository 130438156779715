import { makeStyles } from '@material-ui/core'
import { MenuItem } from '@smartb/archetypes-ui-layout'
import { city } from 'plateform-project-domain'
import { city as cityStats } from 'plateform-project-stats-domain'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MoreOptions } from '../../Component/MoreOptions'
import { ProgressBar } from '../../Component/ProgressBar'
import { StatusTags, StatusTagsFormProps } from '../../Component/StatusTags'
import { PrimaryTypography, Table } from '../../Component/Table'
import { displayNumber } from '../../utils'
interface Project extends city.colisactiv.project.model.Project {}
interface ProjectStats
  extends cityStats.colisactiv.plateform.project.stats.model.ProjectStats {}
interface ProjectFunder extends city.colisactiv.project.model.ProjectFunder {}

export interface ProjectRow {
  id: string
  name: string
  totalBudget: number
  consumedBudget: number
  activeDeliveries: number
  progressionPourcentage: number
  actions: MenuItem[]
  status: StatusTagsFormProps
}

const useStyles = makeStyles(() => ({
  container: {
    '& .rdt_TableRow .rdt_TableCell:nth-child(2n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(2n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(3n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(3n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(4n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(4n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(5n)': {
      minWidth: '140px'
    },
    '& .rdt_TableCol:nth-child(5n)': {
      minWidth: '140px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(6n)': {
      minWidth: '140px'
    },
    '& .rdt_TableCol:nth-child(6n)': {
      minWidth: '140px'
    },
    '& .rdt_TableRow .rdt_TableCell:last-child': {
      minWidth: '80px',
      maxWidth: '80px'
    },
    '& .rdt_TableCol:last-child': {
      minWidth: '80px',
      maxWidth: '80px'
    },
    '& .rdt_TableRow .rdt_TableCell:first-child': {
      minWidth: '150px'
    },
    '& .rdt_TableCol:first-child': {
      minWidth: '150px'
    }
  }
}))

interface ProjectTableProps {
  projects: ProjectStats[]
  onRowClicked?: (row: ProjectRow) => void
  totalPages: number
  page: number
  handlePageChange: (page: number) => void
  isLoading?: boolean
  getActions?: (row: ProjectStats) => MenuItem[]
  getStatus: (row: ProjectStats) => StatusTagsFormProps
}

export const ProjectTable = (props: ProjectTableProps) => {
  const {
    projects,
    onRowClicked,
    handlePageChange,
    page,
    totalPages,
    isLoading = false,
    getActions = () => [],
    getStatus
  } = props
  const calculateTotalBudget = (projectFunders: ProjectFunder[]): number => {
    let total = 0
    projectFunders.forEach((element) => {
      total = total + element.budget
    })
    return total
  }
  const { t } = useTranslation()
  const data: ProjectRow[] = projects.map(
    (project): ProjectRow => {
      const consumedBudget = project.totalBonusAmount
      const totalBudget = calculateTotalBudget(project.funders)
      return {
        id: project.id,
        name: project.details.name,
        totalBudget: totalBudget,
        consumedBudget: consumedBudget,
        activeDeliveries: project.activeDeliveryCount,
        progressionPourcentage:
          totalBudget !== 0 ? (consumedBudget * 100) / totalBudget : 100,
        actions: getActions(project),
        status: getStatus(project)
      }
    }
  )
  const classes = useStyles()

  const columns = [
    {
      name: 'Nom',
      cell: (row: ProjectRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.name}
        </PrimaryTypography>
      )
    },
    {
      name: 'Budget total',
      cell: (row: ProjectRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >{`${displayNumber(row.totalBudget)} €`}</PrimaryTypography>
      )
    },
    {
      name: 'Budget consommé',
      cell: (row: ProjectRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {`${displayNumber(row.consumedBudget)} €`}
        </PrimaryTypography>
      )
    },
    {
      name: 'Livraisons actives',
      cell: (row: ProjectRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {displayNumber(row.activeDeliveries)}
        </PrimaryTypography>
      )
    },
    {
      name: 'Progression',
      cell: (row: ProjectRow) => (
        <ProgressBar
          withLabel
          progressionPourcentage={Math.round(row.progressionPourcentage)}
        />
      )
    },
    {
      name: 'Statut',
      cell: (row: ProjectRow) => {
        const status = row.status
        return <StatusTags {...status} />
      }
    },
    {
      name: 'Action',
      cell: (row: ProjectRow) => (
        <MoreOptions handleClose={() => {}} options={row.actions} />
      )
    }
  ]
  return (
    <Table<ProjectRow>
      data={data}
      className={classes.container}
      columns={columns}
      onRowClicked={onRowClicked}
      handlePageChange={handlePageChange}
      totalPages={totalPages}
      page={page}
      isLoading={isLoading}
      noDataMessage={t('app_no_data_project_table')}
    />
  )
}
