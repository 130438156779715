import { Dispatch } from "redux";
import { actions } from "./user.actions";
import { usersClient } from "../../api/organization/users";

export const fetchUser = (organizationId: string, userId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchUser(userId));

      const user = await usersClient.queries.fetchUser(organizationId, userId);

      dispatch(actions.fetchedUser(user || null));
    } catch (error) {
      // dispatch(addTodo.error(error))
    }
  };
};

export const queries = {
  fetchUser: fetchUser,
};
