import React, { useEffect } from "react";
import { ComponentLoading } from "components";
import { notificationClient } from "api/notification";
import { useParams } from "react-router-dom";
import withConnect from "./withConnect";
import { notificationMapping } from "../../../components/NotificationsContainer/NotificationData";

interface NotificationRouterProps {
  goto: (url: string) => void;
}

const NotificationRouter = (props: NotificationRouterProps) => {
  const { goto } = props;
  const { notificationId } = useParams();

  useEffect(() => {
    handleFetchNotification();
  }, []);

  const handleFetchNotification = async () => {
    if (notificationId == undefined) {
      goto("");
      return;
    }
    const notif = await notificationClient.query.getNotification(
      notificationId
    );
    const notifData = notificationMapping.get(notif);
    goto(notifData.url!);
  };

  return <ComponentLoading />;
};

export default withConnect(NotificationRouter);
