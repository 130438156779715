import { Box, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { FileCopyOutlined, Loop, Visibility } from "@material-ui/icons";
import { Theme, themeContext } from "@smartb/archetypes-ui-components";
import { Organization, organizationClient } from "api/organization";
import { User } from "api/organization/users";
import { InputForm, PopUpConfirmation } from "components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
      alignContent: "flex-start",
    },
    input: {
      width: "70%",
    },
    inputForm: {
      marginTop: "20px",
      width: "350px",
      height: "48px",
    },
    icon: {
      color: "#9a9a9a",
      width: "22px",
      height: "22px",
    },
    iconButton: {
      width: "40px",
      height: "40px",
      "&:hover svg": {
        color: theme.secondaryColor,
      },
    },
    loopButton: {
      "&:hover svg": {
        animation: "$rotate-center 0.5s ease-in-out 1",
        color: "#b31d0c",
      },
    },
    iconContainer: {
      position: "absolute",
      width: "40px",
      right: "-40px",
      top: "4px",
    },
    "@keyframes rotate-center": {
      "0%": {
        transform: "rotate(0)",
      },
      "100%": {
        transform: "rotate(-360deg)",
      },
    },
    copyIcon: {
      position: "absolute",
      right: "-45px",
      "&:hover svg": {
        color: theme.secondaryColor,
      },
    },
  }));

interface ApiKeyProps {
  organization: Organization | null;
  fetchOrganization: (organizationId: string) => void;
  currentUser: User | null;
}

export const ApiKey = (props: ApiKeyProps) => {
  const { organization, currentUser, fetchOrganization } = props;
  const theme = useContext(themeContext);
  const classes = useStyles(theme)();
  const { t } = useTranslation();

  const [secret, setSecret] = useState("");
  const [askConfirmation, setAskConfirmation] = useState(false);

  useEffect(() => {
    if (currentUser) {
      fetchOrganization(currentUser.details.organizationId);
    }
  }, [fetchOrganization, currentUser]);

  const updateApplications = useCallback(async () => {
    if (currentUser && organization && organization.applications.length === 0) {
      await organizationClient.createApplication(organization.id);
      fetchOrganization(currentUser.details.organizationId);
    }
  }, [currentUser, organization, fetchOrganization]);

  useEffect(() => {
    updateApplications();
  }, [organization?.applications, updateApplications]);

  const getSecret = async () => {
    if (organization) {
      const applicationSecret = await organizationClient.query.getSecret(
        organization.id,
        organization.applications[0].keycloackId
      );
      if (applicationSecret !== undefined) setSecret(applicationSecret);
    }
  };

  const updateSecret = async () => {
    setAskConfirmation(false);
    if (organization) {
      const applicationSecret = await organizationClient.updateApplicationSecret(
        organization.id,
        organization.applications[0].keycloackId
      );
      if (applicationSecret !== undefined)
        setSecret(applicationSecret.payload.clientSecret);
    }
  };

  return (
    <Box className={classes.container}>
      <Box
        position="relative"
        maxWidth="100%"
        display="flex"
        marginRight="50px"
      >
        <InputForm
          id="apiKey-id"
          inputType="textField"
          label={t("app_page_api_user_id")}
          inputClassName={classes.input}
          className={classes.inputForm}
          value={
            organization && organization.applications.length >= 1
              ? organization.applications[0].clientId
              : ""
          }
        />
        <Tooltip title="Copier l'identifiant">
          <IconButton
            onClick={() =>
              organization &&
              organization.applications.length >= 1 &&
              navigator.clipboard.writeText(
                organization.applications[0].clientId
              )
            }
            className={classes.copyIcon}
          >
            <FileCopyOutlined className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box position="relative" maxWidth="100%" display="flex">
        <InputForm
          id="apiKey-key"
          inputType="textField"
          label={t("app_page_api_secret")}
          inputClassName={classes.input}
          className={classes.inputForm}
          value={secret === "" ? "************************" : secret}
        />
        <Box
          display="flex"
          flexDirection="column"
          className={classes.iconContainer}
        >
          {secret === "" ? (
            <Tooltip title="Voir la clé">
              <IconButton onClick={getSecret} className={classes.iconButton}>
                <Visibility className={classes.icon} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Copier la clé">
              <IconButton
                onClick={() => navigator.clipboard.writeText(secret)}
                className={classes.iconButton}
              >
                <FileCopyOutlined className={classes.icon} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Générer une nouvelle clé">
            <IconButton
              onClick={() => setAskConfirmation(true)}
              className={`${classes.iconButton} ${classes.loopButton}`}
            >
              <Loop className={classes.icon} />
            </IconButton>
          </Tooltip>
          <PopUpConfirmation
            title={t(
              "app_page_api_are_you_sure_you_want_to_generate_a_new_secret_key"
            )}
            onClose={() => setAskConfirmation(false)}
            onConfirm={updateSecret}
            open={askConfirmation}
            strongConfirmation
          />
        </Box>
      </Box>
    </Box>
  );
};
