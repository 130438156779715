import React, { useState, useContext } from 'react'
import {
  App as SBApp,
  Menu,
  ToolsMenuProps,
  MenuItem
} from '@smartb/archetypes-ui-layout'

import { makeStyles, Theme } from '@material-ui/core'
import {
  Theme as SBTheme,
  themeContext
} from '@smartb/archetypes-ui-components'
import { appLayoutStyleProps, theme as caTheme } from '../../Theme'

const useStyles = (theme: SBTheme) =>
  makeStyles((MuiTheme: Theme) => ({
    toggleButton: {
      '& span': {
        color: '#353945'
      }
    },
    title: {
      '& span': {
        color: '#007DCE',
        fontWeight: 600,
        fontSize: '25px',
        fontFamily: '"Montserrat", Arial !important'
      }
    },
    appBar: {
      background: '#FFFFFF',
      color: 'black',
      height: '80px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
      justifyContent: 'center',
      '& .loCRgl': {
        marginRight: '0 !important'
      },
      width: 'calc(100% - 85px)',
      marginLeft: '85px',
      transition: MuiTheme.transitions.create(['margin', 'width'], {
        easing: MuiTheme.transitions.easing.sharp,
        duration: MuiTheme.transitions.duration.leavingScreen
      })
    },
    appBarOpen: {
      width: 'calc(100% - 150px)',
      marginLeft: '150px',
      transition: MuiTheme.transitions.create(['margin', 'width'], {
        easing: MuiTheme.transitions.easing.easeOut,
        duration: MuiTheme.transitions.duration.enteringScreen
      })
    },
    appContainer: {
      background: theme.tertiaryColor,
      marginTop: '0px !important',
      padding: '0px !important',
      paddingTop: `${appLayoutStyleProps.appBarHeight}px !important`,
      height: `calc(100vh - ${appLayoutStyleProps.appBarHeight}px) `
    },
    appContainerClosed: {
      marginLeft: '85px !important'
    },
    drawer: {
      '& .MuiDrawer-paper': {
        border: 'none',
        background: `${caTheme.nav.color.background} !important`,
        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.09) !important',
        width: '150px !important',
        height: '100vh !important',
        top: '0 ! important',
        zIndex: '1000 !important',
        transform: 'none !important',
        visibility: 'visible !important',
        transition: 'width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms !important'
      }
    },
    drawerClosed: {
      '& .MuiDrawer-paper': {
        width: '85px !important',
        transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important'
      }
    },
    content: {
      background: 'transparent !important',
      padding: '0px !important',
      height: `100%!important`
    },
    logo: {
      display: 'none',
      position: 'absolute'
    },
    drawerMenu: {
      '& .MuiTypography-root': {
        marginTop: '3px',
        fontSize: '15px',
        textAlign: 'center',
        color: caTheme.nav.color.text
      },
      '& .MuiButtonBase-root': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
      '& .MuiButtonBase-root:hover': {
        background: 'rgba(0, 0, 0, 0.1)'
      },
      '& .MuiButtonBase-root:not(:first-child)': {
        width: '80%',
        marginLeft: '10%',
        borderRadius: '5px'
      },
      '& .MuiListItemIcon-root': {
        display: 'block',
        minWidth: '56px',
        textAlign: 'center'
      }
    },
    drawerMenuClosed: {
      '& .MuiButtonBase-root': {
        flexDirection: 'unset',
        justifyContent: 'center',
        minHeight: '80px'
      }
    },
    iconButton: {
      borderRadius: '5px',
      background: 'transparent',
      display: 'flex',
      justifyContent: 'space-around',
      padding: '5px',
      minWidth: '110px',
      '& .MuiTouchRipple-root': {
        display: 'none'
      }
    }
  }))

interface AppProps {
  accountMenu: Menu
  children?: React.ReactNode
  navBarContent?: React.ReactNode
  drawerMenu?: MenuItem[]
  drawerContent?: React.ReactNode
  onToggle: () => void
  open: boolean
}

export const AppLayout = (props: AppProps) => {
  const {
    accountMenu,
    children,
    navBarContent,
    drawerMenu,
    drawerContent,
    onToggle,
    open
  } = props

  const theme = useContext(themeContext)
  const classes = useStyles(theme)()

  const account: ToolsMenuProps = {
    menu: accountMenu,
    display: 'list',
    classes: { iconButton: classes.iconButton }
  }

  return (
    <SBApp
      logo={''}
      open={open}
      onToggle={onToggle}
      styleProps={appLayoutStyleProps}
      toolsMenuProps={[account]}
      appBarProps={{
        classes: {
          menuButton: classes.toggleButton,
          title: classes.title,
          logo: classes.logo
        },
        className: `${classes.appBar} ${open && classes.appBarOpen}`
      }}
      classes={{
        main: `${classes.appContainer} ${!open && classes.appContainerClosed}`,
        content: classes.content
      }}
      drawerMenuProps={{
        classes: {
          drawer: `${classes.drawer} ${!open && classes.drawerClosed}`,
          menu: `${classes.drawerMenu} ${!open && classes.drawerMenuClosed}`
        }
      }}
      navBarContent={navBarContent}
      drawerContent={drawerContent}
      menu={drawerMenu}
    >
      {children}
    </SBApp>
  )
}
