import { OrganizationRef } from "api/organization";
import { InputForm, Option } from "components";
import React, { useCallback, useEffect, useState } from "react";

interface BonusFormOrganizationSelectProps {
  id: string;
  inputClassName: string;
  selectedId?: string;
  organizations: Map<string, OrganizationRef>;
  label: string;
  error: boolean;
  errorMessage: string;
  readonly?: boolean;
  onSelect: (organizationId?: string) => void;
}

const BonusFormOrganizationSelect = (
  props: BonusFormOrganizationSelectProps
) => {
  const {
    error,
    errorMessage,
    id,
    inputClassName,
    label,
    organizations,
    selectedId,
    readonly,
    onSelect,
  } = props;

  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    const options = Array.from(organizations.values()).map((organization) => ({
      label: organization.displayName,
      value: organization.organizationId,
    }));
    setOptions(options);
  }, [organizations]);

  const handleOrganizationChange = useCallback(
    (organizationId?: string) => {
      onSelect(organizationId);
    },
    [onSelect]
  );

  return (
    <InputForm
      inputType="select"
      value={selectedId}
      id={id}
      selectOptions={options}
      onChange={handleOrganizationChange}
      label={label}
      error={error}
      errorMessage={errorMessage}
      readonly={readonly}
      className={inputClassName}
    />
  );
};

export default BonusFormOrganizationSelect;
