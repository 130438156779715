import React from 'react'
import { ReactComponent } from './hour-glass.svg'
import { MergeReactElementProps } from '../types'

interface HourGlassIconProps {
  color?: string
}

type Props = MergeReactElementProps<'svg', HourGlassIconProps>

export const HourGlassIcon = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { color = 'white' } = props
    return (
      <ReactComponent
        stroke={color}
        fill='#69B6A3'
        ref={ref}
        {...props}
        stroke-width='2'
      />
    )
  }
)
