import { city } from "plateform-bonus-group-domain";
import { buildBonusGroupApiUrl } from "api/configuration";
import { http } from "../../http";

export interface DeduceBonusGroupCommand
  extends city.colisactiv.plateform.bonus.group.model.features
    .DeduceBonusGroupCommand {}

export interface BonusGroupStatusUpdatedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupStatusUpdatedEvent {
  type: string;
}

export const createBonusGroupInvoiceCommand = (
  bonusGroupId: string
): DeduceBonusGroupCommand => {
  return {
    type: "CREATE_BONUS_GROUP_INVOICE",
    payload: {
      bonusGroupId: bonusGroupId,
    },
  };
};

export const deduceBonusGroup = (
  bonusGroupId: string
): Promise<BonusGroupStatusUpdatedEvent> => {
  const url = buildBonusGroupApiUrl(`/bonusgroup/${bonusGroupId}/deduce`);
  const body = JSON.stringify(createBonusGroupInvoiceCommand(bonusGroupId));
  return http.put(url, body, "La facture a bien été ajoutée");
};
