import { useTranslation } from "react-i18next";
import React from "react";
import { Notification } from "components";
import { makeStyles } from "@material-ui/core";
import { Info } from "@material-ui/icons";

const useStyles = makeStyles({
  link: {
    fontWeight: "bolder",
    textDecoration: "underline",
    color: "#6697b8",
  },
  notificationContainer: {
    width: "600px",
    marginLeft: "15px",
  },
});

interface BonusesNotificationBoxProps {
  gotoCampaignDelivery: () => void;
}

export const BonusesNotificationBox = (props: BonusesNotificationBoxProps) => {
  const { gotoCampaignDelivery } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const messageLink = (
    <span className={classes.link}>
      {t("app_bonus_list_notification_rejected_delivery_see")}
    </span>
  );
  return (
    <Notification
      severity={"information"}
      icon={<Info style={{ color: "rgb(33, 150, 243)" }} />}
      onClick={() => gotoCampaignDelivery()}
      className={classes.notificationContainer}
    >
      {t("app_bonus_list_notification_rejected_delivery")} {messageLink}
    </Notification>
  );
};
