import * as R from "ramda";
import { PaymentInfoFormState, PaymentInfoFormValidity } from "./model";
import { NO_ERROR } from "../SiretAutocomplete";
import i18n from "i18n";
import { isValidIBAN, isValidBIC } from "ibantools";

export const getValidation = (
  payment: PaymentInfoFormState
): PaymentInfoFormValidity => {
  return {
    iban: getIbanError(payment.iban),
    bic: getBicError(payment.bic),
    tva: getTvaError(payment.tva),
  };
};

function getIbanError(iban: string) {
  if (isEmpty(iban)) {
    return i18n.t("app_organization_payment_form_empty_iban");
  }
  if (!isValidIBAN(iban)) {
    return i18n.t("app_organization_payment_form_incorrect_iban");
  }
  return NO_ERROR;
}

function getBicError(bic: string) {
  if (isEmpty(bic)) {
    return i18n.t("app_organization_payment_form_empty_bic");
  }
  if (!isValidBIC(bic)) {
    return i18n.t("app_organization_payment_form_incorrect_bic");
  }
  return NO_ERROR;
}

function getTvaError(tva: string) {
  if (isEmpty(tva)) {
    return i18n.t("app_organization_payment_form_empty_tva");
  }
  return NO_ERROR;
}

function isEmpty(string: string) {
  return string === undefined || string === "";
}

export const isFormValid = (newValidity: PaymentInfoFormValidity): boolean => {
  return R.values(newValidity)
    .map((value) => value == NO_ERROR)
    .reduce((previousValue, currentValue) => previousValue && currentValue);
};

export const defaultPaymentInfoFormValidity: PaymentInfoFormValidity = {
  iban: NO_ERROR,
  bic: NO_ERROR,
  tva: NO_ERROR,
};

export const defaultPaymentInfoFormState: PaymentInfoFormState = {
  iban: "",
  bic: "",
  tva: "",
  validity: defaultPaymentInfoFormValidity,
};

export const PaymentInfoValidation = {
  validation: getValidation,
  isFormValid: isFormValid,
  defaultPaymentInfoFormValidity: defaultPaymentInfoFormValidity,
  defaultPaymentInfoFormState: defaultPaymentInfoFormState,
};
