import { ProjectStats } from "../model";
import { buildProjectApiUrl } from "api/configuration";
import { http } from "api/http";

export const getProject = (projectId: string): Promise<ProjectStats> => {
  return http.get(
    buildProjectApiUrl(`/projectStats/${projectId}`),
    "Une erreur est survenue"
  );
};
