import { connect } from "react-redux";
import { State } from "store";
import { project } from "store/project";
import { router } from "store/router";
import { global } from "store/global";

const mapStateToProps = (state: State) => ({
  funderRefs: global.selectors.funderRefs(state),
});

const mapDispatchToProps = {
  updateProject: project.commands.updateProject,
  gotoProjectGeneralView: router.goto.project.projectView,
  gotoProjectEdit: router.goto.project.projectEdit,
};

export default connect(mapStateToProps, mapDispatchToProps);
