import React from "react";
import { MergeReactElementProps } from "components";

export type ColisActivLogoColor = "multi" | "white";

interface ColisActivLogoProps {
  height?: number | string;
  width?: number | string;
  color?: ColisActivLogoColor;
}

type Props = MergeReactElementProps<"svg", ColisActivLogoProps>;

export const getLogoSrc = (color?: ColisActivLogoColor) => {
  if (color == "white") return "/assets/colisactivWhite.png";
  return "/assets/colisactiv.png";
};

export const ColisActivLogoInner = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { height = 150, width = 150, color = "multi" } = props;
    const src = getLogoSrc(color);
    return <img height={height} width={width} src={src} />;
  }
);
