import { queries } from "./organization.queries";
import { selectors } from "./organization.selectors";
import { actions } from "./organization.actions";
import { commands } from "./organization.command";

export const organization = {
  actions: actions,
  selectors: selectors,
  queries: queries,
  commands: commands,
};
