import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { BackButton } from '../BackButton'

const useStyles = makeStyles(() => ({
  buttonContainer: {
    position: 'relative',
    width: '100%'
  },
  button: {
    position: 'absolute',
    right: '30px'
  },
  backButton: {
    margin: '20px'
  }
}))

export interface SectionProps {
  actions?: React.ReactElement[]
  withGoBack?: boolean
  children?: React.ReactNode
}

export const Section = (props: SectionProps) => {
  const { actions = [], children, withGoBack } = props
  const classes = useStyles()
  const keyAction = actions.map((component, index) =>
    React.cloneElement(component, { key: `action-${index}` })
  )
  return (
    <Box height='100%'>
      <div className={classes.buttonContainer}>{keyAction}</div>
      {!!withGoBack && <BackButton className={classes.backButton} />}
      {children}
    </Box>
  )
}
