import React from 'react'
import { ReactComponent } from './TrafficLights.svg'
import { MergeReactElementProps } from '../types'

interface TrafficLightsProps {
  color?: string
}

type Props = MergeReactElementProps<'svg', TrafficLightsProps>

export const TrafficLights = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { color = '#FFFFFF' } = props
    return <ReactComponent stroke={color} ref={ref} {...props} />
  }
)
