import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from '../../Theme'
import { TitleHeadBar } from './TitleHeadBar'
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core'
import { SaveCSV } from '../../Icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  title: {
    fontSize: '25px',
    color: theme.secondaryColor,
    fontWeight: 600
  },
  block: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  wrapper: {
    margin: 5,
    position: 'relative'
  },
  fabProgress: {
    color: '#4caf50',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1
  },
  icon: {
    width: '25px',
    height: '25px',
    color: '#4caf50'
  },
  iconButton: {
    padding: '8px'
  }
})

export interface HeadBarProps {
  title: string
  component?: JSX.Element
  downloadCSV?: () => void
}

export const HeadBar = (props: HeadBarProps) => {
  const { title, component, downloadCSV } = props
  const classes = useStyles()
  const content = !!component ? component : <TitleHeadBar title={title} />
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()

  const handleButtonClick = useCallback(async () => {
    if (!loading && downloadCSV) {
      setLoading(true)
      await downloadCSV()
      setLoading(false)
    }
  }, [loading, downloadCSV])

  const csvButton = downloadCSV && (
    <>
      <div style={{ flexGrow: 1 }} />
      {/* <div className={classes.wrapper}>
        <Tooltip
          title={t('app_components_headbar_csv_tooltip') as string}
          arrow
        >
          <IconButton
            className={classes.iconButton}
            onClick={handleButtonClick}
          >
            <SaveCSV className={classes.icon} />
          </IconButton>
        </Tooltip>
        {loading && (
          <CircularProgress size={40} className={classes.fabProgress} />
        )}
      </div> */}
    </>
  )

  return (
    <div className={classes.block}>
      {content}
      {csvButton}
    </div>
  )
}
