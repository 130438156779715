import { Dispatch } from "redux";
import { router } from "store/router";
import { BonusPaymentId } from "api/bonusPayment";
import { payBonusPaymentCommand } from "api/bonusPayment/features/command/payBonusPaymentCommand";
import { actions } from "./bonuspayment.actions";

const payBonusPayment = (ids: BonusPaymentId[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.payBonusPayment(ids));

    ids.map((id) => payBonusPaymentCommand(id));

    dispatch(actions.paidBonusPayment(ids));
    dispatch(router.goto.bonus.bonusList(0, 10));
  };
};

export const commands = {
  payBonusPayment: payBonusPayment,
};
