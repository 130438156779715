import { buildOrganizationApiUrl } from "api/configuration";
import { http } from "../../http";

export const fetchDocuSignUrl = (
  organizationId: string
): Promise<string | void> => {
  return http.getText(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/convention/url?redirectUrl=${window.location}`
    ),
    "La récupération de l'url docusign n'a pas pu être effectuée"
  );
};
