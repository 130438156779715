import { Dispatch } from "redux";
import { State } from "../index";
import { actions } from "./report.actions";
import { selectors } from "./reportselectors";
import { carrierReportClient } from "api/report";

export const fetchReport: (reportId: string) => void = (
    reportId: string
  ) => {
    return async (dispatch: Dispatch, getState: () => State) => {
      const state = getState();
      const report = selectors.report(state);
      if (!report || report.id !== reportId) { 
        dispatch(actions.fetchReport(reportId));
        const newReport = await carrierReportClient.query.getReport(reportId)    
        dispatch(actions.fetchedReport(newReport || null));
      }
    };
  };
export const queries = {
    fetchReport: fetchReport
};
