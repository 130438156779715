import { city } from "plateform-bonus-payment-domain";
import { BonusPaymentId } from "../../index";
import { http } from "api/http";
import { buildBonusPaymentApiUrl } from "api/configuration";

export interface OrganizationCreatedEvent
  extends city.colisactiv.platform.bonus.payment.domain.features.command
    .BonusPaymentPaidEvent {}

export const payBonusPaymentCommand = (
  id: BonusPaymentId
): Promise<OrganizationCreatedEvent> => {
  return http.put(
    buildBonusPaymentApiUrl(`/bonuspayments/${id}/status/paid`),
    null,
    "Le règlement a bien été payé"
  );
};
