import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
interface MonthDateProps {
  date: number
  withoutYear?: boolean
}

const monthNames: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const MonthDate = (props: MonthDateProps) => {
  const { date, withoutYear = false } = props
  const newDate = new Date(date)
  const { t } = useTranslation()
  return (
    <span>
      {withoutYear
        ? t(monthNames[newDate.getMonth()])
        : `${t(monthNames[newDate.getMonth()])} ${newDate.getFullYear()} `}
    </span>
  )
}

export const getMonthDate = (
  date: number,
  t: TFunction,
  withoutYear?: boolean
) => {
  const newDate = new Date(date)
  return withoutYear
    ? t(monthNames[newDate.getMonth()])
    : `${t(monthNames[newDate.getMonth()])} ${newDate.getFullYear()} `
}
