import React, { useState, useEffect } from 'react'
import { Snackbar } from './Snackbar'
import { city as cityPlat, city } from 'plateform-plateform-commons'

export interface Alert extends city.colisactiv.platform.commons.Alert<{}> {}

export type Severity = 'success' | 'info' | 'warning' | 'error'

export const getSeverity = (
  severity: city.colisactiv.platform.commons.AlertSeverity
): Severity => {
  return severity.severity as Severity
}

export interface AlertHandlerProps {
  alerts: Alert[]
  onRemoveAlert: (index: number) => void
}

export const AlertHandler = (props: AlertHandlerProps) => {
  const { alerts, onRemoveAlert } = props
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (alerts.length !== 0) setOpen(true)
  }, [alerts])

  const onClose = (index: number) => {
    setOpen(false)
    onRemoveAlert(index)
  }

  return (
    <div>
      {alerts.length !== 0 && (
        <Snackbar
          onClose={() => onClose(alerts.length - 1)}
          severity={getSeverity(alerts[alerts.length - 1].severity)}
          message={alerts[alerts.length - 1].description}
          open={open}
        />
      )}
    </div>
  )
}
