import { Box, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { geoZoneClient, Territory } from "api/geoZone";
import { OrganizationRef } from "api/organization";
import { ProjectFunder } from "api/project/model";
import { AutoComplete, DeleteButton, InputForm, Option } from "components";
import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectMode } from "store/router/router.project.goto";
const useStyles = makeStyles(() => ({
  grid: {
    minWidth: 240,
  },
  inputForm: {
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
  },
  autocompleteStyle: {
    width: 200,
  },
  input: {
    width: "100%",
    // margin: "10px 0px",
  },
  box: {
    //marginBottom: 50,
    position: "relative",
  },
  zone: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
  },
  selectStyle: {
    width: "100%",
    justifyContent: "center",
    height: "100%",
  },
}));

interface FunderFormProps {
  projectFunder: ProjectFunder;
  onChangeValue: (position: number, projectFunder: ProjectFunder) => void;
  position: number;
  funderPercent: number;
  funderValidation: any;
  funderTableLength: number;
  deleteFunder: (position: number) => void;
  readOnly: boolean;
  funderRefs: Map<string, OrganizationRef>;
  oldProjectFundersLength: number;
  mode: ProjectMode;
}

const FunderForm = (props: FunderFormProps) => {
  const {
    projectFunder,
    funderPercent,
    position,
    onChangeValue,
    funderValidation,
    funderTableLength,
    deleteFunder,
    readOnly,
    funderRefs,
    oldProjectFundersLength,
    mode,
  } = props;
  const classes = useStyles();

  const onFunderValueChange = (
    field: keyof ProjectFunder,
    value: string | number | string[]
  ) => {
    onChangeValue(position, {
      ...projectFunder,
      [`${field}`]: value,
    });
  };
  const handleFunderTerritoryChange = useCallback(
    (territory: Territory | Territory[]) => {
      const territories = Array.isArray(territory) ? territory : [territory];

      onChangeValue(position, {
        ...projectFunder,
        territories: territories,
      });
    },
    [onChangeValue, position, projectFunder]
  );

  const [autocompleteText, setAutocompleteText] = useState(
    "Rechercher un territoire"
  );
  const [autocompleteOptions, setAutocompleteOptions] = useState<Territory[]>(
    []
  );

  const handleSearchTerritory = useCallback(async (search: string) => {
    if (search.trim().length === 0) {
      setAutocompleteOptions([]);
      setAutocompleteText("Rechercher un territoire");
      return;
    }

    try {
      const response = await geoZoneClient.getTerritories(0, 5, search.trim());
      setAutocompleteOptions(response.list);
      if (response.list.length === 0) {
        setAutocompleteText("Aucun territoire trouvé");
      }
    } catch (err) {
      console.error(err);
      setAutocompleteOptions([]);
      setAutocompleteText("Une erreur est survenue, veuillez réessayer");
    }
  }, []);

  const getTerritoryName = useCallback((territory: Territory) => {
    return territory.name;
  }, []);

  const { t } = useTranslation();

  const funderOption: Option[] = Array.from(funderRefs.values()).map(
    (funder) => ({
      value: funder.organizationId,
      label: funder.displayName,
    })
  );
  const shouldDeleteIconAppear = (): boolean => {
    const isAddmode = mode === "add" && funderTableLength !== 1;
    const isEditMode =
      mode == "edit" &&
      funderTableLength !== 1 &&
      (!readOnly || (readOnly && position >= oldProjectFundersLength));
    return isAddmode || isEditMode;
  };
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.box}
      >
        <Grid container spacing={3}>
          <Grid item xs={2} md={2} className={classes.grid}>
            <InputForm
              inputType={"select"}
              inputClassName={classes.input}
              id="Funder-add-name"
              errorMessage={t("app_project_funder_please_insert_the_funder")}
              label={t("app_project_funder_financeur")}
              placeHolder={t("app_project_funder_select")}
              className={classes.inputForm}
              value={projectFunder.funderId}
              onChange={(value) => onFunderValueChange("funderId", value)}
              selectOptions={funderOption}
              error={!funderValidation.funderId}
              readonly={readOnly && position < oldProjectFundersLength}
            />
          </Grid>
          <Grid item xs={2} md={2} className={classes.grid}>
            <AutoComplete<Territory>
              id={position.toString()}
              label={t("app_project_funder_geographical_territory")}
              className={classes.autocompleteStyle}
              multiple
              options={autocompleteOptions}
              defaultValue={projectFunder.territories}
              noOptionsText={autocompleteText}
              onChangeSelectedElement={handleFunderTerritoryChange}
              onSearch={handleSearchTerritory}
              getOptionLabel={getTerritoryName}
              readonly={readOnly && position < oldProjectFundersLength}
              error={!funderValidation.territories}
              errorMessage={t("app_report_form_please_define_the_city")}
            />
          </Grid>
          <Grid item xs={2} md={2} className={classes.grid}>
            <InputForm
              value={projectFunder.budget}
              inputClassName={classes.input}
              id="Budget-target"
              inputIcon={"€"}
              errorMessage={t("app_project_funder_please_insert_budget")}
              inputType="textField"
              label={t("app_project_funder_target_budget")}
              className={classes.inputForm}
              onChange={(value) => onFunderValueChange("budget", value)}
              error={!funderValidation.budget}
              textFieldType={"number"}
              readonly={readOnly && position < oldProjectFundersLength}
            />
          </Grid>
          <Grid item xs={2} md={2} className={classes.grid}>
            <Typography style={{ fontWeight: 600, marginTop: "45px" }}>
              {t("app_project_funder_funder_percentage", {
                percent: funderPercent.toLocaleString(),
              })}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} style={{ paddingTop: "50px" }}>
            {shouldDeleteIconAppear() && (
              <DeleteButton onClick={() => deleteFunder(position)} />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default memo(FunderForm);
