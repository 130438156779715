import React, { Suspense } from 'react'
import { createMuiTheme, makeStyles } from '@material-ui/core'
import { Theme, themeContext } from '@smartb/archetypes-ui-components'
import { ColisActivLogoPngColor } from '../Icons/ColisActivLogo'
import { Theme as MuiTheme } from '@material-ui/core/styles/createMuiTheme'

export const CAThemeContext = themeContext

export const appLayoutStyleProps = {
  appBarHeight: 80,
  detailDrawerWidth: 150,
  menuWidth: 150
}

export const theme = {
  primaryColor: '#61D4A6',
  secondaryColor: '#007DCE',
  error: '#f44336',
  darkGrey: '#353945',
  grey: '#828282',
  lightGrey: '#98a6ac1a',
  tertiaryColor: '#F2F4F5',
  textFieldGrey: '#D1DCE7',
  textFieldTextGrey: '#98A5AE',
  shadows: [
    '0 0px 0px 0 rgba(0,0,0,0)',
    '0px 9px 18px 0px rgba(0,0,0,0.05)',
    '0px 11.25px 22.5px 0px rgba(0,0,0,0.07)',
    '0px 13.5px 27px 0px rgba(0,0,0,0.09)',
    '0px 15.75px 31.5px 0px rgba(0,0,0,0.11)',
    '0px 18px 36px 0px rgba(0,0,0,0.13)',
    '0px 20.25px 40.5px 0px rgba(0,0,0,0.15)',
    '0px 22.5px 45px 0px rgba(0,0,0,0.17)',
    '0px 24.75px 49.5px 0px rgba(0,0,0,0.19)',
    '0px 27px 54px 0px rgba(0,0,0,0.21)',
    '0px 29.25px 58.5px 0px rgba(0,0,0,0.23)',
    '0px 31.5px 63px 0px rgba(0,0,0,0.25)',
    '0px 33.75px 67.5px 0px rgba(0,0,0,0.27)'
  ],
  nav: {
    color: {
      icon: 'white',
      text: 'white',
      background: 'linear-gradient(178.45deg, #69B6A3 5.02%, #4D96B3 114.07%)',
      logo: 'white' as ColisActivLogoPngColor
    }
  }
} //the archetypes theme (maybe not the final version)

export const useInputStyles = (caTheme: Theme, disabled: boolean) =>
  makeStyles(({ palette }: MuiTheme) => ({
    label: {
      marginBottom: '10px',
      fontSize: 16,
      color: `${theme.darkGrey}`
    },
    input: {
      width: '100%',
      '& .MuiInputBase-root': {
        margin: `0px`,
        border: `1px solid ${theme.textFieldGrey}`,
        boxShadow: '0px 0px 0px 1px transparent',
        borderRadius: '5px',
        minHeight: 50,
        backgroundColor: palette.common.white
      },
      '& .MuiInputBase-root.Mui-error': {
        margin: `0px`,
        border: `1px solid ${theme.error}`,
        boxShadow: '0px 0px 0px 1px  transparent',
        borderRadius: '5px',
        backgroundColor: palette.common.white
      },
      '& .MuiInputBase-root:hover': {
        margin: `0px`,
        border: disabled
          ? `1px solid ${theme.textFieldGrey}`
          : `1px solid ${caTheme.secondaryColor}`,
        borderRadius: '5px'
      },
      '& .MuiInputBase-root.Mui-focused': {
        margin: `0px`,
        border: `1px solid ${caTheme.secondaryColor}`,
        borderRadius: '5px'
      },
      '& .MuiFormLabel-root': {
        fontWeight: 500,
        fontSize: 16,
        color: `${theme.darkGrey}`,
        marginTop: '5px'
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: theme.error
      },
      '& .MuiInputLabel-filled .MuiInputLabel-filled.MuiInputLabel-shrink': {
        transform: 'translate(12px, 21px) scale(1)'
      },
      '& .MuiFormLabel-root.Mui-focused': {
        fontWeight: 400,
        transform: 'translate(12px, 10px) scale(0.75)'
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-input': {
        padding: '0px 5px'
      },
      '& .MuiFilledInput-input::placeholder': {
        fontSize: '14px'
      },
      '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
        marginTop: '0px !important'
      }
    },
    helperText: {
      position: 'absolute',
      left: '-12px',
      top: '100%'
    },
    clear: {
      position: 'absolute',
      right: '10px',
      top: '50%',
      marginTop: '-12px',
      cursor: 'pointer',
      color: 'rgba(0, 0, 0, 0.54)'
    }
  }))

export const Muitheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.primaryColor
    },
    secondary: {
      main: theme.secondaryColor
    }
  }
})
