import { Box } from '@material-ui/core'
import { themeContext } from '@smartb/archetypes-ui-components'
import React, { useContext } from 'react'
import {BeatLoader} from "react-spinners"

interface ComponentLoadingProps {
    className?: string
    style?: React.CSSProperties
}

export const ComponentLoading = (props: ComponentLoadingProps) => {
    const {className, style} = props
    const theme = useContext(themeContext)
    return (
        <Box className={className} style={style} display="flex" alignItems="center" justifyContent="center" height="25px">
            <BeatLoader color={theme.primaryColor} size={15}/>
        </Box>
    )
}
