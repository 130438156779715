import { useAuth } from "./useAuth";
import { Route, RouteProps } from "react-router-dom";
import React from "react";
import { Roles } from "./index";

interface PrivateRouteProps extends Partial<RouteProps> {
  roles: Roles[];
  organizationId?: string;
  userId?: string;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const [authService, keycloak] = useAuth();

  const { roles, organizationId, userId } = props;
  return authService.isAuthorized(roles, keycloak, organizationId, userId) ? (
    <Route {...props}></Route>
  ) : (
    <div>Not authorized</div>
    // <Redirect
    //   to={{
    //     pathname: "/",
    //   }}
    // />
  );
};

export default PrivateRoute;
