import { OrganizationRef } from "api/organization";
import { ProjectStats } from "api/project/model";
import ProjectForm from "app/components/ProjectForm";
import {
  defaultProjectFormState,
  ProjectFormState,
} from "app/components/ProjectForm/model";
import { ProjectValidation } from "app/components/ProjectForm/validation";
import { Button, FormButtons } from "components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import withConnect from "./withConnect";

interface EditProjectDetailsProps {
  project: ProjectStats | null;
  projectId: string;
  updateProject: (project: ProjectFormState) => void;
  gotoProjectGeneralView: (projectId: string) => void;
  gotoProjectEdit: (projectId: string) => void;
  readOnly: boolean;
  funderRefs: Map<string, OrganizationRef>;
  allowAddFunder: boolean;
}

const EditProjectDetails = (props: EditProjectDetailsProps) => {
  const {
    project,
    updateProject,
    gotoProjectGeneralView,
    projectId,
    readOnly,
    funderRefs,
  } = props;
  const [projectState, setProject] = useState<ProjectFormState>(
    defaultProjectFormState
  );

  useEffect(() => {
    if (!!project) {
      const [, validation] = ProjectValidation.getFormValidation(project);
      setProject({
        ...projectState,
        ...project,
        validity: validation,
        oldFundersProjectLength: project.funders.length,
      });
    }
  }, [project]);

  const handleProjectUpdate = async () => {
    const [isValid, validation] = ProjectValidation.getFormValidation(
      projectState
    );
    if (!isValid) {
      return setProject({
        ...projectState,
        validity: validation,
        id: projectId,
      });
    }

    await updateProject(projectState);
    gotoProjectGeneralView(projectId);
  };

  const { t } = useTranslation();
  return (
    <>
      <ProjectForm
        project={projectState}
        readOnly={readOnly}
        onProjectChange={setProject}
        funderRefs={funderRefs}
        mode={"edit"}
      />
      <FormButtons>
        <Button onClick={() => handleProjectUpdate()}>
          {t("app_project_edit_validate")}
        </Button>
      </FormButtons>
    </>
  );
};

export default withConnect(EditProjectDetails);
