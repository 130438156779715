import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "api/http";
import { city } from "plateform-organization-domain";

export interface LogoCreatedEvent
  extends city.colisactiv.organization.model.features.LogoCreatedEvent {}

export const logoCreateCommand = (
  organizationId: string,
  file: File
): Promise<LogoCreatedEvent> => {
  const blobLogo = new Blob([file], {
    type: file.type,
  });
  const formData = new FormData();
  formData.append("logo", blobLogo, file.name);
  return http.put(
    buildOrganizationApiUrl(`/organizations/${organizationId}/logo`),
    formData,
    undefined,
    true
  );
};
