import { store } from "store";
import { profile } from "store/profile";
import { User } from "api/organization/users";

export type Roles =
  | "user"
  | "admin"
  | "super_admin"
  | "carrier_admin"
  | "carrier_user"
  | "funder_user"
  | "funder_admin"
  | "operator_admin"
  | "operator_user";

const isColisActivAdmin = (
  user: User | null,
  keycloak?: Keycloak.KeycloakInstance
) => {
  if (user == null) {
    return false;
  }
  return isAuthorized(["admin", "super_admin"], keycloak);
};

const isAuthenticated = (keycloak?: Keycloak.KeycloakInstance): boolean => {
  if (!keycloak) {
    return false;
  }
  return !!keycloak.authenticated && !!keycloak.idToken;
};

const isAdminOrFunderOrOperator = (
  user: User | null,
  keycloak?: Keycloak.KeycloakInstance
) => {
  if (user == null) {
    return false;
  }
  return (
    isOperator(user, keycloak) ||
    isFunder(user, keycloak) ||
    isColisActivAdmin(user, keycloak)
  );
};

const isOperator = (
  user: User | null,
  keycloak?: Keycloak.KeycloakInstance
) => {
  if (user == null) {
    return false;
  }
  return isAuthorized(["operator_admin", "operator_user"], keycloak);
};

const isFunder = (user: User | null, keycloak?: Keycloak.KeycloakInstance) => {
  if (user == null) {
    return false;
  }
  return isAuthorized(["funder_admin", "funder_user"], keycloak);
};

const isCarrier = (user: User | null, keycloak?: Keycloak.KeycloakInstance) => {
  if (user == null) {
    return false;
  }
  return isAuthorized(["carrier_admin", "carrier_user"], keycloak);
};

const hasRoles = (
  user: User,
  roles: Roles[] | null,
  keycloak?: Keycloak.KeycloakInstance
) => {
  return isAuthorized(roles, keycloak);
};

const isAuthorized = (
  roles: Roles[] | null,
  keycloak?: Keycloak.KeycloakInstance
): boolean => {
  if (!keycloak) {
    return false;
  }
  let authorization = false;
  if (!roles || roles.length === 0) authorization = true;
  if (roles) {
    roles.forEach((r) => {
      const realm = keycloak.hasRealmRole(r);
      const resource = keycloak.hasResourceRole(r);
      if (realm || resource) authorization = true;
    });
  }
  return authorization;
};

export interface AuthService {
  isAuthenticated: (keycloak?: Keycloak.KeycloakInstance) => boolean;
  isColisActivAdmin: (
    user: User | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  isAdminOrFunderOrOperator: (
    user: User | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  isOperator: (
    user: User | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  isFunder: (
    user: User | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  isCarrier: (
    user: User | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  hasRoles: (
    user: User,
    roles: Roles[] | null,
    keycloak?: Keycloak.KeycloakInstance
  ) => boolean;
  isAuthorized: (
    roles: Roles[] | null,
    keycloak?: Keycloak.KeycloakInstance,
    organizationId?: string,
    userId?: string
  ) => boolean;
}

export const authService: AuthService = {
  isAuthenticated,
  isColisActivAdmin,
  isOperator,
  isFunder,
  isAdminOrFunderOrOperator,
  hasRoles,
  isAuthorized,
  isCarrier,
};
