import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { InputForm } from "components";
import { Rule } from "api/project/model";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  inputForm: {
    flexDirection: "column",
    marginTop: "10px",
    justifyContent: "start",
    alignItems: "start",
  },
  input: {
    width: "100%",
    margin: "10px 0px",
  },

  selectStyle: {
    justifyContent: "center",
    width: "100% ",
    marginTop: 10,
  },
  trashIcon: {
    position: "absolute",
    right: 122,
    top: 6,
    width: "fit-content",
    height: "fit-content",
    cursor: "pointer",
  },
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
}));
interface RuleFormProps {
  rule: Rule;
  position: number;
  ruleValidation: any;
  onChangeRule: (typeInput: string, position: number, value: any) => void;
  onRuleDeleted: (position: number) => void;
  readOnly: boolean;
}

const RuleComponent = (props: RuleFormProps) => {
  const { rule, position, onChangeRule, ruleValidation, readOnly } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} className={classes.container}>
        <InputForm
          id={`Rule-${position}`}
          value={rule.value}
          label={t("app_campaign_form_rule_hourly_premium_limit_per_operator")}
          inputType="textField"
          className={classes.inputForm}
          inputClassName={classes.input}
          textFieldType="number"
          error={ruleValidation && !ruleValidation.value}
          errorMessage={t("app_campaign_form_rule_fill")}
          onChange={(value) => onChangeRule("value", position, Number(value))}
          readonly={readOnly}
          inputIcon={"€"}
          iconPosition="end"
        />
      </Grid>
    </Grid>
  );
};
export default RuleComponent;
