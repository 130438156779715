import { connect } from "react-redux";
import { State } from "store";
import { bonus } from "store/bonus";
import { global } from "store/global";
import { router } from "store/router";
import { title } from "store/title";

const mapStateToProps = (state: State) => ({
  carriers: global.selectors.carrierRefs(state),
  operators: global.selectors.operatorRefs(state),
});

const mapDispatchToProps = {
  setHeadBar: title.actions.setHeadBar,
  createBonus: bonus.commands.createBonus,
  gotoBonus: router.goto.bonus.bonusList,
  gotoBonusEdit: router.goto.bonus.bonusEdit,
};

export default connect(mapStateToProps, mapDispatchToProps);
