import React from 'react'
import { PopUp as SBPopUp } from '@smartb/archetypes-ui-components'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  footer: {
    padding: '10px',
    paddingTop: '30px'
  }
}))

interface PopUpProps {
  title?: string
  onClose: (event: React.ChangeEvent<{}>) => void
  open: boolean
  children?: React.ReactNode
  footer?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

export const PopUp = (props: PopUpProps) => {
  const { title, className, style, onClose, open, children, footer } = props
  const classes = useStyles()
  return (
    <SBPopUp
      title={title}
      open={open}
      className={className}
      style={style}
      footer={
        footer && (
          <Box
            display='flex'
            justifyContent='center'
            className={classes.footer}
          >
            {footer}
          </Box>
        )
      }
      onClose={(event) => {
        onClose(event)
      }}
    >
      {children}
    </SBPopUp>
  )
}
