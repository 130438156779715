import { ProjectDetails, ProjectFunders } from "../model";
import { city } from "plateform-project-domain";
import { buildProjectApiUrl } from "api/configuration";
import i18n from "i18n";
import { http } from "api/http";

export interface CreateProjectCommand
  extends city.colisactiv.project.model.features.CreateProjectCommand {}

export interface ProjectCreatedEvent
  extends city.colisactiv.project.model.features.ProjectCreatedEvent {
  id: string;
  type: string;
  payload: ProjectCreatedEvent;
}

export const ProjectCreateCommand = (
  details: ProjectDetails,
  projectFunders: ProjectFunders
): CreateProjectCommand => {
  return {
    type: "CREATE_PROJECT",
    payload: {
      details: details,
      projectFunders: projectFunders,
    },
  };
};

export const createProjectCommand = (
  details: ProjectDetails,
  projectFunders: ProjectFunders
): Promise<ProjectCreatedEvent> => {
  return http.post(
    buildProjectApiUrl(`/projects`),
    JSON.stringify(ProjectCreateCommand(details, projectFunders)),
    i18n.t("app_project_create_success")
  );
};
