import React from "react";
import { AlertHandler } from "components";
import withConnect from "./withConnect";
import { Alert } from "api/organization";

export interface AdminAlertHandlerProps {
  alerts: Alert[];
  onRemoveAlert: (index: number) => void;
}

const AdminAlertHandler = (props: AdminAlertHandlerProps) => {
  const { alerts, onRemoveAlert } = props;
  return <AlertHandler alerts={alerts} onRemoveAlert={onRemoveAlert} />;
};

export default withConnect(AdminAlertHandler);
