import * as R from "ramda";
import {
  BonusFormDetailsValidity,
  BonusFormState,
  BonusFormStatsRowState,
  BonusFormStatsRowValidity,
  BonusFormStatsState,
  BonusFormStatsValidity,
  BonusFormValidity,
} from "./model";

const validateBonusForm = (
  bonusForm: BonusFormState
): [boolean, BonusFormValidity] => {
  const bonusFormValidation: BonusFormValidity = {
    details: validateBonusFormDetails(bonusForm)[1],
    bonuses: validateBonusFormStats(bonusForm.details.bonuses)[1],
  };
  const isValid = isFormValid(bonusFormValidation);
  return [isValid, bonusFormValidation];
};

const validateBonusFormDetails = (
  bonus: BonusFormState
): [boolean, BonusFormDetailsValidity] => {
  const validity: BonusFormDetailsValidity = {
    territory: bonus.details.territory && bonus.details.territory.code !== "",
    operatorId: bonus.details.operatorId !== "",
    carrierId: bonus.details.carrierId !== "",
  };

  const isValid = isValidBonusFormDetails(validity);
  return [isValid, validity];
};

const validateBonusFormStats = (
  stats: BonusFormStatsState[]
): [boolean, BonusFormStatsValidity[]] => {
  const validity: BonusFormStatsValidity[] = stats.map((stat) => {
    return {
      areaCode: stat.areaCode?.length > 0,
      campaignId: stat.campaignId?.length > 0,
      manualData: stat.manualData.map(
        (data) => validateBonusFormStatsRow(data, false)[1]
      ),
      automaticData: validateBonusFormStatsRow(stat.automaticData, true)[1],
    };
  });

  const isValid = isValidBonusFormStatsList(validity);
  return [isValid, validity];
};

const validateBonusFormStatsRow = (
  row: BonusFormStatsRowState,
  isAutomatic: boolean
): [boolean, BonusFormStatsRowValidity] => {
  const validity: BonusFormStatsRowValidity = {
    id: true,
    activeDeliveryCount:
      isAutomatic || row.activeDeliveryCount <= row.packCount,
    packCount: isAutomatic || row.packCount > 0,
    duration: isAutomatic || row.duration > 0,
  };

  const isValid = isValidBonusFormStatsRow(validity);
  return [isValid, validity];
};

export const isFormValid = (validation: BonusFormValidity): boolean => {
  const detailsIsValid = isValidBonusFormDetails(validation.details);
  const bonusValidation = isValidBonusFormStatsList(validation.bonuses);
  return detailsIsValid && bonusValidation;
};

const isValidBonusFormDetails = (
  validity: BonusFormDetailsValidity
): boolean => {
  return R.values(validity).every(R.identity);
};

const isValidBonusFormStatsRow = (
  validity: BonusFormStatsRowValidity
): boolean => {
  return R.values(validity).every(R.identity);
};

const isValidBonusFormStats = (validity: BonusFormStatsValidity): boolean => {
  return (
    validity.areaCode &&
    validity.campaignId &&
    validity.manualData.every(isValidBonusFormStatsRow) &&
    isValidBonusFormStatsRow(validity.automaticData)
  );
};

const isValidBonusFormStatsList = (
  validity: BonusFormStatsValidity[]
): boolean => {
  return validity.every(isValidBonusFormStats);
};

export const BonusValidation = {
  validateDetails: validateBonusFormDetails,
  validateStats: validateBonusFormStats,
  validateForm: validateBonusForm,
};
