import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import { makeStyles } from '@material-ui/core/styles'
import { CSVLink } from 'react-csv'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'

const headers = [
  { label: 'Période', key: 'period' },
  { label: 'Opérateur', key: 'operator' },
  { label: 'IBAN', key: 'rib' },
  { label: 'Co-financeurs', key: 'funder' },
  { label: 'Montant', key: 'amount' }
]

const useStyles = makeStyles(() => ({
  fab: {
    width: 40,
    height: 40,
    backgroundColor: '#00000000',
    boxShadow: '0 0 black'
  },
  icon: {
    color: '#007DCE'
  }
}))

export interface CsvExtractorHeader<Data> extends LabelKeyObject {
  key: string & keyof Data
  label: string
}

interface CsvExtractorProps<Data> {
  data: Data[]
  headers: CsvExtractorHeader<Data>[]
  filename: string
  tooltipText: string
}
export const CsvExtractor = <Data extends object>(
  props: CsvExtractorProps<Data>
) => {
  const { data, filename, headers, tooltipText } = props
  const classes = useStyles()
  return (
    <CSVLink
      data={data}
      separator={','}
      filename={filename}
      headers={headers}
    >
      <Tooltip title={tooltipText} arrow>
        <Fab className={classes.fab}>
          <SystemUpdateAltIcon className={classes.icon} />
        </Fab>
      </Tooltip>
    </CSVLink>
  )
}
