import { city } from "plateform-project-domain";
import { http } from "api/http";
import { buildProjectApiUrl } from "api/configuration";

export interface PausedCampaignEvent
  extends city.colisactiv.project.model.features.PausedCampaignEvent {}

export const pauseCampaignCommand = (
  projectId: string,
  campaignId: string
): Promise<PausedCampaignEvent> =>
  http.put(
    buildProjectApiUrl(
      `/projects/${projectId}/campaigns/${campaignId}/status/pause`
    )
  );
