import React, { useCallback, useEffect, useMemo, useState } from "react";
import withConnect from "./withConnect";
import { useTranslation } from "react-i18next";
import PageWithTitle from "app/components/PageWithTitle/PageWithTitle";
import {
  ComponentLoading,
  DashboardFilters,
  DashboardGrid,
  DashboardTable,
  useDashboardCSV,
  DashboardFiltersModel,
  Options,
} from "components";
import { makeStyles, Typography } from "@material-ui/core";
import { User } from "api/organization/users";
import { useAuth } from "auth/useAuth";
import { Heatmap } from "app/components/Heatmap";
import {
  BonusStats,
  poiDashboardClient,
  PoiDashboardStats,
} from "api/poidashboard";
import { GeoLocation } from "../../../api/geoLocation";
import { OrganizationRef } from "../../../api/organization";
import {
  exportCSV,
  formatDate,
  nullToUndefined,
  nullToUndefinedNumber,
  displayNumber,
} from "utils";
import { geoZoneClient } from "api/geoZone";
import { PoiDashboardFilter } from "api/poidashboard/query/fetchPoiDashboardStats";
import { routesAccessRights } from "auth/RoutesAccessRightsMap";
import {
  BonusGroupStatusUtils,
  getLabelStyleStatusTags,
} from "api/bonusGroup/model/bonusStatus";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 50,
    display: "flex",
    justifyContent: "center",
  },
  tableTitle: {
    marginLeft: 25,
    marginBottom: 10,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 19,
    color: "#007DCE",
  },
  poiContainer: {
    marginTop: 50,
  },
}));

interface DashboardPageProps {
  organizationRefs: Map<string, OrganizationRef>;
  carrierRefs: Map<string, OrganizationRef>;
  operatorRefs: Map<string, OrganizationRef>;
  currentUser: User | null;
  goToDashboard: (
    startDate?: number,
    operatorId?: string,
    carrierId?: string,
    territoryName?: string,
    territoryId?: string,
    territoryCode?: string
  ) => void;
}

const DashboardPage = (props: DashboardPageProps) => {
  const {
    organizationRefs,
    currentUser,
    goToDashboard,
    carrierRefs,
    operatorRefs,
  } = props;
  const [authService, keycloak] = useAuth();
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(7);
  const [paginedStats, setpaginedStats] = useState<[]>([]);
  const authResult = new URLSearchParams(window.location.search);
  const [dashboardStats, setDashboardStats] = useState<
    PoiDashboardStats | undefined
  >(undefined);

  const bonusToDeduce = useMemo(
    () =>
      dashboardStats?.bonusStats.reduce(
        (acc: number, bonus: BonusStats) =>
          BonusGroupStatusUtils.isDeducted(bonus.status)
            ? acc + bonus.bonusTotal
            : acc,
        0
      ),
    [dashboardStats]
  );
  const bonusToReport = useMemo(
    () =>
      dashboardStats?.bonusStats.reduce(
        (acc: number, bonus: BonusStats) =>
          BonusGroupStatusUtils.isReported(bonus.status)
            ? acc + bonus.bonusTotal
            : acc,
        0
      ),
    [dashboardStats]
  );
  const bonusDeduced = useMemo(
    () =>
      dashboardStats?.bonusStats.reduce(
        (acc: number, bonus: BonusStats) =>
          BonusGroupStatusUtils.isValidated(bonus.status)
            ? acc + bonus.bonusTotal
            : acc,
        0
      ),
    [dashboardStats]
  );

  const bonusPaid = useMemo(
    () =>
      dashboardStats?.bonusStats.reduce(
        (acc: number, bonus: BonusStats) =>
          BonusGroupStatusUtils.isPaid(bonus.status)
            ? acc + bonus.bonusTotal
            : acc,
        0
      ),
    [dashboardStats]
  );

  const bonusCreated = useMemo(
    () =>
      dashboardStats?.bonusStats.reduce(
        (acc: number, bonus: BonusStats) =>
          BonusGroupStatusUtils.isCreated(bonus.status)
            ? acc + bonus.bonusTotal
            : acc,
        0
      ),
    [dashboardStats]
  );

  const start = formatDate(authResult.get("startDate"));
  const territoryName = nullToUndefined(authResult.get("territoryName"));
  const territoryId = nullToUndefined(authResult.get("territoryId"));
  const territoryCode = nullToUndefined(authResult.get("territoryCode"));
  const [filterValues, setFiltersValue] = useState<DashboardFiltersModel>({
    period: start
      ? {
          startDate: start,
          endDate: start,
        }
      : undefined,
    operatorId: nullToUndefined(authResult.get("operatorId")),
    carrierId: nullToUndefined(authResult.get("carrierId")),
    territory:
      territoryName && territoryId && territoryCode
        ? {
            code: territoryCode,
            id: territoryId,
            name: territoryName,
            zones: [],
          }
        : undefined,
  });

  const isCarrier = useMemo(
    () =>
      authService.isCarrier(currentUser, keycloak) &&
      !authService.isColisActivAdmin(currentUser, keycloak),
    [authService, currentUser, keycloak]
  );

  const isOperator = useMemo(
    () =>
      authService.isOperator(currentUser, keycloak) &&
      !authService.isColisActivAdmin(currentUser, keycloak),
    [authService, currentUser, keycloak]
  );

  const getOperators = useCallback((): Options[] => {
    return Array.from(operatorRefs.values()).map((el) => {
      return {
        label: el.displayName,
        value: el.organizationId,
      };
    });
  }, [organizationRefs]);
  const getCarriers = useCallback((): Options[] => {
    return Array.from(carrierRefs.values()).map((el) => {
      return {
        label: el.displayName,
        value: el.organizationId,
      };
    });
  }, [organizationRefs]);

  const onBonusFiltersChange = (values: DashboardFiltersModel) => {
    goToDashboard(
      nullToUndefinedNumber(values.period?.startDate),
      values.operatorId,
      values.carrierId,
      values.territory?.name,
      values.territory?.id,
      values.territory?.code
    );
    setFiltersValue({
      period: values.period,
      operatorId: values.operatorId,
      carrierId: values.carrierId,
      territory: values.territory,
    });
  };

  useEffect(() => {
    const filters: PoiDashboardFilter = {
      period: filterValues.period,
      carrierId: filterValues.carrierId,
      territoryCode: filterValues.territory?.code,
      operatorId: filterValues.operatorId,
    };
    if (
      authService.isOperator(currentUser, keycloak) &&
      !authService.isColisActivAdmin(currentUser, keycloak)
    ) {
      poiDashboardClient.query
        .fetchPoiDashboardStats({
          ...filters,
          operatorId: currentUser?.details.organizationId,
        })
        .then((stats) => {
          if (stats) setDashboardStats(stats);
        });
    } else if (
      authService.isCarrier(currentUser, keycloak) &&
      !authService.isColisActivAdmin(currentUser, keycloak)
    ) {
      poiDashboardClient.query
        .fetchPoiDashboardStats({
          ...filters,
          carrierId: currentUser?.details.organizationId,
        })
        .then((stats) => {
          if (stats) setDashboardStats(stats);
        });
    } else if (
      authService.isColisActivAdmin(currentUser, keycloak) ||
      authService.isFunder(currentUser, keycloak)
    ) {
      poiDashboardClient.query.fetchPoiDashboardStats(filters).then((stats) => {
        if (stats) setDashboardStats(stats);
      });
    }
  }, [currentUser, filterValues]);

  const paginatedArray = function <T>(
    array: T[],
    pageSize: number,
    page: number
  ): T[] {
    return array.slice((page - 1) * pageSize, page * pageSize);
  };

  useEffect(() => {
    if (dashboardStats) {
      setpaginedStats(
        //@ts-ignore
        paginatedArray(dashboardStats.bonusStats, pageSize, page)
      );
    } else {
      setpaginedStats([]);
    }
  }, [pageSize, page, dashboardStats]);

  const countPickupPoint = (pickupPoints: GeoLocation[]): number => {
    const roundedPickupPoints: GeoLocation[] = [];
    pickupPoints.forEach((pickupPoint) => {
      roundedPickupPoints.push({
        lon: Math.round(pickupPoint.lon * 100000) / 100000,
        lat: Math.round(pickupPoint.lat * 100000) / 100000,
      });
    });

    const filteredArray = roundedPickupPoints.filter((v, i, a) => {
      return a.findIndex((t) => t.lon === v.lon && t.lat === v.lat) === i;
    });

    return filteredArray.length;
  };

  const calculateAverageBonusAmount = (
    bonusTotal: number,
    packCount: number
  ): string => {
    if (packCount == 0) return "0";

    return displayNumber(bonusTotal / packCount, 2);
  };

  const csvdata = useDashboardCSV(
    isOperator,
    isCarrier,
    t,
    organizationRefs,
    dashboardStats?.bonusStats
  );

  const downloadCSV = useCallback(async () => {
    if (csvdata) {
      await exportCSV(csvdata, "Colisactiv - données du tableau de bord");
    }
  }, [csvdata]);

  const headBar = useMemo(
    () => ({
      title: "Tableau de bord",
      downloadCSV: csvdata ? downloadCSV : undefined,
    }),
    [csvdata, downloadCSV]
  );

  if (dashboardStats == null) {
    return <ComponentLoading />;
  }

  return (
    <div>
      <PageWithTitle
        headBar={headBar}
        header={
          <DashboardFilters
            operators={getOperators()}
            carriers={getCarriers()}
            onFilterChange={onBonusFiltersChange}
            getTerritories={geoZoneClient.getTerritories}
            filterValues={filterValues}
            canSeeOperatorFilter={authService.isAuthorized(
              routesAccessRights["/dashboard?operatorFilter"],
              keycloak
            )}
            canSeeCarrierFilter={authService.isAuthorized(
              routesAccessRights["/dashboard?carrierFilter"],
              keycloak
            )}
          />
        }
        columnSwitchWidth={800}
        switchedHeaderHeight={150}
      >
        <>
          <div className={classes.container}>
            <DashboardGrid
              activePackCount={displayNumber(dashboardStats.activePackCount)}
              hubsCount={countPickupPoint(dashboardStats.activePickupPoints)}
              bonusTotal={displayNumber(dashboardStats.bonusTotal, 2)}
              bonusToDeduce={displayNumber(bonusToDeduce, 2)}
              bonusToReport={displayNumber(bonusToReport, 2)}
              bonusDeduced={displayNumber(bonusDeduced, 2)}
              bonusPaid={displayNumber(bonusPaid, 2)}
              bonusCreated={displayNumber(bonusCreated, 2)}
              deliveryTime={dashboardStats.durationTotal}
              activeDeliveries={displayNumber(
                dashboardStats.activeDeliveryPointCount
              )}
              averageAmount={calculateAverageBonusAmount(
                dashboardStats.bonusTotal,
                dashboardStats.activePackCount
              )}
            />
            <Heatmap
              deliveryPoints={dashboardStats.activeDeliveryPoints}
              pickupPoints={dashboardStats.activePickupPoints}
              areaGeolocations={dashboardStats.areaLocation}
            />
          </div>
          <div className={classes.poiContainer}>
            <Typography className={classes.tableTitle}>
              {t("app_report_poi")}
            </Typography>
            <DashboardTable
              stats={paginedStats}
              organizationRef={organizationRefs}
              isOperator={isOperator}
              isCarrier={isCarrier}
              getStatus={getLabelStyleStatusTags}
              page={page}
              totalPages={Math.ceil(
                dashboardStats.bonusStats.length / pageSize
              )}
              handlePageChange={(newPage) => setPage(newPage)}
            />
          </div>
        </>
      </PageWithTitle>
    </div>
  );
};

export default withConnect(DashboardPage);
