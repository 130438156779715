import { TerritoryPage } from "..";
import { buildProjectApiUrl } from "../../configuration";
import { http } from "../../http";

export const fetchTerritories = (
  page: number,
  size: number,
  name: string
): Promise<TerritoryPage> => {
  return http.get(
    buildProjectApiUrl(
      `/geozones/territories?page=${page}&size=${size}&name=${name}`
    ),
    "la récupération des territoires n'a pas pu être effectuée"
  );
};
