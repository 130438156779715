import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => createStyles({}))

interface FunderCardProps {}

export const FunderCard = (props: FunderCardProps) => {
  const classes = useStyles()
  return <div></div>
}
