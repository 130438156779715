import {
  BonusGroup,
  BonusGroupIncident,
  BonusGroupIncidentStatusValues,
  BonusGroupStateMachine,
} from "api/bonusGroup";
import { Button } from "components";
import React, { useCallback } from "react";
import { useBonusActionsStyle } from "./BonusActionsStyle";

interface BonusActionsButtonsProps {
  bonus: BonusGroup;
  publishBonus: (bonusId: string) => void;
  resolveBonusIncident: (bonusId: string, incidentId: string) => void;
}

export const BonusActionsOperatorButtons = (
  props: BonusActionsButtonsProps
) => {
  const { bonus, publishBonus, resolveBonusIncident } = props;
  const classes = useBonusActionsStyle();
  const availableAction = BonusGroupStateMachine.getAvailableActions(
    bonus.status.value,
    false
  );

  const handleIncidentResolve = useCallback(() => {
    bonus.incidents.forEach((incident: BonusGroupIncident) => {
      if (BonusGroupIncidentStatusValues.isCreated(incident.status.value)) {
        resolveBonusIncident(bonus.id, incident.id);
      }
    });
  }, [bonus, resolveBonusIncident]);

  const handlePublish = useCallback(() => {
    publishBonus(bonus.id);
  }, [bonus, publishBonus]);

  if (availableAction.canResolve) {
    return (
      <Button
        className={classes.button}
        onClick={handleIncidentResolve}
        disabled={!availableAction.canResolve}
      >
        Soumettre
      </Button>
    );
  }

  return (
    <Button
      className={classes.button}
      onClick={handlePublish}
      disabled={!availableAction.canPublish}
    >
      Soumettre
    </Button>
  );
};
