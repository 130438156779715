import { connect } from "react-redux";
import { State } from "../../../../../../store";
import { organization } from "../../../../../../store/organization";
import { router } from "../../../../../../store/router";
import { userRedux } from "../../../../../../store/user";

const mapStateToProps = (state: State) => ({
  organization: organization.selectors.organization(state),
  user: userRedux.selectors.user(state),
});

const mapDispatchToProps = {
  gotoUserEdit: router.goto.organization.organizationUsersEdit,
  gotoUserView: router.goto.organization.organizationUsersView,
  fetchUser: userRedux.queries.fetchUser,
};

export default connect(mapStateToProps, mapDispatchToProps);
