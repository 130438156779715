import React, { Fragment } from 'react'
import DataTable, {
  IDataTableColumn,
  IDataTableStyles,
  IExpandableIcon
} from 'react-data-table-component'
import { makeStyles, Typography, withStyles } from '@material-ui/core'
import { theme } from '../../Theme'
import { CustomPagination } from './CustomPagination'
import { ComponentLoading } from '../Loading'
import { ArrowLeft } from '../../Icons'

const useStyles = makeStyles(() => ({
  container: {
    '& .rdt_TableRow .rdt_TableCell:last-child': {
      paddingRight: '30px'
    },
    '& .rdt_TableCol:last-child': {
      paddingRight: '30px'
    },
    '& .rdt_TableRow .rdt_TableCell:first-child': {
      paddingLeft: '30px'
    },
    '& .rdt_TableCol:first-child': {
      paddingLeft: '30px'
    },
    '& .rdt_TableBody': {
      padding: '20px',
      paddingTop: '5px'
    },
    '& .rdt_TableHead': {
      padding: '0 20px'
    }
  },
  row: {
    display: 'block'
  }
}))

const customStyles = {
  table: {
    style: {
      backgroundColor: 'transparent'
    }
  },
  headRow: {
    style: {
      backgroundColor: 'transparent',
      borderBottom: 'none !important'
    }
  },
  rows: {
    style: {
      lineHeight: '17px',
      letterSpacing: '0px',
      margin: '4px 0',
      boxShadow: '0px 9px 18px rgba(0, 0, 0, 0.05)',
      borderRadius: '5px',
      border: `2px solid transparent !important`,
      '&:hover': {
        border: `2px solid ${theme.secondaryColor} !important`,
        backgroundColor: `#FFFFFF !important`,
        borderRadius: 5,
        outlineWidth: 'inherit !important'
      }
    }
  },
  headCells: {
    style: {
      lineHeight: '16px',
      letterSpacing: '0px',
      color: '#353945',
      fontWeight: 600,
      fontSize: '14px',
      textAlign: 'left',
      padding: '0 20px'
    }
  },
  cells: {
    style: {
      padding: '0 20px',
      '& p': {
        color: '#353945',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '17px'
      }
    }
  },
  noData: {
    style: {
      backgroundColor: 'transparent',
      height: '25px'
    }
  },
  expanderRow: {
    style: {
      backgroundColor: 'transparent'
    }
  },
  expanderButton: {
    style: {
      '&:hover:not(:disabled)': {
        backgroundColor: 'transparent'
      },
      '&:focus': {
        backgroundColor: 'transparent'
      }
    }
  }
}
interface TableProps<Row> {
  data: Row[]
  columns: IDataTableColumn<Row>[]
  selectableRows?: boolean
  onRowClicked?: (row: Row) => void
  totalPages?: number
  handlePageChange?: (page: number) => void
  isLoading?: boolean
  className?: string
  keyField?: string
  noDataMessage?: string
  page?: number
  expandableRows?: boolean
  ExpandableComponents?: React.ReactNode
  expandOnRowClicked?: boolean
}

export const PrimaryTypography = withStyles(() => ({
  root: {
    color: '#071017 !important',
    fontSize: '13px !important',
    fontWeight: 600
  }
}))(Typography)

export const SecondaryTypography = withStyles(() => ({
  root: {
    color: '#9a9a9a',
    fontSize: '12px'
  }
}))(Typography)

export interface Contributor {
  name: string
  amount: number
}

export interface BonusCellProps {
  bonusTotal: number
  contributors: Contributor[]
}

export const BonusCell = (props: BonusCellProps) => {
  const { bonusTotal, contributors } = props
  const classes = useStyles()
  const toDisplay = contributors.map((contributor) => {
    return (
      <SecondaryTypography
        data-tag='___react-data-table-allow-propagation___'
        align='left'
      >
        {((contributor.amount / bonusTotal) * 100).toFixed(2)} % par{' '}
        {contributor.name}
      </SecondaryTypography>
    )
  })

  return (
    <div className={classes.row}>
      <PrimaryTypography
        data-tag='___react-data-table-allow-propagation___'
        align='left'
      >
        {bonusTotal} €
      </PrimaryTypography>
      {toDisplay}
    </div>
  )
}

export const Table = <Row,>(props: TableProps<Row>) => {
  const {
    data,
    columns,
    selectableRows = false,
    onRowClicked,
    totalPages,
    handlePageChange,
    page,
    isLoading = false,
    className,
    keyField,
    noDataMessage,
    expandableRows = false,
    ExpandableComponents,
    expandOnRowClicked = false
  } = props
  const icon: IExpandableIcon = {
    collapsed: (
      <ArrowLeft
        key='expanderIcon'
        color='#353945'
        width='20px'
        height='20px'
        style={{ transform: 'rotate(-90deg)', transition: '0.2s' }}
      />
    ),
    expanded: (
      <ArrowLeft
        key='expanderIcon'
        color='#353945'
        width='20px'
        height='20px'
        style={{ transform: 'rotate(90deg)', transition: '0.2s' }}
      />
    )
  }
  const classes = useStyles()
  return (
    <Fragment>
      {isLoading ? (
        <ComponentLoading
          style={{ height: 'calc(100% - 36px)', maxHeight: '800px' }}
        />
      ) : (
        <DataTable
          columns={columns}
          className={`${className} ${classes.container}`}
          theme='colisactiv'
          data={data}
          noHeader
          noDataComponent={
            noDataMessage ? noDataMessage : 'Aucun élément à afficher'
          }
          highlightOnHover
          pointerOnHover
          selectableRows={selectableRows}
          onRowClicked={onRowClicked}
          customStyles={customStyles as IDataTableStyles}
          keyField={keyField}
          expandableRows={expandableRows}
          expandableRowsComponent={ExpandableComponents}
          expandOnRowClicked={expandOnRowClicked}
          expandableIcon={icon}
        />
      )}

      {page && totalPages ? (
        <CustomPagination
          onPageChange={handlePageChange}
          page={page}
          totalPage={totalPages}
        />
      ) : undefined}
    </Fragment>
  )
}
