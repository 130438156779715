import { connect } from "react-redux";
import { State } from "store";
import { router } from "store/router";
import { bonus } from "store/bonus";
import { global } from "store/global";
import { title } from "store/title";

const mapStateToProps = (state: State) => ({
  bonus: bonus.selectors.bonus(state),
  carriers: global.selectors.carrierRefs(state),
  operators: global.selectors.operatorRefs(state),
});

const mapDispatchToProps = {
  setHeadBar: title.actions.setHeadBar,
  fetchBonus: bonus.queries.fetchBonus,
  updateBonus: bonus.commands.updateBonus,
  gotoBonusView: router.goto.bonus.bonusView,
};

export default connect(mapStateToProps, mapDispatchToProps);
