import { connect } from "react-redux";
import { State } from "store";
import { profile } from "store/profile";
import { goto } from "../../../store/router/router.goto";

const mapStateToProps = (state: State) => ({
  currentUser: profile.selectors.User(state),
});

const mapDispatchToProps = {
  goto: goto.push,
};

export default connect(mapStateToProps, mapDispatchToProps);
