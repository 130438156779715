import { makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { Delete } from '../../Icons/Delete'
import { TextButton } from '../TextButton'
import { Theme, themeContext } from '@smartb/archetypes-ui-components'

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    icon: {
      width: '19px',
      height: '19px',
      marginRight: '5px'
    },
    root: {
      '& span': {
        color: theme.secondaryColor
      }
    }
  }))

interface DeleteButtonProps {
  onClick: (event: React.ChangeEvent<{}>) => void
  children?: string
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
}

export const DeleteButton = (props: DeleteButtonProps) => {
  const { className, style, onClick, disabled, children } = props
  const theme = useContext(themeContext)
  const classes = useStyles(theme)()
  return (
    <TextButton
      onClick={onClick}
      className={`${className ?? ''} ${classes.root}`}
      style={style}
      disabled={disabled}
    >
      <Delete color={theme.secondaryColor} className={classes.icon} />
      {children}
    </TextButton>
  )
}
