import { connect } from "react-redux";
import { router } from "store/router";
import { State } from "store";
import { project } from "store/project";
import { title } from "store/title";
import { global } from "store/global";

const mapStateToProps = (state: State) => ({
  operatorRefs: global.selectors.operatorRefs(state),
  campaign: project.selectors.campaign(state),
  project: project.selectors.project(state),
  funderRefs: global.selectors.funderRefs(state),
});

const mapDispatchToProps = {
  setHeadBar: title.actions.setHeadBar,
  gotoProjectView: router.goto.project.projectView,

  fetchCampaign: project.queries.fetchCampaign,
  updateCampaign: project.commands.updateCampaign,
};

export default connect(mapStateToProps, mapDispatchToProps);
