import { queries } from "./report.queries";
import { actions } from "./report.actions";
import { commands } from "./report.command";
import { selectors } from "./reportselectors";
export const report = {
  actions: actions,
  queries: queries,
  commands: commands,
  selectors: selectors,
};
