import { Campaign, ProjectDetails, ProjectFunders } from "api/project/model";

export interface ProjectFormState {
  details: ProjectDetails;
  funders: ProjectFunders;
  campaigns: Array<Campaign>;
  id: string | null;
  validity: ProjectFormValidity;
  oldFundersProjectLength: number;
}

export interface ProjectFormValidity {
  details: ProjectDetailValidity;
  funders: ProjectFunderValidity[];
}

export interface ProjectDetailValidity {
  name: boolean;
}

export interface ProjectFunderValidity {
  territories: boolean;
  budget: boolean;
  funderId: boolean;
}

export const defaultProjectFormValidity: ProjectFormValidity = {
  details: {
    name: true,
  },
  funders: [{ territories: true, funderId: true, budget: true }],
};

export const defaultProjectFormState: ProjectFormState = {
  details: {
    name: "",
  },
  funders: [{ territories: [], funderId: "", budget: "" }],
  validity: defaultProjectFormValidity,
  campaigns: [],
  id: null,
  oldFundersProjectLength: 1,
};
