import { Territory } from "api/geoZone";
import { OrganizationRef, OrganizationTypeValues } from "api/organization";
import { Campaign } from "api/project/model";
import { createReducer } from "deox";
import * as R from "ramda";
import { OrganizeImportsScope } from "typescript";
import { actions } from "./global.actions";

export interface organizationState {
  campaigns: Campaign[] | null;
  organizationRefs: Map<string, OrganizationRef> | null;
  funderRefs: Map<string, OrganizationRef> | null;
  operatorRefs: Map<string, OrganizationRef> | null;
  carrierRefs: Map<string, OrganizationRef> | null;
  projectTerritories: Territory[] | null;
}

export const initialState: organizationState = {
  campaigns: null,
  organizationRefs: null,
  funderRefs: null,
  operatorRefs: null,
  carrierRefs: null,
  projectTerritories: null,
};

export type GlobalStateTransformer = (
  state: organizationState
) => organizationState;

type OrganizationIdAndRef = [string, OrganizationRef];

const setOrganizationRef = (
  organizationRefs: OrganizationRef[]
): GlobalStateTransformer => {
  const operators: OrganizationIdAndRef[] = [];
  const carriers: OrganizationIdAndRef[] = [];
  const funders: OrganizationIdAndRef[] = [];
  const organizations: OrganizationIdAndRef[] = organizationRefs.map((ref) => {
    let target: OrganizationIdAndRef[];
    switch (ref.organizationType.type) {
      case OrganizationTypeValues.FUNDER.type:
      case OrganizationTypeValues.FUNDER_CEE.type:
        target = funders;
        break;
      case OrganizationTypeValues.CARRIER.type:
        target = carriers;
        break;
      case OrganizationTypeValues.OPERATOR.type:
        target = operators;
        break;
      default:
        target = [];
    }
    const organizationIdAndRef: OrganizationIdAndRef = [
      ref.organizationId,
      ref,
    ];
    target.push(organizationIdAndRef);
    return organizationIdAndRef;
  });
  return (state: organizationState) => ({
    ...state,
    organizationRefs: new Map(organizations),
    carrierRefs: new Map(carriers),
    operatorRefs: new Map(operators),
    funderRefs: new Map(funders),
  });
};

const setCampaigns = (campaigns: Campaign[]): GlobalStateTransformer => {
  return R.assoc("campaigns", campaigns || []);
};

const setProjectTerritories = (
  projectGeoZones: Territory[]
): GlobalStateTransformer => {
  return R.assoc("projectTerritories", projectGeoZones || []);
};

export const globalReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    actions.fetchedOrganizationRefs,
    (state: organizationState, action) =>
      setOrganizationRef(action.payload.organizationRefs)(state)
  ),
  handleAction(actions.fetchedCampaigns, (state: organizationState, action) =>
    setCampaigns(action.payload.campaigns)(state)
  ),
  handleAction(
    actions.fetchedProjectTerritories,
    (state: organizationState, action) =>
      setProjectTerritories(action.payload.ProjectTerritories)(state)
  ),
]);
