import { GeoLocation } from "../../../api/geoLocation";
import { DeliveryLocation } from "../../../api/poidashboard";
import { truncate } from "../../../utils";

export interface Bounds {
  southWest: [number, number];
  northEast: [number, number];
}

export const getBounds = (locations: GeoLocation[]): Bounds => {
  if (locations.length == 0) {
    return {
      southWest: [-10.209183, 42.599851],
      northEast: [16.026168, 50.922299],
    };
  }
  let x0: number = locations[0].lat;
  let x1: number = locations[0].lat;
  let y0: number = locations[0].lon;
  let y1: number = locations[0].lon;
  locations.map((location) => {
    if (location.lat > x1) x1 = location.lat;
    if (location.lat < x0) x0 = location.lat;
    if (location.lon > y1) y1 = location.lon;
    if (location.lon < y0) y0 = location.lon;
  });
  return {
    southWest: [y1, x1],
    northEast: [y0, x0],
  };
};

export const geoLocationToFeatureCollection = (
  locations: GeoLocation[]
): GeoJSON.FeatureCollection => {
  const features: GeoJSON.Feature[] = locations.map((loc) => ({
    type: "Feature",
    properties: {},
    geometry: { type: "Point", coordinates: [loc.lon, loc.lat] },
  }));

  return {
    type: "FeatureCollection",
    features: features,
  };
};

export const deliveryLocationToFeatureCollection = (
  locations: DeliveryLocation[]
): GeoJSON.FeatureCollection => {
  const features: GeoJSON.Feature[] = locations.map((loc) => ({
    type: "Feature",
    properties: { nbColis: loc.packageCount },
    geometry: {
      type: "Point",
      coordinates: [
        truncate(loc.location.lon, 3),
        truncate(loc.location.lat, 3),
      ],
    },
  }));

  return {
    type: "FeatureCollection",
    features: features,
  };
};
