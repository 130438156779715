import { connect } from "react-redux";
import { State } from "store";
import { organization } from "store/organization";

const mapStateToProps = (state: State) => ({
  organization: organization.selectors.organization(state),
  conventionActions: organization.selectors.getConventionActions(state),
  convention: organization.selectors.getConvention(state),
});

const mapDispatchToProps = {
  prepareSignConvention: organization.commands.prepareSignConvention,
  resetConvention: organization.commands.resetConvention,
  fetchConvention: organization.queries.fetchConvention,
};

export default connect(mapStateToProps, mapDispatchToProps);
