import { createSelector } from "reselect";
import { State } from "..";
import { ConventionStateMachine } from "api/organization";
import { keycloakClient } from "api/keyclaok";

const getOrganizationState = (state: State) => state.organization;

export const getConvention = createSelector(
  [getOrganizationState],
  (state) => state.convention
);

export const getOrganization = createSelector(
  [getOrganizationState],
  (state) => state.organization
);

export const getConventionActions = createSelector(
  [getConvention],
  (convention) => {
    if (!convention) {
      return {
        canPrefill: false,
        canView: false,
        canSign: false,
      };
    }
    const status = convention.status;
    const isAdmin = keycloakClient.instance.hasRealmRole("admin");
    const isOperatorAdmin = keycloakClient.instance.hasRealmRole(
      "operator_admin"
    );
    return ConventionStateMachine.getAvailableActions(
      status.value,
      isAdmin,
      !isAdmin && isOperatorAdmin
    );
  }
);

export const selectors = {
  organization: getOrganization,
  getConventionActions: getConventionActions,
  getConvention: getConvention,
};
