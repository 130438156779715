import { createSelector } from "reselect";
import { State } from "..";

const getCarrierReportState = (state: State) => state.report;

export const getReport = createSelector(
  [getCarrierReportState],
  (state) => state.carrierReport
);
export const selectors = {
  report: getReport,
};
