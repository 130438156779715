import { InputLabel, makeStyles } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { themeContext } from '@smartb/archetypes-ui-components'
import React, { useContext } from 'react'
import { Calendar } from '../../Icons/Calendar'
import { useInputStyles } from '../../Theme'

const useStyles = makeStyles(() => ({
  input: {
    '& .MuiInputBase-input': {
      paddingLeft: 10,
      color: '#98A5AE !important',
      minHeight: 50,
      padding: 0
    }
  },
  noIcon: {
    '& .MuiIconButton-root.Mui-disabled': {
      display: 'none'
    }
  },
  toolbar: {
    '& .MuiPickersToolbar-toolbar': {}
  }
}))

type viewsFormat = 'year' | 'month' | 'date'
interface DatePickerProps {
  value: Date | string | null
  onChangeDate: (date: Date) => void
  className?: string
  id: string
  dateLabel?: string
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  views?: Array<viewsFormat>
  openTo?: viewsFormat
  notUseformat?: boolean
  clearable?: boolean
  placeholder?: string
  disableToolBar?: boolean
}

const applyTimezoneOffset = (date: Date): Date => {
  const timestampWithOffset = date.getTime() + date.getTimezoneOffset() * 60000
  return new Date(timestampWithOffset)
}

export const DatePicker = (props: DatePickerProps) => {
  const {
    value,
    onChangeDate,
    className,
    id,
    dateLabel,
    minDate,
    maxDate,
    disabled = false,
    views,
    openTo,
    notUseformat,
    clearable = false,
    placeholder = '',
    disableToolBar = true
  } = props
  const localClasses = useStyles()
  const theme = useContext(themeContext)
  const classes = useInputStyles(theme, disabled)()

  return (
    <div className={`${className}`}>
      {dateLabel ? (
        <InputLabel htmlFor={id} className={classes.label}>
          {dateLabel}
        </InputLabel>
      ) : null}
      <KeyboardDatePicker
        views={views ? views : ['year', 'month', 'date']}
        openTo={openTo ? openTo : 'date'}
        disableToolbar={disableToolBar}
        clearable={clearable}
        variant={clearable ? 'dialog' : 'inline'}
        cancelLabel={''}
        clearLabel='Vider'
        okLabel='Valider'
        format={notUseformat ? undefined : 'dd/MM/yy'}
        id={id}
        minDate={minDate && applyTimezoneOffset(minDate)}
        maxDate={maxDate && applyTimezoneOffset(maxDate)}
        invalidDateMessage='Format de date non valide'
        placeholder={placeholder}
        className={
          disabled
            ? `${classes.input} ${localClasses.input} ${localClasses.noIcon} `
            : `${classes.input} ${localClasses.input} `
        }
        InputProps={{
          disableUnderline: true
        }}
        disabled={disabled ? disabled : false}
        value={value}
        onChange={onChangeDate}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        keyboardIcon={
          <Calendar
            color='#98A5AE'
            style={{
              width: 15,
              height: 15
            }}
          />
        }
        minDateMessage={''}
      />
    </div>
  )
}
