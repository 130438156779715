import { buildBonusGroupApiUrl } from "api/configuration";
import { BonusGroupFunder } from "..";
import { http } from "../../http";

export const fetchBonusGroupFundersQuery = (
  bonusGroupId: string
): Promise<BonusGroupFunder[]> => {
  return http.get(
    buildBonusGroupApiUrl(`/bonusgroup/${bonusGroupId}/funders`),
    "la récupération des financeurs de la prime n'a pas pu être effectuée"
  );
};
