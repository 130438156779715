import { makeStyles, Typography } from "@material-ui/core";
import { bonusGroupClient } from "api/bonusGroup";
import { OrganizationRef } from "api/organization";
import BonusForm from "app/components/Bonus/BonusForm";
import {
  BonusFormDetailsState,
  BonusFormState,
  defaultBonusFormState,
} from "app/components/Bonus/BonusForm/model";
import { BonusValidation } from "app/components/Bonus/BonusForm/validation";
import {
  Button,
  CancelButton,
  FormButtons,
  HeadBarProps,
  PopUpConfirmation,
  SectionForm,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import withConnect from "./withConnect";

const useStyles = makeStyles(() => ({
  button: {
    margin: "0 10px",
  },
}));

interface BonusCreatePageProps {
  setHeadBar: (headBar: HeadBarProps) => void;
  carriers: Map<string, OrganizationRef>;
  operators: Map<string, OrganizationRef>;
  createBonus: (bonus: BonusFormDetailsState) => void;
  gotoBonusList: (page: number, size: number) => void;
  gotoBonus: (page: number, size: number) => void;
  gotoBonusEdit: (bonusId: string) => void;
}

const BonusCreatePage = (props: BonusCreatePageProps) => {
  const {
    setHeadBar,
    carriers,
    operators,
    createBonus,
    gotoBonus,
    gotoBonusEdit,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [bonusState, setBonus] = useState<BonusFormState>(
    defaultBonusFormState()
  );
  const [duplicateBonusId, setDuplicateBonusId] = useState<number>();
  const [openDuplicatePopup, setOpenDuplicatePopup] = useState(false);

  useEffect(() => {
    setHeadBar({ title: "Créer une prime" });
  }, []);

  const handleBonusCreation = async () => {
    const [isValid, validation] = BonusValidation.validateForm(bonusState);

    if (!isValid) {
      return setBonus({ ...bonusState, validity: validation });
    }
    await createBonus(bonusState.details);
  };

  useEffect(() => {
    if (
      !bonusState.details.operatorId ||
      !bonusState.details.carrierId ||
      !bonusState.details.territory ||
      !bonusState.details.period
    ) {
      return;
    }

    bonusGroupClient.query
      .fetchBonusGroupPage(
        0,
        1,
        bonusState.details.period.startDate,
        bonusState.details.period.endDate,
        bonusState.details.operatorId,
        bonusState.details.carrierId,
        bonusState.details.territory.code,
        "CREATED"
      )
      .then((bonusGroupPage) => {
        const bonusGroup = bonusGroupPage.list[0];
        setDuplicateBonusId(bonusGroup?.id);
        if (bonusGroup != null) {
          setOpenDuplicatePopup(true);
        }
      });
  }, [
    bonusState.details.operatorId,
    bonusState.details.carrierId,
    bonusState.details.territory?.code,
    bonusState.details.period,
  ]);

  const handleCloseDuplicatePopup = useCallback(() => {
    setOpenDuplicatePopup(false);
  }, []);

  const handleGoToBonusUpdate = useCallback(() => {
    gotoBonusEdit && gotoBonusEdit(duplicateBonusId?.toString() ?? "");
  }, [duplicateBonusId, gotoBonusEdit]);

  return (
    <SectionForm withGoBack>
      <BonusForm
        carriers={carriers}
        operators={operators}
        bonusState={bonusState}
        onBonusChange={setBonus}
        mode="create"
      />

      <FormButtons>
        <CancelButton onClick={() => gotoBonus(0, 10)}>
          {t("app_page_bonus_create_cancel")}
        </CancelButton>
        <Button
          className={classes.button}
          onClick={handleBonusCreation}
          disabled={!!duplicateBonusId}
        >
          {t("app_page_bonus_create_create")}
        </Button>
      </FormButtons>

      <PopUpConfirmation
        open={openDuplicatePopup}
        title={t("app_components_bonus_bonusform_popup_duplicate_title")}
        onClose={handleCloseDuplicatePopup}
        onConfirm={handleGoToBonusUpdate}
        validateText={t("app_components_bonus_bonusform_popup_duplicate_ok", {
          bonusId: duplicateBonusId ?? "",
        })}
      >
        <Typography variant="body1">
          {t("app_components_bonus_bonusform_popup_duplicate", {
            bonusId: duplicateBonusId ?? "",
          })}
        </Typography>
      </PopUpConfirmation>
    </SectionForm>
  );
};

export default withConnect(BonusCreatePage);
