import { createSelector } from "reselect";
import { State } from "..";

const getBonusState = (state: State) => state.bonus;

export const getBonus = createSelector(
  [getBonusState],
  (state) => state.bonus
);
export const selectors = {
  bonus: getBonus,
};
