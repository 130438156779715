import { bonusGroupClient } from "api/bonusGroup";
import { Dispatch } from "redux";
import { State } from "../index";
import { actions } from "./bonus.actions";
import { selectors } from "./bonus.selectors";

const fetchBonus: (bonusId: string, force?: boolean) => void = (
  bonusId: string,
  force: boolean = true
) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      const state = getState();
      const bonus = selectors.bonus(state);
      if (!bonus || bonus.id !== Number(bonusId) || force) {
        const fetched = await bonusGroupClient.query.fetchBonusGroup(bonusId);
        dispatch(actions.fetchedBonus(fetched || null));
      }
    } catch (error) {
      // dispatch(addTodo.error(error))
    }
  };
};

export const queries = {
  fetchBonus: fetchBonus,
};
