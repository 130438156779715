import { connect } from "react-redux";
import { State } from "../../../../store";
import { organization } from "../../../../store/organization";
import { title } from "../../../../store/title";
import { router } from "../../../../store/router";
import { profile } from "store/profile";

const mapStateToProps = (state: State) => ({
  organization: organization.selectors.organization(state),
  currentUser: profile.selectors.User(state),
});

const mapDispatchToProps = {
  fetchOrganization: organization.queries.fetchOrganization,
  setHeadBar: title.actions.setHeadBar,
  gotoOrganizationView: router.goto.organization.organizationView,
  gotoOrganizationUser: router.goto.organization.organizationUsersList,
};

export default connect(mapStateToProps, mapDispatchToProps);
