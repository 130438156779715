import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useContext } from 'react'
import InputBase from '@material-ui/core/InputBase'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useInputStyles } from '../../Theme'
import { themeContext } from '@smartb/archetypes-ui-components'
import { Option } from '../Select'
import { MenuProps } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  helperText: {
    position: 'relative',
    marginTop: '10px'
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    height: '100%'
  },
  chip: {
    margin: 8,
    background: '#F2F4F5',
    borderRadius: '5px',
    color: '#353945'
  },
  input: {
    minHeight: '50px',
    padding: 0,
    background: 'white !important',
    borderRadius: '5px'
  },
  noMenu: {
    '& .MuiSelect-icon.Mui-disabled': {
      display: 'none'
    },
    '& .MuiInputBase-root.Mui-disabled': {
      cursor: 'pointer'
    },
    '& .MuiSelect-select.Mui-disabled': {
      cursor: 'pointer'
    }
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuPropsP: Partial<MenuProps> = {
  PaperProps: {
    style: {
      marginTop: 70,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

interface MultiSelectProps {
  values?: string[]
  onChange?: (value: string[]) => void
  onClick?: () => void
  placeHolder?: string
  options?: Option[]
  id: string
  className?: string
  style?: React.CSSProperties
  error?: boolean
  errorMessage?: string
  minHeight?: string
  disabled?: boolean
  noMenu?: boolean
}

const findOptionLabel = (options: Option[], value: string): string | number => {
  return options.find((option) => option.value === value)?.label ?? ''
}

export const MultiSelect = (props: MultiSelectProps) => {
  const {
    values = [],
    onChange,
    onClick,
    placeHolder = '',
    options,
    className,
    style,
    id,
    error = false,
    errorMessage = '',
    minHeight,
    disabled = false,
    noMenu = false
  } = props
  const classesLocal = useStyles()
  const theme = useContext(themeContext)
  const classes = useInputStyles(theme, disabled)()
  return (
    <FormControl
      variant='filled'
      style={style}
      error={error}
      className={`${className}  ${classes.input} ${
        noMenu && !disabled ? classesLocal.noMenu : ''
      }`}
    >
      {placeHolder !== '' && (
        <InputLabel htmlFor={id}>{placeHolder}</InputLabel>
      )}
      <Select
        id={`mutiple-chip-${id}`}
        variant='filled'
        multiple
        onClick={!disabled ? onClick : undefined}
        value={values}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          onChange && onChange(event.target.value as string[])
        }}
        disabled={noMenu || disabled}
        input={
          <InputBase
            error={error}
            classes={{
              input: `${classesLocal.input} ${minHeight} `
            }}
          />
        }
        renderValue={(selected: string[]) => {
          return (
            <div className={classesLocal.chips}>
              {selected.map((value, index) => {
                if (index > 1) return
                return (
                  <Chip
                    key={value}
                    label={findOptionLabel(options ?? [], value)}
                    className={classesLocal.chip}
                  />
                )
              })}
              {selected.length > 2 && (
                <Chip
                  label={`+ ${selected.length - 2}`}
                  className={classesLocal.chip}
                />
              )}
            </div>
          )
        }}
        MenuProps={MenuPropsP}
      >
        {options &&
          options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
      {errorMessage !== '' && error && (
        <FormHelperText className={classes.helperText}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}
