import { createReducer } from "deox";
import { actions } from "./alert.actions";
import { Alert } from "../../api/organization";

export interface AlertState {
  alerts: Alert[];
}

export const initialState: AlertState = {
  alerts: [],
};

export type AlertStateTransformer = (state: AlertState) => AlertState;

const addAlert = (alert: Alert, state: AlertState): AlertState => {
  const tab = [...state.alerts];
  tab.push(alert);
  return { ...state, alerts: tab };
};

const removeAlert = (index: number, state: AlertState): AlertState => {
  const tab = [...state.alerts];
  tab.splice(index, 1);
  return { ...state, alerts: tab };
};

export const alertReducer = createReducer(initialState, (handleAction) => [
  handleAction(actions.add, (state: AlertState, action) =>
    addAlert(action.payload.alert, state)
  ),
  handleAction(actions.remove, (state: AlertState, action) =>
    removeAlert(action.payload.index, state)
  ),
]);
