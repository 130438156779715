import { Grid, makeStyles } from "@material-ui/core";
import { InputForm, Option } from "components";
import React from "react";
import { OrganizationDetails, OrganizationTypeValues } from "api/organization";
import { useTranslation } from "react-i18next";
import OrganizationDropLogo from "./OrganizationLogo";
import { TFunction } from "i18next";
import SirenAutocomplete, { NO_ERROR } from "../SiretAutocomplete";
import { defaultOrganizationDetailsFormValidity } from "./validation";
import { OrganizationDetailsFormState } from "./model";

const useStyles = makeStyles(() => ({
  leftContainer: {
    width: "250px",
    paddingTop: "40px",
  },
  rightContainer: {
    paddingTop: "20px",
    marginBottom: "30px",
  },
  container: {
    justifyContent: " space-between",
  },
  select: {
    width: "100%",
    marginTop: "30px",
  },
  input: {
    width: "50%",
  },
  text: {
    width: "35%",
  },
  inputForm: {
    marginTop: "30px",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "15px 0",
  },
}));

interface OrganizationDetailsFormProps {
  organization: OrganizationDetailsFormState;
  onOrganizationChange: (organization: OrganizationDetailsFormState) => void;
  readonly: boolean;
  edition?: boolean;
}

const typeOptions = (t: TFunction): Option[] => [
  { value: "Operator", label: `${t("app_organization_form_Operateur")} ` },
  { value: "Funder", label: `${t("app_organization_form_co_funder")} ` },
  { value: "Carrier", label: `${t("app_organization_form_Carrier")} ` },
];

const OrganizationDetailsForm = (props: OrganizationDetailsFormProps) => {
  const {
    organization,
    onOrganizationChange,
    readonly,
    edition = false,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const onSiretFound = (details: OrganizationDetails) => {
    onOrganizationChange({
      ...organization,
      details: details,
      validity: defaultOrganizationDetailsFormValidity,
    });
  };

  const onChangeSiret = (siret: string) => {
    onOrganizationChange({
      ...organization,
      details: { ...organization.details, siret: siret },
      validity: { ...organization.validity, siret: NO_ERROR },
    });
  };

  const onChangeName = (name: string) => {
    onOrganizationChange({
      ...organization,
      details: { ...organization.details, name: name },
      validity: { ...organization.validity, name: NO_ERROR },
    });
  };

  const onChangeStreet = (street: string) => {
    onOrganizationChange({
      ...organization,
      details: {
        ...organization.details,
        address: { ...organization.details.address, street: street },
      },
      validity: { ...organization.validity, street: NO_ERROR },
    });
  };

  const onChangeZipCode = (zipCode: string) => {
    onOrganizationChange({
      ...organization,
      details: {
        ...organization.details,
        address: { ...organization.details.address, zipCode: zipCode },
      },
      validity: { ...organization.validity, zipCode: NO_ERROR },
    });
  };

  const onChangeCity = (city: string) => {
    onOrganizationChange({
      ...organization,
      details: {
        ...organization.details,
        address: { ...organization.details.address, city: city },
      },
      validity: { ...organization.validity, city: NO_ERROR },
    });
  };

  const onChangeType = (type: string) => {
    onOrganizationChange({
      ...organization,
      type: { type: type },
      validity: { ...organization.validity, type: NO_ERROR },
    });
  };

  const setLogo = (logo?: File) => {
    onOrganizationChange({
      ...organization,
      logo: { file: logo },
    });
  };

  return (
    <Grid container direction="row" className={classes.container}>
      <Grid item xs={6}>
        <SirenAutocomplete
          onSiretChange={onChangeSiret}
          siret={organization.details.siret}
          readonly={readonly}
          onSiretFound={onSiretFound}
          error={organization.validity.siret}
        />
        <InputForm
          value={organization.details.name}
          onChange={onChangeName}
          id="organisation-add-selectOrganisationName"
          error={organization.validity.name !== NO_ERROR}
          errorMessage={organization.validity.name}
          inputType="textField"
          label={t("app_organization_form_name_of_the_organization")}
          inputClassName={readonly ? classes.text : classes.input}
          className={classes.inputForm}
          readonly={readonly}
        />
        <InputForm
          value={
            readonly || edition
              ? getFrenchType(organization.type.type, t)
              : organization.type.type
          }
          inputType={readonly || edition ? "textField" : "select"}
          selectOptions={typeOptions(t)}
          onChange={onChangeType}
          id="organisation-add-selectOrganisationType"
          error={organization.validity.type !== NO_ERROR}
          errorMessage={organization.validity.type}
          label={t("app_organization_form_type")}
          className={classes.select}
          readonly={readonly || edition}
        />
        {readonly &&
          organization.type.type === OrganizationTypeValues.CARRIER.type && (
            <InputForm
              value={organization.friendlyId}
              inputType="textField"
              id="organisation-add-friendlyId"
              label={t("app_organization_form_friendlyId")}
              className={classes.inputForm}
              readonly
            />
          )}
        <InputForm
          value={organization.details.address.street}
          onChange={onChangeStreet}
          id="organisation-add-selectOrganisationAdress"
          error={organization.validity.street !== NO_ERROR}
          errorMessage={organization.validity.street}
          inputType="textField"
          label={t("app_organization_form_address")}
          inputClassName={readonly ? classes.text : classes.input}
          className={classes.inputForm}
          readonly={readonly}
        />
        <InputForm
          value={organization.details.address.zipCode}
          onChange={onChangeZipCode}
          id="organisation-add-selectOrganisationPostalCode"
          error={organization.validity.zipCode !== NO_ERROR}
          errorMessage={organization.validity.zipCode}
          inputType="textField"
          label={t("app_organization_form_postal_code")}
          textFieldType="number"
          inputClassName={readonly ? classes.text : classes.input}
          className={classes.inputForm}
          readonly={readonly}
        />
        <InputForm
          value={organization.details.address.city}
          onChange={onChangeCity}
          id="organisation-add-selectOrganisationCity"
          error={organization.validity.city !== NO_ERROR}
          errorMessage={organization.validity.city}
          inputType="textField"
          label={t("app_organization_form_city")}
          inputClassName={readonly ? classes.text : classes.input}
          className={classes.inputForm}
          readonly={readonly}
        />
      </Grid>
      <Grid item xs={4} style={{ marginTop: "64px" }}>
        <OrganizationDropLogo
          organization={organization}
          readOnly={readonly}
          setLogo={setLogo}
        />
      </Grid>
    </Grid>
  );
};

export default OrganizationDetailsForm;

const getFrenchType = (type: string, t: TFunction): string => {
  switch (type) {
    case "Carrier":
      return `${t("app_organization_form_Carrier")} `;
    case "Operator":
      return `${t("app_organization_form_Operateur")} `;
    case "Funder":
    case "FunderCee":
      return `${t("app_organization_form_co_funder")} `;
    default:
      return `${t("app_organization_form_organization")} `;
  }
};
