import { useKeycloak } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import { AuthService, authService } from "./index";

export const useAuth = (): [
  AuthService,
  Keycloak.KeycloakInstance | undefined
] => {
  const [keycloak, initialized] = useKeycloak();

  if (initialized && keycloak.authenticated) {
    return [authService, keycloak];
  }

  return [authService, undefined];
};

// isAuthorized
// isColisActivAdmin
