import React, { useContext } from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { Theme, themeContext } from '@smartb/archetypes-ui-components'

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    progressContainer: {
      flexGrow: 1,
      background: '#e2e2e2',
      height: '8px',
      borderRadius: '30px',
      overflow: 'hidden'
    },
    progressBar: {
      background: theme.primaryColor,
      height: '100%',
      borderRadius: '30px'
    },
    label: {
      marginRight: '5px',
      color: `${theme.primaryColor} !important`,
      fontSize: '13px !important'
    }
  }))

export interface ProgressBarProps {
  progressionPourcentage: number
  withLabel?: boolean
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { progressionPourcentage, withLabel = false } = props
  const theme = useContext(themeContext)
  const classes = useStyles(theme)()

  // data-tag for the Onclick progation on the table component
  return (
    <Box display='flex' width='100%' alignItems='center'>
      {withLabel && (
        <Typography
          className={classes.label}
          variant='body2'
        >{`${progressionPourcentage}%`}</Typography>
      )}
      <div
        className={classes.progressContainer}
        data-tag='___react-data-table-allow-propagation___'
      >
        <div
          data-tag='___react-data-table-allow-propagation___'
          className={classes.progressBar}
          style={{ width: `${progressionPourcentage}%` }}
        ></div>
      </div>
    </Box>
  )
}
