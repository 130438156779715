import { buildOrganizationApiUrl } from "../../configuration";
import { OrganizationRef } from "../index";
import { http } from "../../http";
import { city as cityOrga } from "plateform-organization-domain";
import { getOrganizationLogoUrl } from "../utils";

export interface OrganizationRefBase
  extends cityOrga.colisactiv.organization.model.references.OrganizationRef {
  profileImgUrl: string;
}

export const fetchOrganizationsReferences = (): Promise<OrganizationRef[]> => {
  const refs: Promise<OrganizationRefBase[]> = http.get(
    buildOrganizationApiUrl(`/organizationRefs`),
    "la récupération des organizations n'a pas pu être effectuée"
  );
  return refs.then(asRefs);
};

export const asRefs = (base: OrganizationRefBase[]): OrganizationRef[] =>
  base.map(asRef);

export const asRef = (base: OrganizationRefBase): OrganizationRef => {
  return {
    ...base,
    profileImgUrl: getOrganizationLogoUrl(base.organizationId),
  } as OrganizationRef;
};
