import { BonusGroupPage } from "..";
import { buildBonusGroupApiUrl } from "../../configuration";
import { http } from "../../http";

export const fetchBonusGroupPageQuery = (
  page: number,
  size: number,
  startDate?: number,
  endDate?: number,
  operatorId?: string,
  carrierId?: string,
  territory?: string,
  status?: string
): Promise<BonusGroupPage> => {
  let path = `/bonusgroup?page=${page}&size=${size}`;
  if (startDate) path = `${path}&startDate=${startDate}`;
  if (endDate) path = `${path}&endDate=${endDate}`;
  if (operatorId) path = `${path}&operatorId=${operatorId}`;
  if (carrierId) path = `${path}&carrierId=${carrierId}`;
  if (territory) path = `${path}&territoryCode=${territory}`;
  if (status) path = `${path}&status=${status}`;
  return http.get(
    buildBonusGroupApiUrl(path),
    "la récupération des primes n'a pas pu être effectuée"
  );
};
