import { city } from "plateform-bonus-group-domain";
import { buildBonusGroupApiUrl } from "api/configuration";
import { http } from "../../http";
import { BonusGroupFunderInvoiceDetails } from "..";

export interface UpdateBonusGroupInvoiceDetailsCommand
  extends city.colisactiv.plateform.bonus.group.model.features
    .UpdateBonusGroupInvoiceDetailsCommand {}

export interface UpdateBonusGroupInvoiceDetailsCommandDetails
  extends city.colisactiv.plateform.bonus.group.model.features
    .UpdateBonusGroupInvoiceDetailsCommandDetails {}

export interface BonusGroupInvoiceDetailsUpdatedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupInvoiceDetailsUpdatedEvent {
  type: string;
}

export const updateBonusGroupInvoiceDetailsCommand = (
  bonusGroupId: string,
  invoiceId: string,
  details: BonusGroupFunderInvoiceDetails
): UpdateBonusGroupInvoiceDetailsCommand => {
  return {
    type: "UPDATE_BONUS_GROUP_INVOICE_DETAILS",
    payload: {
      bonusGroupId: bonusGroupId,
      invoiceId: invoiceId,
      details: details,
    },
  };
};

export const updateInvoiceDetails = (
  bonusGroupId: string,
  invoiceId: string,
  details: BonusGroupFunderInvoiceDetails
): Promise<BonusGroupInvoiceDetailsUpdatedEvent> => {
  return http.put(
    buildBonusGroupApiUrl(
      `/bonusgroup/${bonusGroupId}/invoice/${invoiceId}/details`
    ),
    JSON.stringify(
      updateBonusGroupInvoiceDetailsCommand(bonusGroupId, invoiceId, details)
    )
  );
};
