import { makeStyles } from '@material-ui/core'
import React, { ChangeEvent } from 'react'
import { PopUpForm } from '../../Component/PopUp'
import { BonusRejectionForm } from './BonusRejectionForm'

const useStyles = makeStyles(() => ({
  popup: {
    '& .MuiDialog-paper': {
      width: 750,
      maxWidth: 750
    }
  },
  button: {
    margin: 8
  }
}))

export interface BonusRejectionFormState {
  description: string
}

export interface BonusRejectionPopupProps {
  form: BonusRejectionFormState
  open: boolean
  onChange: (form: BonusRejectionFormState) => void
  onValidate: (form: BonusRejectionFormState) => void
  onCancel: (event: ChangeEvent<{}>) => void
  className?: string
  style?: React.CSSProperties
}

export const BonusRejectionPopup = (props: BonusRejectionPopupProps) => {
  const { form, open, className, onChange, onValidate, onCancel } = props

  const classes = useStyles()
  return (
    <PopUpForm
      title='Rejeter la prime'
      form={form}
      onValidate={onValidate}
      onCancel={onCancel}
      open={open}
      className={`${classes.popup} ${className || ''}`}
    >
      <BonusRejectionForm form={form} onChange={onChange} />
    </PopUpForm>
  )
}
