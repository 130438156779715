import {
  BonusGroupIncident,
  BonusGroupIncidentTypeValues,
} from "api/bonusGroup";

export interface BonusIncidentMapping {
  url?: string;
  messageKey?: string;
  messageLinkKey?: string;
  messageDefault: string;
  messageParams: Record<string, string>;
}

type BonusIncidentMapper = (
  incident: BonusGroupIncident
) => BonusIncidentMapping;

const getInvalidDataMapping: BonusIncidentMapper = (incident) => {
  const reportId = incident.properties.carrierReportId;
  return {
    ...getDefault(incident),
    url: `/report/${reportId}/view`,
    messageKey: "app_components_bonus_bonusincidentbar_invalid_data",
    messageLinkKey: "app_components_bonus_bonusincidentbar_invalid_data_link",
  };
};

const getMissingDataMapping: BonusIncidentMapper = (incident) => {
  const reportId = incident.properties.carrierReportId;
  return {
    ...getDefault(incident),
    url: `/report/${reportId}/view`,
    messageKey: "app_components_bonus_bonusincidentbar_missing_data",
    messageLinkKey: "app_components_bonus_bonusincidentbar_missing_data_link",
  };
};

const getDefault: BonusIncidentMapper = (incident) => {
  return {
    messageDefault: incident.description,
    messageParams: incident.properties,
  };
};

const getterMap: Record<string, BonusIncidentMapper> = {
  [BonusGroupIncidentTypeValues.CARRIER_REPORT_INVALID_DATA
    .value]: getInvalidDataMapping,
  [BonusGroupIncidentTypeValues.CARRIER_REPORT_MISSING_DATA
    .value]: getMissingDataMapping,
};

const get: BonusIncidentMapper = (incident) => {
  const getter = getterMap[incident.type.value] ?? getDefault;
  return getter(incident);
};

export const bonusIncidentMapping = {
  get,
};
