import React from 'react'
import { Card as MuiCard } from '@material-ui/core'
import { CardHeader, CardContent, CardActions } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      borderRadius: '5px',
      position: 'relative'
    },
    header: {
      minHeight: '10px',
      background: '#F9FAFC',
      position: 'relative',
      padding: '15px 20px'
    },
    content: {
      backgroundColor: 'white',
      minHeight: '20px',
      position: 'relative',
      padding: '15px 25px'
    },
    footer: {
      minHeight: '10px',
      position: 'relative',
      padding: '15px 25px'
    },
    divider: {
      height: '0.05rem',
      background: '#DFDFDF',
      width: 'calc(100% - 50px)',
      position: 'absolute',
      top: '-0.025rem',
      left: '25px',
      borderRadius: '5px'
    }
  })
)

export interface Section {
  key: string
  content: React.ReactNode
}

interface PanelClasses {
  header?: string
  content?: string
  footer?: string
  section?: string
  divider?: string
}

interface PanelStyles {
  header?: React.CSSProperties
  content?: React.CSSProperties
  footer?: React.CSSProperties
  section?: React.CSSProperties
  divider?: React.CSSProperties
}

export interface PanelProps {
  header?: React.ReactNode
  children?: React.ReactNode
  footer?: React.ReactNode
  sections?: Section[]
  classes?: PanelClasses
  styles?: PanelStyles
  className?: string
  style?: React.CSSProperties
  id?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const Panel = (props: PanelProps) => {
  const {
    header,
    children,
    className,
    footer,
    sections,
    style,
    id,
    classes,
    styles,
    onClick
  } = props
  const defaultClasses = useStyles()
  return (
    <MuiCard
      className={clsx(defaultClasses.card, 'ColisactivPanel-root', className)}
      style={style}
      id={id}
      elevation={1}
      onClick={onClick}
    >
      {!!header && (
        <CardHeader
          className={clsx(
            defaultClasses.header,
            'ColisactivPanel-header',
            classes?.header
          )}
          style={styles?.header}
          subheader={header}
        />
      )}
      <CardContent
        className={clsx(
          defaultClasses.content,
          'ColisactivPanel-content',
          classes?.content
        )}
        style={styles?.content}
      >
        {children}
      </CardContent>
      {sections &&
        sections.map((it) => (
          <CardActions
            key={it.key}
            className={clsx(
              defaultClasses.footer,
              'ColisactivPanel-section',
              classes?.section
            )}
            style={styles?.section}
          >
            <div
              className={clsx(
                defaultClasses.divider,
                'ColisactivPanel-divider',
                classes?.divider
              )}
              style={styles?.divider}
            ></div>
            {it.content}
          </CardActions>
        ))}
      {!!footer && (
        <CardActions
          className={clsx(
            defaultClasses.footer,
            'ColisactivPanel-footer',
            classes?.footer
          )}
          style={styles?.footer}
        >
          <div
            className={clsx(
              defaultClasses.divider,
              'ColisactivPanel-divider',
              classes?.divider
            )}
            style={styles?.divider}
          ></div>
          {footer}
        </CardActions>
      )}
    </MuiCard>
  )
}
