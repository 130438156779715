import { createActionCreator } from "deox";
import { HeadBarProps } from "components";

const setHeadBar = createActionCreator(
  "TITLE_HEADBAR_SET",
  (resolve) => (headBar: HeadBarProps) => resolve({ headBar: headBar })
);

export const actions = {
  setHeadBar: setHeadBar,
};
