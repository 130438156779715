import { buildBonusGroupApiUrl } from "api/configuration";
import { BonusGroup } from "..";
import { http } from "../../http";

export const fetchBonusGroupQuery = (
  bonusGroupId: string
): Promise<BonusGroup> => {
  return http.get(
    buildBonusGroupApiUrl(`/bonusgroup/${bonusGroupId}`),
    "la récupération de la prime n'a pas pu être effectuée"
  );
};
