import { buildProjectApiUrl } from "../../configuration";
import { Campaign } from "api/project/model";
import { http } from "../../http";

export const fetchCampaigns = (): Promise<Campaign[]> => {
  return http.get(
    buildProjectApiUrl(`/campaigns`),
    "la récupération des campagnes n'a pas pu être effectuée"
  );
};
