import { buildDashboardPoiApiUrl } from "api/configuration";
import { DateInterval } from "api/report";
import { http } from "../../http";
import { PoiDashboardStats } from "../index";

export interface PoiDashboardFilter {
  operatorId?: string;
  carrierId?: string;
  period?: DateInterval;
  territoryCode?: string;
}

export const fetchPoiDashboardStats = (
  filter: PoiDashboardFilter
): Promise<PoiDashboardStats> => {
  const { territoryCode, carrierId, operatorId, period } = filter;
  return http.post(
    buildDashboardPoiApiUrl("/poidashboard"),
    JSON.stringify({
      payload: {
        operatorId: operatorId,
        carrierId: carrierId,
        period: period,
        territoryCode: territoryCode,
      },
    })
  );
};
