import { Box, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { OrganizationRef } from "api/organization";
import { InputForm, PrimaryTypography, Table } from "components";
import {
  CsvExtractor,
  CsvExtractorHeader,
} from "components/src/Component/CsvExtractor";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiCarriersCellId } from "./ApiCarriersCellId";

const carrierIdColumn = {
  position: "last-child",
  style: {
    maxWidth: 150,
  },
};

const useStyles = makeStyles({
  actionBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  filterName: {
    width: 250,
    marginLeft: 20,
  },
  icon: {
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
  },
  table: {
    margin: "0 -20px",
    maxWidth: 500,
    [`& .rdt_TableRow .rdt_TableCell:${carrierIdColumn.position}`]: carrierIdColumn.style,
    [`& .rdt_TableCol:${carrierIdColumn.position}`]: carrierIdColumn.style,
  },
});

interface ApiCarriersProps {
  carrierRefs: Map<string, OrganizationRef>;
}

export const ApiCarriers = (props: ApiCarriersProps) => {
  const { carrierRefs } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [filterName, setFilterName] = useState("");

  const carriers = useMemo(() => {
    return Array.from(carrierRefs.values()).filter((carrier) =>
      carrier.displayName.toLowerCase().includes(filterName)
    );
  }, [carrierRefs, filterName]);

  const columns = [
    {
      name: `${t("app_api_carrier_table_name")} `,
      cell: (carrier: OrganizationRef) => (
        <PrimaryTypography
          data-tag="___react-data-table-allow-propagation___"
          align="left"
        >
          {carrier.displayName}
        </PrimaryTypography>
      ),
    },
    {
      name: `${t("app_api_carrier_table_id")} `,
      cell: (carrier: OrganizationRef) => (
        <ApiCarriersCellId
          carrier={carrier}
          onCopyToClipboard={copyIdToClipboard}
        />
      ),
    },
  ];

  const copyIdToClipboard = useCallback((carrier: OrganizationRef) => {
    navigator.clipboard.writeText(carrier.friendlyId);
  }, []);

  const handleFilterNameChange = useCallback((search: string) => {
    setFilterName(search.toLowerCase());
  }, []);

  const handleFilterNameClear = useCallback(() => {
    setFilterName("");
  }, []);

  const csvHeaders: CsvExtractorHeader<OrganizationRef>[] = [
    { key: "displayName", label: t("app_api_carrier_table_name") },
    { key: "friendlyId", label: t("app_api_carrier_table_id") },
  ];

  return (
    <>
      <Box className={classes.actionBar}>
        <CsvExtractor
          data={carriers}
          headers={csvHeaders}
          filename={"ID_Transporteurs.csv"}
          tooltipText={"Exporter"}
        />
        <InputForm
          inputType="textField"
          id="api-carriers-filters-name"
          onChange={handleFilterNameChange}
          value={filterName}
          placeHolder={t("app_api_carrier_filter_name")}
          className={classes.filterName}
          inputIcon={<Search className={classes.icon} />}
          onRemoveValue={handleFilterNameClear}
        />
      </Box>
      <Table
        className={classes.table}
        data={carriers}
        columns={columns}
        onRowClicked={copyIdToClipboard}
      />
    </>
  );
};
