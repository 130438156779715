import { createActionCreator } from "deox";
import { BonusPaymentId, BonusPayment } from "api/bonusPayment";

const fetchBonusPayment = createActionCreator(
  "BONUS_PAYMENT_FETCH",
  (resolve) => (bonusPaymentIds: BonusPaymentId) => resolve({ bonusPaymentIds })
);

const fetchedBonusPayment = createActionCreator(
  "BONUS_PAYMENT_FETCHED",
  (resolve) => (bonusPayment: BonusPayment) => resolve({ bonusPayment })
);

const payBonusPayment = createActionCreator(
  "BONUS_PAYMENT_PAY",
  (resolve) => (bonusPaymentIds: BonusPaymentId[]) =>
    resolve({ bonusPaymentIds })
);

const paidBonusPayment = createActionCreator(
  "BONUS_PAYMENT_PAID",
  (resolve) => (bonusPaymentIds: BonusPaymentId[]) =>
    resolve({ bonusPaymentIds })
);

export const actions = {
  fetchBonusPayment: fetchBonusPayment,
  fetchedBonusPayment: fetchedBonusPayment,
  payBonusPayment: payBonusPayment,
  paidBonusPayment: paidBonusPayment,
};
