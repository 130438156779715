import { Grid, makeStyles } from "@material-ui/core";
import { Territory } from "api/geoZone";
import { OrganizationRef } from "api/organization";
import { Period } from "components";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BonusFormDetailsState, BonusFormDetailsValidity } from "../model";
import BonusFormOrganizationSelect from "./BonusFormOrganizationSelect";
import BonusFormPeriodPicker from "./BonusFormPeriodPicker";
import BonusFormTerritoryInput from "./BonusFormTerritoryInput";

const useStyles = makeStyles(() => ({
  textInputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  textInput: {
    width: "100%",
    marginTop: 10,
    minWidth: 250,
    display: "block",
  },
  grid: {
    display: "flex",
    margin: "15px 0",
  },
  select: {
    width: "100%",
    minWidth: 250,
    marginTop: 10,
    "& .MuiInput-underline:before": {
      content: "none",
    },
    display: "block",
  },
}));

interface BonusFormGeneralDetailsPops {
  bonusDetails: BonusFormDetailsState;
  operators: Map<string, OrganizationRef>;
  carriers: Map<string, OrganizationRef>;
  validity: BonusFormDetailsValidity;
  readonly: boolean;
  onChange: (property: keyof BonusFormDetailsState, value: any) => void;
}

const BonusFormGeneralDetails = (props: BonusFormGeneralDetailsPops) => {
  const {
    bonusDetails,
    validity,
    onChange,
    operators,
    carriers,
    readonly,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleTerritoryChange = useCallback(
    (territory: Territory | Territory[]) => {
      const newTerritory =
        Array.isArray(territory) && territory.length === 0
          ? undefined
          : territory;
      onChange("territory", newTerritory);
    },
    [onChange]
  );

  const handlePeriodChange = useCallback(
    (period: Period) => {
      onChange("period", period);
    },
    [onChange]
  );

  const handleOperatorChange = useCallback(
    (operatorId?: string) => {
      onChange("operatorId", operatorId);
    },
    [onChange]
  );

  const handleCarrierChange = useCallback(
    (carrierId?: string) => {
      onChange("carrierId", carrierId);
    },
    [onChange]
  );

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12} md={3} className={classes.grid}>
          <div>
            <BonusFormPeriodPicker
              value={bonusDetails.period}
              onChange={handlePeriodChange}
              inputClassName={classes.textInput}
              readonly={readonly}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12} md={3} className={classes.grid}>
          <div>
            <BonusFormTerritoryInput
              value={bonusDetails.territory}
              onChange={handleTerritoryChange}
              className={classes.textInputContainer}
              inputClassName={classes.textInput}
              error={!validity.territory}
              readonly={readonly}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={3} className={classes.grid}>
          <div>
            <BonusFormOrganizationSelect
              id="operator"
              organizations={operators}
              selectedId={bonusDetails.operatorId}
              onSelect={handleOperatorChange}
              label={t("app_components_bonus_bonusform_operator")}
              error={!validity.operatorId}
              errorMessage={t("app_components_bonus_bonusform_operator_error")}
              inputClassName={classes.select}
              readonly={readonly}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={3} className={classes.grid}>
          <div>
            <BonusFormOrganizationSelect
              id="carrier"
              organizations={carriers}
              selectedId={bonusDetails.carrierId}
              onSelect={handleCarrierChange}
              label={t("app_components_bonus_bonusform_carrier")}
              error={!validity.carrierId}
              errorMessage={t("app_components_bonus_bonusform_carrier_error")}
              inputClassName={classes.select}
              readonly={readonly}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default BonusFormGeneralDetails;
