import { Box, makeStyles } from "@material-ui/core";
import { Territory } from "api/geoZone";
import { OrganizationRef } from "api/organization";
import { BonusPrice, ProjectStats, ProjectFunder } from "api/project/model";
import {
  DeleteButton,
  GeoZoneWithTerritory,
  InputForm,
  MultiSelectBox,
  Option,
  TerritorySelectorDialog,
} from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BonusPriceFormValidity } from "./Model";

const useStyles = makeStyles(() => ({
  inputForm: {
    flexDirection: "column",
    marginTop: "10px",
    justifyContent: "start",
    alignItems: "start",
    width: "100%",
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    marginBottom: 50,
    maxWidth: "900px",
  },
  zone: {
    display: "flex",
    flexDirection: "column",
  },
  containerSelectStyle: {
    display: "flex",
    flexDirection: "column",
  },
  selectStyle: {
    justifyContent: "center",
    width: "100% ",
    marginTop: 11,
    minHeight: "2.1rem",
  },
  itemsWidth: {
    width: "33%",
    marginRight: 20,
  },
  deletebutton: {
    margin: "auto",
    paddingTop: "36px",
  },
  zoneButton: {
    marginTop: "36px",
    height: "50px",
    width: "100%",
  },
}));

interface BonusPriceFormProps {
  project: ProjectStats | null;
  bonus: BonusPrice;
  position: number;
  bonusLength: number;
  bonusValidation: BonusPriceFormValidity;
  onBonusDeleted: (position: number) => void;
  onChangeBonus: (
    typeInput: keyof BonusPrice,
    position: number,
    value: any
  ) => void;
  operatorRefs: Map<string, OrganizationRef>;
  funderRefs: Map<string, OrganizationRef>;
  readOnly: boolean;
}

const BonusPriceRow = (props: BonusPriceFormProps) => {
  const {
    project,
    bonus,
    position,
    bonusLength,
    bonusValidation,
    onChangeBonus,
    onBonusDeleted,
    operatorRefs,
    readOnly,
    funderRefs,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [funderOption, setFunderOption] = useState<Option[]>([]);

  const operatorOptions: Option[] = useMemo(
    () =>
      Array.from(operatorRefs.values()).map((it: OrganizationRef) => ({
        label: it.displayName,
        value: it.organizationId,
      })),
    [operatorRefs]
  );

  const territories = useMemo(() => {
    if (!project) return [];
    return (project.funders as ProjectFunder[]).flatMap(
      (funder) => funder.territories as Territory[]
    );
  }, [project?.funders]);

  const onValidate = useCallback(
    (zones: Map<string, GeoZoneWithTerritory>) =>
      onChangeBonus("areas", position, Array.from(zones.values())),
    [position, onChangeBonus]
  );

  useEffect(() => {
    if (project) {
      const allFunderOption: Option[] = Array.from(funderRefs.values()).map(
        (it: any) => ({
          label: it.displayName,
          value: it.organizationId,
        })
      );
      const projectFunders: string[] = project.funders.map(
        (funder: any) => funder.funderId
      );
      const fundersOption = allFunderOption.filter((el) =>
        projectFunders.includes(el.value.toString())
      );
      setFunderOption(fundersOption);
    }
  }, [project, funderRefs]);

  return (
    <Box className={classes.box}>
      <div className={classes.itemsWidth}>
        <InputForm
          id={`bonus-value-${position}`}
          value={bonus.value}
          inputType="textField"
          label={t(
            "app_campaign_form_bonus_price_row_project_premium_delivery"
          )}
          textFieldType="number"
          onChange={(value) =>
            onChangeBonus(
              "value",
              position,
              !!value ? Number(value) : undefined
            )
          }
          className={classes.inputForm}
          error={!bonusValidation.value}
          errorMessage={t(
            "app_campaign_form_bonus_price_row_please_enter_the_premium_value"
          )}
          readonly={readOnly}
          inputIcon={"€"}
          iconPosition="end"
        />
      </div>
      <div className={classes.itemsWidth}>
        <TerritorySelectorDialog
          onValidate={onValidate}
          selectedZones={bonus.areas}
          readonly={readOnly}
          title={t(
            "app_campaign_form_bonus_price_row_territory_selector_dialog"
          )}
          error={!bonusValidation.areas}
          errorMessage={t(
            "app_campaign_form_bonus_price_row_please_choose_area"
          )}
          className={classes.inputForm}
          territories={territories}
        />
      </div>
      <div className={classes.itemsWidth}>
        <MultiSelectBox
          label={t("app_campaign_form_bonus_price_row_operator")}
          id={`bonus-operator-${position}`}
          onChange={(value: string[]) =>
            onChangeBonus("operator", position, value)
          }
          options={operatorOptions}
          className={classes.selectStyle}
          values={bonus.operator}
          error={!bonusValidation.operator}
          errorMessage={t(
            "app_campaign_form_bonus_price_row_please_select_at_least_one_operator"
          )}
          readonly={readOnly}
        />
      </div>
      {bonusLength !== 1 && !readOnly && (
        <DeleteButton
          className={classes.deletebutton}
          onClick={() => onBonusDeleted(position)}
        />
      )}
    </Box>
  );
};

export default BonusPriceRow;
