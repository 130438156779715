import React, { ChangeEvent, Fragment, useState } from 'react'
import { Button } from '../Button'
import { makeStyles, TextField, Typography } from '@material-ui/core'
import { PopUp } from './PopUp'
import { CancelButton } from '../CancelButton'

const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiInputBase-root': {
      height: '30px'
    },
    '& input': {
      height: '11px',
      padding: '10px 14px'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#d3d7da'
      },
      '&:hover fieldset': {
        borderColor: '#d3d7da'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#9a9a9a',
        borderWidth: '1.5px'
      }
    }
  },
  cancel: {
    marginRight: '50px'
  }
}))

interface ConfirmationPopUpProps {
  title?: string
  onClose: (event: React.ChangeEvent<{}>) => void
  onConfirm?: (event: React.ChangeEvent<{}>) => void
  open: boolean
  children?: React.ReactNode
  strongConfirmation?: boolean
  className?: string
  style?: React.CSSProperties
  validateText?: string
}

export const PopUpConfirmation = (props: ConfirmationPopUpProps) => {
  const {
    title,
    onClose,
    onConfirm,
    open,
    children,
    strongConfirmation = false,
    className,
    style,
    validateText = 'Confirmer'
  } = props
  const [value, setValue] = useState<string>('')
  const classes = useStyles()
  return (
    <PopUp
      title={title}
      className={className}
      style={style}
      open={open}
      footer={
        onConfirm ? (
          <Fragment>
            <CancelButton
              onClick={(event: ChangeEvent<{}>) => {
                setValue('')
                onClose(event)
              }}
              className={classes.cancel}
            >
              Annuler
            </CancelButton>
            <Button
              onClick={(event: ChangeEvent<{}>) => {
                setValue('')
                onConfirm(event)
              }}
              disabled={strongConfirmation ? value !== 'confirmation' : false}
            >
              {validateText}
            </Button>
          </Fragment>
        ) : (
          <Button
            onClick={(event: ChangeEvent<{}>) => {
              setValue('')
              onClose(event)
            }}
          >
            Ok
          </Button>
        )
      }
      onClose={(event: ChangeEvent<{}>) => {
        setValue('')
        onClose(event)
      }}
    >
      {children}
      {strongConfirmation && (
        <Fragment>
          <Typography variant='body1'>
            Veuillez confirmer en recopiant le mot:{' '}
            <strong>confirmation</strong> dans le champ ci-dessous:
          </Typography>
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            type='text'
            className={`${classes.textField}`}
            variant='outlined'
            error={value !== '' && value !== 'confirmation'}
            helperText={
              value !== '' && value !== 'confirmation'
                ? "Le mot recopié n'est pas le bon"
                : ''
            }
          />
        </Fragment>
      )}
    </PopUp>
  )
}
