import { connect } from "react-redux";
import { organization } from "store/organization";
import { State } from "../../../../store";
import { router } from "../../../../store/router";

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {
  gotoOrganizationsAdd: router.goto.organization.organizationsAdd,
  gotoOrganizationView: router.goto.organization.organizationView,
  gotoOrganizationsList: router.goto.organization.organizationsList,
  setOrganizationToNull: organization.actions.setOrganizationToNull,
  gotoOrganizationEditGeneralInformations:
    router.goto.organization.organizationEditGeneralInformations,
  deleteOrganization: organization.commands.deleteOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps);
