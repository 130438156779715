import { connect } from "react-redux";
import { State } from "store";
import { title } from "store/title";
import { project } from "store/project";
import { global } from "store/global";
import { router } from "store/router";

const mapStateToProps = (state: State) => ({
  funderRefs: global.selectors.funderRefs(state),
});

const mapDispatchToProps = {
  setHeadBar: title.actions.setHeadBar,
  createProject: project.commands.createProject,
  gotoProjectsView: router.goto.project.projectsList,
};

export default connect(mapStateToProps, mapDispatchToProps);
