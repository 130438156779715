import React from 'react'
import { Button as SbButton } from '@smartb/archetypes-ui-components'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    textTransform: 'none',
    border: 'none !important',
    minWidth: '100px !important',
    background: 'transparent',
    backgroundImage: 'none !important',
    boxShadow: 'none !important',
    height: '44px',
    '& span': {
      color: '#98A5AE',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '17px'
    },
    '&:hover': {
      opacity: '0.9',
      background: '#ebeef0'
    }
  }
}))

interface TextButtonProps {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  onClick: (event: React.ChangeEvent<{}>) => void
  disabled?: boolean
}

export const TextButton = (props: TextButtonProps) => {
  const { children, className, style, onClick, disabled = false } = props
  const classes = useStyles()

  return (
    <SbButton
      onClick={onClick}
      className={`${classes.root} ${className}`}
      style={{ borderRadius: '5px', padding: '7px 10px', ...style }}
      disabled={disabled}
    >
      {children}
    </SbButton>
  )
}
