import { useMemo } from "react";
import {
  BonusGroup,
  BonusGroupAction,
  BonusGroupStateMachine,
} from "api/bonusGroup";
import { useAuth } from "../../../../auth/useAuth";
import { User } from "api/organization/users";

export interface UseBonusActions {
  currentUser: User | null;
  bonus: BonusGroup | null;
}

export const useBonusActions = (props: UseBonusActions): [BonusGroupAction] => {
  const { currentUser, bonus } = props;
  const [authService, keycloak] = useAuth();
  const action: BonusGroupAction = useMemo(() => {
    if (!!currentUser && !!bonus) {
      const isColisActivAdmin = authService.isColisActivAdmin(
        currentUser,
        keycloak
      );
      const availableAction = BonusGroupStateMachine.getAvailableActions(
        bonus.status.value,
        isColisActivAdmin
      );
      return availableAction;
    }
    return {
      canEdit: false,
      canResolve: false,
      canReject: false,
      canApprove: false,
      canAddIncident: false,
      canPublish: false,
      canEditDocument: false,
      canPay: false,
      canDeduce: false,
      canReport: false,
    };
  }, [currentUser, bonus?.status.value]);

  return [action];
};
