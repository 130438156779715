import { city } from "plateform-poi-dashboard-domain";
import { fetchPoiDashboardStats } from "./query/fetchPoiDashboardStats";

export interface PoiDashboardStats
  extends city.colisactiv.plateform.poi.dashboard.domain.PoiDashboardStats {}

export interface DeliveryLocation
  extends city.colisactiv.plateform.poi.dashboard.domain.DeliveryLocation {}

export interface BonusStats
  extends city.colisactiv.plateform.poi.dashboard.domain.BonusStats {}

export const poiDashboardClient = {
  query: {
    fetchPoiDashboardStats: fetchPoiDashboardStats,
  },
};
