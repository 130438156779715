import { OrganizationPage } from "../index";
import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "../../http";

export const fetchOrganizationsPage = (
  page: number,
  size: number,
  organizationName?: string,
  organizationType?: string
): Promise<OrganizationPage> => {
  const organizationNameTrim = !organizationName ? "" : organizationName;
  const organizationTypeTrim = !organizationType ? "" : organizationType;
  return http.get(
    buildOrganizationApiUrl(
      `/organizations?page=${page}&size=${size}&organizationName=${organizationNameTrim}&organizationType=${organizationTypeTrim}`
    ),
    "la récupération des organizations n'a pas pu être effectuée"
  );
};
