import { BonusGroupStatus, BonusGroupStatusValues } from "api/bonus";
import { BonusRow, StatusTagsFormProps } from "components";
import i18n from "i18next";

export const getLabelStyleStatusTags = (
  reportStatus: BonusGroupStatus
): StatusTagsFormProps => {
  switch (reportStatus.value) {
    case "CREATED":
      return {
        label: i18n.t("app_bonus_created_status"),
        color: "grey",
      };
    case "REPORTED":
      return {
        label: i18n.t("app_bonus_reported_status"),
        color: "purple",
      };
    case "DEDUCTED":
      return {
        label: i18n.t("app_bonus_deducted_status"),
        color: "lightGreen",
      };
    case "PUBLISHED":
      return {
        label: i18n.t("app_bonus_published_status"),
        color: "yellow",
      };
    case "APPROVED":
      return {
        label: i18n.t("app_bonus_approved_status"),
        color: "green",
      };
    case "INVALID":
      return {
        label: i18n.t("app_bonus_invalid_status"),
        color: "orange",
      };
    case "REJECTED":
      return {
        label: i18n.t("app_bonus_rejected_status"),
        color: "red",
      };
    case "PAID":
      return {
        label: i18n.t("app_bonus_paid_status"),
        color: "blue",
      };
    default:
      return {
        label: "",
        color: "grey",
      };
  }
};

const getStatusFromRow = (row: BonusRow): StatusTagsFormProps => {
  const status = { value: row.status };
  return getLabelStyleStatusTags(status);
};

const isCreated = (status: BonusGroupStatus) => {
  return BonusGroupStatusValues.isCreated(status.value);
};
const isReported = (status: BonusGroupStatus) => {
  return BonusGroupStatusValues.isReported(status.value);
};
const isDeducted = (status: BonusGroupStatus) => {
  return BonusGroupStatusValues.isDeducted(status.value);
};
const isPublished = (status: BonusGroupStatus) => {
  return BonusGroupStatusValues.isPublished(status.value);
};
const isValidated = (status: BonusGroupStatus) => {
  return BonusGroupStatusValues.isValidated(status.value);
};
const isInvalid = (status: BonusGroupStatus) => {
  return BonusGroupStatusValues.isInvalid(status.value);
};
const isRejected = (status: BonusGroupStatus) => {
  return BonusGroupStatusValues.isRejected(status.value);
};
const isPaid = (status: BonusGroupStatus) => {
  return BonusGroupStatusValues.isPaid(status.value);
};

export const BonusGroupStatusUtils = {
  getLabelStyleStatusTags: getLabelStyleStatusTags,
  getStatusFromRow: getStatusFromRow,
  isCreated: isCreated,
  isReported: isReported,
  isDeducted: isDeducted,
  isPublished: isPublished,
  isValidated: isValidated,
  isPaid: isPaid,
  isInvalid: isInvalid,
  isRejected: isRejected,
};
