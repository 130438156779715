import { Box, makeStyles, Paper } from "@material-ui/core";
import { Theme, themeContext } from "@smartb/archetypes-ui-components";
import { BonusGroup } from "api/bonusGroup";
import { OrganizationRef } from "api/organization";
import { HeadlineBox } from "components";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { displayNumber } from "utils";

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    details: {
      width: "100%",
      padding: "20px 10px",
      marginBottom: "20px",
      boxSizing: "border-box",
      position: "sticky",
      zIndex: 3,
      top: "5px",
    },
    amount: {
      "& .colisactivHeadlineBox-header": {
        color: theme.primaryColor,
      },
    },
    headBox: {
      marginRight: "20px",
      maxWidth: "150px",
    },
    divider: {
      width: "1px",
      alignSelf: "stretch",
      marginRight: "10px",
      background: "#EAEAEA",
    },
  }));

interface BonusPanelProps {
  bonus: BonusGroup;
  carrier: OrganizationRef | undefined;
  operator?: OrganizationRef | undefined;
  actions?: React.ReactNode;
}

const BonusPanel = (props: BonusPanelProps) => {
  const { bonus, carrier, operator, actions } = props;
  const theme = useContext(themeContext);
  const classes = useStyles(theme)();

  const activeDeliveries = useMemo(() => {
    let activeDeliveryCount = 0;
    let activePackageCount = 0;
    bonus.bonuses
      .flatMap((bonus) => bonus.activeDeliveryTours)
      .forEach((tour) => {
        activeDeliveryCount += tour.deliveryCount;
        activePackageCount += tour.packCount;
      });
    return {
      activeDeliveryCount: activeDeliveryCount,
      activePackageCount: activePackageCount,
    };
  }, [bonus]);

  const nonActiveDeliveryCount = useMemo(() => {
    return bonus.bonuses
      .flatMap((bonus) => bonus.nonActiveDeliveryTours)
      .reduce((deliveryCount, tour) => deliveryCount + tour.deliveryCount, 0);
  }, [bonus]);
  const { t } = useTranslation();

  return (
    <Paper className={classes.details}>
      <Box display="flex" width="100%">
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexGrow="1"
        >
          <HeadlineBox
            header={carrier ? carrier.displayName : ""}
            className={classes.headBox}
            size="small"
            reverse
          >
            {t("app_components_bonus_chargeur")}
          </HeadlineBox>
          <HeadlineBox
            header={operator ? operator.displayName : ""}
            className={classes.headBox}
            size="small"
            reverse
          >
            {t("app_components_bonus_Operateur")}
          </HeadlineBox>
          <HeadlineBox
            header={bonus.territory.name}
            className={classes.headBox}
            size="small"
            reverse
          >
            {t("app_components_bonus_territory")}
          </HeadlineBox>
          <HeadlineBox
            header={displayNumber(
              activeDeliveries.activeDeliveryCount + nonActiveDeliveryCount
            )}
            className={classes.headBox}
            size="small"
            reverse
          >
            {t("app_domain_bonus_Livraison_effectuée")}
          </HeadlineBox>
          <HeadlineBox
            header={displayNumber(activeDeliveries.activeDeliveryCount)}
            className={classes.headBox}
            size="small"
            reverse
          >
            {t("app_domain_bonus_active_delivries")}
          </HeadlineBox>
          <HeadlineBox
            header={displayNumber(activeDeliveries.activePackageCount)}
            className={classes.headBox}
            size="small"
            reverse
          >
            {t("app_domain_bonus_active_packages")}
          </HeadlineBox>
          <HeadlineBox
            header={`${displayNumber(bonus.amount, 2)} €`}
            className={`${classes.amount} ${classes.headBox}`}
            reverse
          >
            {t("app_components_bonus_amount")}
          </HeadlineBox>
        </Box>
        <div className={classes.divider} />
        <Box display="flex">{actions}</Box>
      </Box>
    </Paper>
  );
};

export default BonusPanel;
