import { queries } from "./bonus.queries";
import { actions } from "./bonus.actions";
import { commands } from "./bonus.command";
import { selectors } from "./bonus.selectors";
export const bonus = {
  actions: actions,
  queries: queries,
  commands: commands,
  selectors: selectors,
};
