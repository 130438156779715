import { StatusTagsFormProps, BonusPayment } from "components";
import i18n from "i18next";
import { BonusPaymentStatus } from "../index";

const getStatusFromRow = (row: BonusPayment): StatusTagsFormProps => {
  return getLabelStyleStatusTags(row.status);
};

const getLabelStyleStatusTags = (
  status: BonusPaymentStatus
): StatusTagsFormProps => {
  switch (status.value) {
    case "CREATED":
      return {
        label: i18n.t("app_bonusPayment_valid_status"),
        color: "green",
      };
    case "PAID":
      return {
        label: i18n.t("app_bonusPayment_paid_status"),
        color: "blue",
      };

    default:
      return {
        label: status.value,
        color: "grey",
      };
  }
};

export const BonusPaymentStatusUtils = {
  getStatusFromRow: getStatusFromRow,
  getLabelStyleStatusTags: getLabelStyleStatusTags,
};
