import { BonusGroup } from "api/bonusGroup";
import { OrganizationRef } from "api/organization";
import { User } from "api/organization/users";
import React from "react";
import { BonusActionsAdminButtons } from "./BonusActionsAdminButtons";
import { BonusActionsOperatorButtons } from "./BonusActionsOperatorButtons";
import { useAuth } from "auth/useAuth";

interface BonusActionsButtonsProps {
  bonus: BonusGroup;
  currentUser: User | null;
  carrier: OrganizationRef | undefined;
  publishBonus: (bonusGroupId: string) => void;
  rejectBonus: (bonusGroupId: string, reasons: string) => void;
  validateBonus: (bonusGroupId: string) => void;
  createBonusIncident: (bonusGroupId: string, description: string) => void;
  resolveBonusIncident: (bonusGroupId: string, incidentId: string) => void;
  checkDocumentsValidity: () => Promise<boolean>;
  deduceBonusGroup: (bonusGroupId: string) => void;
}

export const BonusActionsButtons = (props: BonusActionsButtonsProps) => {
  const {
    bonus,
    carrier,
    currentUser,
    publishBonus,
    rejectBonus,
    validateBonus,
    createBonusIncident,
    resolveBonusIncident,
    checkDocumentsValidity,
    deduceBonusGroup,
  } = props;
  const [authService, keycloak] = useAuth();
  if (authService.isColisActivAdmin(currentUser, keycloak)) {
    return (
      <BonusActionsAdminButtons
        bonus={bonus}
        carrier={carrier}
        rejectBonus={rejectBonus}
        validateBonus={validateBonus}
        createBonusIncident={createBonusIncident}
        resolveBonusIncident={resolveBonusIncident}
        checkDocumentsValidity={checkDocumentsValidity}
        deduceBonusGroup={deduceBonusGroup}
      />
    );
  }

  if (authService.isOperator(currentUser, keycloak)) {
    return (
      <BonusActionsOperatorButtons
        bonus={bonus}
        publishBonus={publishBonus}
        resolveBonusIncident={resolveBonusIncident}
      />
    );
  }

  return <div />;
};
