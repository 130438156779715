import { connect } from "react-redux";
import { State } from "store";
import { notification } from "store/notification";
import { profile } from "store/profile";
import { title } from "store/title";

const mapStateToProps = (state: State) => ({
  currentLocationHeadBar: title.selectors.headBar(state),
  unSeenNumber: notification.selectors.unSeenNumber(state),
  currentUser: profile.selectors.User(state)
});

const mapDispatchToProps = {
  setUnSeenNumber: notification.actions.setUnSeenNumber
};

export default connect(mapStateToProps, mapDispatchToProps);
