import * as R from "ramda";
import {
  ProjectStats,
  ProjectDetails,
  ProjectFunders,
} from "api/project/model";
import {
  ProjectDetailValidity,
  ProjectFormState,
  ProjectFormValidity,
  ProjectFunderValidity,
} from "./model";

const getDetailsValidation = (
  details: ProjectDetails
): ProjectDetailValidity => {
  return {
    name: details.name !== "",
  };
};

const getFundersValidation = (
  projectFunders: ProjectFunders
): ProjectFunderValidity[] => {
  return projectFunders.map((el) => {
    let territories = el.territories.length > 0;
    let budget = el.budget !== "";
    let funderId = el.funderId !== "";
    return {
      territories: territories,
      budget: budget,
      funderId: funderId,
    };
  });
};

const isDetailsValid = (newValidity: ProjectDetailValidity): boolean => {
  return R.values(newValidity).reduce(
    (previousValue, currentValue) => previousValue && currentValue
  );
};

const isFunderValid = (newValidity: ProjectFunderValidity): boolean => {
  return R.values(newValidity).reduce(
    (previousValue, currentValue) => previousValue && currentValue
  );
};

const isFundersValid = (newValidity: ProjectFunderValidity[]): boolean => {
  return newValidity
    .map(isFunderValid)
    .reduce((previousValue, currentValue) => previousValue && currentValue);
};

const getFormValidation = (
  form: ProjectFormState | ProjectStats
): [boolean, ProjectFormValidity] => {
  const validation = getDetailsValidation(form.details);
  const fundersValidation = getFundersValidation(form.funders);
  const isValid =
    isDetailsValid(validation) && isFundersValid(fundersValidation);
  const projectValidation = {
    details: validation,
    funders: fundersValidation,
  } as ProjectFormValidity;
  return [isValid, projectValidation];
};

export const ProjectValidation = {
  getFormValidation: getFormValidation,
  getFundersValidation: getFundersValidation,
};
