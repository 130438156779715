import * as R from "ramda";
import { createReducer } from "deox";
import { actions } from "./profile.actions";
import { User } from "api/organization/users";

export interface UserState {
  user: User | null;
}

export const initialState: UserState = {
  user: null,
};

export type userStateTransformer = (state: UserState) => UserState;

const setUser = (user: User): userStateTransformer => {
  return R.assoc("user", user);
};

export const profileReducer = createReducer(initialState, (handleAction) => [
  handleAction(actions.fetchedProfile, (state: UserState, action) =>
    setUser(action.payload.user)(state)
  ),
]);
