import { buildCarrierReportApiUrl } from "../../configuration";
import { CarrierReport } from "../index";
import { http } from "../../http";

export const getReport = (carrierReportId: string): Promise<CarrierReport> => {
  return http.get(
    buildCarrierReportApiUrl(`/carrierReports/${carrierReportId}`),
    "Une erreur est survenue"
  );
};
