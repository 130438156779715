import { push } from "connected-react-router";
import { gotoProject } from "./router.project.goto";
import { gotoOrganization } from "./router.organization.goto";
import { gotoReports } from "./router.reports.goto";
import { gotoUser } from "./router.user.goto";
import { gotoBonus } from "./router.bonus.goto";
import { gotoBonusPayment } from "./router.bonusPayment.goto";

const dashboard = (
  startDate?: number,
  operatorId?: string,
  carrierId?: string,
  areaName?: string,
  areaId?: string,
  areaCode?: string
) => {
  let filters: string[] = [];
  if (startDate) filters.push(`startDate=${startDate}`);
  if (operatorId) filters.push(`operatorId=${operatorId}`);
  if (carrierId) filters.push(`carrierId=${carrierId}`);
  if (areaName) filters.push(`areaName=${areaName}`);
  if (areaId) filters.push(`areaId=${areaId}`);
  if (areaCode) filters.push(`areaCode=${areaCode}`);
  return push(`/dashboard?${filters.join("&")}`);
};

const api = () => push(`/api`);

const bonusView = (bonusId: string) => push(`/bonus/${bonusId}/view`);

const notifications = (pinned?: boolean) =>
  push(
    `/notifications?${
      pinned !== undefined ? "pinned=" + pinned : "pinned=false"
    }`
  );

const campaignDelivery = (
  page?: number,
  size?: number,
  startDate?: number,
  endDate?: number,
  operatorId?: string,
  areaName?: string,
  areaId?: string,
  areaCode?: string
) => {
  let path = `/campaignDelivery?page=${page ?? 0}&size=${size ?? 10}`;
  if (startDate) path += `&startDate=${startDate}`;
  if (endDate) path += `&endDate=${endDate}`;
  if (operatorId) path += `&operatorId=${operatorId}`;
  if (areaName) path += `&areaName=${areaName}`;
  if (areaId) path += `&areaId=${areaId}`;
  if (areaCode) path += `&areaCode=${areaCode}`;
  return push(path);
};

const pushUrl = (url: string) => push(url);

export const goto = {
  push: pushUrl,
  organization: gotoOrganization,
  project: gotoProject,
  user: gotoUser,
  report: gotoReports,
  bonus: gotoBonus,
  bonusPayment: gotoBonusPayment,
  dashboard: dashboard,
  api: api,
  bonusView: bonusView,
  notifications: notifications,
  campaignDelivery: campaignDelivery,
};
