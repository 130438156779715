import { makeStyles } from "@material-ui/core";
import { Territory } from "api/geoZone";
import { OrganizationRef } from "api/organization";
import { User } from "api/organization/users";
import { CarrierReport, carrierReportClient, DateInterval } from "api/report";
import PageWithTitle from "app/components/PageWithTitle/PageWithTitle";
import { usePaginedList } from "app/hooks/usePaginedList/usePaginedList";
import { useAuth } from "auth/useAuth";
import {
  Button,
  Options,
  ReportFilters,
  ReportRow,
  ReportsTable,
  Section,
} from "components";
import { ReportFiltersModel } from "components/src/Domain/Report";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate, nullToUndefined, nullToUndefinedNumber } from "utils";
import { ReportStatusUtils } from "../../../../api/report/model/reportStatus";
import withConnect from "./withConnect";
const useStyles = makeStyles(() => ({
  button: {
    float: "right",
    marginRight: "30px",
    height: "49.5px !important",
  },
}));

interface ReportsFormProps {
  addCarrierReport: () => void;
  resetReport: () => void;
  currentUser: User | null;
  carriers: Map<string, OrganizationRef>;
  gotoReportView: (reportId: string) => void;
  territories: Territory[];
  gotoReportsList: (
    page: number,
    size: number,
    startDate?: number | undefined,
    endDate?: number | undefined,
    carrierId?: string | undefined,
    area?: string | undefined,
    status?: string | undefined
  ) => void;
}

const Reports = (props: ReportsFormProps) => {
  const {
    addCarrierReport,
    resetReport,
    currentUser,
    carriers,
    gotoReportView,
    territories,
    gotoReportsList,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [authService, keycloak] = useAuth();

  const isCarrier = useMemo(
    () =>
      authService.isCarrier(currentUser, keycloak) &&
      !authService.isColisActivAdmin(currentUser, keycloak),
    [authService, currentUser, keycloak]
  );
  const [showCarrierColumn, setShowCarrierColumn] = useState<boolean>(true);

  const authResult = new URLSearchParams(window.location.search);
  const [filterValues, setFiltersValue] = useState<ReportFiltersModel>({
    startDate: formatDate(authResult.get("startDate")),
    endDate: formatDate(authResult.get("endDate")),
    carrierId: nullToUndefined(authResult.get("carrierId")),
    status: nullToUndefined(authResult.get("status")),
    territoryId: nullToUndefined(authResult.get("territory")),
  });

  const getTerritories: Options[] = useMemo(
    () =>
      territories?.map((el) => {
        return {
          label: el.name,
          value: el.code,
        };
      }),
    [territories]
  );

  const getCarriers = useMemo(
    () =>
      Array.from(carriers.values()).map((el) => {
        return {
          label: el.displayName,
          value: el.organizationId,
        };
      }),
    [carriers]
  );

  const fetchReports = useCallback(
    (page: number, size: number) => {
      let period: DateInterval | undefined = undefined;
      if (filterValues.startDate && filterValues.endDate) {
        period = {
          startDate: filterValues.startDate,
          endDate: filterValues.endDate,
        };
      }
      return carrierReportClient.query.getCarrierReportsPage(
        page,
        size,
        period,
        filterValues.carrierId,
        filterValues.territoryId,
        filterValues.status
      );
    },
    [filterValues]
  );

  const gotoListCallback = useCallback(
    (page: number, size: number) => {
      gotoReportsList(
        page,
        size,
        nullToUndefinedNumber(filterValues.startDate),
        nullToUndefinedNumber(filterValues.endDate),
        filterValues.carrierId,
        filterValues.territoryId,
        filterValues.status
      );
    },
    [
      gotoReportsList,
      nullToUndefinedNumber(filterValues.startDate),
      nullToUndefinedNumber(filterValues.endDate),
      filterValues.carrierId,
      filterValues.territoryId,
      filterValues.status,
    ]
  );

  const [items, isLoading, handlePageChange] = usePaginedList<CarrierReport>(
    [],
    fetchReports,
    gotoListCallback,
    [gotoListCallback, fetchReports]
  );

  useEffect(() => {
    currentUser &&
      setShowCarrierColumn(
        !authService.hasRoles(currentUser, ["admin", "user"], keycloak)
      );
  }, [currentUser]);

  const onBonusFiltersChange = (values: ReportFiltersModel) => {
    gotoReportsList(
      0,
      items.perPage,
      nullToUndefinedNumber(values.startDate),
      nullToUndefinedNumber(values.endDate),
      values.carrierId,
      values.territoryId,
      values.status
    );
    setFiltersValue({
      startDate: values.startDate,
      endDate: values.endDate,
      carrierId: values.carrierId,
      territoryId: values.territoryId,
      status: values.status,
    });
  };

  const getStatus = useCallback(
    (row: ReportRow) => ReportStatusUtils.getStatusFromRow(row, isCarrier),
    [isCarrier]
  );

  return (
    <PageWithTitle
      headBar={{ title: "Reporting" }}
      header={
        <>
          <ReportFilters
            carriers={getCarriers}
            onFilterChange={onBonusFiltersChange}
            filterValues={filterValues}
            territories={getTerritories}
            columnSwitchWidth={1000}
          />
          <Button
            className={classes.button}
            onClick={() => {
              resetReport();
              addCarrierReport();
            }}
          >
            {t("app_reports_list_new_rapport")}
          </Button>
        </>
      }
      columnSwitchWidth={1000}
      switchedHeaderHeight={150}
    >
      <>
        <Section actions={[]}>
          <ReportsTable
            carrierReports={items.list}
            isLoading={isLoading}
            totalPages={Math.ceil(items.totalRows / items.perPage)}
            page={items.page + 1}
            onRowClicked={(row: ReportRow) => gotoReportView(row.id)}
            handlePageChange={handlePageChange}
            omitCarrier={showCarrierColumn}
            carriers={carriers}
            getStatus={getStatus}
          />
        </Section>
      </>
    </PageWithTitle>
  );
};

export default withConnect(Reports);
