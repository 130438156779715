import React, { useContext, useState, useEffect } from "react";
import withConnect from "./withConnect";
import {
  makeStyles,
  Box,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@material-ui/core";
import NotificationsContainer from "app/components/NotificationsContainer";
import { getURLParameter } from "api/configuration";
import { Theme, themeContext } from "@smartb/archetypes-ui-components";
import { HeadBarProps } from "components/src/Component/HeadBar";
import { PinIcon } from "components";
import { useTranslation } from "react-i18next";
import { User } from "api/organization/users";
import { notificationClient } from "api/notification";

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    button: {
      float: "right",
      marginRight: "30px",
    },
    container: {
      background: "transparent !important",
      padding: "0px !important",
      width: "100% !important",
      boxShadow: "none !important",
      height: "calc(100vh - 220px) !important",
      overflow: "visible !important",
      [`@media (max-width: 800px)`]: {
        height: `calc(100vh - 320px) !important`,
      },
    },
    notification: {
      marginTop: "15px",
      width: "calc(100% - 40px) !important",
      marginLeft: "20px",
    },
    notificationContainer: {
      marginBottom: "40px",
    },
    checkedThumb: {
      color: theme.primaryColor,
    },
    uncheckedThumb: {
      color: "white",
    },
    pin: {
      marginTop: "3px",
    },
    pinIcon: {
      width: "20px",
      height: "20px",
    },
    label: {
      marginLeft: "24px",
      marginTop: "20px",
    },
  }));

interface NotificationsProps {
  setHeadBar: (headBar: HeadBarProps) => void;
  gotoNotifications: (pinned?: boolean) => void;
  currentUser: User | null;
  unSeenNumber: number;
  setUnSeenNumber: (unseenNumber: number) => void;
}

const Notifications = (props: NotificationsProps) => {
  const {
    setHeadBar,
    gotoNotifications,
    currentUser,
    unSeenNumber,
    setUnSeenNumber,
  } = props;
  const theme = useContext(themeContext);
  const classes = useStyles(theme)();
  const { t } = useTranslation();
  const [pinned, setPinned] = useState<boolean>(
    getURLParameter("pinned") !== ""
      ? Boolean(JSON.parse(getURLParameter("pinned")))
      : false
  );
  const [pageNumber, setPageNumber] = useState<number>(1);

  useEffect(() => {
    if (unSeenNumber > 0 && currentUser) {
      notificationClient
        .markAllAsSeen(currentUser.details.organizationId)
        .then((event) => {
          if (event) setUnSeenNumber(0);
        });
    }
  }, [unSeenNumber, currentUser, setUnSeenNumber]);

  useEffect(() => {
    setHeadBar({
      title: "Notifications",
    });
  }, [setHeadBar]);

  return (
    <>
      <Tooltip
        title={
          pinned
            ? (t("app_notification_unpin_tooltip") as string)
            : (t("app_notification_pin_tooltip") as string)
        }
      >
        <FormControlLabel
          value="start"
          className={classes.label}
          control={
            <Switch
              color="primary"
              checked={pinned}
              onChange={() => {
                setPinned(!pinned);
                setPageNumber(1);
                gotoNotifications(!pinned);
              }}
              classes={{
                thumb: pinned ? classes.checkedThumb : classes.uncheckedThumb,
              }}
            />
          }
          label={
            <div className={classes.pin}>
              <PinIcon
                className={classes.pinIcon}
                color={pinned ? theme.primaryColor : "#98A5AE"}
              />
            </div>
          }
          labelPlacement="start"
        />
      </Tooltip>
      <Box className={classes.notificationContainer}>
        <NotificationsContainer
          pageNumber={pageNumber}
          onPageNumberChange={(page: number) => setPageNumber(page)}
          pinned={pinned}
          className={classes.notification}
          id="notificationPage"
        />
      </Box>
    </>
  );
};

export default withConnect(Notifications);
