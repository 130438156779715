import { buildOrganizationApiUrl } from "api/configuration";
import { User } from "api/organization/users";
import { http } from "../../../http";

export const fetchOrganizationUser = (
  organizationId: string,
  userId: string
): Promise<User> => {
  return http.get(
    buildOrganizationApiUrl(`/organizations/${organizationId}/users/${userId}`),
    "la récupération des données de l'utilisateur n'a pas pu être effectuée"
  );
};
