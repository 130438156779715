import { Grid, makeStyles } from "@material-ui/core";
import { Territory } from "api/geoZone";
import { OrganizationRef } from "api/organization";
import { User } from "api/organization/users";
import { CheckBox, InputForm, Option, Period, PeriodPicker } from "components";
import "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { wholeMonthOf } from "utils";
import { AuthService } from "../../../auth";
import { useAuth } from "../../../auth/useAuth";
import { CarrierReportFormState } from "./model";

const useStyles = makeStyles(() => ({
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "15px 0",
  },
  inputClassName: {
    display: "flex",
    flexDirection: "column",
    width: "100%",

    "&:first-child": {
      marginRight: "20px",
    },
  },
  cityInput: {
    width: "100%",
  },
  button: {
    position: "absolute",
    right: "30px",
    bottom: "3px",
  },
  selectStyle: {
    minWidth: 250,
    width: "100%",
  },
  checkBoxStyle: {
    marginTop: 40,
    "& span": {
      fontWeight: 600,
    },
  },
  error: {
    fontSize: " 0.75rem",
    fontWeight: 400,
    color: "#f44336",
    marginLeft: 20,
    marginBottom: 10,
  },
  datePikerStyle: {
    width: "100%",
  },
  title: {
    marginBottom: 11,
    fontSize: "16px",
    color: "#353945",
    fontWeight: 600,
    marginTop: "20px",
  },
  informationText: {
    fontSize: "12px",
    fontStyle: "italic",
  },
}));

interface ReportFormProps {
  currentUser: User | null;
  carriers: Map<string, OrganizationRef>;
  carrierReportState: CarrierReportFormState;
  availableTerritories: Territory[];
  onCarrierReportChange: (carrierReport: CarrierReportFormState) => void;
  mode?: string;
}

export const showCarrierInput = (
  currentUser: User | null,
  authService: AuthService,
  keycloak?: Keycloak.KeycloakInstance
): boolean => {
  return (
    !!currentUser &&
    authService.hasRoles(currentUser, ["user", "admin"], keycloak)
  );
};

const ReportForm = (props: ReportFormProps) => {
  const classes = useStyles();
  const {
    currentUser,
    carriers,
    carrierReportState,
    availableTerritories,
    onCarrierReportChange,
    mode,
  } = props;

  const [now] = useState(wholeMonthOf(new Date().getTime()));
  const [options, setOptions] = useState<Option[]>([{ label: "", value: "" }]);
  const [authService, keycloak] = useAuth();

  const period = useMemo(() => {
    const reportPeriod = carrierReportState.carrierReport.period;
    if (reportPeriod) return reportPeriod;
    return now;
  }, [carrierReportState.carrierReport.period, now]);

  useEffect(() => {
    if (!showCarrierInput(currentUser, authService, keycloak)) {
      currentUser &&
        onCarrierReportChange({
          ...carrierReportState,
          carrierReport: {
            ...carrierReportState.carrierReport,
            carrierId: currentUser.details.organizationId,
          },
          validity: { ...carrierReportState.validity, carrierId: false },
        });
    }
  }, [currentUser]);

  const returnStateAccordingToType = (type: string) => {
    switch (type) {
      case "activeMode":
        return carrierReportState.carrierReport.activeMode;
      case "electricMode":
        return carrierReportState.carrierReport.electricMode;
      case "thermalMode":
        return carrierReportState.carrierReport.thermalMode;
      default:
        break;
    }
  };

  const onPeriodChange = (period?: Period) => {
    if (period == null) {
      return;
    }
    onCarrierReportChange({
      ...carrierReportState,
      carrierReport: {
        ...carrierReportState.carrierReport,
        period: period,
      },
    });
  };

  const onChangeCarrierReportFields = (
    type: string,
    value: string,
    validityType: string,
    nestedType?: string
  ) => {
    nestedType
      ? onCarrierReportChange({
          ...carrierReportState,
          carrierReport: {
            ...carrierReportState.carrierReport,
            [type]: {
              ...returnStateAccordingToType(type),
              [nestedType]: value ? Number(value) : undefined,
            },
          },
          validity: { ...carrierReportState.validity, [validityType]: true },
        })
      : onCarrierReportChange({
          ...carrierReportState,
          carrierReport: {
            ...carrierReportState.carrierReport,
            [type]: value,
          },
          validity: { ...carrierReportState.validity, [validityType]: true },
        });
  };

  const handleAreaChange = useCallback(
    (territoryCode: string) => {
      const newCarrierReportState = { ...carrierReportState };

      const territory =
        availableTerritories &&
        availableTerritories.find(
          (territory) => territory.code === territoryCode
        );
      newCarrierReportState.carrierReport.territory = territory;
      newCarrierReportState.validity.territory = true;

      onCarrierReportChange(newCarrierReportState);
    },
    [availableTerritories, carrierReportState, onCarrierReportChange]
  );

  useEffect(() => {
    const optionsArray =
      carriers &&
      Array.from(carriers.values()).map((el) => {
        return {
          label: el.displayName,
          value: el.organizationId,
        };
      });
    setOptions([...optionsArray]);
  }, [carriers]);

  const territoryOptions: Option[] =
    availableTerritories &&
    availableTerritories
      .map((territory) => ({
        label: territory.name,
        value: territory.code,
      }))
      .sort((territory1, territory2) =>
        territory1.label < territory2.label ? -1 : 1
      );

  const { t } = useTranslation();
  return (
    <div>
      {showCarrierInput(currentUser, authService, keycloak) && (
        <Grid container direction="row">
          <Grid item xs={9} className={classes.grid}>
            <InputForm
              value={carrierReportState.carrierReport.carrierId}
              id="carrier-select"
              label={t("app_report_form_choose_the_carrier")}
              inputType="select"
              onChange={(value) =>
                onChangeCarrierReportFields("carrierId", value, "carrierId")
              }
              selectOptions={options}
              className={classes.selectStyle}
              error={!carrierReportState.validity.carrierId}
              errorMessage={t("app_report_form_please_the_carrier")}
              readonly={mode === "edit"}
            />
          </Grid>
        </Grid>
      )}
      <Grid container direction="row">
        <Grid item xs={9} className={classes.grid}>
          <PeriodPicker
            id="report-form-periodPicker"
            label={t("app_report_form_choose_the_period")}
            period={period}
            onDateChange={onPeriodChange}
            readonly={mode === "edit"}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={9} className={classes.grid}>
          <InputForm
            id="territory"
            value={carrierReportState.carrierReport.territory.code}
            inputType="select"
            label={t("app_report_form_choose_the_zone")}
            onChange={handleAreaChange}
            className={classes.selectStyle}
            error={!carrierReportState.validity.territory}
            errorMessage={t("app_report_form_please_define_the_city")}
            selectOptions={territoryOptions}
            readonly={mode === "edit"}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <div className={classes.title} style={{ marginBottom: "20px" }}>
          <span>{t("app_report_form_active_mode")}</span>
        </div>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={9} className={classes.grid}>
          <InputForm
            id="activModeKM"
            value={carrierReportState.carrierReport.activeMode.kmNumber}
            onChange={(value) =>
              onChangeCarrierReportFields(
                "activeMode",
                value,
                "activeModekmNumber",
                "kmNumber"
              )
            }
            label={t("app_report_form_Nb_Kilometers")}
            inputType="textField"
            className={classes.inputClassName}
            textFieldType="number"
            //error={!carrierReportState.validity.activeModekmNumber}
            //errorMessage={t("app_report_form_required_Field")}
          />
          <InputForm
            id="activModeColis"
            value={carrierReportState.carrierReport.activeMode.packNumber}
            onChange={(value) =>
              onChangeCarrierReportFields(
                "activeMode",
                value,
                "activeModePackNumber",
                "packNumber"
              )
            }
            label={t("app_report_form_Nb_of_parcels")}
            inputType="textField"
            className={classes.inputClassName}
            textFieldType="number"
            error={!carrierReportState.validity.activeModepackNumber}
            errorMessage={t("app_report_form_required_Field")}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <div className={classes.title} style={{ marginBottom: "20px" }}>
          <span>{t("app_report_form_electric_vehicle")}</span>
        </div>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={9} className={classes.grid}>
          <InputForm
            id="electricalModeKM"
            value={carrierReportState.carrierReport.electricMode.kmNumber}
            onChange={(value) =>
              onChangeCarrierReportFields(
                "electricMode",
                value,
                "electricalModekmNumber",
                "kmNumber"
              )
            }
            label={t("app_report_form_Nb_Kilometers")}
            inputType="textField"
            className={classes.inputClassName}
            textFieldType="number"
            // error={!carrierReportState.validity.electricalModekmNumber}
            //errorMessage={t("app_report_form_required_Field")}
          />
          <InputForm
            id="electricalModeColis"
            value={carrierReportState.carrierReport.electricMode.packNumber}
            onChange={(value) =>
              onChangeCarrierReportFields(
                "electricMode",
                value,
                "electricalModePackNumber",
                "packNumber"
              )
            }
            label={t("app_report_form_Nb_of_parcels")}
            inputType="textField"
            className={classes.inputClassName}
            textFieldType="number"
            error={!carrierReportState.validity.electricalModepackNumber}
            errorMessage={t("app_report_form_required_Field")}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <div className={classes.title} style={{ marginBottom: "20px" }}>
          <span>{t("app_report_form_thermal_vehicle")}</span>
        </div>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={9} className={classes.grid}>
          <InputForm
            id="thermalModeKM"
            value={carrierReportState.carrierReport.thermalMode.kmNumber}
            onChange={(value) =>
              onChangeCarrierReportFields(
                "thermalMode",
                value,
                "thermalModekmNumber",
                "kmNumber"
              )
            }
            label={t("app_report_form_Nb_Kilometers")}
            inputType="textField"
            className={classes.inputClassName}
            textFieldType="number"
            // error={!carrierReportState.validity.thermalModekmNumber}
            // errorMessage={t("app_report_form_required_Field")}
          />
          <InputForm
            id="thermalModeColis"
            value={carrierReportState.carrierReport.thermalMode.packNumber}
            onChange={(value) =>
              onChangeCarrierReportFields(
                "thermalMode",
                value,
                "thermalModePackNumber",
                "packNumber"
              )
            }
            label={t("app_report_form_Nb_of_parcels")}
            inputType="textField"
            className={classes.inputClassName}
            textFieldType="number"
            error={!carrierReportState.validity.thermalModepackNumber}
            errorMessage={t("app_report_form_required_Field")}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12} className={classes.checkBoxStyle}>
          <CheckBox
            checked={carrierReportState.honorConfirmation}
            text={t(
              "app_report_form_I_certify_on_my_honor_that_the_above_information_is_correct"
            )}
            onChange={() =>
              onCarrierReportChange({
                ...carrierReportState,
                honorConfirmation: !carrierReportState.honorConfirmation,
                validity: {
                  ...carrierReportState.validity,
                  honorConfirmation: !carrierReportState.honorConfirmation,
                },
              })
            }
          />
          {!carrierReportState.validity.honorConfirmation && (
            <div className={classes.error}>
              {t(
                "app_report_form_please_indicate_that_your_information_is_correct"
              )}
            </div>
          )}
          <div className={classes.informationText}>
            {t("app_report_text_informations")}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportForm;
