import { createActionCreator } from "deox";
import { Alert } from "../../api/organization";

const addAlert = createActionCreator("ALERT_ADD", (resolve) => (alert: Alert) =>
  resolve({ alert: alert })
);

const removeAlert = createActionCreator(
  "ALERT_REMOVE",
  (resolve) => (index: number) => resolve({ index: index })
);

export const actions = {
  add: addAlert,
  remove: removeAlert,
};
