import { createSelector } from "reselect";
import { State } from "..";
import { Campaign, ProjectRef } from "api/project/model";
import * as R from "ramda";

const getProjectState = (state: State) => state.project;

export const getProjectRefs = createSelector([getProjectState], (state) =>
  !!state.projectRefs ? state.projectRefs : ([] as ProjectRef[])
);
export const getProject = createSelector(
  [getProjectState],
  (state) => state.project
);

export const getCampaignId = createSelector(
  [getProjectState],
  (state) => state.campaignId
);

export const getCampaigns = createSelector([getProject], (project) => {
  return (project?.campaigns || []) as Campaign[];
});

export const getCampaign = createSelector(
  [getCampaigns, getCampaignId],
  (campaigns, campaignId) => {
    if (campaignId == null) {
      return null;
    }
    const campaign: Campaign | null =
      R.find(R.propEq("id", campaignId), campaigns) || null;
    return campaign;
  }
);

export const getTerritories = createSelector(
  [getProjectState],
  (state) => state.territories
);

export const selectors = {
  projectRefs: getProjectRefs,
  project: getProject,
  campaign: getCampaign,
  territories: getTerritories,
};
