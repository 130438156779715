import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { BarLoader } from 'react-spinners'
import { theme } from '../../Theme/Themes'

const useStyles = makeStyles(() => ({
  container: {
    width: '100vw',
    height: 'calc(100vh - 2px)'
  },
  logo: {
    width: '80%',
    maxWidth: '400px'
  }
}))

export interface LoadingProps {
  img: string
  showOnlyBarLoader?: boolean
}

export const Loading = (props: LoadingProps) => {
  const classes = useStyles()
  const { img, showOnlyBarLoader } = props

  return (
    <React.Fragment>
      <BarLoader color={theme.primaryColor} width='100%' height='2px' />
      {!showOnlyBarLoader ? (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          className={classes.container}
        >
          <img src={img} className={classes.logo} alt='logo colisactiv' />
        </Box>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  )
}
