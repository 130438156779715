import Keycloak, { KeycloakInitOptions } from "keycloak-js";
import { buildConfigApiUrl } from "../configuration";

const instance = Keycloak(buildConfigApiUrl(`/config/keycloak.json`));

const initConfig: KeycloakInitOptions = {
  onLoad: "login-required",
  checkLoginIframe: false,
};

const getUserId = (): string => {
  // @ts-ignore
  const username = instance.tokenParsed.userId;
  return !!username ? username : "";
};
const getOrganizationId = (): string | null => {
  // @ts-ignore
  const organizationId = instance.tokenParsed?.organizationId;
  return !!organizationId ? organizationId : null;
};

export const keycloakClient = {
  instance: instance,
  initConfig: initConfig,
  token: {
    getOrganizationId: getOrganizationId,
    getUserId: getUserId,
  },
};
