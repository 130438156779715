import { Box } from '@material-ui/core'
import React from 'react'
import { InputForm } from '../../Component/InputForm'

interface BonusIncidentFormState {
  description: string
}

interface BonusIncidentFormProps {
  form: BonusIncidentFormState
  onChange: (form: BonusIncidentFormState) => void
}

export const BonusIncidentForm = (props: BonusIncidentFormProps) => {
  const { form, onChange } = props
  const handleChange = (field: keyof BonusIncidentFormState, value: string) => {
    onChange({
      ...form,
      [`${field}`]: value
    })
  }
  return (
    <Box>
      <InputForm
        label='Description'
        inputType='textField'
        id={'bonus-incident-description'}
        value={form.description}
        onChange={(description: string) =>
          handleChange('description', description)
        }
      />
    </Box>
  )
}
