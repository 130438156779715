import React from 'react'
import { CheckBox } from '../CheckBox'

export type StatusSeverity = 'information' | 'success' | 'warning'

export interface StatusCheckboxProps {
  text: string
  checked: boolean
  severity: StatusSeverity
  className?: string
  iconClassName?: string
}

const getColor = (severity: StatusSeverity): string => {
  switch (severity) {
    case 'success':
      return '#48d7a2'
    case 'warning':
      return '#ffa60d'
    default:
      return ''
  }
}
export const StatusCheckbox = (props: StatusCheckboxProps) => {
  const { text, severity, checked, className, iconClassName } = props

  const color = getColor(severity)
  return (
    <CheckBox
      checked={checked}
      checkedIconStyle={{ color: color }}
      text={text}
      className={className}
      checkedIconClassName={iconClassName}
      uncheckedIconClassName={iconClassName}
    />
  )
}
