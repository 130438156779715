import { CampaignDetails, BonusPrice, Rule, CampaignFunder } from "../model";
import { city } from "plateform-project-domain";
import { buildProjectApiUrl } from "../../configuration";
import { http } from "../../http";

export interface UpdatedCampaignEvent {
  payload: {
    projectId: string;
    campaignId: string;
  };
}
export interface UpdateCampaignCommand
  extends city.colisactiv.project.model.features.UpdateCampaignCommand {}

export const campaignUpdateCommand = (
  projectId: string,
  campaignId: string,
  campaignDetails: CampaignDetails,
  bonusPrice: BonusPrice[],
  funders: CampaignFunder[],
  budget: number,
  rules: Rule[]
): UpdateCampaignCommand => {
  return {
    type: "UPDATE_CAMPAIGN",
    payload: {
      projectId: projectId,
      budget: budget,
      campaignId: campaignId,
      campaignDetails: campaignDetails,
      bonusPrices: bonusPrice,
      funders: funders,
      rules: rules,
    },
  };
};

export const updateCampaignCommand = (
  projectId: string,
  campaignId: string,
  campaignDetails: CampaignDetails,
  bonusPrices: BonusPrice[],
  funders: CampaignFunder[],
  budget: number,
  rules: Rule[]
): Promise<UpdatedCampaignEvent> => {
  return http.post(
    buildProjectApiUrl(`/projects/${projectId}/campaigns/${campaignId}`),
    JSON.stringify(
      campaignUpdateCommand(
        projectId,
        campaignId,
        campaignDetails,
        bonusPrices,
        funders,
        budget,
        rules
      ).payload
    ),
    "Campagne modifiée avec succès"
  );
};
