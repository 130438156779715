import { connect } from "react-redux";
import { State } from "store";
import { title } from "store/title";
import { bonus } from "store/bonus";
import { profile } from "store/profile";
import { router } from "store/router";
import { global } from "store/global";

const mapStateToProps = (state: State) => ({
  organizationRef: global.selectors.organizationRefs(state),
  currentUser: profile.selectors.User(state),
  bonus: bonus.selectors.bonus(state),
});

const mapDispatchToProps = {
  setHeadBar: title.actions.setHeadBar,
  fetchBonus: bonus.queries.fetchBonus,
  createBonusIncident: bonus.commands.createBonusIncident,
  resolveBonusIncident: bonus.commands.resolveBonusIncident,
  publishBonus: bonus.commands.publishBonus,
  rejectBonus: bonus.commands.rejectBonus,
  validateBonus: bonus.commands.validateBonus,
  gotoBonusView: router.goto.bonus.bonusView,
  gotoBonusEdit: router.goto.bonus.bonusEdit,
};

export default connect(mapStateToProps, mapDispatchToProps);
