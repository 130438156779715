import { buildProjectApiUrl } from "api/configuration";
import { Territory } from "api/geoZone";
import { http } from "api/http";

export const getTerritoriesOfAllUnfinishedProjects = (): Promise<
  Territory[]
> => {
  return http.get(
    buildProjectApiUrl(`/projects/territories/unfinished`),
    "Une erreur est survenue"
  );
};
