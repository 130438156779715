import { connect } from "react-redux";
import { State } from "store";
import { router } from "store/router";
import { global } from "store/global";
import { profile } from "store/profile";
import { bonus } from "store/bonus";

const mapStateToProps = (state: State) => ({
  organizationRefs: global.selectors.organizationRefs(state),
  carrierRefs: global.selectors.carrierRefs(state),
  operatorRefs: global.selectors.operatorRefs(state),
  currentUser: profile.selectors.User(state),
  territories: global.selectors.projectTerritories(state),
});

const mapDispatchToProps = {
  gotoBonusAdd: router.goto.bonus.bonusAdd,
  gotoBonusView: router.goto.bonus.bonusView,
  gotoBonusList: router.goto.bonus.bonusList,
  restBonus: bonus.commands.resetBonus,
  gotoCampaignDelivery: router.goto.campaignDelivery,
};

export default connect(mapStateToProps, mapDispatchToProps);
