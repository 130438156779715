import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { DateIntervalLabel } from '../DateInterval'
import { StatusTagsFormProps, StatusTags } from '../StatusTags'
import { theme } from '../../Theme'
import { EditButton } from '../EditButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: theme.secondaryColor,
      fontWeight: 600,
      fontSize: 25
    },
    secondaryTitle: {
      fontSize: 14,
      paddingBottom: 2,
      color: theme.grey,
      marginLeft: 6
    },
    icon: {
      '& .MuiButtonBase-root': {
        padding: 3
      },
      '& .MuiTypography-root': {
        fontWeight: 600
      }
    },
    editButton: {
      marginLeft: 8,
      marginRight: 8,
      marginTop: '-25px',
      height: '32px'
    }
  })
)

interface HeadBarViewProps {
  title: string
  secondaryTitle?: string
  status: StatusTagsFormProps
  startDate?: number
  endDate?: number
  onEdit?: (event: React.ChangeEvent<{}>) => void
}

export const HeadBarView = (props: HeadBarViewProps) => {
  const { status, title, endDate, secondaryTitle, startDate, onEdit } = props
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box display='flex' width='100%' alignItems='center'>
      <Box display='flex' flexDirection='column'>
        <Box display='flex' alignItems='flex-end' width='max-content'>
          <Typography className={classes.title} component='h1'>
            {title}
          </Typography>
          {secondaryTitle && (
            <Typography className={classes.secondaryTitle} component='h2'>
              {secondaryTitle}
            </Typography>
          )}
        </Box>
        <StatusTags label={status.label} color={status.color} />
      </Box>
      {onEdit && (
        <EditButton
          key={0}
          className={classes.editButton}
          onClick={onEdit}
          label={t('app_components_headbar_edit')}
        />
      )}
      {startDate && endDate && (
        <DateIntervalLabel start={startDate} end={endDate} />
      )}
    </Box>
  )
}
