import { city as cityOrga, city } from "plateform-organization-domain";
import { buildOrganizationApiUrl } from "api/configuration";
import { http } from "api/http";

export interface CreateApplicationCommand
  extends cityOrga.colisactiv.organization.model.features
    .CreateApplicationCommand {}

export interface CreateApplicationCommandDTO
  extends city.colisactiv.organization.model.features
    .CreateApplicationCommandDTO {}

export interface ApplicationCreatedEvent
  extends city.colisactiv.organization.model.features.ApplicationCreatedEvent {
  type: string;
}

export const createApplicationCommand = (
  organizationId: string
): CreateApplicationCommand => {
  return {
    type: "CREATE_APPLICATION",
    payload: {
      organizationId: organizationId,
    },
  };
};

export const createApplication = (
  organizationId: string
): Promise<ApplicationCreatedEvent> => {
  return http.post(
    buildOrganizationApiUrl(`/organizations/${organizationId}/applications`),
    JSON.stringify(createApplicationCommand(organizationId))
  );
};
