import React, { useEffect } from "react";
import { HeadBarProps, SectionForm } from "components";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import OrganizationDetails from "./Details";
import withConnect from "./withConnect";
import { OrganizationDetailsFormState } from "../../../components/OrganizationDetailsForm/model";

const useStyles = makeStyles(() => ({
  title: {
    borderBottom: "2px solid #98a5ab33",
    padding: "20px 0",
    marginBottom: "30px",
  },
  text: {
    color: "#353945",
    fontWeight: 600,
    fontSize: "19px",
    lineHeight: "23px",
  },
  arrow: {
    marginLeft: "-8px",
    marginRight: "8px",
    marginTop: "-1px",
    width: "20px",
    height: "20px",
  },
}));

interface AddOrganizationPageProps {
  gotoOrganizationsList: (page: number, size: number) => void;
  setHeadBar: (headBar: HeadBarProps) => void;
  createOrganization: (form: OrganizationDetailsFormState) => void;
}

const OrganizationCreatePage = (props: AddOrganizationPageProps) => {
  const { setHeadBar, gotoOrganizationsList, createOrganization } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    setHeadBar({
      title: `${t("app_organizations_create_add_organization")} `,
    });
  }, [setHeadBar, t]);
  return (
    <SectionForm withGoBack>
      <div className={classes.title}>
        <span className={classes.text}>
          {t("app_organizations_create_generale_information")}
        </span>
      </div>
      <OrganizationDetails
        createOrganization={createOrganization}
        gotoOrganizationsList={() => gotoOrganizationsList(0, 10)}
      />
    </SectionForm>
  );
};

export default withConnect(OrganizationCreatePage);
