import React from 'react'
import { ReactComponent } from './SignDocument.svg'
import { MergeReactElementProps } from '../types'

interface SignDocumentProps {
  color?: string
}

type Props = MergeReactElementProps<'svg', SignDocumentProps>

export const SignDocument = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { color = '#9a9a9a' } = props
    return <ReactComponent stroke={color} ref={ref} {...props} />
  }
)
