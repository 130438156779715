import { Box, Fab, makeStyles, Tooltip } from '@material-ui/core'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import { themeContext } from '@smartb/archetypes-ui-components'
import { city as citybonus } from 'plateform-bonus-domain'
import { city as payment } from 'plateform-bonus-payment-domain'
import { city as cityDom } from 'plateform-organization-domain'
import { city as commons } from 'plateform-plateform-commons'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../Component/Button'
import { CheckBox } from '../../Component/CheckBox'
import { MonthDate } from '../../Component/MonthDate'
import { ProfileLogo } from '../../Component/ProfileLogo'
import { StatusTags, StatusTagsFormProps } from '../../Component/StatusTags'
import { PrimaryTypography, Table } from '../../Component/Table'
import { displayNumber, UTCToTimedZone } from '../../utils'
import { BonusRow } from '../Bonus'
import { BonusPaymentBonusTable } from './BonusPaymentBonusTable'

export interface BonusPayment
  extends payment.colisactiv.platform.bonus.payment.domain.model.BonusPayment {}

export interface BonusRef
  extends payment.colisactiv.platform.bonus.payment.domain.model.BonusRef {}
export interface Bonus
  extends citybonus.colisactiv.plateform.bonus.model.Bonus {}
export interface DateInterval
  extends commons.colisactiv.platform.commons.DateInterval {}
export interface OrganizationRef
  extends cityDom.colisactiv.organization.model.references.OrganizationRef {
  profileImgUrl: string
}
export interface OrganizationType
  extends cityDom.colisactiv.organization.model.OrganizationType {}
export interface BonusPaymentStatus {
  value: string
}
export interface PaymentInformation {
  period: string
  operator: string
  rib: string
  funder: string
  amount: string
}

export interface BonusPaymentRow {
  id: string
  period: number
  budget: Number
  status: StatusTagsFormProps
  operator: OrganizationRef | undefined
  isPaid: boolean
  bonuses: BonusRow[]
  bonusPayment: BonusPayment
  paymentInformations: PaymentInformation[]
}

const useStyles = makeStyles(() => ({
  container: {
    '& .rdt_TableRow .rdt_TableCell:nth-child(3n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(3n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(4n)': {
      minWidth: '220px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableCol:nth-child(4n)': {
      minWidth: '220px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(5n)': {
      minWidth: '150px'
    },
    '& .rdt_TableCol:nth-child(5n)': {
      minWidth: '150px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(6n)': {
      minWidth: '150px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableCol:nth-child(6n)': {
      minWidth: '150px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(7n)': {
      minWidth: '140px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableCol:nth-child(7n)': {
      minWidth: '140px !important',
      maxWidth: 'unset !important'
    },
    '& .rdt_TableRow .rdt_TableCell:last-child': {
      minWidth: '80px !important',
      maxWidth: '50px !important'
    },
    '& .rdt_TableCol:last-child': {
      minWidth: '80px !important',
      maxWidth: '80px !important'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(2n)': {
      minWidth: '80px',
      maxWidth: '80px'
    },
    '& .rdt_TableCol:nth-child(2n)': {
      minWidth: '80px',
      maxWidth: '80px'
    }
  },
  fab: {
    width: 40,
    height: 40,
    backgroundColor: '#00000000',
    boxShadow: '0 0 black'
  },
  icon: {
    color: '#007DCE'
  }
}))

interface BonusPaymentTableProps {
  bonusPayments: BonusPayment[]
  onRowClicked?: (row: BonusPayment) => void
  totalPages: number
  page: number
  handlePageChange?: (page: number) => void
  isLoading?: boolean
  getStatus: (row: BonusPayment) => StatusTagsFormProps
  checkedBonus: string[]
  onClickCheckBox: (check: boolean, row: BonusPayment) => void
  onChangeGlobalCheck: (globalCheck: boolean) => void
  onClickBonus: (bonusId: string) => void
  getOrganizationRef: (id: string) => OrganizationRef | undefined
  getDefaultOrganizationLogo: (
    type: OrganizationType | undefined,
    small: boolean
  ) => JSX.Element
  onPayClick: (bonusPaymentId: string) => void
  showPaidstatus: boolean
  onDownload: (bonusGroupIds: number[]) => void
}

export const toBonusPaymentRow = (
  bonusPayment: BonusPayment,
  getOrganizationRef: (id: string) => OrganizationRef | undefined,
  getStatus: (row: BonusPayment) => StatusTagsFormProps
): BonusPaymentRow => {
  const operatorRef = getOrganizationRef(bonusPayment.operator.id)
  return {
    id: bonusPayment.id,
    budget: bonusPayment.budget,
    operator: operatorRef,
    status: getStatus(bonusPayment),
    period: bonusPayment.period.startDate,
    isPaid: bonusPayment.status.value == 'PAID',
    bonuses: bonusPayment.bonuses.map((ref: BonusRef) => ({
      amount: ref.amount,
      carrier: getOrganizationRef(ref.carrierId),
      carrierId: ref.carrierId,
      city: ref.city,
      id: ref.id,
      operatorId: ref.operatorId,
      Logo: getOrganizationRef(ref.carrierId)?.profileImgUrl
    })),
    bonusPayment: bonusPayment,
    paymentInformations: [
      {
        period: `Du ${UTCToTimedZone(
          bonusPayment.period.startDate
        ).toLocaleDateString()} au ${UTCToTimedZone(
          bonusPayment.period.endDate
        ).toLocaleDateString()} `,
        operator: operatorRef?.displayName || 'Inconnu',
        rib: bonusPayment.operator.iban,
        funder: bonusPayment.funders
          .map((id: string) => getOrganizationRef(id)?.displayName)
          .join(', '),
        amount: `${displayNumber(bonusPayment.budget)} €`
      }
    ]
  }
}

export const BonusPaymentTable = (props: BonusPaymentTableProps) => {
  const {
    bonusPayments,
    onRowClicked,
    handlePageChange,
    totalPages,
    isLoading = false,
    getStatus,
    checkedBonus,
    onClickCheckBox,
    onChangeGlobalCheck,
    page,
    onClickBonus,
    getOrganizationRef,
    getDefaultOrganizationLogo,
    onPayClick,
    showPaidstatus,
    onDownload
  } = props

  const theme = useContext(themeContext)
  const classes = useStyles()
  const [checkedBonusState, setCheckedBonusState] = useState<string[]>([])

  useEffect(() => {
    setCheckedBonusState(checkedBonus)
    if (checkedBonus.length === 0) {
      setGlobalCheck(false)
    }
  }, [checkedBonus])

  const [globalCheck, setGlobalCheck] = useState<boolean>(false)
  const isCheckedBonus = (bonusId: string, checkedBonus: string[]): boolean => {
    return checkedBonus.includes(bonusId)
  }

  const { t } = useTranslation()
  const data: BonusPaymentRow[] = bonusPayments.map(
    (bonusPayment): BonusPaymentRow => {
      return toBonusPaymentRow(bonusPayment, getOrganizationRef, getStatus)
    }
  )
  const getAllChecked = (
    bonusPayments: BonusPayment[],
    checkedBonus: string[]
  ): boolean => {
    let allChecked = true
    const unpaidBonus = bonusPayments.filter((el) => el.status.value !== 'PAID')
    let counter = 0
    if (checkedBonus.length === 0) {
      return false
    }
    while (allChecked && counter < unpaidBonus.length) {
      allChecked = checkedBonus.includes(unpaidBonus[counter].id)
      counter++
    }
    return allChecked
  }

  useEffect(() => {
    if (bonusPayments.length > 0) {
      setGlobalCheck(getAllChecked(bonusPayments, checkedBonus))
    }
  }, [bonusPayments, checkedBonus])

  const columns = [
    {
      name: (
        <CheckBox
          checked={globalCheck}
          disabled={showPaidstatus}
          onChange={() => {
            onChangeGlobalCheck(!globalCheck)
            setGlobalCheck(!globalCheck)
          }}
          checkedIconStyle={{
            color: showPaidstatus ? ' #999999' : theme.primaryColor
          }}
        />
      ),
      cell: (row: BonusPaymentRow) => (
        <PrimaryTypography align='left'>
          <CheckBox
            onChange={(event) =>
              onClickCheckBox(event.target.checked, row.bonusPayment)
            }
            checked={
              row.isPaid ? true : isCheckedBonus(row.id, checkedBonusState)
            }
            disabled={row.isPaid}
            checkedIconStyle={{
              color: row.isPaid ? ' #999999' : theme.primaryColor
            }}
          />
        </PrimaryTypography>
      )
    },
    {
      name: 'Période',
      cell: (row: BonusPaymentRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          <MonthDate date={row.period} />
        </PrimaryTypography>
      )
    },
    {
      name: 'Opérateur',
      cell: (row: BonusPaymentRow) => (
        <Box display='flex' alignItems='center'>
          {row.operator?.hasLogo ? (
            <ProfileLogo
              alt={row?.operator?.displayName}
              src={row?.operator?.profileImgUrl}
              style={{ width: '50px', margin: '5px 10px 0px 0px' }}
            />
          ) : (
            getDefaultOrganizationLogo(row.operator?.organizationType, true)
          )}

          <PrimaryTypography
            data-tag='___react-data-table-allow-propagation___'
            align='left'
            style={{ fontSize: '15px', fontWeight: 600 }}
          >
            {row?.operator?.displayName}
          </PrimaryTypography>
        </Box>
      )
    },
    {
      name: 'Montant',
      cell: (row: BonusPaymentRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >{`${displayNumber(Number(row.budget))}  €`}</PrimaryTypography>
      )
    },
    {
      name: 'Statut',
      cell: (row: BonusPaymentRow) => {
        const status = row.status
        return <StatusTags {...status} />
      }
    },
    {
      name: '',
      cell: (row: BonusPaymentRow) => (
        <Button
          disabled={row.isPaid}
          style={{
            background: row.isPaid ? '  #999999' : 'normal'
          }}
          onClick={() => onPayClick(row.id)}
        >
          Payer
        </Button>
      )
    },
    {
      name: '',
      cell: (row: BonusPaymentRow) => {
        const bonusGroupIds = row.bonusPayment.bonuses.map((bonus: BonusRef) => bonus.id)

        return <Tooltip title={"Télécharger l'ordre de paiement"} arrow onClick={() => onDownload(bonusGroupIds)}>
          <Fab className={classes.fab}>
            <SystemUpdateAltIcon className={classes.icon} />
          </Fab>
        </Tooltip>
      }
    }
  ]

  return (
    <Table<BonusPaymentRow>
      data={data}
      columns={columns}
      className={classes.container}
      onRowClicked={(row) => onRowClicked && onRowClicked(row.bonusPayment)}
      handlePageChange={handlePageChange}
      totalPages={totalPages}
      page={page}
      isLoading={isLoading}
      noDataMessage={t('app_no_data_bonusPayment_table')}
      expandableRows={true}
      ExpandableComponents={
        <BonusPaymentBonusTable
          getDefaultOrganizationLogo={getDefaultOrganizationLogo}
          onClickBonus={onClickBonus}
        />
      }
      expandOnRowClicked={true}
    />
  )
}
