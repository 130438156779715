import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { Theme, themeContext } from "@smartb/archetypes-ui-components";
import { OrganizationRef } from "api/organization";
import {
  BonusPrice,
  CampaignDetails,
  CampaignFunder,
  ProjectStats,
  Rule,
} from "api/project/model";
import { CampaignStatusUtils } from "api/project/model/CampaignStatus";
import { InputForm, PeriodPicker, Period } from "components";
import { displayNumber } from "components/src/utils";
import "date-fns";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { wholeMonthOf, UTCToTimedZone } from "utils";
import BonusPricePriceRows from "./BonusPriceRows";
import CampaignFundersRows from "./CampaignFundersRows";
import {
  BonusPriceFormValidity,
  CampaignFormState,
  CampaignFunderValidity,
  RulesFormValidity,
} from "./Model";
import RulesRows from "./RuleRows";

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    input: {
      width: "100%",
      margin: "10px 0px",
    },
    inputForm: {
      marginTop: "10px",
      justifyContent: "start",
      flexDirection: "column",
      alignItems: "start",
      width: "100%",
    },

    title: {
      marginBottom: 11,
      fontSize: "16px",
      color: "#353945",
      fontWeight: 600,
    },

    period: {
      display: "flex",
      justifyContent: "left",
    },
    boxes: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    spaceTopBoxes: {
      marginTop: 35,
    },
    datePicker: {
      minWidth: 250,
      marginTop: 10,
      marginRight: 20,
    },
    projectBudget: {
      color: theme.secondaryColor,
      fontStyle: "italic",
      fontWeight: 600,
    },
  }));

interface CampaignFormProps {
  campaign: CampaignFormState;
  onCampaignChange: (campaign: CampaignFormState) => void;
  projectBudget?: number;
  readonly: boolean;
  operatorRefs: Map<string, OrganizationRef>;
  funderRefs: Map<string, OrganizationRef>;
  project: ProjectStats | null;
}

const CampaignForm = (props: CampaignFormProps) => {
  const {
    campaign,
    onCampaignChange,
    projectBudget,
    readonly,
    operatorRefs,
    project,
    funderRefs,
  } = props;
  const theme = useContext(themeContext);
  const classes = useStyles(theme)();

  const startMonth: Period = useMemo(
    () =>
      wholeMonthOf(UTCToTimedZone(campaign.details.period.startDate).getTime()),
    [campaign.details.period.startDate]
  );

  const endMonth: Period = useMemo(
    () =>
      wholeMonthOf(UTCToTimedZone(campaign.details.period.endDate).getTime()),
    [campaign.details.period.endDate]
  );

  const onStartMonthChange = useCallback(
    (period?: Period) => {
      if (period == null) {
        return;
      }

      let newEndDate = campaign.details.period.endDate;

      if (period.endDate > campaign.details.period.endDate) {
        newEndDate = period.endDate;
      }

      onCampaignChange({
        ...campaign,
        details: {
          ...campaign.details,
          period: {
            startDate: period.startDate,
            endDate: newEndDate,
          },
        },
        validation: {
          ...campaign.validation,
          validity: {
            ...campaign.validation.validity,
            startDate: true,
            endDate: true,
          },
        },
      });
    },
    [campaign, onCampaignChange]
  );

  const handleBonusPriceChange = useCallback(
    (bonus: BonusPrice[], validation: BonusPriceFormValidity[]) => {
      onCampaignChange({
        ...campaign,
        bonusPrices: bonus,
        validation: {
          ...campaign.validation,
          bonusPriceValidation: validation,
        },
      });
    },
    [campaign, onCampaignChange]
  );

  const handleCampaignFundersChange = useCallback(
    (
      campaignFunders: CampaignFunder[],
      validation: CampaignFunderValidity[]
    ) => {
      onCampaignChange({
        ...campaign,
        funders: campaignFunders,
        validation: {
          ...campaign.validation,
          campaignFundersValidation: validation,
        },
      });
    },
    [campaign, onCampaignChange]
  );

  const handleRulesChange = useCallback(
    (rules: Rule[], validation: RulesFormValidity[]) => {
      onCampaignChange({
        ...campaign,
        rules: rules,
        validation: {
          ...campaign.validation,
          rulesValidation: validation,
        },
      });
    },
    [campaign, onCampaignChange]
  );

  const onEndMonthChange = useCallback(
    (period?: Period) => {
      if (period == null) {
        return;
      }

      let newStartDate = campaign.details.period.startDate;

      if (period.startDate < campaign.details.period.startDate) {
        newStartDate = period.startDate;
      }

      onCampaignChange({
        ...campaign,
        details: {
          ...campaign.details,
          period: {
            startDate: newStartDate,
            endDate: period.endDate,
          },
        },
        validation: {
          ...campaign.validation,
          validity: {
            ...campaign.validation.validity,
            endDate: true,
          },
        },
      });
    },
    [campaign, onCampaignChange]
  );

  const onChangeStaticFields = useCallback(
    (type: keyof CampaignDetails, value: string) => {
      onCampaignChange({
        ...campaign,
        details: { ...campaign.details, [type]: value },
        validation: {
          ...campaign.validation,
          validity: {
            ...campaign.validation.validity,
            [type]: true,
          },
        },
      });
    },
    [campaign, onCampaignChange]
  );

  const onChangeBudget = useCallback(
    (value: string) => {
      onCampaignChange({
        ...campaign,
        budget: Number(value),
        validation: {
          ...campaign.validation,
          validity: {
            ...campaign.validation.validity,
            budget: true,
          },
        },
      });
    },
    [campaign, onCampaignChange]
  );

  const { t } = useTranslation();

  return (
    <>
      <Box className={`${classes.boxes}`}>
        <div className={classes.title}>
          <span>{t("app_campaign_form_campaign_name")}</span>
        </div>
        <Grid item xs={6}>
          <InputForm
            value={campaign.details.name}
            className={`${classes.inputForm}`}
            id="Campaign-name"
            inputType="textField"
            label={""}
            inputClassName={classes.input}
            onChange={(value) => onChangeStaticFields("name", value)}
            error={!campaign.validation.validity.name}
            errorMessage={t("app_campaign_form_please_enter_the_campaign_name")}
            readonly={readonly}
          />
        </Grid>
      </Box>
      <Box className={`${classes.boxes} ${classes.spaceTopBoxes}`}>
        <div className={classes.title}>
          <span>{t("app_campaign_form_period")}</span>
        </div>
        <div className={classes.period}>
          <PeriodPicker
            id="campaign-form-periodPicker-left"
            label={t("app_campaign_form_start_date")}
            readonly={readonly}
            period={startMonth}
            onDateChange={onStartMonthChange}
          />
          <PeriodPicker
            id="campaign-form-periodPicker-right"
            label={t("app_campaign_form_end_date")}
            readonly={readonly}
            period={endMonth}
            displayEnd
            onDateChange={onEndMonthChange}
          />
        </div>
      </Box>
      <Box className={`${classes.boxes} ${classes.spaceTopBoxes}`}>
        <div className={classes.title}>
          <span>{t("app_campaign_form_global_budget")}</span>
        </div>
        {projectBudget && (
          <Typography variant="body2" className={classes.projectBudget}>
            {t("app_campaign_form_project_budget_left", {
              projectBudget: displayNumber(projectBudget),
            })}
          </Typography>
        )}
        <Box width="200px" marginBottom="10px">
          <InputForm
            value={
              readonly ? displayNumber(campaign?.budget ?? 0) : campaign.budget
            }
            className={`${classes.inputForm}`}
            id="Campaign-global-budget"
            inputType="textField"
            textFieldType={readonly ? "text" : "number"}
            inputIcon="€"
            iconPosition="end"
            inputClassName={classes.input}
            onChange={onChangeBudget}
            error={!campaign.validation.validity.budget}
            errorMessage={t("app_campaign_form_global_budget_error")}
            readonly={readonly}
          />
        </Box>
      </Box>
      <Box className={`${classes.boxes} ${classes.spaceTopBoxes}`}>
        <div className={classes.title}>
          <span>{t("app_campaign_form_campaign_form_funder")}</span>
        </div>
        <CampaignFundersRows
          campaignFunders={campaign.funders}
          project={project}
          validity={campaign.validation.campaignFundersValidation}
          onCampaignFundersChange={handleCampaignFundersChange}
          readonly={
            readonly ||
            (campaign.status.value !== "" &&
              !CampaignStatusUtils.isCreated(campaign.status))
          }
          funderRefs={funderRefs}
        />
      </Box>
      <Box className={`${classes.boxes} ${classes.spaceTopBoxes}`}>
        <div className={classes.title}>
          <span>{t("app_campaign_form_define_a_premium_rate_per_zone")}</span>
        </div>
        <BonusPricePriceRows
          bonus={campaign.bonusPrices}
          project={project}
          validity={campaign.validation.bonusPriceValidation}
          onBonusPriceChange={handleBonusPriceChange}
          readonly={readonly}
          operatorRefs={operatorRefs}
          funderRefs={funderRefs}
        />
      </Box>

      <Box className={`${classes.boxes} ${classes.spaceTopBoxes}`}>
        <div className={classes.title}>
          <span>{t("app_campaign_form_compensation_rule")}</span>
        </div>
        <RulesRows
          rules={campaign.rules}
          validity={campaign.validation.rulesValidation}
          onRulesChange={handleRulesChange}
          readonly={readonly}
        />
      </Box>
    </>
  );
};

export default CampaignForm;
