import { Box } from "@material-ui/core";
import {
  BonusGroup,
  BonusGroupIncident,
  BonusGroupIncidentStatusValues,
} from "api/bonusGroup";
import { BonusGroupStatusUtils } from "api/bonusGroup/model/bonusStatus";
import React, { useMemo } from "react";
import { BonusIncidentBox } from "./BonusIncidentBox";
import {
  BonusIncidentMapping,
  bonusIncidentMapping,
} from "./BonusIncidentMapping";
import { User } from "../../../../api/organization/users";

interface BonusIncidentBarProps {
  bonus: BonusGroup;
  className?: string;
  currentUser: User | null;
}

export const BonusIncidentBar = (props: BonusIncidentBarProps) => {
  const { bonus, className, currentUser } = props;

  const isRejected = BonusGroupStatusUtils.isRejected(bonus.status);

  const incidentsCreated = useMemo(
    () =>
      bonus.incidents.filter((incident: BonusGroupIncident) =>
        BonusGroupIncidentStatusValues.isCreated(incident.status.value)
      ),
    [bonus.incidents]
  );

  const incidentBoxes = useMemo(
    () =>
      incidentsCreated
        .map(bonusIncidentMapping.get)
        .map((mappedIncident: BonusIncidentMapping) => (
          <BonusIncidentBox
            incident={mappedIncident}
            isRejection={isRejected}
            currentUser={currentUser}
          />
        )),
    [incidentsCreated]
  );

  return <Box className={className}>{incidentBoxes}</Box>;
};
