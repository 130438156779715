import {
  Box,
  Card,
  Divider,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core'
import React, { useMemo } from 'react'
import { ComponentLoading } from '../Loading'
import { CustomItem } from './CustomItem'
import { GenericWithId, GenericGroup } from './TransferList'

const useStyles = makeStyles(() => ({
  card: {
    width: '300px'
  },
  list: {
    width: '100%',
    height: 300,
    overflow: 'auto'
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
    margin: 0
  },
  subHeader: {
    background: '#fafafa',
    lineHeight: '35px',
    fontSize: '16px'
  }
}))

interface CustomListProps<T> {
  groups: GenericGroup<T>[]
  orientation?: 'left' | 'right'
  header?: React.ReactNode
  onSwitch?: (item: GenericWithId<T>) => void
  isLoading?: boolean
  itemIcon: React.ReactNode
  getItemTitle: (item: GenericWithId<T>) => string
}

export const CustomList = function <T>(props: CustomListProps<T>) {
  const {
    groups,
    header,
    isLoading,
    onSwitch,
    itemIcon,
    getItemTitle,
    orientation = 'left'
  } = props
  const classes = useStyles()
  const items = useMemo(
    () =>
      groups.map((group) => {
        if (group.list.length === 0) return
        return (
          <li key={'territory' + group.id} className={classes.listSection}>
            <ul className={classes.ul}>
              <ListSubheader className={classes.subHeader}>
                {group.name}
              </ListSubheader>
              {group.list.map((item: GenericWithId<T>) => {
                return (
                  <CustomItem<T>
                    key={'zone' + item.id}
                    orientation={orientation}
                    item={item}
                    onSwitch={onSwitch}
                    itemIcon={itemIcon}
                    getItemTitle={getItemTitle}
                  />
                )
              })}
            </ul>
          </li>
        )
      }),
    [groups, getItemTitle, onSwitch, itemIcon, orientation]
  )

  return (
    <Card className={classes.card}>
      {header && header}
      <Divider />
      {isLoading ? (
        <Box
          display='flex'
          height='300px'
          width='100%'
          justifyContent='center'
          alignItems='center'
        >
          <ComponentLoading />
        </Box>
      ) : (
        <List
          subheader={<li />}
          className={classes.list}
          dense
          component='div'
          role='list'
        >
          {items}
        </List>
      )}
    </Card>
  )
}
