import { buildProjectApiUrl } from "../../configuration";
import { ProjectDetails, ProjectFunders } from "../model";
import { http } from "../../http";

export interface UpdatedProjectEvent {
  payload: {
    id: string;
  };
}
export interface UpdateProjectCommand {
  type: string;
  payload: {
    details: ProjectDetails;
    projectFunders: ProjectFunders;
    projectId: string;
  };
}

export const projectUpdateCommand = (
  details: ProjectDetails,
  projectFunders: ProjectFunders,
  id: string
): UpdateProjectCommand => {
  return {
    type: "UPDATE_PROJECT",
    payload: {
      details: details,
      projectFunders: projectFunders,
      projectId: id,
    },
  };
};

export const updateProjectCommand = (
  details: ProjectDetails,
  projectFunders: ProjectFunders,
  id: string
): Promise<UpdatedProjectEvent> => {
  return http.post(
    buildProjectApiUrl(`/projects/${id}`),
    JSON.stringify(projectUpdateCommand(details, projectFunders, id).payload),
    "Projet modifié avec succès"
  );
};
