import { buildNotificationApiUrl } from "api/configuration";
import { NotificationPage } from "..";
import { http } from "../../http";

export const getUnseenNumber = (
  organizationId: string,
): Promise<number> => {
  return http.get(
    buildNotificationApiUrl(
      `/notifications/unseenNumber?organizationId=${organizationId}`
    ),
    "la récupération du nombre de nouvelle notifications n'a pas pu être effectué"
  );
};
