import { createSelector } from "reselect";
import { State } from "..";

const getUserState = (state: State) => state.profile;

export const getUser = createSelector([getUserState], (state) => state.user);

export const selectors = {
  User: getUser,
};
