import { GeoZonePage } from "..";
import { buildProjectApiUrl } from "../../configuration";
import { http } from "../../http";

export const fetchGeoZone = (
  page: number,
  size: number,
  name: string
): Promise<GeoZonePage> => {
  return http.get(
    buildProjectApiUrl(`/geozones?page=${page}&size=${size}&name=${name}`),
    "la récupération des zones géographiques n'a pas pu être effectuée"
  );
};
