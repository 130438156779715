import { buildOrganizationApiUrl } from "api/configuration";
import { http } from "../../../http";

export const changePassword = (organizationId: string, userId: string) => {
  http.put(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/users/${userId}/password`
    ),
    null,
    "Un mail de changement de mot de passe vous a été envoyé",
    false,
    true
  );
};
