import { createActionCreator } from "deox";
import { User } from "api/organization/users";

const fetchUser = createActionCreator(
  "USER_FETCH",
  (resolve) => (userId: string) => resolve({ userId: userId })
);

const fetchedUser = createActionCreator(
  "USER_FETCHED",
  (resolve) => (user: User) => resolve({ user: user })
);

export const actions = {
  fetchUser: fetchUser,
  fetchedUser: fetchedUser,
};
