import { connect } from "react-redux";
import { State } from "store";
import { organization } from "store/organization";
import { router } from "store/router";
import { global } from "store/global";

const mapStateToProps = (state: State) => ({
  organization: organization.selectors.organization(state),
  organizationRefs: global.selectors.organizationRefs(state),
});

const mapDispatchToProps = {
  gotoOrganizationUsers: router.goto.organization.organizationView,
};

export default connect(mapStateToProps, mapDispatchToProps);
