import { Badge as MuiBadge, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiBadge-colorPrimary': {
      backgroundColor: '#48d7ae'
    },

    '& span': {
      color: 'white'
    }
  }
}))

interface BadgeProps {
  content: number | string
  className?: string
  style?: React.CSSProperties
}

export const Badge = (props: BadgeProps) => {
  const { content, className, style } = props
  const classes = useStyles()
  return (
    <MuiBadge
      className={`${classes.root} ${className}`}
      style={{ marginRight: 15, marginLeft: 10, ...style }}
      badgeContent={content}
      color='primary'
    />
  )
}
