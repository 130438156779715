import { makeStyles, Tooltip } from '@material-ui/core'
import React from 'react'
import { InfoIcon } from '../../Icons'

const useStyles = makeStyles(() => ({
  tooltip: {
    background: 'white',
    border: 'solid 1px #C5C7D0',
    color: '#353945'
  },
  arrow: {
    color: '#C5C7D0'
  }
}))

interface InfoBoxProps {
  content: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal
  className?: string
  style?: React.CSSProperties
}

export const InfoBox = (props: InfoBoxProps) => {
  const { className, content, style } = props
  const classes = useStyles()
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      arrow
      placement='top'
      title={content}
    >
      <InfoIcon
        data-tag='___react-data-table-allow-propagation___'
        className={className}
        style={style}
        color='#C5C7D0'
      />
    </Tooltip>
  )
}
