import React from 'react'
import { makeStyles, Box, Button } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  notifContainer: {
    marginLeft: '10px',
    maxWidth: 'calc(100% - 10px)'
  },
  message: {
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  tagContainer: {
    marginTop: '3px'
  },
  tag: {
    borderRadius: '4px',
    padding: '3px 20px',
    marginRight: '5px',
    '& span': {
      fontSize: '12px',
      fontWeight: 600,
      textTransform: 'none'
    }
  },
  redTag: {
    background: 'rgba(244, 67, 54, 0.1)',
    '&:hover': {
      background: 'rgba(244, 67, 54, 0.2)'
    },
    '& span': {
      color: 'rgb(244, 67, 54)'
    }
  },
  blueTag: {
    background: 'rgba(33, 150, 243, 0.1)',
    '&:hover': {
      background: 'rgba(33, 150, 243, 0.2)'
    },
    '& span': {
      color: 'rgb(33, 150, 243)'
    }
  },
  orangeTag: {
    background: 'rgba(255, 152, 0, 0.1)',
    '&:hover': {
      background: 'rgba(255, 152, 0, 0.2)'
    },
    '& span': {
      color: 'rgb(255, 152, 0)'
    }
  },
  greenTag: {
    background: 'rgba(76, 175, 80, 0.1)',
    '&:hover': {
      background: 'rgba(76, 175, 80, 0.2)'
    },
    '& span': {
      color: 'rgb(76, 175, 80)'
    }
  }
}))

export type Color = 'red' | 'blue' | 'green' | 'orange'

export type TagWithColor = {
  label: React.ReactNode
  id: string
  color: Color
}

interface TagsProps {
  tags?: TagWithColor[]
  onTagClick?: (tagId: string) => void
  className?: string
  tagClassName?: string
}

export const Tags = (props: TagsProps) => {
  const { tags, onTagClick, className, tagClassName } = props
  const classes = useStyles()
  return (
    <Box display='flex' alignItems='center' className={className}>
      {tags !== undefined &&
        tags.length > 0 &&
        tags.map((tag, index) => (
          <Button
            key={index}
            onClick={(event) => {
              event.stopPropagation()
              onTagClick && onTagClick(tag.id)
            }}
            className={`${classes.tag} ${tagClassName} ${
              tag.color === 'blue'
                ? classes.blueTag
                : tag.color === 'red'
                ? classes.redTag
                : tag.color === 'green'
                ? classes.greenTag
                : classes.orangeTag
            }`}
          >
            {tag.label}
          </Button>
        ))}
    </Box>
  )
}
