import React, { Fragment } from 'react'
import { PrimaryTypography } from '../../Component/Table'
import { ProfileLogo } from '../../Component/ProfileLogo'
import { Box, makeStyles } from '@material-ui/core'
import { HeadlineBox } from '../../Component/HeadlineBox'
import { Maps, Pay } from '../../Icons'
import { BonusPaymentRow, OrganizationType } from './BonusPaymentTable'
import { BonusRow } from '../Bonus'
import { useTranslation } from 'react-i18next'
import { displayNumber } from '../../utils'
const useStyles = makeStyles(() => ({
  icon: {
    width: '35px',
    marginRight: '10px'
  },
  bonusContainer: {
    padding: '15px 20px',
    width: '90%',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0,0,0,0.05)'
    }
  },
  separator: {
    height: '1px',
    width: 'calc(90% + 40px)',
    marginTop: '-0.5px',
    background: 'rgba(0,0,0,0.2)'
  }
}))

export interface BonusPaymentBonusTableProps {
  onClickBonus: (bonusId: string) => void
  getDefaultOrganizationLogo: (
    type: OrganizationType | undefined,
    small: boolean
  ) => JSX.Element
  data?: BonusPaymentRow
}

export const BonusPaymentBonusTable = (props: BonusPaymentBonusTableProps) => {
  const { onClickBonus, data, getDefaultOrganizationLogo } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const bonuses: BonusRow[] = data?.bonuses || []

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      {bonuses.map((bonus, index) => (
        <Fragment>
          <Box
            key={bonus.id}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            className={classes.bonusContainer}
            onClick={() => onClickBonus(`${bonus.id}`)}
          >
            <PrimaryTypography>{`Prime n°${bonus.id}`}</PrimaryTypography>
            <Box display='flex' alignItems='center'>
              {bonus.carrier.hasLogo ? (
                <ProfileLogo
                  alt={bonus.carrier.displayName}
                  src={bonus.carrier.profileImgUrl}
                  className={classes.icon}
                />
              ) : (
                getDefaultOrganizationLogo(bonus.carrier.organizationType, true)
              )}

              <HeadlineBox header={bonus.carrier.displayName} size='small'>
                {t('app_bonusPayment_table_carrier')}
              </HeadlineBox>
            </Box>
            <Box display='flex' alignItems='center'>
              <Maps className={classes.icon} color='#69b6a3' />
              <HeadlineBox header={bonus.city} size='small'>
                {t('app_bonusPayment_table_territory')}
              </HeadlineBox>
            </Box>
            <Box display='flex' alignItems='center'>
              <Pay className={classes.icon} color='#69b6a3' />
              <HeadlineBox
                header={`${displayNumber(bonus.amount)} €`}
                size='small'
              >
                {t('app_bonusPayment_table_bonus_amount')}
              </HeadlineBox>
            </Box>
          </Box>
          {index !== bonuses.length - 1 && (
            <div className={classes.separator} />
          )}
        </Fragment>
      ))}
    </Box>
  )
}
