import { Period } from "components";
import { TFunction } from "i18next";
import {
  differenceInMinutes,
  startOfMonth,
  endOfMonth,
  addMinutes,
  addMonths,
} from "date-fns";

export const nullToUndefined = (value: string | null): string | undefined => {
  return !!value ? value : undefined;
};
export const nullToUndefinedNumber = (
  value: number | null | undefined
): number | undefined => {
  return !!value ? value : undefined;
};

export const formatDate = (date: string | null): number | undefined => {
  if (!date) {
    return undefined;
  }
  const numberDate = Number(date);
  return numberDate;
};

export const applyTimeZoneOffset = (date: number): number => {
  const formatedDate = new Date(date);
  return addMinutes(formatedDate, formatedDate.getTimezoneOffset()).getTime();
};

export const removeTimeZoneOffset = (date: number): number => {
  const formatedDate = new Date(date);
  return addMinutes(formatedDate, -formatedDate.getTimezoneOffset()).getTime();
};

export const getFirstDayOfTheMonthUTC = (date: Date | number): number => {
  return removeTimeZoneOffset(startOfMonth(date).getTime());
};

export const getFirstDayOfLastMonthUTC = (date: Date | number): number => {
  return removeTimeZoneOffset(endOfMonth(addMonths(date, -1)).getTime());
};

export const getLastDayOfTheMonthUTC = (date: Date | number): number => {
  return removeTimeZoneOffset(endOfMonth(date).getTime());
};

export const UTCToTimedZone = (date: number): Date => {
  return new Date(applyTimeZoneOffset(date));
};

export const wholeMonthOf = (date: number): Period => ({
  startDate: getFirstDayOfTheMonthUTC(date),
  endDate: getLastDayOfTheMonthUTC(date),
});

export const differenceInMinutesToString = (
  difference: number,
  t: TFunction
): string => {
  if (difference <= 1) {
    return t("app_utils_lt_minute");
  }
  if (difference < 60) {
    return t("app_utils_eq_minutes", { nbMinute: Math.round(difference) });
  }
  const differenceInHours = difference / 60;
  if (differenceInHours < 1.5) {
    return t("app_utils_eq_hour");
  }
  if (differenceInHours < 23.5) {
    return t("app_utils_eq_hours", { nbHour: Math.round(differenceInHours) });
  }
  const differenceInDays = differenceInHours / 24;
  if (differenceInDays < 1.5) {
    return t("app_utils_eq_day");
  }
  if (differenceInDays < 30) {
    return t("app_utils_eq_days", { nbDay: Math.round(differenceInDays) });
  }
  const differenceInMonth = differenceInDays / 30;
  if (differenceInMonth < 1.5) {
    return t("app_utils_eq_month");
  }
  if (differenceInMonth < 12) {
    return t("app_utils_eq_months", { nbMonth: Math.round(differenceInMonth) });
  }
  return t("app_utils_gt_year");
};

export const displayNumber = (
  value: number,
  fractionDigits?: number
): string => {
  const fixedValue = value.toFixed(fractionDigits);
  return parseFloat(fixedValue).toLocaleString("fr-FR");
};

export const getRelativeDate = (date: string, t: TFunction): string => {
  const creationDate = new Date(Number(date));
  const difference = differenceInMinutes(new Date(), creationDate);

  const yearTime = 365 * 24 * 60;
  const dayTime = 24 * 60;

  if (difference >= yearTime)
    return creationDate.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

  if (difference >= 2 * dayTime)
    return creationDate.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
    });

  return differenceInMinutesToString(difference, t);
};

export const distinct = <T>(list: T[]): T[] => {
  if (list == null) {
    return [];
  }

  const jsonList = list.map((elm) => JSON.stringify(elm));
  const set = new Set(jsonList);
  return Array.from(set).map((elm) => JSON.parse(elm));
};

/**
 * transform an array of objects to a csv and download it
 * @param data has to be an array of objects, the keys of the first object will be the headers of the csv file
 * @param fileName the name of the csv (whithout .csv)
 */
export const exportCSV = <T extends object = {}>(
  data: T[],
  fileName: string
) => {
  const headers = Object.keys(data[0]);
  const content: string[][] = data.map((object) => Object.values(object)) ?? [
    [],
  ];

  const matrix = [headers, ...content];

  const csvContent =
    "data:text/csv;charset=utf-8," + matrix.map((e) => e.join(",")).join("\n");

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const truncate = (number: number, decimals: number): number => {
  const coef = Math.pow(10, decimals);
  return Math.trunc(number * coef) / coef;
};
