import { push } from "connected-react-router";
export type ReportsMode = "view" | "add" | "edit";

const carrierReportSectionView = (reportId: string, mode: ReportsMode) =>
  push(`/report/${reportId}/${mode}`);

const carrierReportsView = (
  page: number,
  size: number,
  startDate?: number,
  endDate?: number,
  carrierId?: string,
  area?: string,
  status?: string
) => {
  let path = `/reports?page=${page}&size=${size}`;
  if (startDate) path = `${path}&startDate=${startDate}`;
  if (endDate) path = `${path}&endDate=${endDate}`;
  if (carrierId) path = `${path}&carrierId=${carrierId}`;
  if (area) path = `${path}&area=${area}`;
  if (status) path = `${path}&status=${status}`;
  return push(path);
};

const carrierReportAdd = () => push("report/add");
const carrierReportView = (reportId: string) =>
  carrierReportSectionView(reportId, "view");
const carrierReportEdit = (reportId: string) =>
  carrierReportSectionView(reportId, "edit");

export const gotoReports = {
  reportsList: carrierReportsView,
  reportView: carrierReportView,
  reportAdd: carrierReportAdd,
  reportEdit: carrierReportEdit,
};
