import { Box, makeStyles } from "@material-ui/core";
import {
  BonusGroup,
  bonusGroupClient,
  BonusGroupFunder,
  BonusGroupFunderInvoiceDetails,
  BonusGroupInvoice,
  BonusGroupInvoiceType,
} from "api/bonusGroup";
import { BonusGroupStatusUtils } from "api/bonusGroup/model/bonusStatus";
import { OrganizationRef } from "api/organization";
import { User } from "api/organization/users";
import { BonusActionsButtons } from "app/components/Bonus/BonusActionsButtons";
import { BonusIncidentBar } from "app/components/Bonus/BonusIncidentBar";
import BonusPanel from "app/components/Bonus/BonusPanel";
import BonusPdfViewer from "app/components/Bonus/BonusPdfViewer";
import { FunderInvoiceDetails } from "app/components/Bonus/FunderInvoiceDetails/FunderInvoiceDetails";
import { useBonusActions } from "app/components/Bonus/hook/useBonusActions";
import { useAuth } from "auth/useAuth";
import {
  Attachment,
  FileList as FileListComponent,
  HeadBarProps,
  HeadBarView,
  Section,
  getMonthDate,
} from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { displayNumber, UTCToTimedZone } from "utils";
import Loading from "../../../components/Loading";
import withConnect from "./withConnect";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    justifyContent: "center",
  },
  contentCenter: {},
  notificationContainer: {
    marginBottom: 8,
  },
  arrow: {
    marginLeft: "-8px",
    marginRight: "8px",
    marginTop: "-1px",
    width: "20px",
    height: "20px",
  },
  headerStyle: {
    width: "100%",
  },
  filesPanel: {
    marginBottom: "20px",
  },
}));

interface Invoices {
  previous: {
    data: (BonusGroupInvoice & Attachment)[];
    validity: boolean;
  };
  carrier: {
    data: (BonusGroupInvoice & Attachment)[];
    validity: boolean;
  };
  funder: {
    data: Map<string, (BonusGroupInvoice & Attachment)[]>;
    validity: boolean;
  };
}

interface BonusViewProps {
  organizationRef: Map<string, OrganizationRef>;
  setHeadBar: (props: HeadBarProps) => void;
  bonus: BonusGroup | null;
  fetchBonus: (bonusId: string, force?: boolean) => void;
  currentUser: User | null;
  publishBonus: (bonusId: string) => void;
  rejectBonus: (bonusId: string, reasons: string) => void;
  validateBonus: (bonusId: string) => void;
  createBonusIncident: (bonusId: string, description: string) => void;
  resolveBonusIncident: (bonusId: string, incidentId: string) => void;
  gotoBonusEdit: (bonusId: string) => void;
}

const BonusView = (props: BonusViewProps) => {
  const {
    organizationRef,
    bonus,
    fetchBonus,
    setHeadBar,
    currentUser,
    publishBonus,
    rejectBonus,
    validateBonus,
    createBonusIncident,
    resolveBonusIncident,
    gotoBonusEdit,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [authService, keycloak] = useAuth();
  const { bonusId } = useParams();

  const [funders, setFunders] = useState<BonusGroupFunder[]>([]);

  const [submitForms, setSubmitForms] = useState<
    Map<string, () => Promise<string | undefined>>
  >(new Map());

  const [currentPdf, setCurrentPdf] = useState<string>("");
  const [invoiceAddedId, setInvoiceAddedId] = useState<string>("");
  const [invoices, setInvoices] = useState<Invoices>({
    carrier: {
      data: [],
      validity: true,
    },
    previous: {
      data: [],
      validity: true,
    },
    funder: {
      data: new Map(),
      validity: true,
    },
  });

  const isColisActivAdmin = useMemo(
    () => authService.isColisActivAdmin(currentUser, keycloak),
    [authService, currentUser, keycloak]
  );

  const noInvoiceText = t("app_bonus_view_invoices_empty");

  const [bonusAction] = useBonusActions({
    currentUser: currentUser,
    bonus: bonus,
  });

  const startDateString = useMemo(
    () => UTCToTimedZone(bonus?.period.startDate).toLocaleDateString(),
    [bonus?.period.startDate]
  );
  const endDateString = useMemo(
    () => UTCToTimedZone(bonus?.period.endDate).toLocaleDateString(),
    [bonus?.period.endDate]
  );

  useEffect(() => {
    fetchBonus(bonusId, true);
    bonusGroupClient.query.fetchBonusGroupFunders(bonusId).then(setFunders);
  }, [bonusId, fetchBonus]);

  useEffect(() => {
    if (bonus) {
      const title = `Prime n°${bonusId}`;
      setHeadBar({
        title: title,
        component: (
          <Box display="flex" className={classes.headerStyle}>
            <HeadBarView
              title={title}
              status={BonusGroupStatusUtils.getLabelStyleStatusTags(
                bonus.status
              )}
              onEdit={
                bonusAction.canEdit ? () => gotoBonusEdit(bonusId) : undefined
              }
              startDate={bonus?.period.startDate ?? 0}
              endDate={bonus?.period.endDate ?? 0}
            />
          </Box>
        ),
      });
    }
  }, [bonus, bonusAction, bonusId, classes, gotoBonusEdit, setHeadBar]);

  useEffect(() => {
    const input = document.getElementById("invoiceAdded");
    input && input.focus();
  }, [invoiceAddedId]);

  useEffect(() => {
    if (bonus?.invoices) {
      const previousInvoices: (BonusGroupInvoice & Attachment)[] = [];
      const carrierInvoices: (BonusGroupInvoice & Attachment)[] = [];
      const funderInvoices = new Map<
        string,
        (BonusGroupInvoice & Attachment)[]
      >();
      bonus.invoices.forEach((invoice: BonusGroupInvoice) => {
        switch (invoice.type.value) {
          case "PREVIOUS":
            previousInvoices.push({ ...invoice, cantBeRemoved: true });
            break;
          case "CUSTOM_PREVIOUS":
            previousInvoices.push(invoice);
            break;
          case "CARRIER":
            carrierInvoices.push(invoice);
            break;
          case "FUNDER":
            if (!funderInvoices.has(invoice.targetId)) {
              funderInvoices.set(invoice.targetId, []);
            }
            funderInvoices.get(invoice.targetId)!!.push(invoice);
            break;
        }
      });
      setInvoices((oldInvoices) => ({
        previous: {
          data: previousInvoices,
          validity: oldInvoices.previous.validity,
        },
        carrier: {
          data: carrierInvoices,
          validity: oldInvoices.carrier.validity,
        },
        funder: { data: funderInvoices, validity: oldInvoices.funder.validity },
      }));
    }
  }, [bonus?.invoices, fetchBonus]);

  const memoizedCarrier = useMemo(() => {
    if (!bonus) return undefined;
    return organizationRef.get(bonus.carrierId);
  }, [organizationRef, bonus]);

  const memoizedOperator: OrganizationRef | undefined = useMemo(() => {
    if (!bonus) return undefined;
    return organizationRef.get(bonus.operatorId);
  }, [organizationRef, bonus]);

  const createInvoice = useCallback(
    async (file: File, type: BonusGroupInvoiceType, targetId?: string) => {
      if (currentUser) {
        const blobInvoice = new Blob([file], {
          type: file.type,
        });
        const reader = new FileReader();
        reader.readAsDataURL(blobInvoice);
        reader.onload = async () => {
          const event = await bonusGroupClient.createInvoice(
            bonusId,
            reader.result as string,
            file.name,
            0,
            currentUser.details.organizationId,
            type,
            targetId ?? ""
          );
          await fetchBonus(bonusId);
          event && event.payload && setInvoiceAddedId(event.payload.invoiceId);
        };
      }
    },
    [bonusId, fetchBonus, currentUser]
  );

  const createCarrierInvoice = useCallback(
    async (file: File) => {
      if (currentUser) {
        await createInvoice(file, { value: "CARRIER" });
        setInvoices((oldInvoices) => ({
          ...oldInvoices,
          carrier: { data: oldInvoices.carrier.data, validity: true },
        }));
      }
    },
    [currentUser, createInvoice]
  );

  const createHandleAddFunderInvoice = useCallback(
    (funderId: string) => {
      return async (file: File) => {
        if (currentUser) {
          await createInvoice(file, { value: "FUNDER" }, funderId);
          setInvoices((oldInvoices) => ({
            ...oldInvoices,
            funder: { data: oldInvoices.funder.data, validity: true },
          }));
        }
      };
    },
    [currentUser, createInvoice]
  );

  const createCustomPreviousInvoice = useCallback(
    async (file: File) => {
      if (currentUser) {
        await createInvoice(file, { value: "CUSTOM_PREVIOUS" });
      }
    },
    [currentUser, createInvoice]
  );

  const checkDocumentsValidity = useCallback(async () => {
    let carrierValidity = invoices.carrier.data.length > 0;
    let wrongInvoiceId: string | undefined = undefined;
    const values = Array.from(submitForms.entries());
    await Promise.all(
      values.map(async (value) => {
        const r = await value[1]();
        if (r !== "validated") {
          wrongInvoiceId = value[0];
        }
        return r;
      })
    );

    invoices.carrier.data.forEach((invoice) => {
      if (invoice.amount <= 0) {
        carrierValidity = false;
      }
    });
    wrongInvoiceId && setInvoiceAddedId(wrongInvoiceId);
    setInvoices({
      ...invoices,
      carrier: { data: invoices.carrier.data, validity: carrierValidity },
      funder: {
        data: invoices.funder.data,
        validity: wrongInvoiceId === undefined,
      },
    });
    return carrierValidity && wrongInvoiceId === undefined;
  }, [invoices, submitForms]);

  const handlePublishBonus = useCallback(
    (bonusId: string) => {
      if (!bonus) return;
      checkDocumentsValidity() && publishBonus(bonusId);
    },
    [checkDocumentsValidity, bonus, publishBonus]
  );

  const handleResolveIncidentBonus = useCallback(
    (bonusId: string, incidentId: string) => {
      checkDocumentsValidity() && resolveBonusIncident(bonusId, incidentId);
    },
    [checkDocumentsValidity, resolveBonusIncident]
  );

  const handleValidateBonus = useCallback(
    (bonusId: string) => {
      validateBonus(bonusId);
    },
    [validateBonus]
  );

  const deleteInvoice = useCallback(
    async (invoiceId: string) => {
      await bonusGroupClient.deleteInvoice(bonusId, invoiceId);
      await fetchBonus(bonusId);
    },
    [bonusId, fetchBonus]
  );

  const updateInvoice = useCallback(
    async (newAmount: number, invoice: Attachment) => {
      setInvoiceAddedId("");
      if (newAmount !== invoice.amount) {
        await bonusGroupClient.updateInvoice(bonusId, invoice.id, newAmount);
        await fetchBonus(bonusId);
      }
    },
    [bonusId, fetchBonus]
  );
  const onCLickAttachment = useCallback((attachment: Attachment) => {
    setCurrentPdf(attachment.file);
  }, []);

  const canAddAndRemoveCarrierInvoice = useMemo(() => {
    if (!bonus) return false;
    if (isColisActivAdmin && BonusGroupStatusUtils.isPublished(bonus.status)) {
      return true;
    }
    return (
      BonusGroupStatusUtils.isCreated(bonus.status) ||
      BonusGroupStatusUtils.isInvalid(bonus.status) ||
      BonusGroupStatusUtils.isDeducted(bonus.status) ||
      BonusGroupStatusUtils.isReported(bonus.status)
    );
  }, [bonus?.status, isColisActivAdmin]);

  const canSeeFunderInvoice = useMemo(() => {
    if (!bonus) return true;
    return !BonusGroupStatusUtils.isReported(bonus.status);
  }, [bonus?.status]);

  const funderBlockDescription = useMemo(() => {
    const carrier = bonus?.carrierId
      ? organizationRef.get(bonus?.carrierId)
      : undefined;
    const month = getMonthDate(bonus?.period.startDate, t, true).toLowerCase();
    return t("app_bonus_view_invoices_cofunder_description", {
      bonusId: bonus?.id,
      month: month,
      carrier: carrier?.displayName,
    });
  }, [bonus, organizationRef]);

  const funderSections = useMemo(() => {
    return funders.map((funder) => {
      const funderRef = organizationRef.get(funder.id);
      const funderInvoices = invoices.funder.data.get(funder.id) ?? [];
      return {
        title: `- ${
          funderRef && funderRef.organizationType.type === "FunderCee"
            ? t("app_bonus_view_invoices_sofub")
            : t("app_bonus_view_invoices_fundercee")
        } : ${displayNumber(funder.fundedAmount, 2)}€ (Nette de taxe)`,
        attachments: funderInvoices,
        noAttachmentText: noInvoiceText,
        canDownload: true,
        canAddFile:
          !!bonus &&
          canAddAndRemoveCarrierInvoice &&
          funderInvoices.length === 0,
        canChangePrice: false,
        fundedAmount: funder.fundedAmount,
        onRemoveFile: canAddAndRemoveCarrierInvoice ? deleteInvoice : undefined,
        onFileAdded: createHandleAddFunderInvoice(funder.id),
      };
    });
  }, [
    funders,
    bonus,
    invoices.funder.data,
    organizationRef,
    canAddAndRemoveCarrierInvoice,
    deleteInvoice,
    createHandleAddFunderInvoice,
  ]);

  const onDeduceBonus = useCallback(
    (bonusGroupId: string) =>
      bonusGroupClient.deduceBonusGroup(bonusGroupId).then(() => {
        fetchBonus(bonusGroupId);
      }),
    []
  );

  const getAdditionnalNodesOfFunderInvoice = useCallback(
    (
      attachementId: string,
      fundedAmount?: number,
      details?: BonusGroupFunderInvoiceDetails,
      focusAttachementId?: string
    ) => {
      return (
        <FunderInvoiceDetails
          bonusId={bonusId}
          readonly={!canAddAndRemoveCarrierInvoice}
          fetchBonus={fetchBonus}
          isValid={invoices.funder.validity}
          fundedAmount={fundedAmount}
          details={details}
          isFocused={focusAttachementId === attachementId}
          attachementId={attachementId}
          onChangeSubmitForm={(submitForm) =>
            setSubmitForms((old) => old.set(attachementId, submitForm))
          }
        />
      );
    },
    [
      bonusId,
      invoices.funder.validity,
      fetchBonus,
      canAddAndRemoveCarrierInvoice,
    ]
  );

  if (bonus === null) {
    return <Loading />;
  }

  const canChangePrice = (): boolean => {
    if (isColisActivAdmin) {
      return (
        canAddAndRemoveCarrierInvoice ||
        BonusGroupStatusUtils.isPublished(bonus.status)
      );
    }
    return canAddAndRemoveCarrierInvoice;
  };

  return (
    <Section withGoBack>
      <Box className={classes.content}>
        <Box className={classes.contentCenter}>
          <BonusIncidentBar
            bonus={bonus}
            className={classes.notificationContainer}
            currentUser={currentUser}
          />
          <BonusPanel
            bonus={bonus}
            carrier={memoizedCarrier}
            operator={memoizedOperator}
            actions={
              <BonusActionsButtons
                deduceBonusGroup={onDeduceBonus}
                createBonusIncident={createBonusIncident}
                resolveBonusIncident={handleResolveIncidentBonus}
                publishBonus={handlePublishBonus}
                rejectBonus={rejectBonus}
                validateBonus={handleValidateBonus}
                checkDocumentsValidity={checkDocumentsValidity}
                bonus={bonus}
                carrier={memoizedCarrier}
                currentUser={currentUser}
              />
            }
          />
          <Box alignContent="flex-end" justifyContent="center" display="flex">
            <Box
              display="flex"
              width="420px"
              flexDirection="column"
              marginRight="20px"
            >
              <FileListComponent
                sections={[
                  {
                    title: `1- ${t("app_bonus_view_invoices_carrier")}`,
                    attachments: invoices.carrier.data,
                    noAttachmentText: noInvoiceText,
                    canAddFile: canAddAndRemoveCarrierInvoice,
                    canChangePrice: canChangePrice(),
                    description: t(
                      "app_bonus_view_invoices_carrier_description"
                    ),
                    canDownload: true,
                    withPrice: true,
                    onRemoveFile: canAddAndRemoveCarrierInvoice
                      ? deleteInvoice
                      : undefined,
                    onFileAdded: createCarrierInvoice,
                    onPriceChange: updateInvoice,
                  },
                ]}
                focusAttachementPriceId={invoiceAddedId}
                className={classes.filesPanel}
                onClickAttachement={onCLickAttachment}
                isValid={invoices.carrier.validity}
                errorMessage={t("app_bonus_view_invoices_error")}
              />
              {canSeeFunderInvoice && (
                <FileListComponent
                  description={funderBlockDescription}
                  title={`2- ${t("app_bonus_view_invoices_funder")}`}
                  sections={funderSections}
                  focusAttachementPriceId={invoiceAddedId}
                  className={classes.filesPanel}
                  getAdditionnalNodes={getAdditionnalNodesOfFunderInvoice}
                  onClickAttachement={onCLickAttachment}
                  errorMessage={t("app_bonus_view_invoices_error")}
                />
              )}
              <FileListComponent
                sections={[
                  {
                    title: `3 -${t(
                      "app_bonus_view_invoices_carrier_lastMonth",
                      {
                        currentMonth: new Date(
                          bonus.period.startDate
                        ).toLocaleDateString("fr-FR", { month: "long" }),
                      }
                    )}`,
                    description: t(
                      "app_bonus_view_invoices_carrier_lastMonth_description",
                      { startDate: startDateString, endDate: endDateString }
                    ),
                    attachments: invoices.previous.data,
                    noAttachmentText: noInvoiceText,
                    canAddFile: canAddAndRemoveCarrierInvoice,
                    withPrice: false,
                    canDownload: true,
                    onFileAdded: createCustomPreviousInvoice,
                    onRemoveFile: canAddAndRemoveCarrierInvoice
                      ? deleteInvoice
                      : undefined,
                  },
                ]}
                className={classes.filesPanel}
                onClickAttachement={onCLickAttachment}
              />
            </Box>
            <BonusPdfViewer
              pdf={currentPdf}
              bonusHasDocuments={bonus.invoices.length > 0}
            />
          </Box>
        </Box>
      </Box>
    </Section>
  );
};

export default withConnect(BonusView);
