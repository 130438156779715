import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Button, EditButton, Section } from "components";
import withConnect from "./withConnect";
import { useParams } from "react-router-dom";
import UserForm, { UserFormState } from "app/components/UserForm";
import { UserValidation } from "app/components/UserForm/validation";
import { buildOrganizationApiUrl } from "api/configuration";
import { Organization, OrganizationRef } from "api/organization";
import { User, UserDetails, usersClient } from "api/organization/users";
import { useAuth } from "../../../../../../auth/useAuth";
import { routesAccessRights } from "../../../../../../auth/RoutesAccessRightsMap";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  button: {
    position: "absolute",
    right: "30px",
  },
}));

interface GeneralUserInformationsProps {
  organization: Organization | null;
  fetchUser: (organizationId: string, userId: string) => void;
  user: User | null;
  gotoUserEdit: (organizationId: string, userId: string) => void;
  gotoUserView: (organizationId: string, userId: string) => void;
}

const buildUserForm = (user: User): UserFormState => ({
  details: user.details,
  logo: {
    src: buildOrganizationApiUrl(
      `/organizations/${user.details.organizationId}/users/${user.userId}/logo`
    ),
  },
  validity: UserValidation.validation(user.details),
});

const GeneralUserInformations = (props: GeneralUserInformationsProps) => {
  const { organization, user, fetchUser, gotoUserEdit, gotoUserView } = props;
  const classes = useStyles();
  const { organizationId, mode, objectId } = useParams();
  const { t } = useTranslation();
  const [userEditState, setUserEditState] = useState<UserFormState>(
    UserValidation.defaultUserFormState
  );
  const [authService, keycloak] = useAuth();

  useEffect(() => {
    fetchUser(organizationId, objectId);
  }, []);

  useEffect(() => {
    if (user) {
      const userForm = buildUserForm(user);
      setUserEditState(userForm);
    }
  }, [user]);

  if (user == null) {
    return <div>Pas de user dans le store</div>;
  }

  const onSaveUser = async () => {
    const { details } = userEditState;
    const validation = UserValidation.validation(details);
    if (!UserValidation.isFormValid(validation)) {
      return setUserEditState({
        ...userEditState,
        validity: validation,
      });
    }

    const userDetails: UserDetails = {
      ...userEditState.details,
      firstname: userEditState.details.firstname.trim(),
      lastname: userEditState.details.lastname.trim(),
      email: userEditState.details.email.trim(),
    };
    const response = await usersClient.updateUser(
      organizationId,
      userDetails,
      user.userId
    );

    if (userEditState.logo && userEditState.logo.file) {
      const blobLogo = new Blob([userEditState.logo.file], {
        type: userEditState.logo.file.type,
      });
      usersClient.uploadUserLogo(
        response.payload.organizationId,
        response.payload.userId,
        blobLogo,
        userEditState.logo.file.name
      );
    }
    gotoUserView(organizationId, user?.userId);
  };
  const isViewMode = mode === "view";
  return (
    <Section
      actions={[
        authService.isAuthorized(
          routesAccessRights["/organizations/users/edit"],
          keycloak
        ) ? (
          isViewMode ? (
            <EditButton
              key={0}
              className={classes.button}
              onClick={() => gotoUserEdit(organizationId, user?.userId)}
              label={t("app_organizations_profil_users_view_edit")}
            />
          ) : (
            <Button key={0} className={classes.button} onClick={onSaveUser}>
              {t("app_organizations_profil_users_view_validate")}
            </Button>
          )
        ) : (
          <> </>
        ),
      ]}
    >
      <UserForm
        organization={!!organization ? organization : undefined}
        organizationRefs={new Map<string, OrganizationRef>()}
        user={isViewMode ? buildUserForm(user) : userEditState}
        onDetailsChange={isViewMode ? () => {} : setUserEditState}
        readonly={isViewMode}
      />
    </Section>
  );
};

export default withConnect(GeneralUserInformations);
