import * as R from "ramda";
import { createReducer } from "deox";
import { CarrierReport } from "../../api/report";
import { actions } from "./report.actions";
export interface CarrierReportState {
  carrierReport: CarrierReport | null;
}
export const initialState: CarrierReportState = {
  carrierReport: null,
};

export type carrierReportStateTransformer = (
  state: CarrierReportState
) => CarrierReportState;

const setCarrierReport = (
  carrierReport: CarrierReport | null
): carrierReportStateTransformer => {
  return R.assoc("carrierReport", carrierReport);
};

export const reportReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    actions.createCarrierReport,
    (state: CarrierReportState, action) =>
      setCarrierReport(action.payload.report)(state)
  ),

  handleAction(
    actions.resetCarrierReport,
    (state: CarrierReportState, action) => setCarrierReport(null)(state)
  ),
  handleAction(actions.fetchReport, (state: CarrierReportState, action) =>
    setCarrierReport(null)(state)
  ),
  handleAction(actions.fetchedReport, (state: CarrierReportState, action) =>
    setCarrierReport(action.payload.carrierReport)(state)
  ),
  handleAction(
    actions.updateCarrierReport,
    (state: CarrierReportState, action) =>
      setCarrierReport(action.payload.report)(state)
  ),
]);
