import { buildNotificationApiUrl } from "api/configuration";
import { http } from "../../http";
import { Notification } from "..";

export const getNotification = (
  notificationId: string
): Promise<Notification> => {
  return http.get(
    buildNotificationApiUrl(`/notifications/${notificationId}`),
    "La récupération de la notification n'a pas pu être effectuée"
  );
};
