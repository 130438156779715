import * as R from "ramda";
import { createReducer } from "deox";
import { actions } from "./project.actions";
import { ProjectStats, ProjectRef } from "api/project/model";
import { GeoZone, Territory } from "api/geoZone";

export interface ProjectState {
  project: ProjectStats | null;
  projectRefs: ProjectRef[] | null;
  campaignId: String | null;
  territories: Territory[];
}
export const initialState: ProjectState = {
  project: null,
  projectRefs: null,
  campaignId: null,
  territories: [],
};

export type ProjectStateTransformer = (state: ProjectState) => ProjectState;

const setProject = (project: ProjectStats | null): ProjectStateTransformer => {
  return R.assoc("project", project);
};

const setCampaign = (
  project: ProjectStats | null,
  campaignId: string
): ProjectStateTransformer => {
  return R.pipe(R.assoc("campaignId", campaignId), R.assoc("project", project));
};

const setProjectRefs = (projectRefs: ProjectRef[]): ProjectStateTransformer => {
  return R.assoc("projectRefs", projectRefs);
};

const setTerritories = (areas: GeoZone[]): ProjectStateTransformer => {
  return R.assoc("territories", areas);
};

export const projectReducer = createReducer(initialState, (handleAction) => [
  handleAction(actions.createdProject, (state: ProjectState, action) =>
    setProject(action.payload.project)(state)
  ),

  handleAction(actions.resetProject, (state: ProjectState) =>
    setProject(null)(state)
  ),

  handleAction(actions.fetchedProjectRefs, (state: ProjectState, action) =>
    setProjectRefs(action.payload.projectRefs)(state)
  ),

  handleAction(actions.fetchedProject, (state: ProjectState, action) =>
    setProject(action.payload.project)(state)
  ),

  handleAction(actions.fetchedTerritories, (state: ProjectState, action) =>
    setTerritories(action.payload.territories)(state)
  ),

  handleAction(actions.fetchedCampaign, (state: ProjectState, action) =>
    setCampaign(action.payload.project, action.payload.campaignId)(state)
  ),

  handleAction(actions.updatedCampaign, (state: ProjectState, action) =>
    setProject(action.payload.project)(state)
  ),

  handleAction(actions.updatedProject, (state: ProjectState, action) =>
    setProject(action.payload.project)(state)
  ),

  handleAction(actions.publishedCampaign, (state: ProjectState, action) =>
    setProject(action.payload.project)(state)
  ),

  handleAction(actions.archivedCampaign, (state: ProjectState, action) =>
    setProject(action.payload.project)(state)
  ),
  handleAction(actions.pausedCampaign, (state: ProjectState, action) =>
    setProject(action.payload.project)(state)
  ),
  handleAction(actions.deletedCampaign, (state: ProjectState, action) =>
    setProject(action.payload.project)(state)
  ),
  handleAction(actions.duplicatedCampaign, (state: ProjectState, action) =>
    setProject(action.payload.project)(state)
  ),
]);
