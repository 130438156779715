import { buildNotificationApiUrl } from "api/configuration";
import { NotificationPage } from "..";
import { http } from "../../http";

export const getNotificationPage = (
  organizationId: string,
  page: number,
  size: number,
  pinned: boolean
): Promise<NotificationPage> => {
  return http.get(
    buildNotificationApiUrl(
      `/notifications?organizationId=${organizationId}&page=${page}&size=${size}&pinned=${pinned}`
    ),
    "la récupération des notifications n'a pas pu être effectuée"
  );
};
