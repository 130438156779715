import { makeStyles } from '@material-ui/core'
import 'date-fns'
import React, { Fragment, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputForm } from '../../Component/InputForm'
import { Period, PeriodPicker } from '../../Component/PeriodPicker'
import { Option } from '../../Component/Select'

const useStyles = (columnSwitchWidth?: number) =>
  makeStyles(() => ({
    filter: {
      width: '200px',
      marginRight: '10px',
      [`@media (max-width: ${columnSwitchWidth ?? 800}px)`]: {
        width: '100% !important',
        marginRight: '0 !important'
      },
      '& .MuiOutlinedInput-root': {
        height: '44px !important'
      },
      '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
        marginTop: '0px'
      },
      '& .MuiInputLabel-filled ': {
        transform: ' translate(12px, 25px) scale(1)'
      }
    },
    icon: {
      color: '#9a9a9a',
      cursor: 'pointer'
    },
    container: {
      display: 'flex'
    }
  }))

export interface BonusFiltersModel {
  startDate?: number
  endDate?: number
  status?: string
  operatorId?: string
  carrierId?: string
  territoryId?: string
}

export type Options = {
  label: string
  value: string
}
interface BonusFiltersProps {
  operators: Options[]
  carriers: Options[]
  territories: Options[]
  filterValues: BonusFiltersModel
  onFilterChange: (values: BonusFiltersModel) => void
  columnSwitchWidth?: number
}

export const BonusFilters = (props: BonusFiltersProps) => {
  const {
    operators,
    filterValues,
    onFilterChange,
    carriers,
    territories,
    columnSwitchWidth
  } = props
  const classes = useStyles(columnSwitchWidth)()
  const { t } = useTranslation()

  const [selectedPeriod, setSelectedPeriod] = useState<Period | undefined>(
    filterValues.startDate == null
      ? undefined
      : {
          startDate: filterValues.startDate!,
          endDate: filterValues.endDate!
        }
  )

  const BonusStatusOptions: Option[] = [
    {
      label: t('app_bonus_created_status') as string,
      value: 'CREATED'
    },
    {
      label: t('app_bonus_reported_status') as string,
      value: 'REPORTED'
    },
    {
      label: t('app_bonus_deducted_status') as string,
      value: 'DEDUCTED'
    },
    {
      label: t('app_bonus_published_status') as string,
      value: 'PUBLISHED'
    },
    {
      label: t('app_bonus_approved_status') as string,
      value: 'APPROVED'
    },
    {
      label: t('app_bonus_invalid_status') as string,
      value: 'INVALID'
    },
    {
      label: t('app_bonus_rejected_status') as string,
      value: 'REJECTED'
    },
    {
      label: t('app_bonusPayment_paid_status') as string,
      value: 'PAID'
    }
  ]

  const handlePeriodChange = useCallback(
    (period: Period | undefined) => {
      setSelectedPeriod(period)

      onFilterChange({
        ...filterValues,
        startDate: period?.startDate,
        endDate: period?.endDate
      })
    },
    [onFilterChange, filterValues]
  )

  const handleFilterChange = (
    property: keyof BonusFiltersModel,
    value: any
  ) => {
    onFilterChange({
      ...filterValues,
      [property]: value
    })
  }

  return (
    <Fragment>
      <PeriodPicker
        id='filterPeriod'
        placeholder={'Période'}
        period={selectedPeriod}
        onDateChange={handlePeriodChange}
        inputClassName={classes.filter}
        clearable
      />
      <InputForm
        id='Bonus-filters-operator'
        inputType={'select'}
        onChange={(operator) => handleFilterChange('operatorId', operator)}
        selectOptions={operators}
        label={''}
        value={filterValues.operatorId}
        placeHolder={t('app_components_bonus_Operateur')}
        className={`${classes.filter} project-filters`}
        onRemoveValue={() => handleFilterChange('operatorId', undefined)}
      />
      <InputForm
        id='Bonus-filters-carrier'
        inputType={'select'}
        onChange={(carrier) => handleFilterChange('carrierId', carrier)}
        selectOptions={carriers}
        label={''}
        value={filterValues.carrierId}
        placeHolder={t('app_components_bonus_bonusform_carrier')}
        className={`${classes.filter} project-filters`}
        onRemoveValue={() => handleFilterChange('carrierId', undefined)}
      />
      <InputForm
        id='Bonus-filters-territory'
        inputType={'select'}
        onChange={(territory) => handleFilterChange('territoryId', territory)}
        selectOptions={territories}
        label={''}
        value={filterValues.territoryId}
        placeHolder={t('app_components_bonus_territory')}
        className={`${classes.filter} project-filters`}
        onRemoveValue={() => handleFilterChange('territoryId', undefined)}
      />
      <InputForm
        id='Bonus-filters-status'
        inputType={'select'}
        onChange={(status) => handleFilterChange('status', status)}
        selectOptions={BonusStatusOptions}
        label={''}
        value={filterValues.status}
        placeHolder={t('app_domain_project_project_status')}
        className={`${classes.filter} project-filters`}
        onRemoveValue={() => handleFilterChange('status', undefined)}
      />
    </Fragment>
  )
}
