import { OrganizationDetails, OrganizationType } from "../index";
import { city } from "plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface CreateOrganizationCommand
  extends city.colisactiv.organization.model.features
    .CreateOrganizationCommand {}

export interface OrganizationEventDetails
  extends city.colisactiv.organization.model.features
    .OrganizationEventDetails {}

export interface OrganizationCreatedEvent
  extends city.colisactiv.organization.model.features.OrganizationCreatedEvent {
  type: string;
  payload: OrganizationEventDetails;
}

export const organizationCreateCommand = (
  type: OrganizationType,
  details: OrganizationDetails
): CreateOrganizationCommand => {
  return {
    type: "CREATE_ORGANIZATION",
    payload: {
      details: details,
      type: type,
    },
  };
};

export const createOrganizationCommand = (
  type: OrganizationType,
  details: OrganizationDetails
): Promise<OrganizationCreatedEvent> => {
  return http.post(
    buildOrganizationApiUrl(`/organizations`),
    JSON.stringify(organizationCreateCommand(type, details)),
    "L'organisation a bien été ajoutée"
  );
};
