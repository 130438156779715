import { Box, Grid, makeStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { OrganizationRef } from "api/organization";
import { ProjectFunder } from "api/project/model";
import { InputForm } from "components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProjectMode } from "store/router/router.project.goto";
import Funder from "./Funder";
import { ProjectFormState } from "./model";
import { ProjectValidation } from "./validation";
const useStyles = makeStyles(() => ({
  input: {
    width: "100%",
  },

  inputForm: {
    justifyContent: "start",
    flexDirection: "column",
    alignItems: "start",
  },
  funderBox: {
    marginTop: "35px",
  },
  addFunders: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "fit-content",
    color: "#007DCE",
    fontSize: "14px",
    marginTop: "20px",
  },
  addCircleIcon: {
    marginRight: 5,
    width: "14px",
    height: "29px",
  },
  title: {
    marginBottom: 11,
    fontSize: "16px",
    color: "#353945",
    fontWeight: 600,
  },
}));

interface ProjectFormProps {
  project: ProjectFormState;
  onProjectChange: (project: ProjectFormState) => void;
  readOnly: boolean;
  funderRefs: Map<string, OrganizationRef>;
  mode: ProjectMode;
}

const ProjectForm = (props: ProjectFormProps) => {
  const { t } = useTranslation();
  const { project, onProjectChange, readOnly, funderRefs, mode } = props;

  const totalFundation = useMemo(() => {
    return project.funders.reduce(
      (total, funder) => total + Number(funder.budget),
      0
    );
  }, [project.funders]);

  const classes = useStyles();
  const newFunder: ProjectFunder = {
    territories: [],
    budget: "",
    funderId: "",
  };
  const newFunderValidation: any = {
    territories: true,
    budget: true,
    funderId: true,
  };

  const addFunder = () => {
    let funderValidation = ProjectValidation.getFundersValidation(
      project.funders
    );
    let funderFieldRequired = funderValidation.map((el) =>
      Object.values(el).some((item) => !item)
    );

    if (funderFieldRequired.every((el) => !el)) {
      onProjectChange({
        ...project,
        funders: [...project.funders, newFunder],
        validity: {
          ...project.validity,
          funders: [...funderValidation, newFunderValidation],
        },
      });
    } else {
      onProjectChange({
        ...project,
        validity: {
          ...project.validity,
          funders: [...funderValidation, newFunderValidation],
        },
      });
    }
  };

  const onChangeFunder = (position: number, projectFunder: ProjectFunder) => {
    let projectFundersCopy = [...project.funders];
    projectFundersCopy[position] = projectFunder;
    onProjectChange({
      ...project,
      funders: projectFundersCopy,
    });
  };

  const onDeleteFunder = (position: number): void => {
    let projectFundersCopy = [...project.funders];
    let projectFunderValidationCopy = [...project.validity.funders];
    projectFundersCopy.splice(position, 1);
    projectFunderValidationCopy.splice(position, 1);
    onProjectChange({
      ...project,
      funders: projectFundersCopy,
      validity: {
        ...project.validity,
        funders: [...projectFunderValidationCopy, newFunderValidation],
      },
    });
  };

  const onChangeName = (name: string) => {
    onProjectChange({
      ...project,
      details: { ...project.details, name: name },
      validity: {
        ...project.validity,
        details: {
          ...project.validity.details,
          name: true,
        },
      },
    });
  };
  const shouldShowAddFunder = (): boolean => {
    const isAllowedToAdd = mode === "edit" && readOnly;
    return !readOnly || isAllowedToAdd || mode === "add";
  };
  return (
    <>
      <Box display="flex" flexDirection="column" width="100%">
        <Grid container>
          <Grid item xs={6}>
            <div className={classes.title} style={{ marginBottom: "20px" }}>
              <span>{t("app_create_project_project_info")}</span>
            </div>
            <InputForm
              value={project.details.name}
              onChange={onChangeName}
              error={!project.validity.details.name}
              inputClassName={classes.input}
              id="project-add-name"
              errorMessage={t(
                "app_create_project_please_insert_the_name_of_the_project"
              )}
              inputType="textField"
              label={t("app_create_project_name")}
              className={classes.inputForm}
              readonly={readOnly}
            />
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" flexDirection="column" className={classes.funderBox}>
        <>
          <div className={classes.title}>
            <span>{t("app_create_project_name_title")} </span>
          </div>

          {project.funders.map((el, index) => (
            <Funder
              funderRefs={funderRefs}
              funderPercent={
                el.budget ? (Number(el.budget) * 100) / totalFundation : 0
              }
              funderValidation={project.validity.funders[index]}
              onChangeValue={onChangeFunder}
              funderTableLength={project.funders.length}
              projectFunder={el}
              key={index}
              position={index}
              deleteFunder={onDeleteFunder}
              readOnly={readOnly}
              oldProjectFundersLength={project.oldFundersProjectLength}
              mode={mode}
            />
          ))}
        </>
      </Box>
      <Box>
        {shouldShowAddFunder() && (
          <div className={classes.addFunders} onClick={() => addFunder()}>
            <AddCircleIcon className={classes.addCircleIcon} />
            {t("app_create_project_add_a_funder")}
          </div>
        )}
      </Box>
    </>
  );
};

export default ProjectForm;
