import { startOfMonth, endOfMonth, addMinutes, addMonths } from 'date-fns'
import { Period } from '../Component/PeriodPicker'

export const nullToUndefined = <T>(value: T | null): T | undefined => {
  return value ?? undefined
}

export const displayNumber = (
  value: number,
  fractionDigits?: number
): string => {
  const fixedValue = value.toFixed(fractionDigits)
  return parseFloat(fixedValue).toLocaleString('fr-FR')
}

export const removeTimeZoneOffset = (date: number): number => {
  const formatedDate = new Date(date)
  return addMinutes(formatedDate, -formatedDate.getTimezoneOffset()).getTime()
}

export const applyTimeZoneOffset = (date: number): number => {
  const formatedDate = new Date(date)
  return addMinutes(formatedDate, formatedDate.getTimezoneOffset()).getTime()
}

export const getFirstDayOfTheMonthUTC = (date: Date | number): number => {
  return removeTimeZoneOffset(startOfMonth(date).getTime())
}

export const getFirstDayOfLastMonthUTC = (date: Date | number): number => {
  return removeTimeZoneOffset(endOfMonth(addMonths(date, -1)).getTime())
}

export const getLastDayOfTheMonthUTC = (date: Date | number): number => {
  return removeTimeZoneOffset(endOfMonth(date).getTime())
}

export const UTCToTimedZone = (date: number): Date => {
  return new Date(applyTimeZoneOffset(date))
}

export const wholeMonthOf = (date: number): Period => ({
  startDate: getFirstDayOfTheMonthUTC(date),
  endDate: getLastDayOfTheMonthUTC(date)
})

export const formatDate = (date: number): string => {
  const newDate = new Date(date)
  const month = newDate.toLocaleString('fr-fr', { month: 'long' })
  const year = newDate.getFullYear()

  return month[0].toUpperCase() + month.substr(1) + ' ' + year
}
