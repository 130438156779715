import { TFunction } from 'i18next'
import { city } from 'plateform-poi-dashboard-domain'
import { useMemo } from 'react'
import { OrganizationRef } from 'web-admin/src/api/organization'
import { formatDate, displayNumber } from '../../utils'

interface BonusStats
  extends city.colisactiv.plateform.poi.dashboard.domain.BonusStats {}

export const useDashboardCSV = (
  isOperator: boolean,
  isCarrier: boolean,
  t: TFunction,
  organizationRefs: Map<string, OrganizationRef>,
  stats?: BonusStats[]
): object[] | undefined => {
  const date = t('app_domain_date')
  const operator = t('app_domain_bonus_operateur')
  const carrier = t('app_domain_bonus_chargeur')
  const funder = t('app_domain_organization_financeur')
  const packageCount = t('app_domain_package_count')
  const total = t('app_domain_bonus_total')
  const csvData = useMemo(
    () =>
      stats
        ? stats.map((stat) => {
            const totalPrice = displayNumber(stat.bonusTotal)
            return {
              [date]: formatDate(stat.period.startDate),
              ...(!isOperator
                ? {
                    [operator]: organizationRefs.get(stat.operatorId)
                      ?.displayName
                  }
                : {}),
              ...(!isCarrier
                ? {
                    [carrier]: organizationRefs.get(stat.carrierId)?.displayName
                  }
                : {}),
              [funder]: `"${stat.funderRepartition
                .map(
                  (funder: any) =>
                    organizationRefs.get(funder.id)!!.displayName + ', salut'
                )
                .join(', ')}"`,
              [packageCount]: stat.activePackCount,
              [total]: `${totalPrice} €`
            }
          })
        : undefined,
    [stats, organizationRefs]
  )
  return csvData
}
