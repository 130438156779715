import { city } from "plateform-notification-domain";
import { buildNotificationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface AllSeenEvent
  extends city.colisactiv.platform.notification.domain.features
    .AllSeenEvent { }

export interface MarkAllAsSeenCommand
  extends city.colisactiv.platform.notification.domain.features
    .MarkAllAsSeenCommand { }
  
const markAllAsSeenCommand = (organizationId: string): MarkAllAsSeenCommand => ({
  payload: {
    organizationId: organizationId
  },
  type: "MARK_ALL_AS_SEEN_COMMAND"
})

export const markAllAsSeen = (
  organizationId: string
): Promise<AllSeenEvent> => {
  return http.put(
    buildNotificationApiUrl(`/notifications/markAllAsSeen`),
    JSON.stringify(markAllAsSeenCommand(organizationId))
  );
};
