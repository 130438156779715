import { makeStyles } from "@material-ui/core";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { Notification } from "components";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { router } from "store/router";
import { BonusIncidentMapping } from "./BonusIncidentMapping";
import { useAuth } from "../../../../auth/useAuth";
import { User } from "../../../../api/organization/users";

const useStyles = makeStyles({
  incident: {
    background: "#f8e7d1",
    marginBottom: "15px",
    marginTop: "5px",
  },
  incidentIcon: {
    color: "#ff9900",
  },
  incidentReason: {
    color: "#ff9900",
    fontWeight: "bold",
  },
  rejected: {
    background: "#f3b3b2",
    marginBottom: "15px",
    marginTop: "5px",
  },
  rejectedIcon: {
    color: "red",
  },
  rejectedReason: {
    color: "red",
    fontWeight: "bold",
  },
  link: {
    fontWeight: "bolder",
    textDecoration: "underline",
    color: "inherit",
  },
});

interface BonusIncidentBoxProps {
  isRejection: boolean;
  incident: BonusIncidentMapping;
  currentUser: User | null;
}

export const BonusIncidentBox = (props: BonusIncidentBoxProps) => {
  const { isRejection, incident, currentUser } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [authService, keycloak] = useAuth();

  const isOperator = useMemo(
    () =>
      authService.isOperator(currentUser, keycloak) &&
      !authService.isColisActivAdmin(currentUser, keycloak),
    [currentUser]
  );

  const messageLink = useMemo(() => {
    if (isOperator) return;
    return (
      incident.messageLinkKey && (
        <span className={classes.link}>{t(incident.messageLinkKey)}</span>
      )
    );
  }, [isOperator]);

  const handleIncidentClick = useCallback(() => {
    if (isOperator) return;

    incident.url && dispatch(router.goto.push(incident.url));
  }, [dispatch]);

  const message = !!incident.messageKey
    ? t(incident.messageKey, incident.messageParams)
    : incident.messageDefault;

  return (
    <Notification
      severity={isRejection ? "important" : "warning"}
      className={isRejection ? classes.rejected : classes.incident}
      onClick={handleIncidentClick}
      icon={
        isRejection ? (
          <ErrorOutlineOutlinedIcon className={classes.rejectedIcon} />
        ) : (
          <ReportProblemOutlinedIcon className={classes.incidentIcon} />
        )
      }
    >
      <div
        className={
          isRejection ? classes.rejectedReason : classes.incidentReason
        }
      >
        {message} {messageLink}
      </div>
    </Notification>
  );
};
