import { connect } from "react-redux";
import { State } from "store";
import { alert } from "store/alert";

const mapStateToProps = (state: State) => ({
  alerts: alert.selectors.alerts(state),
});

const mapDispatchToProps = {
  onRemoveAlert: alert.actions.remove,
};

export default connect(mapStateToProps, mapDispatchToProps);
