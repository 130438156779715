import { city } from "plateform-project-domain";
import { buildProjectApiUrl } from "../../configuration";
import i18n from "i18n";
import { http } from "../../http";

export interface CreateCampaignCommand
  extends city.colisactiv.project.model.features.AddCampaignCommand {}

export interface AddedCampaignEvent {
  payload: {
    projectId: string;
    campaignId: string;
  };
}

export const createCampaignCommand = (
  command: CreateCampaignCommand
): Promise<AddedCampaignEvent> => {
  return http.post(
    buildProjectApiUrl(`/projects/${command.payload.projectId}/campaigns`),
    JSON.stringify(command),
    i18n.t("app_project_campaign_create_success")
  );
};
