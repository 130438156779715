import { Action, Dispatch } from "redux";
import { getProjectsReferences } from "api/project/query/getProjectsReferences";
import { State } from "../index";
import { ThunkAction } from "redux-thunk";
import { actions } from "./project.actions";
import { selectors } from "./project.selectors";
import { projectClient } from "api/project";
export const fetchProjectRefs = (): ThunkAction<
  void,
  State,
  unknown,
  Action<string>
> => {
  return async (dispatch: Dispatch, getState: () => State) => {
    if (getState().project.projectRefs === null) {
      dispatch(actions.fetchProjectRefs());
      const projectRefs = await getProjectsReferences();
      dispatch(actions.fetchedProjectRefs(projectRefs || null));
    }
  };
};

export const fetchProject: (projectId: string) => void = (
  projectId: string
) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const project = selectors.project(state);
    if (!project || project.id !== projectId) {
      dispatch(actions.fetchProject);
      const newProject = await projectClient.query.getProject(projectId);

      dispatch(actions.fetchedProject(newProject || null));
    }
  };
};

export const fetchCampaign: (projectId: string, campaignId: string) => void = (
  projectId: string,
  campaignId: string
) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const project = selectors.project(state);
    if (project && project.id === projectId) {
      dispatch(actions.fetchedCampaign(project, campaignId));
      return;
    }
    dispatch(actions.fetchProject);
    const fetchedProject = await projectClient.query.getProject(projectId);
    dispatch(actions.fetchedCampaign(fetchedProject, campaignId));
  };
};

export const fetchTerritories: () => void = () => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.fetchTerritories());
    const territories = await projectClient.query.getTerritoriesOfAllProjects();
    dispatch(actions.fetchedTerritories(territories));
  };
};

export const fetchUnfinishedTerritories: () => void = () => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.fetchTerritories());
    const territories = await projectClient.query.getTerritoriesOfAllUnfinishedProjects();
    dispatch(actions.fetchedTerritories(territories));
  };
};

export const queries = {
  fetchProjectRefs: fetchProjectRefs,
  fetchProject: fetchProject,
  fetchCampaign: fetchCampaign,
  fetchTerritories: fetchTerritories,
  fetchUnfinishedTerritories: fetchUnfinishedTerritories,
};
