import { city } from "plateform-bonus-group-domain";
import { buildBonusGroupApiUrl } from "api/configuration";
import { http } from "api/http";

export interface ResolveBonusGroupIncidentCommand
  extends city.colisactiv.plateform.bonus.group.model.features
    .ResolveBonusGroupIncidentCommand {}

export interface ResolveBonusGroupIncidentCommandDTO
  extends city.colisactiv.plateform.bonus.group.model.features
    .ResolveBonusGroupIncidentCommandDTO {}

export interface BonusGroupIncidentCreatedEvent
  extends city.colisactiv.plateform.bonus.group.model.features
    .BonusGroupIncidentCreatedEvent {}

export const resolveBonusGroupIncident = (
  command: ResolveBonusGroupIncidentCommandDTO
): Promise<BonusGroupIncidentCreatedEvent> => {
  const url = buildBonusGroupApiUrl(
    `/bonusgroup/${command.bonusGroupId}/incidents/${command.incidentId}/status/resolved`
  );
  return http.put(url, null, "L'incident a bien été résolu");
};
