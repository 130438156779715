import { Box, makeStyles, Tooltip } from '@material-ui/core'
import React from 'react'
import { CampaignDeliveryRow } from './CampaignDeliveryTable'
import { InfoIcon } from '../../Icons'
import { useTranslation } from 'react-i18next'
import { PrimaryTypography } from '../../Component/Table'
import { InfoBox } from '../../Component/InfoBox'

interface CampaignDeliveryReasonTooltipProps {
  campaignDeliveryRow: CampaignDeliveryRow
}

const useStyles = makeStyles(() => ({
  infoIcon: {
    width: '20px',
    height: '20px'
  }
}))

export const CampaignDeliveryReasonTooltip = (
  props: CampaignDeliveryReasonTooltipProps
) => {
  const { campaignDeliveryRow } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const prepareProbabilities = (probabilities: any) => {
    let sum = 0
    let max = 0
    let maxKey = ''
    const resultMap = new Map()

    if (!probabilities) {
      return { resultMap, maxKey, sum }
    }

    new Map(Object.entries(probabilities)).forEach(
      (value: number, transport: string) => {
        const valuePercent = value * 100
        sum += valuePercent
        resultMap.set(
          transport,
          `${t(
            'app_domain_campaign_delivery_transport_mode_' + transport
          )} = ${valuePercent.toFixed(0)}%`
        )
        if (valuePercent > max) {
          max = valuePercent
          maxKey = transport
        }
      }
    )
    return { resultMap, maxKey, sum }
  }

  const toDisplay = (
    probabilitiesMap: Map<string, string>,
    highlightKey?: string
  ) => {
    const highlighted: JSX.Element[] = []
    probabilitiesMap.forEach((value, key) => {
      if (key == highlightKey)
        highlighted.push(<span style={{ color: 'blue' }}>{value}</span>)
      else highlighted.push(<>{value}</>)
    })
    return highlighted.reduce((acc, curr) => (
      <>
        {acc}, {curr}
      </>
    ))
  }

  const {
    sum: activeModesSum,
    resultMap: activeModeText
  } = prepareProbabilities(campaignDeliveryRow.delivery.activeProbabilities)
  const {
    sum: nonActiveModesSum,
    resultMap: nonActiveModeText,
    maxKey: nonActiveMaxKey
  } = prepareProbabilities(campaignDeliveryRow.delivery.nonActiveProbabilities)

  return (
    <Box
      display='flex'
      justifyContent='space-around'
      width='100%'
      height='100%'
      alignItems='center'
    >
      <Box
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='space-around'
      >
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          variant='body2'
        >
          {t('app_domain_campaign_delivery_reason_activ', {
            proba: activeModesSum ? activeModesSum.toFixed(0) : 0
          })}
        </PrimaryTypography>
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          variant='body2'
        >
          {t('app_domain_campaign_delivery_reason_thermal', {
            proba: nonActiveModesSum ? nonActiveModesSum.toFixed(0) : 0
          })}
        </PrimaryTypography>
      </Box>
      <InfoBox
        className={classes.infoIcon}
        content={
          campaignDeliveryRow.reason != '01' ? (
            <PrimaryTypography variant='body2'>
              {t(
                'app_domain_campaign_delivery_reason_' +
                  campaignDeliveryRow.reason
              )}
            </PrimaryTypography>
          ) : (
            <>
              <PrimaryTypography>
                {t('app_domain_campaign_delivery_active')} ={' '}
                {activeModesSum.toFixed(0)}% dont ({toDisplay(activeModeText)})
              </PrimaryTypography>
              <PrimaryTypography>
                {t('app_domain_campaign_delivery_thermal')} ={' '}
                {nonActiveModesSum.toFixed(0)}% dont (
                {toDisplay(nonActiveModeText, nonActiveMaxKey)})
              </PrimaryTypography>
              <PrimaryTypography>
                {t('app_domain_campaign_delivery_ai_probabilities_hint', {
                  transportMode: t(
                    'app_domain_campaign_delivery_transport_mode_' +
                      nonActiveMaxKey
                  ).toUpperCase()
                })}
              </PrimaryTypography>
            </>
          )
        }
      />
    </Box>
  )
}
