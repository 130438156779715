import { Button, FormButtons } from "components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import OrganizationDetailsForm from "app/components/OrganizationDetailsForm";
import { OrganizationDetailsValidation } from "../../../../components/OrganizationDetailsForm/validation";
import { OrganizationDetailsFormState } from "../../../../components/OrganizationDetailsForm/model";

interface OrganizationDetailsFormProps {
  gotoOrganizationsList: () => void;
  createOrganization: (form: OrganizationDetailsFormState) => void;
}

const OrganizationDetails = (props: OrganizationDetailsFormProps) => {
  const { gotoOrganizationsList, createOrganization } = props;
  const [organization, setOrganization] = useState<
    OrganizationDetailsFormState
  >(OrganizationDetailsValidation.defaultOrganizationDetailsFormState);

  const onAddOrganization = async () => {
    const validation = OrganizationDetailsValidation.validation(
      organization.details,
      organization.type
    );
    if (!OrganizationDetailsValidation.isFormValid(validation)) {
      return setOrganization({
        ...organization,
        validity: validation,
      });
    }
    await createOrganization(organization);
    gotoOrganizationsList();
  };
  const { t } = useTranslation();
  return (
    <>
      <OrganizationDetailsForm
        organization={organization}
        onOrganizationChange={setOrganization}
        readonly={false}
      />
      <FormButtons>
        <Button onClick={onAddOrganization}>
          {t("app_organizations_create_detail_validate")}
        </Button>
      </FormButtons>
    </>
  );
};

export default OrganizationDetails;
