import { createActionCreator } from "deox";
import { User } from "api/organization/users";

const fetchProfile = createActionCreator(
  "PROFILE_FETCH",
  (resolve) => (userId: string) => resolve({ userId: userId })
);

const fetchedProfile = createActionCreator(
  "PROFILE_FETCHED",
  (resolve) => (user: User) => resolve({ user: user })
);

// const updateuser = createActionCreator(
//   "user_UPDATE",
//   (resolve) => (
//     userId: string,
//     userForm: userFormState
//   ) => resolve({ userId, userForm })
// );

// const updateduser = createActionCreator(
//   "USER_UPDATED",
//   (resolve) => (user: User) => resolve({ user })
// );

export const actions = {
  fetchProfile: fetchProfile,
  fetchedProfile: fetchedProfile,
  // updateUser: updateUser,
  // updatedUser: updatedUser,
};
