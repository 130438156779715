import { city as citydel } from "plateform-campaign-delivery-domain";
import { city as cityPlat } from "plateform-plateform-commons";
import { forceAcceptDeliveryTours } from "./command/forceAcceptDeliveryTours";
import { rejectDeliveryTours } from "./command/rejectDeliveryTours";
import { getRejectedDeliveryTours } from "./query/getRejectedDeliveryTours";

export interface DeliveryTourPage
  extends cityPlat.colisactiv.platform.commons.Page<RejectedDeliveryTour> {}

export interface RejectedDeliveryTour
  extends citydel.colisactiv.platform.campaign.delivery.model
    .RejectedDeliveryTour {
  bonusIds: number[];
  packCountPerCarrier: Map<string, number>;
}

export const campaignDeliveryClient = {
  query: {
    getRejectedDeliveryTours: getRejectedDeliveryTours,
  },
  command: {
    forceAcceptDeliveryTours: forceAcceptDeliveryTours,
    rejectDeliveryTours: rejectDeliveryTours
  },
};
