import { push } from "connected-react-router";

export type CampaignMode = "add" | "view" | "edit";
export type ProjectMode = "add" | "view" | "edit";

const projectSection = (projectId: string, mode: ProjectMode) =>
  push(`/projects/${projectId}/${mode}`);

const projectsList = (page: number, size: number, name?: string, status?: string) =>
  push(`/projects?page=${page}&size=${size}${name ? "&projectName=" + name : ""}${status ? "&projectStatus=" + status : ""}`);

const projectAdd = () => push(`/projects/add`);

const projectEdit = (projectId: string) => projectSection(projectId, "edit");
const projectView = (projectId: string) => projectSection(projectId, "view");

const campaignSectionView = (
  projectId: string,
  mode: CampaignMode,
  campaignId?: string
) =>
  push(
    `/projects/${projectId}/campaign/${mode}${
      campaignId && campaignId !== "" ? `/${campaignId}` : ""
    }`
  );

const gotoCreateCampaign = (projectId: string) =>
  campaignSectionView(projectId, "add");

const gotoCampaignView = (projectId: string, campaignId: string) =>
  campaignSectionView(projectId, "view", campaignId);

const gotoCampaignEdit = (projectId: string, campaignId: string) =>
  campaignSectionView(projectId, "edit", campaignId);

export const gotoProject = {
  projectsList: projectsList,
  projectView: projectView,
  projectEdit: projectEdit,
  projectAdd: projectAdd,

  campaignCreate: gotoCreateCampaign,
  campaignEdit: gotoCampaignEdit,
  campaignView: gotoCampaignView,
};
