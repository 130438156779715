import { city } from "plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface UploadPaymentInfoCommand
  extends city.colisactiv.organization.model.features
    .UploadPaymentInfoCommand {}

export interface UploadedPaymentInfoEvent
  extends city.colisactiv.organization.model.features
    .UploadedPaymentInfoEvent {}

export interface PaymentDetails
  extends city.colisactiv.organization.model.PaymentDetails {}

export const updatePaymentInfo = (
  organizationId: string,
  payment: PaymentDetails
): Promise<UploadedPaymentInfoEvent> => {
  return http.put(
    buildOrganizationApiUrl(`/organizations/${organizationId}/payment`),
    JSON.stringify(payment)
  );
};
