import * as R from "ramda";
import { createReducer } from "deox";
import { actions } from "./notification.actions";

export interface NotificationState {
  unSeenNumber: number;
}

export const initialState: NotificationState = {
  unSeenNumber: 0
};

export type NotificationStateTransformer = (state: NotificationState) => NotificationState;

const setUnseenNumber = (unSeenNumber: number): NotificationStateTransformer => {
  return R.assoc("unSeenNumber", unSeenNumber);
};

export const notificationReducer = createReducer(initialState, (handleAction) => [
  handleAction(actions.setUnSeenNumber, (state: NotificationState, action) =>
  setUnseenNumber(action.payload.unSeenNumber)(state)
  ),
]);
