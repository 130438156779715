import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { themeContext } from '@smartb/archetypes-ui-components'
import React, { useContext } from 'react'
import {
  DeliveryBoxIcon,
  HourGlassIcon,
  Pay,
  Pickup,
  ShipmentDelivered
} from '../../Icons'
import { HeadlineBox } from '../HeadlineBox'
import { InfoBox } from '../InfoBox'
import { Panel } from '../Panel'

const useStyles = makeStyles(() => ({
  gridRoot: {
    maxWidth: '800px',
    minWidth: '600px',
    height: '300px',
    marginBottom: '20px'
  },
  icon: {
    width: '30px',
    height: '30px',
    marginBottom: '20px'
  },
  text: {
    '& .colisactivHeadlineBox-header': {
      marginBottom: '5px'
    }
  },
  panel: {
    width: '100%',
    height: '100%',
    '& .ColisactivPanel-content': {
      padding: '15px !important'
    }
  },
  infoIcon: {
    width: '20px',
    height: '20px',
    margin: '5px',
    marginBottom: '-2px'
  },
  internText: {
    fontSize: '12px',
    color: '#828282'
  }
}))

interface DashboardGridProps {
  activePackCount: string
  hubsCount: number
  bonusTotal: string
  bonusToDeduce: string
  bonusToReport: string
  bonusPaid: string
  bonusCreated: string
  bonusDeduced: string
  averageAmount: string
  deliveryTime: string
  activeDeliveries: string
}

export const DashboardGrid = (props: DashboardGridProps) => {
  const {
    activePackCount,
    hubsCount,
    bonusTotal,
    bonusToDeduce,
    bonusToReport,
    bonusPaid,
    bonusDeduced,
    bonusCreated,
    averageAmount,
    deliveryTime,
    activeDeliveries
  } = props
  const classes = useStyles()
  const theme = useContext(themeContext)

  return (
    <div>
      <Box display='flex' justifyContent='space-around' flexWrap='wrap'>
        <Grid container spacing={3} className={classes.gridRoot}>
          <Grid item xs={4}>
            <Panel className={classes.panel}>
              <Pay className={classes.icon} color={theme.primaryColor} />
              <Box display='flex' alignItems='flex-end'>
                <HeadlineBox
                  className={classes.text}
                  size='big'
                  header={`${bonusTotal} €`}
                >
                  Total de la prime
                </HeadlineBox>
                <InfoBox
                  className={classes.infoIcon}
                  content={
                    <Box display='flex' flexDirection='column'>
                      <Typography
                        variant='body2'
                        className={classes.internText}
                      >
                        {`${bonusToDeduce} € à déduire`}
                      </Typography>
                      <Typography
                        variant='body2'
                        className={classes.internText}
                      >
                        {`${bonusToReport} € à reporter`}
                      </Typography>
                      <Typography
                        variant='body2'
                        className={classes.internText}
                      >
                        {`${bonusDeduced} € déduit`}
                      </Typography>
                      <Typography
                        variant='body2'
                        className={classes.internText}
                      >
                        {`${bonusPaid} € payé`}
                      </Typography>
                      <Typography
                        variant='body2'
                        className={classes.internText}
                      >
                        {`${bonusCreated} € en construction`}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Panel>
          </Grid>
          <Grid item xs={4}>
            <Panel className={classes.panel}>
              <Pay className={classes.icon} color={theme.primaryColor} />
              <HeadlineBox
                className={classes.text}
                size='big'
                header={`${averageAmount} €`}
              >
                Prime moyenne par colis
              </HeadlineBox>
            </Panel>
          </Grid>
          <Grid item xs={4}>
            <Panel className={classes.panel}>
              <HourGlassIcon
                className={classes.icon}
                color={theme.primaryColor}
              />
              <HeadlineBox
                className={classes.text}
                size='big'
                header={deliveryTime}
              >
                Temps total de livraison
              </HeadlineBox>
            </Panel>
          </Grid>
          <Grid item xs={4}>
            <Panel className={classes.panel}>
              <ShipmentDelivered
                className={classes.icon}
                color={theme.primaryColor}
              />
              <HeadlineBox
                className={classes.text}
                size='big'
                header={activeDeliveries}
              >
                Nombre de livraison active
              </HeadlineBox>
            </Panel>
          </Grid>
          <Grid item xs={4}>
            <Panel className={classes.panel}>
              <DeliveryBoxIcon
                className={classes.icon}
                color={theme.primaryColor}
              />
              <HeadlineBox
                className={classes.text}
                size='big'
                header={activePackCount}
              >
                Nombre de colis
              </HeadlineBox>
            </Panel>
          </Grid>
          <Grid item xs={4}>
            <Panel className={classes.panel}>
              <Pickup className={classes.icon} color={theme.primaryColor} />
              <HeadlineBox
                className={classes.text}
                size='big'
                header={hubsCount}
              >
                Nombre de HUB
              </HeadlineBox>
            </Panel>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
