import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Form,
  FormField,
  FormAction,
  useForm,
} from "@smartb/archetypes-ui-forms";
import { Theme, themeContext } from "@smartb/archetypes-ui-components";
import { highLevelStyles } from "@smartb/archetypes-ui-themes";
import { Typography } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import {
  bonusGroupClient,
  BonusGroupFunderInvoiceDetails,
} from "api/bonusGroup";
import { useTranslation } from "react-i18next";

const useStyles = highLevelStyles<Theme>()({
  root: {
    "& .AruiForm-field:first-child": {
      marginTop: 0,
    },
    "& .MuiFormLabel-root": {
      fontSize: "14px",
      marginBottom: "8px",
    },
  },
  submitButton: {
    background: (theme) => theme.secondaryColor,
    color: "white",
    padding: "3px 7px",
    "& span": {
      color: "white",
    },
    "&:hover": {
      background: (theme) => theme.secondaryColor,
    },
  },
  seeMoreLabel: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: (theme) => theme.secondaryColor,
    marginTop: "-10px",
    cursor: "pointer",
  },
  unSeeMoreLabel: {
    marginTop: "-10px",
  },
});

interface FunderInvoiceDetailsProps {
  isFocused: boolean;
  attachementId: string;
  fetchBonus: (bonusId: string, force?: boolean) => void;
  fundedAmount?: number;
  details?: BonusGroupFunderInvoiceDetails;
  bonusId: string;
  isValid?: boolean;
  readonly?: boolean;
  onChangeSubmitForm?: (submitForm: () => Promise<string | undefined>) => void;
}

export const FunderInvoiceDetails = (props: FunderInvoiceDetailsProps) => {
  const {
    attachementId,
    isFocused,
    details,
    fundedAmount,
    bonusId,
    isValid = true,
    readonly = false,
    fetchBonus,
    onChangeSubmitForm,
  } = props;
  const [seeMore, setSeeMore] = useState(isFocused);
  const { t } = useTranslation();

  useEffect(() => {
    isFocused && setSeeMore(true);
  }, [isFocused, isValid]);

  useEffect(() => {
    if (!isValid && isFocused) {
      setTimeout(() => {
        const button = document.getElementById(
          `invoice-${attachementId}-details-number`
        ) as HTMLButtonElement;
        if (button) {
          button.focus();
          button.click();
        }
      }, 300);
    }
  }, [isValid]);

  const theme = useContext(themeContext);
  const classes = useStyles(theme);

  const fields = useMemo(
    (): FormField[] => [
      {
        key: `invoice-${attachementId}-details-number`,
        name: "invoiceNumber",
        label: t("app_bonus_view_invoices_details_number"),
        type: "textfield",
        defaultValue: details?.invoiceNumber,
        validator: (value) =>
          value === undefined || value === ""
            ? (t("app_bonus_view_invoices_details_number_error") as string)
            : undefined,
        textFieldProps: { size: "small", disabled: readonly },
      },
      {
        key: `invoice-${attachementId}-details-description`,
        name: "description",
        label: t("app_bonus_view_invoices_details_description"),
        type: "textfield",
        defaultValue: details?.description,
        validator: (value) =>
          value === undefined || value === ""
            ? (t("app_bonus_view_invoices_details_description_error") as string)
            : undefined,
        textFieldProps: { size: "small", disabled: readonly },
      },
      {
        key: `invoice-${attachementId}-details-quantity`,
        name: "quantity",
        label: t("app_bonus_view_invoices_details_quantity"),
        type: "textfield",
        defaultValue: details?.quantity,
        validator: (value) =>
          value === undefined || value <= 0
            ? (t("app_bonus_view_invoices_details_quantity_error") as string)
            : undefined,
        textFieldProps: {
          textFieldType: "number",
          size: "small",
          disabled: readonly,
        },
      },
      {
        key: `invoice-${attachementId}-details-ttcPrice`,
        name: "ttcPrice",
        label: t("app_bonus_view_invoices_details_ttcPrice"),
        type: "textfield",
        defaultValue: details?.ttcPrice,
        validator: (value) =>
          value === undefined || value <= 0
            ? (t("app_bonus_view_invoices_details_ttcPrice_error") as string)
            : undefined,
        textFieldProps: {
          textFieldType: "number",
          inputIcon: "€",
          iconPosition: "end",
          size: "small",
          disabled: readonly,
        },
      },
      {
        key: `invoice-${attachementId}-details-htPrice`,
        name: "htPrice",
        label: t("app_bonus_view_invoices_details_htPrice"),
        type: "textfield",
        defaultValue:
          details?.htPrice ?? fundedAmount
            ? Math.round(Number(fundedAmount))
            : undefined,
        validator: (value) =>
          value === undefined || value <= 0
            ? (t("app_bonus_view_invoices_details_htPrice_error") as string)
            : undefined,
        textFieldProps: {
          textFieldType: "number",
          inputIcon: "€",
          iconPosition: "end",
          size: "small",
          disabled: readonly,
        },
      },
    ],
    [attachementId, fundedAmount, details, readonly]
  );

  const onSubmit = useCallback(
    async (values) => {
      await bonusGroupClient.updateInvoiceDetails(
        bonusId,
        attachementId,
        values as BonusGroupFunderInvoiceDetails
      );
      await fetchBonus(bonusId);
      return "validated";
    },
    [bonusId, attachementId, fetchBonus]
  );

  const formState = useForm({ fields: fields, onSubmit: onSubmit });

  useEffect(() => {
    onChangeSubmitForm && onChangeSubmitForm(formState.submitForm);
  }, [formState.submitForm, onChangeSubmitForm]);

  const onChangeSeeMore = useCallback(
    () => setSeeMore((oldValue) => !oldValue),
    []
  );

  return !seeMore ? (
    <Typography
      variant="body2"
      component="span"
      onClick={onChangeSeeMore}
      className={classes.seeMoreLabel}
    >
      {t("app_bonus_view_invoices_details_seeMore")}
      <ArrowDropDown key={`invoice-${attachementId}-arrow`} />
    </Typography>
  ) : (
    <>
      <Form
        formState={formState}
        fields={fields}
        actions={[]}
        className={classes.root}
      />
      <Typography
        variant="body2"
        component="span"
        onClick={onChangeSeeMore}
        className={`${classes.seeMoreLabel} ${
          !readonly ? classes.unSeeMoreLabel : ""
        }`}
      >
        {t("app_bonus_view_invoices_details_unseeMore")}
        <ArrowDropUp key={`invoice-${attachementId}-arrow`} />
      </Typography>
    </>
  );
};
