import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { Delete, Document } from '../../Icons'
import { InputForm } from '../InputForm'
import { Theme, themeContext } from '@smartb/archetypes-ui-components'
import { GetApp } from '@material-ui/icons'

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    fileContainer: {
      display: 'flex',
      flex: 1,
      borderRadius: '6px',
      background: '#F7F8F9',
      padding: '8px',
      alignItems: 'center',
      margin: '3px 0px',
      cursor: 'pointer'
    },
    documentIcon: {
      width: '25px',
      height: '20px',
      marginRight: '10px'
    },
    documentTitle: {
      overflow: 'hidden',
      fontSize: '13px',
      fontWeight: 600,
      flex: 1,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
      WebkitLineClamp: 2
    },
    deleteIcon: {
      cursor: 'pointer',
      width: '25px',
      height: '25px',
      marginLeft: '7px',
      stroke: '#9a9a9a',
      color: '#9a9a9a',
      '&:hover': {
        stroke: '#005ecc',
        color: '#005ecc'
      }
    },
    error: {
      color: '#bd1313',
      fontSize: '12px'
    },
    button: {
      padding: '5px 9px',
      border: `1px solid ${theme.secondaryColor}`,
      fontSize: '12px',
      borderRadius: '4px',
      textTransform: 'none',
      '& span': {
        color: theme.secondaryColor
      }
    },
    priceIcon: {
      fontWeight: 600,
      color: '#353945',
      whiteSpace: 'nowrap'
    },
    textField: {
      width: '80px',
      '& .MuiInputBase-root': {
        minHeight: '35px !important',
        paddingTop: '2px',
        paddingRight: '5px'
      },
      '& .MuiInputAdornment-root': {
        marginLeft: '0px'
      },
      '& .MuiFilledInput-input': {
        textAlign: 'end',
        fontWeight: 600,
        color: '#353945'
      }
    },
    textFieldError: {
      '& .MuiInputBase-root': {
        border: '1px solid #EC8A90 !important'
      }
    }
  }))

export interface Attachment {
  file: string
  id: string
  name: string
  amount?: number
  cantBeRemoved?: boolean
}

interface FileProps {
  attachment: Attachment
  canChangePrice?: boolean
  onClickAttachement?: (attachment: Attachment) => void
  onRemoveFile?: (attachementId: string) => void
  onDownloadFile?: (attachment: Attachment) => void
  onPriceChange?: (newAmount: number, attachment: Attachment) => void
  withPrice?: boolean
  className?: string
  style?: React.CSSProperties
}

export const File = (props: FileProps) => {
  const {
    canChangePrice,
    className,
    onClickAttachement,
    attachment,
    onPriceChange,
    onRemoveFile,
    onDownloadFile,
    style,
    withPrice
  } = props
  const theme = useContext(themeContext)
  const classes = useStyles(theme)()

  const upHandler = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      const element = document.activeElement as HTMLElement
      if (element && element.classList.contains('FileList-priceInput')) {
        element.blur()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  return (
    <Box
      display='flex'
      width='100%'
      alignItems='center'
      style={style}
      className={className}
    >
      <Box
        onClick={() => onClickAttachement && onClickAttachement(attachment)}
        className={classes.fileContainer}
      >
        <Document color={'#9a9a9a'} className={classes.documentIcon} />
        <Typography variant='body1' className={classes.documentTitle}>
          {attachment.name}
        </Typography>
        {withPrice && canChangePrice ? (
          <InputForm
            id={attachment.id}
            baseClassName='FileList-priceInput'
            inputType='textField'
            textFieldType='number'
            defaultValue={attachment.amount}
            inputIcon={
              <Typography variant='body1' className={classes.priceIcon}>
                €
              </Typography>
            }
            iconPosition='end'
            onBlur={(event) =>
              onPriceChange &&
              onPriceChange(Number(event.currentTarget.value), attachment)
            }
            onClick={(event) => {
              event.stopPropagation()
            }}
            className={`${classes.textField} ${
              attachment.amount === 0 ? classes.textFieldError : ''
            }`}
          />
        ) : (
          withPrice && (
            <Typography
              variant='body1'
              className={classes.priceIcon}
              style={{ marginLeft: '5px' }}
            >{`${attachment.amount} €`}</Typography>
          )
        )}
      </Box>
      {onRemoveFile && !attachment.cantBeRemoved && (
        <Delete
          className={classes.deleteIcon}
          onClick={(event) => {
            event.stopPropagation()
            onRemoveFile(attachment.id)
          }}
        />
      )}
      {onDownloadFile && (
        <GetApp
          className={classes.deleteIcon}
          onClick={(event) => {
            event.stopPropagation()
            onDownloadFile(attachment)
          }}
        />
      )}
    </Box>
  )
}
