import { city } from "plateform-carrier-report-domain";
import { city as cityPlat } from "plateform-plateform-commons";
import { fetchReportsPage } from "./query/getReportsPageQuery";
import { getReport } from "./query/getReportQuery";
import { createCarrierReportCommand } from "./commande/createCarrierReportCommand";
import { updateCarrierReportCommand } from "./commande/updateCarrierReportCommand";

export interface CarrierReport
  extends city.colisactiv.platform.carrier.report.domain.CarrierReport {}

export interface Page<T> extends cityPlat.colisactiv.platform.commons.Page<T> {}

export interface Mode
  extends city.colisactiv.platform.carrier.report.domain.Mode {}

export interface DateInterval
  extends cityPlat.colisactiv.platform.commons.DateInterval {}

export interface CarrierReportStatus
  extends city.colisactiv.platform.carrier.report.domain.CarrierReportStatus {}

export const carrierReportClient = {
  createCarrierReport: createCarrierReportCommand,
  updateCarrierReport: updateCarrierReportCommand,
  query: {
    getCarrierReportsPage: fetchReportsPage,
    getReport: getReport,
  },
};
