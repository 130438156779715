import { makeStyles } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { InputForm } from '../../Component/InputForm'
import { useTranslation } from 'react-i18next'
import { Option } from '../../Component/Select'

const useStyles = makeStyles(() => ({
  filter: {
    width: '200px',
    marginRight: '10px',
    ['@media (max-width: 800px)']: {
      width: '100% !important',
      marginRight: '0 !important'
    },
    '& .MuiOutlinedInput-root': {
      height: '44px !important'
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
      marginTop: '0px'
    },
    '& .MuiInputLabel-filled ': {
      transform: ' translate(12px, 25px) scale(1)'
    }
  },
  icon: {
    color: '#9a9a9a',
    cursor: 'pointer'
  }
}))

export interface ProjectFiltersModel {
  name?: string
  status?: string
}

interface ProjectFiltersProps {
  filterValues: ProjectFiltersModel
  onFilterChange: (values: ProjectFiltersModel) => void
}

export const ProjectFilters = (props: ProjectFiltersProps) => {
  const { filterValues, onFilterChange } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [name, setName] = useState<string | undefined>(filterValues.name)
  const statusOptions: Option[] = [{
    label: t("app_project_created_status") as string,
    value: "CREATED"
  }, {
    label: t("app_project_started_status") as string,
    value: "STARTED"
  }, {
    label: t("app_project_done_status") as string,
    value: "DONE"
  }, {
    label: t("app_project_archived_status") as string,
    value: "ARCHIVED"
  },
  ]

  const upHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onFilterChange({name: name, status: filterValues.status})
      }
    },
    [onFilterChange, name]
  )

  useEffect(() => {
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keyup', upHandler)
    }
  }, [upHandler])

  return (
    <Fragment>
      <InputForm
        id='project-filters-name'
        placeHolder={t('app_domain_project_search')}
        inputType={'textField'}
        onChange={setName}
        value={name}
        className={`${classes.filter}`}
        inputIcon={<Search className={classes.icon} onClick={() => onFilterChange({name: name, status: filterValues.status})}/>}
        onRemoveValue={() => {setName(""); onFilterChange({name: "", status: filterValues.status})}}
      />
      <InputForm
        inputType={'select'}
        onChange={(status) => onFilterChange({name: name, status: status})}
        selectOptions={statusOptions}
        placeHolder={t('app_domain_project_project_status')}
        value={filterValues.status}
        className={`${classes.filter} project-filters`}
        id='project-filters-status'
        onRemoveValue={() => onFilterChange({name: name, status: ""})}
      />
    </Fragment>
  )
}

export default ProjectFilters
