import React from 'react'
import { ReactComponent } from './shipment-delivered.svg'
import { MergeReactElementProps } from '../types'

interface ShipmentDeliveredProps {
  color?: string
}

type Props = MergeReactElementProps<'svg', ShipmentDeliveredProps>

export const ShipmentDelivered = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { color = '#9a9a9a' } = props
    return (
      <ReactComponent
        stroke={color}
        fill='none'
        ref={ref}
        {...props}
        strokeWidth='1.5'
      />
    )
  }
)
