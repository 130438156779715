import { Organization } from "../../../api/organization";
import { defaultPaymentInfoFormValidity } from "./validation";

export const buildPaymentInfoForm = (
  organization: Organization
): PaymentInfoFormState => ({
  ...organization?.payment,
  validity: defaultPaymentInfoFormValidity,
});

export interface PaymentInfoFormState {
  iban: string;
  bic: string;
  tva: string;
  validity: PaymentInfoFormValidity;
}

export interface PaymentInfoFormValidity {
  iban?: string;
  bic?: string;
  tva?: string;
}
