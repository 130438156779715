import { Page, PageProps } from "components";
import React from "react";
import withConnect from "./withConnect";

interface OrganizationsPageProps extends PageProps {}

const PageWithTitle = (props: OrganizationsPageProps) => {
  return <Page {...props} />;
};

export default withConnect(PageWithTitle);
