import * as R from "ramda";
import { createReducer } from "deox";
import { actions } from "./title.actions";
import { HeadBarProps } from "../../../../components/src/Component/HeadBar";

export interface TitleState {
  headBar: HeadBarProps;
}

export const initialState: TitleState = {
  headBar: {
    title: "ColisActiv'",
    component: undefined,
  },
};

export type TitleStateTransformer = (state: TitleState) => TitleState;

const setHeadBar = (headBar: HeadBarProps): TitleStateTransformer => {
  return R.assoc("headBar", headBar);
};

export const titleReducer = createReducer(initialState, (handleAction) => [
  handleAction(actions.setHeadBar, (state: TitleState, action) =>
    setHeadBar(action.payload.headBar)(state)
  ),
]);
