import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Option } from '../../Component/Select'
import { Search } from '@material-ui/icons'
import { InputForm } from '../../Component/InputForm'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  filter: {
    width: '200px',
    marginRight: '10px',
    ['@media (max-width: 900px)']: {
      width: '100% !important',
      marginRight: '0 !important'
    },
    '& .MuiOutlinedInput-root': {
      height: '44px !important'
    },
    '& .MuiInputLabel-filled ': {
      transform: ' translate(12px, 25px) scale(1)'
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
      marginTop: '0px'
    }
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer'
  }
}))

export interface OrganizationFiltersModel {
  name?: string
  type?: string
}
export interface OrganizationFiltersProps {
  values: OrganizationFiltersModel
  onValuesChange: (value: OrganizationFiltersModel) => void
  typeOptions: Option[]
}

export const OrganizationFilters = (props: OrganizationFiltersProps) => {
  const { values, onValuesChange, typeOptions } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [name, setName] = useState<string | undefined>(values.name)

  const onNameChange = (value?: string) => {
    setName(value)
  }
  useEffect(() => {
    setName(values.name)
  }, [values.name])

  const valuesChange = (
    field: keyof OrganizationFiltersModel,
    value?: string
  ) => {
    onValuesChange({
      ...values,
      [field]: value
    })
  }
  const onSearchClick = useCallback(() => {
    onValuesChange({
      name: name,
      type: values.type
    })
  }, [valuesChange])

  const upHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        valuesChange('name', name)
      }
    },
    [valuesChange]
  )

  useEffect(() => {
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keyup', upHandler)
    }
  }, [upHandler])

  return (
    <Fragment>
      <InputForm
        inputType='textField'
        style={{width:"215px"}}
        id='organization-filters-name'
        onChange={(value) => onNameChange(value)}
        value={name}
        label={''}
        placeHolder={t('app_organization_filter_name')}
        className={`${classes.filter}`}
        inputIcon={<Search className={classes.icon} onClick={onSearchClick} />}
        onRemoveValue={() => valuesChange('name')}
      />
      <InputForm
        inputType='select'
        label={''}
        onChange={(value) => valuesChange('type', value)}
        value={values.type}
        selectOptions={typeOptions}
        placeHolder={t('app_organization_filter_type')}
        className={`${classes.filter} project-filters`}
        id='organization-filters-status'
        onRemoveValue={() => valuesChange('type')}
      />
    </Fragment>
  )
}

export default OrganizationFilters
