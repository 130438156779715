import { Box, makeStyles, Typography } from "@material-ui/core";
import {
  Block,
  DeleteForever,
  Done,
  Edit,
  Search,
  Visibility,
} from "@material-ui/icons";
import { MenuItem } from "@smartb/archetypes-ui-layout";
import { getURLParameter } from "api/configuration";
import { Organization } from "api/organization";
import { User, usersClient } from "api/organization/users";
import {
  Button,
  CircleProfileIcon,
  InputForm,
  Option,
  PopUpConfirmation,
  ProfileLogo,
  Section,
  UserRow,
  UserTable,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { routesAccessRights } from "../../../../../../auth/RoutesAccessRightsMap";
import { useAuth } from "../../../../../../auth/useAuth";
import { usePaginedList } from "../../../../../hooks/usePaginedList/usePaginedList";
import withConnect from "./withConnect";
const useStyles = makeStyles(() => ({
  button: {
    position: "absolute",
    right: "30px",
    top: "0px",
  },
  buttonAddUser: {
    position: "absolute",
    right: "30px",
    top: "0px",
    padding: "10px 20px",
  },
  filter: {
    width: "240px",
    marginRight: "10px",
    marginBottom: "10px",
    "& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)": {
      marginTop: "0px",
    },
    "& .MuiInputLabel-filled ": {
      transform: " translate(12px, 25px) scale(1)",
    },
  },
  filterContainer: {
    maxWidth: "calc(100% - 200px)",
  },
  icon: {
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
  },
}));

interface OrganizationUsersProps {
  organization: Organization | null;
  gotoUserAdd: (organizationId: string) => void;
  gotoOrganizationUser: (organizationId: string, userId: string) => void;
  gotoUserEdit: (organizationId: string, userId: string) => void;
  gotoUserView: (userId: string) => void;
  currentUser: User | null;
  gotoOrganizationUsersFilteredList: (
    organizationId: string,
    emailOrFirstnameOrLastname?: string,
    userRole?: string,
    userStatus?: string
  ) => void;
}

const OrganizationUsersPage = (props: OrganizationUsersProps) => {
  const {
    organization,
    gotoUserAdd,
    gotoUserEdit,
    gotoOrganizationUser,
    gotoUserView,
    currentUser,
    gotoOrganizationUsersFilteredList,
  } = props;
  const classes = useStyles();
  const { organizationId } = useParams();

  const [askDisableConfirmation, setAskDisableConfirmation] = useState<boolean>(
    false
  );
  const [askDeleteConfirmation, setAskDeleteConfirmation] = useState<boolean>(
    false
  );
  const [userConcerned, setUserConcerned] = useState<User | undefined>(
    undefined
  );
  const { t } = useTranslation();
  const roleOptions: Option[] = [
    {
      value: "admin",
      label: `${t("app_organizations_profil_users_list_administrateur")} `,
    },
    {
      value: "user",
      label: `${t("app_organizations_profil_users_list_user")} `,
    },
  ];

  const statusOptions: Option[] = [
    {
      value: "created",
      label: `${t("app_organizations_profil_users_list_waiting")} `,
    },
    {
      value: "enabled",
      label: `${t("app_organizations_profil_users_list_valid")} `,
    },
    {
      value: "disabled",
      label: `${t("app_organizations_profil_users_list_deactivated")} `,
    },
  ];

  const fetchUsers = useCallback(
    (page: number, size: number) => {
      return usersClient.queries.fetchPageUsers(
        organizationId,
        page,
        size,
        getURLParameter("emailOrFirstnameOrLastname"),
        getURLParameter("userRole"),
        getURLParameter("userStatus")
      );
    },
    [organization, organizationId]
  );

  const handleListUrlChange = useCallback(() => {
    organization &&
      gotoOrganizationUsersFilteredList(
        organization.id,
        getURLParameter("emailOrFirstnameOrLastname"),
        getURLParameter("userRole"),
        getURLParameter("userStatus")
      );
  }, [organization, gotoOrganizationUsersFilteredList]);

  const [
    items,
    isLoading,
    handlePageChange,
    fetchItems,
  ] = usePaginedList<User>([], fetchUsers, handleListUrlChange, [
    organization?.id,
  ]);
  const [authService, keycloak] = useAuth();

  const handleAddUser = useCallback(() => {
    organization && gotoUserAdd(organization.id);
  }, [organization, gotoUserAdd]);

  const onFilterChange = useCallback(
    (name?: string, role?: string, status?: string) => {
      organization &&
        gotoOrganizationUsersFilteredList(
          organization.id,
          name !== undefined ? name : getURLParameter("emailOrFirstnameOrLastname"),
          role !== undefined ? role : getURLParameter("userRole"),
          status !== undefined ? status : getURLParameter("userStatus")
        );
      fetchItems()
    },
    [organization, gotoOrganizationUsersFilteredList, fetchItems]
  );



  const upHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        onFilterChange();
      }
    },
    [onFilterChange]
  );

  useEffect(() => {
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keyup", upHandler);
    };
  }, [upHandler]);

  const handleRowClicked = useCallback(
    (row: UserRow) => {
      if (organization) {
        if (currentUser?.userId === row.id) {
          gotoUserView(row.id);
        } else {
          gotoOrganizationUser(organization.id, row.id);
        }
      }
    },
    [currentUser, organization, gotoUserView, gotoOrganizationUser]
  );

  const getUserLogo = useCallback((user: User) => {
    if (user.hasLogo) {
      return (<ProfileLogo
        data-tag="___react-data-table-allow-propagation___"
        alt="L'image de l'utilisateur"
        src={usersClient.queries.userLogoUrl(user.userId)}
        style={{ width: '50px', margin: '5px 10px 0px 0px' }}
      />)
    } else {
      return <CircleProfileIcon style={{ width: '50px', height:"50px", margin: '0px 10px 0px 0px' }}  data-tag="___react-data-table-allow-propagation___"/>
    }
  }, []);

  const getUserOptions = useCallback(
    (user: User): MenuItem[] => {
      return [
        {
          key: `viewUser-${user.userId}`,
          label: `${t("app_organizations_profil_users_list_see")} `,
          icon: <Visibility className={classes.icon} />,
          goto: () => {
            if (currentUser?.userId === user.userId) {
              gotoUserView(user.userId);
            } else {
              gotoOrganizationUser(user.details.organizationId, user.userId);
            }
          },
        },
        ...(authService.isAuthorized(
          routesAccessRights["/organizations/users/edit"],
          keycloak
        )
          ? [
            {
              key: `editUser-${user.userId}`,
              label: `${t("app_organizations_profil_users_list_edit")} `,
              icon: <Edit className={classes.icon} />,
              goto: () =>
                gotoUserEdit(user.details.organizationId, user.userId),
            },
            ...(!!currentUser && currentUser.userId !== user.userId
              ? [getChangeableStatus(user)]
              : []),
          ]
          : []),
      ];
    },
    [classes, usersClient, currentUser]
  );

  const getChangeableStatus = useCallback(
    (user: User): MenuItem => {
      switch (user.status.value) {
        case "created":
          return {
            key: `deleteUser-${user.userId}`,
            label: `${t("app_organizations_profil_users_list_remove")} `,
            icon: <DeleteForever className={classes.icon} />,
            goto: () => {
              setUserConcerned(user);
              setAskDeleteConfirmation(true);
            },
          };
        case "enabled":
          return {
            key: `disableUser-${user.userId}`,
            label: `${t("app_organizations_profil_users_list_deactivated")} `,
            icon: <Block className={classes.icon} />,
            goto: () => {
              setUserConcerned(user);
              setAskDisableConfirmation(true);
            },
          };
        case "disabled":
          return {
            key: `enableUser-${user.userId}`,
            label: `${t("app_organizations_profil_users_list_activate")} `,
            icon: <Done className={classes.icon} />,
            goto: () => {
              usersClient
                .enableUser(user.details.organizationId, user.userId)
                .then((event) => {
                  fetchItems();
                });
            },
          };
        default:
          return {
            key: `deleteUser-${user.userId}`,
            label: `${t("app_organizations_profil_users_list_remove")} `,
            icon: <DeleteForever className={classes.icon} />,
            goto: () => {
              setUserConcerned(user);
              setAskDeleteConfirmation(true);
            },
          };
      }
    },
    [classes]
  );

  const handleRemoveFilterName = useCallback(() => {
    onFilterChange("");
  }, [onFilterChange]);

  const handleRemoveFilterRole = useCallback(() => {
    onFilterChange(undefined, "");
  }, [onFilterChange]);

  const handleRemoveFilterStatus = useCallback(() => {
    onFilterChange(undefined, undefined, "");
  }, [onFilterChange]);

  const handleCloseDeleteConfirmation = useCallback(() => {
    setAskDeleteConfirmation(false);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (!userConcerned) {
      return;
    }
    await usersClient.deleteUser(
      userConcerned.details.organizationId,
      userConcerned.userId
    );
    fetchItems();

    setAskDeleteConfirmation(false);
  }, [userConcerned, fetchItems]);

  const handleCloseDisableConfirmation = useCallback(() => {
    setAskDisableConfirmation(false);
  }, []);

  const handleConfirmDisable = useCallback(async () => {
    if (!userConcerned) {
      return;
    }
    await usersClient.disableUser(
      userConcerned.details.organizationId,
      userConcerned.userId
    );
    fetchItems();

    setAskDisableConfirmation(false);
  }, [userConcerned, fetchItems]);

  if (!organization) {
    return <div />;
  }

  return (
    <Section
      actions={[
        <Box display="flex" flexWrap="wrap" className={classes.filterContainer}>
          <InputForm
            label=""
            placeHolder={t(
              "app_organizations_profil_users_first_last_name_email"
            )}
            inputType="textField"
            id="users-filters-role"
            onChange={(value) => {
              organization &&
                gotoOrganizationUsersFilteredList(
                  organization.id,
                  value,
                  getURLParameter("userRole"),
                  getURLParameter("userStatus")
                );
            }}
            value={getURLParameter("emailOrFirstnameOrLastname")}
            className={`${classes.filter}`}
            inputIcon={
              <Search
                className={classes.icon}
                onClick={() => onFilterChange()}
              />
            }
            onRemoveValue={handleRemoveFilterName}
          />
          <InputForm
            inputType="select"
            onChange={(value) => {
              onFilterChange(undefined, value);
            }}
            value={getURLParameter("userRole")}
            selectOptions={roleOptions}
            label=""
            placeHolder={t("app_organizations_profil_users_list_user_role")}
            className={`${classes.filter}`}
            id="users-filters-role"
            onRemoveValue={handleRemoveFilterRole}
          />
          <InputForm
            inputType="select"
            onChange={(value) => {
              onFilterChange(undefined, undefined, value);
            }}
            value={getURLParameter("userStatus")}
            selectOptions={statusOptions}
            label=""
            placeHolder={t("app_organizations_profil_users_list_user_status")}
            className={`${classes.filter}`}
            id="users-filters-status"
            onRemoveValue={handleRemoveFilterStatus}
          />
        </Box>,
        authService.isAuthorized(
          routesAccessRights["/organizations/users/add"],
          keycloak
        ) ? (
            <Button className={classes.buttonAddUser} onClick={handleAddUser}>
              {t("app_organizations_profil_users_list_add_user")}
            </Button>
          ) : (
            <> </>
          ),
      ]}
    >
      <UserTable
        users={items.list}
        onRowClicked={handleRowClicked}
        isLoading={isLoading}
        totalPages={Math.ceil(items.totalRows / items.perPage)}
        page={items.page + 1}
        handlePageChange={handlePageChange}
        getUserImage={getUserLogo}
        getUserOptions={getUserOptions}
      />
      <PopUpConfirmation
        title={t(
          "app_organizations_profil_users_list_confirmation_of_deleting_a_usere"
        )}
        onClose={handleCloseDeleteConfirmation}
        onConfirm={handleConfirmDelete}
        open={askDeleteConfirmation}
      >
        <Typography variant="body1">
          {t("app_organizations_profil_users_list_delete_user")}
        </Typography>
      </PopUpConfirmation>
      <PopUpConfirmation
        title={t(
          "app_organizations_profil_users_list_confirmation_of_deactivation_of_a_user"
        )}
        onClose={handleCloseDisableConfirmation}
        onConfirm={handleConfirmDisable}
        open={askDisableConfirmation}
      >
        <Typography variant="body1">
          {t("app_organizations_profil_users_list_once_deactivated")}
        </Typography>
      </PopUpConfirmation>
    </Section>
  );
};

export default withConnect(OrganizationUsersPage);
