import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  DeleteButton,
  EditButton,
  PopUpConfirmation,
  Section,
} from "components";
import { Organization, OrganizationStateMachine } from "api/organization";
import OrganizationForm from "app/components/OrganizationDetailsForm";
import withConnect from "./withConnect";
import { useParams } from "react-router-dom";
import { useAuth } from "auth/useAuth";
import { routesAccessRights } from "auth/RoutesAccessRightsMap";
import { useTranslation } from "react-i18next";
import { PaymentInfoForm } from "../../../../components/PaymentInfoForm";
import { OrganizationDetailsFormState } from "../../../../components/OrganizationDetailsForm/model";
import { PaymentInfoFormState } from "../../../../components/PaymentInfoForm/model";
import {
  buildOrganizationForm,
  defaultOrganizationFormState,
  OrganizationFormState,
} from "./model";
import { isOrganizationFormValid } from "./validation";
import usePrevious from "app/hooks/usePrevious/usePrevious";

const useStyles = makeStyles(() => ({
  button: {
    position: "relative",
    marginLeft: 50,
  },
  title: {
    borderBottom: "2px solid #98a5ab33",
    padding: "20px 0",
    marginBottom: "30px",
  },
  text: {
    color: "#353945",
    fontWeight: 600,
    fontSize: "19px",
    lineHeight: "23px",
  },
  paymentInfoSection: {
    marginTop: 30,
  },
}));

interface GeneralInformationsProps {
  organization: Organization | null;
  gotoOrganizationEditGeneralInformations: (organizationId: string) => void;
  gotoOrganizationEditPaymentInformations: (organizationId: string) => void;
  gotoOrganizationsView: (organizationId: string) => void;
  updateOrganization: (
    organizationId: string,
    form: OrganizationFormState,
    updatePayment: boolean
  ) => void;
  deleteOrganization: (organizationId: string) => void;
}

const GeneralInformations = (props: GeneralInformationsProps) => {
  const {
    organization,
    gotoOrganizationsView,
    updateOrganization,
    deleteOrganization,
    gotoOrganizationEditGeneralInformations,
    gotoOrganizationEditPaymentInformations,
  } = props;
  const classes = useStyles();
  const { mode } = useParams();
  const { t } = useTranslation();
  const isViewMode = mode === "view";

  const [organizationForm, setOrganizationForm] = useState<
    OrganizationFormState
  >(defaultOrganizationFormState);

  const [authService, keycloak] = useAuth();
  const [deleteConfirmation, openDeleteConfirmation] = useState(false);
  const [edit, setEdit] = useState({
    generalInfos: false,
    paymentInfos: false,
  });

  const [hasPaymentChanged, setHasPaymentChanged] = useState(false);

  const previousMode = usePrevious(mode);
  useEffect(() => {
    if (
      previousMode !== undefined &&
      !isViewMode &&
      previousMode !== "view" &&
      organization
    ) {
      const organizationForm = buildOrganizationForm(organization);
      setOrganizationForm(organizationForm);
    }

    if (!isViewMode) {
      setEdit({
        generalInfos: mode === "editGeneralInformations",
        paymentInfos: mode == "editPaymentInformations",
      });
    }
  }, [mode]);

  useEffect(() => {
    if (organization) {
      const organizationForm = buildOrganizationForm(organization);
      setOrganizationForm(organizationForm);
    }
  }, [organization]);

  const showPaymentForm = useMemo((): boolean => {
    return (
      !!organization &&
      organization.organizationType.type === "Operator" &&
      authService.isAuthorized(routesAccessRights["/bonus"], keycloak)
    );
  }, [organization, authService, keycloak]);

  const setDetails = useCallback(
    (details: OrganizationDetailsFormState): void => {
      setOrganizationForm({
        ...organizationForm,
        details: details,
      });
    },
    [setOrganizationForm, organizationForm]
  );

  const onSaveOrganization = useCallback(async () => {
    if (!organization) return;
    if (edit.generalInfos) {
      const organizationValidity = isOrganizationFormValid(
        false,
        true,
        organizationForm
      );
      if (!organizationValidity.isValid) {
        setOrganizationForm({
          ...organizationForm,
          details: {
            ...organizationForm.details,
            validity: organizationValidity.details,
          },
        });
        return;
      }
    } else {
      const organizationValidity = isOrganizationFormValid(
        true,
        false,
        organizationForm
      );
      if (!organizationValidity.isValid) {
        setOrganizationForm({
          ...organizationForm,
          payment: {
            ...organizationForm.payment,
            validity: organizationValidity.payment,
          },
        });
        return;
      }
    }
    updateOrganization(organization.id, organizationForm, hasPaymentChanged);
    gotoOrganizationsView(organization.id);
  }, [
    edit,
    organizationForm,
    organization,
    hasPaymentChanged,
    isOrganizationFormValid,
    updateOrganization,
    gotoOrganizationsView,
  ]);

  const actions = useMemo(() => {
    const actionReturn = [];
    if (!organization) return [];
    if (isViewMode || !edit.generalInfos) {
      if (isViewMode) {
        actionReturn.push(
          <EditButton
            key={0}
            className={classes.button}
            onClick={() => {
              gotoOrganizationEditGeneralInformations(organization?.id);
            }}
            label={t("app_organization_action_update")}
          />
        );
        if (
          OrganizationStateMachine.getAvailableActions(
            organization.status.value
          ).canDelete
        ) {
          actionReturn.push(
            <>
              <PopUpConfirmation
                title={t("app_organization_action_delete_confirm")}
                onClose={() => openDeleteConfirmation(false)}
                onConfirm={() => deleteOrganization(organization.id)}
                open={deleteConfirmation}
              />
              <DeleteButton
                style={{ marginLeft: "15px" }}
                onClick={() => openDeleteConfirmation(true)}
              >
                {t("app_organization_action_remove")}
              </DeleteButton>
            </>
          );
        }
      }
    } else {
      actionReturn.push(
        <Button key={0} className={classes.button} onClick={onSaveOrganization}>
          {t("app_organization_action_validate")}
        </Button>
      );
    }
    return actionReturn;
  }, [
    organization,
    deleteConfirmation,
    isViewMode,
    edit.generalInfos,
    onSaveOrganization,
    openDeleteConfirmation,
    deleteOrganization,
    openDeleteConfirmation,
    gotoOrganizationEditGeneralInformations,
  ]);

  const setPayment = useCallback(
    (payment: PaymentInfoFormState): void => {
      setOrganizationForm({
        ...organizationForm,
        payment: payment,
      });
      setHasPaymentChanged(true);
    },
    [organizationForm, setOrganizationForm]
  );

  if (organization == null) {
    return <div />;
  }

  return (
    <Section actions={[]}>
      <div className={classes.title}>
        <span className={classes.text}>
          {t("app_organizations_profil_users_general_info")}
        </span>
        {actions}
      </div>
      <OrganizationForm
        organization={organizationForm.details}
        onOrganizationChange={isViewMode ? () => {} : setDetails}
        readonly={isViewMode || !edit.generalInfos}
        edition={edit.generalInfos}
      />
      {showPaymentForm && (
        <>
          <div className={`${classes.title} ${classes.paymentInfoSection}`}>
            <span className={classes.text}>
              {" "}
              {t("app_organizations_profil_users_payment_info")}{" "}
            </span>
            {isViewMode || !edit.paymentInfos ? (
              <EditButton
                key={0}
                className={classes.button}
                onClick={() => {
                  gotoOrganizationEditPaymentInformations(organization?.id);
                }}
                label={t("app_organization_action_update")}
              />
            ) : (
              <Button
                key={0}
                className={classes.button}
                onClick={onSaveOrganization}
              >
                {t("app_organization_action_validate")}
              </Button>
            )}
          </div>
          <PaymentInfoForm
            onDetailsChange={
              isViewMode || !edit.paymentInfos ? () => {} : setPayment
            }
            payment={organizationForm.payment}
            readonly={isViewMode || !edit.paymentInfos}
          />
        </>
      )}
    </Section>
  );
};

export default withConnect(GeneralInformations);
