import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory, History } from "history";
import { organizationReducer } from "./organization/organization.reducer";
import thunk from "redux-thunk";
import { titleReducer } from "./title/title.reducer";
import { alertReducer } from "./alert/alert.reducer";
import { profileReducer } from "./profile/profile.reducer";
import { userReducer } from "./user/user.reducer";
import { projectReducer } from "./project/project.reducer";
import { reportReducer } from "./report/report.reducer";
import { bonusReducer } from "./bonus/bonus.reducer";
import { globalReducer } from "./global/global.reducer";
import { notificationReducer } from "./notification/notification.reducer";

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    organization: organizationReducer,
    global: globalReducer,
    user: userReducer,
    title: titleReducer,
    alert: alertReducer,
    profile: profileReducer,
    project: projectReducer,
    report: reportReducer,
    bonus: bonusReducer,
    notification: notificationReducer,
  });

export const history = createBrowserHistory();

const reducer = createRootReducer(history);

export type State = ReturnType<typeof reducer>;

function configureStore(preloadedState?: any) {
  const composeEnhancer: typeof compose =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducer,
    preloadedState,
    composeEnhancer(applyMiddleware(routerMiddleware(history), thunk))
  );

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    // module.hot.accept('./reducers', () => {
    //     store.replaceReducer(createRootReducer(history));
    // });
  }

  return store;
}

export let store = configureStore();

export default store;
