import React, { useRef, useEffect, forwardRef } from 'react'
import { Box, Typography } from '@material-ui/core'
import {
  Notification as NotifComponent,
  NotificationSeverity
} from './Notification'
import { ComponentLoading } from '../Loading'
import { NotificationData } from 'web-admin/src/app/components/NotificationsContainer/NotificationData'

interface NotificationListProps {
  notificationClassName?: string
  lastElementId: string
  pageNumber: number
  total: number
  notifications: NotificationData[]
  loading: boolean
  onPageNumberChange: (page: number) => void
  onPinnedNotif: (notif: NotificationData, index: number) => void
  onUnPinnedNotif: (notif: NotificationData, index: number) => void
  onRemoveNotif: (notif: NotificationData, index: number) => void
  onClickNotif: (notif: NotificationData) => void
}

export const NotificationList = forwardRef<
  HTMLDivElement,
  NotificationListProps
>((props, ref) => {
  const {
    notificationClassName,
    lastElementId,
    loading,
    notifications,
    pageNumber,
    total,
    onPageNumberChange,
    onPinnedNotif,
    onUnPinnedNotif,
    onRemoveNotif,
    onClickNotif
  } = props

  const observer = useRef<IntersectionObserver>()
  useEffect(() => {
    if (!loading) {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && total > pageNumber * 10 && !loading) {
          onPageNumberChange(pageNumber + 1)
        }
      })
      const element = document.getElementById(lastElementId)
      element && observer.current.observe(element)
    }
    return () => {
      if (observer.current) observer.current.disconnect()
    }
  }, [loading, total, pageNumber])

  return (
    <div ref={ref}>
      {notifications &&
        notifications
          .filter((notification) => notification.show)
          .map((notif, index) => {
            return (
              <NotifComponent
                className={notificationClassName}
                key={notif.id}
                icon={notif.icon}
                date={notif.creationDate}
                onClick={() => onClickNotif(notif)}
                isPinned={notif.pinned}
                onPin={
                  notif.changeable
                    ? () => onPinnedNotif(notif, index)
                    : undefined
                }
                onUnpin={
                  notif.changeable
                    ? () => onUnPinnedNotif(notif, index)
                    : undefined
                }
                onRemove={
                  notif.changeable
                    ? () => onRemoveNotif(notif, index)
                    : undefined
                }
                severity={notif.severity as NotificationSeverity}
                unSeen={notif.unSeen}
              >
                {notif.label}
              </NotifComponent>
            )
          })}
      {loading ? (
        <ComponentLoading style={{ marginTop: '50px' }} />
      ) : (
        <Box display='flex' justifyContent='center'>
          <Typography
            id={lastElementId}
            variant='body2'
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              marginTop: '50px',
              marginLeft: '15px',
              marginRight: '15px'
            }}
          >
            {total > pageNumber * 10
              ? 'Voir plus'
              : 'Pas de notification supplémentaire'}
          </Typography>
        </Box>
      )}
    </div>
  )
})
