import { BonusGroup } from "api/bonusGroup";
import { createReducer } from "deox";
import * as R from "ramda";
import { actions } from "./bonus.actions";

export interface BonusState {
  bonus: BonusGroup | null;
}
export const initialState: BonusState = {
  bonus: null,
};

export type BonusStateTransformer = (state: BonusState) => BonusState;

const setBonus = (bonus: BonusGroup | null): BonusStateTransformer => {
  return R.assoc("bonus", bonus);
};

export const bonusReducer = createReducer(initialState, (handleAction) => [
  handleAction(actions.createBonus, (state: BonusState, action) =>
    setBonus(action.payload.bonus)(state)
  ),

  handleAction(actions.fetchedBonus, (state: BonusState, action) =>
    setBonus(action.payload.bonus)(state)
  ),
  handleAction(actions.updatedBonus, (state: BonusState, action) =>
    setBonus(action.payload.bonus)(state)
  ),
  handleAction(actions.resetBonus, (state: BonusState, action) =>
    setBonus(null)(state)
  ),
]);
