import { Badge, IconButton } from '@material-ui/core'
import { Notifications } from '@material-ui/icons'
import React from 'react'

interface NotificationButtonProps {
    notifNumber?: number
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    buttonClassName?: string
    buttonStyle?: React.CSSProperties
    iconClassName?: string
}

export const NotificationButton = (props: NotificationButtonProps) => {
    const {buttonClassName, buttonStyle, iconClassName, notifNumber, onClick} = props
    return (
        <IconButton
        onClick={onClick}
        style={buttonStyle}
        className={buttonClassName}
      >
        <Badge badgeContent={notifNumber} color="error">
          <Notifications className={iconClassName} />
        </Badge>
      </IconButton>
    )
}
