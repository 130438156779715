import { city as citynotif } from "plateform-notification-domain";
import { city as cityPlat } from "plateform-plateform-commons";
import { getNotificationPage } from "./query/getNotificationPage";
import { pinNotification } from "./command/pinNotification";
import { unpinNotification } from "./command/unpinNotification";
import { archiveNotification } from "./command/archiveNotification";
import { markAllAsSeen } from "./command/markAllAsSeen";
import { getUnseenNumber } from "./query/getUnseenNumber";
import { getNotification } from "./query/getNotification";

export interface NotificationPage
  extends cityPlat.colisactiv.platform.commons.Page<Notification> { }

export interface Notification
  extends citynotif.colisactiv.platform.notification.domain.Notification { }

export interface NotificationType
  extends citynotif.colisactiv.platform.notification.domain.NotificationType { }

export const NotificationTypeValues = new citynotif.colisactiv.platform.notification.domain.NotificationTypeValues();

export interface NotificationRef
  extends citynotif.colisactiv.platform.notification.domain
    .NotificationObjRef { }

export class NotificationStatusValues
  extends citynotif.colisactiv.platform.notification.domain
    .NotificationStatusValues { }

export const notificationClient = {
  pinNotification: pinNotification,
  unpinNotification: unpinNotification,
  archiveNotification: archiveNotification,
  markAllAsSeen: markAllAsSeen,
  query: {
    getNotificationPage: getNotificationPage,
    getNotification: getNotification,
    getUnseenNumber: getUnseenNumber
  },
};
