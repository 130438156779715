import { createSelector } from "reselect";
import { State } from "..";

const getTitleState = (state: State) => state.title;

export const getHeadBar = createSelector(
  [getTitleState],
  (state) => state.headBar
);

export const selectors = {
  headBar: getHeadBar,
};
