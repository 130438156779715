import { Box } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputForm } from "components";
import { useTranslation } from "react-i18next";
import { PaymentInfoFormState } from "./model";

const useStyles = makeStyles(() => ({
  select: {
    width: "100%",
    marginTop: "30px",
  },
  input: {
    width: "70%",
  },
  text: {
    width: "550px !important",
    alignContent: "center",
    marginTop: "5px",
  },
  inputForm: {
    marginTop: "30px",
    width: "550px !important",
  },
}));

interface PaymentFromProps {
  payment: PaymentInfoFormState;
  onDetailsChange?: (payment: PaymentInfoFormState) => void;
  readonly: boolean;
}
export const NO_ERROR = undefined;

export const PaymentInfoForm = (props: PaymentFromProps) => {
  const { payment, onDetailsChange, readonly } = props;

  const onIbanChange = (iban: string) => {
    if (onDetailsChange) {
      onDetailsChange({
        ...payment,
        iban: iban,
        validity: { ...payment.validity, iban: NO_ERROR },
      });
    }
  };

  const onBicChange = (bic: string) => {
    if (onDetailsChange) {
      onDetailsChange({
        ...payment,
        bic: bic,
        validity: { ...payment.validity, bic: NO_ERROR },
      });
    }
  };

  const onTvaChange = (tva: string) => {
    if (onDetailsChange) {
      onDetailsChange({
        ...payment,
        tva: tva,
        validity: { ...payment.validity, tva: NO_ERROR },
      });
    }
  };

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <InputForm
        value={
          payment.iban === undefined && readonly
            ? `${t("app_payment_info_form_no_information_recorded")} `
            : payment.iban
        }
        onChange={onIbanChange}
        id="organisation-add-payment-iban"
        error={payment.validity.iban !== NO_ERROR}
        errorMessage={payment.validity.iban}
        inputType="textField"
        label={t("app_payment_info_form_IBAN")}
        placeHolder="Ex: FR76 4007 7654 0000 6578 3245 378"
        inputClassName={readonly ? classes.text : classes.input}
        className={classes.inputForm}
        readonly={readonly}
      />
      <InputForm
        value={
          payment.bic === undefined && readonly
            ? `${t("app_payment_info_form_no_information_recorded")} `
            : payment.bic
        }
        onChange={onBicChange}
        id="organisation-add-payment-bic"
        error={payment.validity.bic !== NO_ERROR}
        errorMessage={payment.validity.bic}
        inputType="textField"
        label={t("app_payment_info_form_BIC")}
        placeHolder="Ex: AGRIMQMX"
        inputClassName={readonly ? classes.text : classes.input}
        className={classes.inputForm}
        readonly={readonly}
      />
      <InputForm
        value={
          payment.tva === undefined && readonly
            ? `${t("app_payment_info_form_no_information_recorded")} `
            : payment.tva
        }
        onChange={onTvaChange}
        id="organisation-add-payment-tva"
        error={payment.validity.tva !== NO_ERROR}
        errorMessage={payment.validity.tva}
        inputType="textField"
        label={t("app_payment_info_form_TVA")}
        placeHolder="Ex: FR 00 449565217"
        inputClassName={readonly ? classes.text : classes.input}
        className={classes.inputForm}
        readonly={readonly}
      />
    </Box>
  );
};
