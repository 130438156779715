import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Territory } from "api/geoZone";
import { OrganizationRef } from "api/organization";
import { User } from "api/organization/users";
import { CarrierReport } from "api/report";
import { ReportStatusUtils } from "api/report/model/reportStatus";
import ReportForm from "app/components/ReportForm";
import {
  CarrierReportFormState,
  defaultCarrierReportFormState,
} from "app/components/ReportForm/model";
import { ReportValidation } from "app/components/ReportForm/validation";
import { useAuth } from "auth/useAuth";
import {
  Button,
  CancelButton,
  FormButtons,
  HeadBarProps,
  HeadBarView,
  PopUpConfirmation,
  SectionForm,
} from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import withConnect from "./withConnect";

const useStyles = makeStyles(() => ({
  button: {
    margin: "0 10px",
  },
  form: {
    position: "relative",
    paddingBottom: 100,
    marginLeft: "0px",
  },
  buttonDraft: {
    background: "#61D4A6",
    "&:hover": {
      background: "#61D4A6 !important",
      opacity: "0.9",
    },
  },
}));
interface ReportCreatePageFormProps {
  setHeadBar: (title: HeadBarProps) => void;
  currentUser: User | null;
  carriers: Map<string, OrganizationRef>;
  currentReport: CarrierReport | null;
  gotoReports: (page: number, size: number) => void;
  fetchReport: (reportId: string) => void;
  updateCarrierReport: (form: CarrierReportFormState) => void;
  fetchTerritories: () => void;
  availableTerritories: Territory[];
}

const ReportEditView = (props: ReportCreatePageFormProps) => {
  const classes = useStyles();
  const {
    setHeadBar,
    currentUser,
    carriers,
    currentReport,
    gotoReports,
    fetchReport,
    updateCarrierReport,
    fetchTerritories,
    availableTerritories,
  } = props;
  const [carrierReportState, setCarrierReportState] = useState<
    CarrierReportFormState
  >(defaultCarrierReportFormState);
  const [authService, keycloak] = useAuth();

  const isCarrier = useMemo(
    () =>
      authService.isCarrier(currentUser, keycloak) &&
      !authService.isColisActivAdmin(currentUser, keycloak),
    [authService, currentUser, keycloak]
  );

  const { reportId } = useParams();
  const { t } = useTranslation();

  const [openValidationConfirmation, setOpenValidationConfirmation] = useState(
    false
  );

  useEffect(() => {
    if (currentReport && carriers.size > 0) {
      const carrier = carriers.get(currentReport.carrierId);
      if (carrier)
        setHeadBar({
          title: ` ${t("app_reports_view_monthly_report")} ${
            carrier.displayName
          }`,
          component: (
            <HeadBarView
              title={t("app_reports_view_monthly_report")}
              status={ReportStatusUtils.getLabelStyleStatusTags(
                currentReport.status,
                isCarrier
              )}
            />
          ),
        });
      else
        setHeadBar({
          title: `${t("app_reports_view_monthly_report")} `,
          component: (
            <HeadBarView
              title={t("app_reports_view_monthly_report")}
              status={ReportStatusUtils.getLabelStyleStatusTags(
                currentReport.status,
                isCarrier
              )}
            />
          ),
        });
    }
  }, [currentReport, carriers, isCarrier]);

  useEffect(() => {
    fetchReport(reportId);
  }, [reportId]);

  useEffect(() => {
    if (currentReport) {
      setCarrierReportState({
        ...carrierReportState,
        carrierReport: { ...currentReport, status: { value: "" } },
      });
    }
  }, [currentReport]);

  useEffect(() => {
    if (
      carrierReportState.carrierReport.status.value === "PUBLISHED" ||
      carrierReportState.carrierReport.status.value === "CREATED"
    ) {
      updateCarrierReport(carrierReportState);
    }
  }, [carrierReportState.carrierReport.status.value]);

  useEffect(() => {
    fetchTerritories();
  }, []);

  const handleCarrierReportCreation = async () => {
    setCarrierReportState({
      ...carrierReportState,
      carrierReport: {
        ...carrierReportState.carrierReport,
        status: { value: "PUBLISHED" },
      },
    });
  };

  const handleCarrierReportScarmblingCreation = async () => {
    setCarrierReportState({
      ...carrierReportState,
      carrierReport: {
        ...carrierReportState.carrierReport,
        status: { value: "CREATED" },
      },
    });
  };

  const onToggleConfirmation = useCallback(
    () =>
      setOpenValidationConfirmation((open) => {
        const [
          isValid,
          carrierReportValidation,
        ] = ReportValidation.getFormValidation(carrierReportState);
        if (!isValid && !open) {
          setCarrierReportState({
            ...carrierReportState,
            validity: carrierReportValidation,
          });
          return false;
        } else {
          return !open;
        }
      }),
    [carrierReportState]
  );

  return (
    <SectionForm>
      <ReportForm
        currentUser={currentUser}
        carriers={carriers}
        carrierReportState={carrierReportState}
        onCarrierReportChange={setCarrierReportState}
        mode={"edit"}
        availableTerritories={availableTerritories}
      />
      <FormButtons>
        <CancelButton onClick={() => gotoReports(0, 10)}>
          {t("app_reports_edit_cancel")}
        </CancelButton>
        {currentReport &&
          !ReportStatusUtils.isInvalid(currentReport.status.value) && (
            <Button
              className={classes.buttonDraft}
              onClick={handleCarrierReportScarmblingCreation}
            >
              {t("app_reports_save_as_a_draft")}
            </Button>
          )}
        <Button className={classes.button} onClick={onToggleConfirmation}>
          {t("app_reports_edit_submit_for_validation")}
        </Button>
        <PopUpConfirmation
          open={openValidationConfirmation}
          title={t("app_reports_edit_submit_for_validation_confimation_title")}
          onClose={onToggleConfirmation}
          onConfirm={handleCarrierReportCreation}
        >
          <Typography variant="body1">
            {t("app_reports_edit_submit_for_validation_confimation_body")}
          </Typography>
        </PopUpConfirmation>
      </FormButtons>
    </SectionForm>
  );
};

export default withConnect(ReportEditView);
