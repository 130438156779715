import { Dispatch } from "redux";
import { actions } from "./profile.actions";
import { State } from "../index";
import { usersClient } from "api/organization/users";
import { keycloakClient } from "api/keyclaok";

const fetchProfile = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const userId = keycloakClient.token.getUserId();
    const organizationId = keycloakClient.token.getOrganizationId();
    if (!!organizationId) {
      try {
        dispatch(actions.fetchProfile(userId));

        const user = await usersClient.queries.fetchUser(
          organizationId,
          userId
        );
        dispatch(actions.fetchedProfile(user ?? null));
      } catch (error) {
        // dispatch(addTodo.error(error))
      }
    }
  };
};

export const queries = {
  fetchProfile: fetchProfile,
};
