import { Box, makeStyles, Typography, withStyles } from '@material-ui/core'
import { MenuItem } from '@smartb/archetypes-ui-layout'
import { TFunction } from 'i18next'
import { city, city as cityOrga } from 'plateform-organization-domain'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MoreOptions } from '../../Component/MoreOptions'
import { StatusTags } from '../../Component/StatusTags'
import { Table } from '../../Component/Table'

export interface Organization
  extends cityOrga.colisactiv.organization.model.Organization {}

export interface OrganizationRow {
  id: string
  namePrimary: string
  organizationType: string
  address: string
  statusPrimary: boolean
  statusSecondary: Date
  imgSource?: JSX.Element
  actions: MenuItem[]
}

interface OrganizationTableProps {
  organizations: city.colisactiv.organization.model.Organization[]
  onRowClicked?: (row: OrganizationRow) => void
  totalPages: number
  page: number
  handlePageChange: (page: number) => void
  isLoading?: boolean
  getOrganizationImage?: (organization: Organization) => JSX.Element
  getActions: (organization: Organization) => MenuItem[]
}

const PrimaryTypography = withStyles(() => ({
  root: {
    color: '#071017 !important',
    fontSize: '12px  !important',
    fontWeight: 500
  }
}))(Typography)

const useStyles = makeStyles(() => ({
  container: {
    '& .rdt_TableRow .rdt_TableCell:nth-child(2n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(2n) div': {
      textAlign: 'left'
    },
    '& .rdt_TableCol:nth-child(2n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(3n)': {
      minWidth: '200px'
    },
    '& .rdt_TableCol:nth-child(3n)': {
      minWidth: '200px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(4n)': {
      minWidth: '120px',
      maxWidth: '150px'
    },
    '& .rdt_TableCol:nth-child(4n)': {
      minWidth: '120px',
      maxWidth: '150px'
    },
    '& .rdt_TableRow .rdt_TableCell:last-child': {
      minWidth: '100px',
      maxWidth: '100px'
    },
    '& .rdt_TableCol:last-child': {
      minWidth: '100px',
      maxWidth: '100px'
    },
    '& .rdt_TableRow .rdt_TableCell:first-child': {
      minWidth: '250px'
    },
    '& .rdt_TableCol:first-child': {
      minWidth: '250px'
    }
  }
}))

const propsAreEqual = (
  prevProps: OrganizationTableProps,
  nextProps: OrganizationTableProps
) => {
  return (
    R.equals(prevProps.organizations, nextProps.organizations) &&
    R.equals(prevProps.handlePageChange, nextProps.handlePageChange) &&
    R.equals(prevProps.isLoading, nextProps.isLoading) &&
    R.equals(prevProps.totalPages, nextProps.totalPages) &&
    R.equals(prevProps.page, nextProps.page) &&
    R.equals(prevProps.onRowClicked, nextProps.onRowClicked) &&
    R.equals(prevProps.getOrganizationImage, nextProps.getOrganizationImage)
  )
}

export const OrganizationTable = React.memo((props: OrganizationTableProps) => {
  const {
    organizations,
    onRowClicked,
    handlePageChange,
    page,
    totalPages,
    getOrganizationImage,
    isLoading = false,
    getActions
  } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const data: OrganizationRow[] = organizations.map(
    (orga): OrganizationRow => {
      return {
        id: orga.id,
        namePrimary: orga.details.name,
        organizationType: getFrenchType(orga.organizationType.type, t),
        address: `${orga.details.address.street}, ${orga.details.address.zipCode} ${orga.details.address.city}`,
        statusPrimary: true,
        imgSource: getOrganizationImage && getOrganizationImage(orga),
        statusSecondary: new Date(),
        actions: getActions(orga)
      }
    }
  )

  const columns = [
    {
      name: 'Organisation',
      cell: (row: OrganizationRow) => (
        <Box
          display='flex'
          justifyContent='space-around'
          alignItems='center'
          data-tag='___react-data-table-allow-propagation___'
        >
          <Box
            width='50px'
            data-tag='___react-data-table-allow-propagation___'
            marginRight='10px'
          >
            {row.imgSource}
          </Box>
          <Typography
            data-tag='___react-data-table-allow-propagation___'
            align='left'
            style={{ fontSize: '14px', fontWeight: 600 }}
          >
            {row.namePrimary}
          </Typography>
        </Box>
      )
    },
    {
      name: 'Type',
      cell: (row: OrganizationRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.organizationType}
        </PrimaryTypography>
      )
    },
    {
      name: 'Adresse',
      cell: (row: OrganizationRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.address}
        </PrimaryTypography>
      )
    },
    {
      name: 'Statut',
      cell: (row: OrganizationRow) => (
        <Box
          data-tag='___react-data-table-allow-propagation___'
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
        >
          <StatusTags label='Active' color='green' />
        </Box>
      )
    },
    {
      name: 'Action',
      cell: (row: OrganizationRow) => (
        <MoreOptions handleClose={() => {}} options={row.actions} />
      )
    }
  ]

  return (
    <Table<OrganizationRow>
      data={data}
      className={classes.container}
      columns={columns}
      onRowClicked={onRowClicked}
      handlePageChange={handlePageChange}
      totalPages={totalPages}
      page={page}
      isLoading={isLoading}
      noDataMessage={t('app_no_data_organization_table')}
    />
  )
}, propsAreEqual)

const getFrenchType = (type: string, t: TFunction): string => {
  switch (type) {
    case 'Carrier':
      return `${t('app_domain_organization_carrier')} `
    case 'Operator':
      return `${t('app_domain_organization_operator')} `
    case 'Funder':
    case 'FunderCee':
      return `${t('app_domain_organization_financeur')} `
    default:
      return `${t('app_domain_organization_Organisation')} `
  }
}
