import React, { Fragment } from 'react'
import { HeadlineBox } from '../HeadlineBox'


interface ProfileMenuProps {
  name: string
  role: string
  img: JSX.Element
}

export const ProfileMenu = (props: ProfileMenuProps) => {
  const { name, role, img } = props
  return (
    <Fragment>
      {img}
      <HeadlineBox header={name}>{role}</HeadlineBox>
    </Fragment>
  )
}
