import React from 'react'
import { ReactComponent } from './building.svg'
import { MergeReactElementProps } from '../types'

interface ApartmentIconProps {
  color?: string
}

type Props = MergeReactElementProps<'svg', ApartmentIconProps>

export const ApartmentIcon = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { color = 'white' } = props
    return <ReactComponent stroke={color} fill="none" ref={ref} {...props} />
  }
)
