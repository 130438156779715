import React, { useState, Fragment, useCallback } from 'react'
import {
  ListItemIcon,
  makeStyles,
  MenuItem,
  Typography
} from '@material-ui/core'
import { MenuItemAction } from './MoreOptions'
import { PopUpConfirmation } from '../PopUp'

const useStyles = makeStyles(() => ({
  icon: {
    minWidth: '35px'
  },
  menuItem: {
    padding: '6px 15px'
  }
}))

export interface ConfirmationMenuItemButtonProps {
  item: MenuItemAction
}

export const MenuItemActionButton = (
  props: ConfirmationMenuItemButtonProps
) => {
  const { item } = props
  const [open, setOpen] = useState<boolean>(false)
  const classes = useStyles()

  const onClose = useCallback(() => setOpen(false), [])

  const onClick = useCallback(() => {
    !!item.confirmMsg ? setOpen(true) : item.goto && item.goto()
    close()
  }, [item.confirmMsg])

  return (
    <Fragment>
      <MenuItem key={item.key} onClick={onClick} className={classes.menuItem}>
        {item.icon && (
          <ListItemIcon classes={{ root: classes.icon }}>
            {item.icon}
          </ListItemIcon>
        )}
        <Typography variant='subtitle2'>{item.label}</Typography>
      </MenuItem>
      {!!item.confirmMsg && (
        <PopUpConfirmation
          title={item.confirmMsg}
          onClose={onClose}
          onConfirm={item.goto}
          open={open}
        />
      )}
    </Fragment>
  )
}
