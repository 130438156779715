import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(() => ({
  tagWidth: {
    width: 'fit-content',
    padding: '0px 7px',
    '& .MuiChip-label': {
      fontWeight: 'bold'
    }
  },
  blue: {
    background: '#c9daf89e',
    border: '1px solid #3c78d8',
    '& .MuiChip-label': {
      color: '#3c78d8'
    }
  },
  green: {
    background: '#d9ead375',
    border: '1px solid #5ea73e',
    '& .MuiChip-label': {
      color: '#5ea73e'
    }
  },
  lightGreen: {
    background: '#00C8753D',
    border: '1px solid #00C875',
    '& .MuiChip-label': {
      color: '#00C875'
    }
  },
  orange: {
    background: '#ffab4030',
    border: '1px solid #ffab40',
    '& .MuiChip-label': {
      color: '#ffab40'
    }
  },
  purple: {
    background: '#d9d2e9af',
    border: '1px solid #674ea7',
    '& .MuiChip-label': {
      color: '#674ea7'
    }
  },
  red: {
    background: '#f4cccca2',
    border: '1px solid #cc0000',
    '& .MuiChip-label': {
      color: '#cc0000'
    }
  },
  grey: {
    background: '#eeeeee92',
    border: '1px solid #999999',
    '& .MuiChip-label': {
      color: '#999999'
    }
  },
  yellow: {
    background: '#FBFDA566',
    border: '1px solid #CAB641',
    '& .MuiChip-label': {
      color: '#CAB641'
    }
  },
  light_grey: {
    background: '#fcfcfc',
    border: '1px solid #f6f6f6',
    '& .MuiChip-label': {
      color: '#f6f6f6'
    }
  },
  brown: {
    background: '#f2ad5c',
    border: '1px solid #825a2c',
    '& .MuiChip-label': {
      color: '#825a2c'
    }
  }
}))
export type Colors =
  | 'blue'
  | 'green'
  | 'orange'
  | 'purple'
  | 'red'
  | 'grey'
  | 'yellow'
  | 'light_grey'
  | 'brown'
  | 'lightGreen'

export interface StatusTagsFormProps {
  label: string
  color: Colors
  className?: string
}

export const StatusTags = (props: StatusTagsFormProps) => {
  const { label, color, className } = props
  const classes = useStyles()
  const returnClassColor = (color: string): any => {
    switch (color) {
      case 'blue':
        return classes.blue
      case 'green':
        return classes.green
      case 'lightGreen':
        return classes.lightGreen
      case 'orange':
        return classes.orange
      case 'purple':
        return classes.purple
      case 'red':
        return classes.red
      case 'grey':
        return classes.grey
      case 'yellow':
        return classes.yellow
      case 'light_grey':
        return classes.light_grey
      case 'brown':
        return classes.brown
      default:
        break
    }
  }
  return (
    <Chip
      label={label}
      className={`${returnClassColor(color)} ${classes.tagWidth} ${className}`}
    />
  )
}
