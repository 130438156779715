import React from 'react'
import { ReactComponent } from './icon-pickup.svg'
import { MergeReactElementProps } from '../types'

interface DashboardProps {
  color?: string
}

type Props = MergeReactElementProps<'svg', DashboardProps>

export const Pickup = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { color = 'white' } = props
    return <ReactComponent stroke={color} fill={color} ref={ref} {...props} />
  }
)
