import { buildCarrierReportApiUrl } from "../../configuration";
import { CarrierReport, DateInterval, Page } from "..";
import { http } from "../../http";

export const fetchReportsPage = (
  page: number,
  size: number,
  period?: DateInterval,
  carrierId?: string,
  territoryCode?: string,
  status?: string
): Promise<Page<CarrierReport>> => {
  return http.get(
    buildCarrierReportApiUrl(
      `/carrierReports?page=${page}&size=${size}${
        period ? `&startDate=${period.startDate}&endDate=${period.endDate}` : ""
      }${carrierId ? `&carrierId=${carrierId}` : ""}${
        territoryCode ? `&territoryCode=${territoryCode}` : ""
      }${status ? `&status=${status}` : ""}`
    ),
    "la récupération des rapports n'a pas pu être effectuée"
  );
};
