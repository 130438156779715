import React, { useEffect } from "react";
import { HeadBarProps } from "components";
import withConnect from "./withConnect";
import GeneralInformations from "./GeneralInformations";
import { useTranslation } from "react-i18next";

interface OrganizationProfileProps {
  setHeadBar: (title: HeadBarProps) => void;
}

const OrganizationProfile = (props: OrganizationProfileProps) => {
  const { setHeadBar } = props;
  const { t } = useTranslation();

  useEffect(() => {
    setHeadBar({ title: `${t("app_users_my_profil")} ` });
  }, [setHeadBar]);

  return (
      <GeneralInformations />
  );
};

export default withConnect(OrganizationProfile);
