(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports'], factory);
  else if (typeof exports === 'object')
    factory(module.exports);
  else
    root['plateform-plateform-commons'] = factory(typeof this['plateform-plateform-commons'] === 'undefined' ? {} : this['plateform-plateform-commons']);
}(this, function (_) {
  'use strict';
  Long.prototype = Object.create(Number_0.prototype);
  Long.prototype.constructor = Long;
  Exception.prototype = Object.create(Error.prototype);
  Exception.prototype.constructor = Exception;
  RuntimeException.prototype = Object.create(Exception.prototype);
  RuntimeException.prototype.constructor = RuntimeException;
  ClassCastException.prototype = Object.create(RuntimeException.prototype);
  ClassCastException.prototype.constructor = ClassCastException;
  NotFoundException_0.prototype = Object.create(Exception.prototype);
  NotFoundException_0.prototype.constructor = NotFoundException_0;
  function Comparable() {
  }
  Comparable.$metadata$ = {
    simpleName: 'Comparable',
    kind: 'interface',
    interfaces: []
  };
  function Number_0() {
  }
  Number_0.$metadata$ = {
    simpleName: 'Number',
    kind: 'class',
    interfaces: []
  };
  function captureStack(instance, constructorFunction) {
    if (Error.captureStackTrace != null) {
      Error.captureStackTrace(instance, constructorFunction);
    } else {
      instance.stack = (new Error()).stack;
    }
  }
  function extendThrowable(this_, message, cause) {
    Error.call(this_);
    setPropertiesToThrowableInstance(this_, message, cause);
  }
  function setPropertiesToThrowableInstance(this_, message, cause) {
    if (!hasOwnPrototypeProperty(this_, 'message')) {
      var tmp1_elvis_lhs = message;
      var tmp;
      if (tmp1_elvis_lhs == null) {
        var tmp0_safe_receiver = cause;
        tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.toString();
      } else {
        tmp = tmp1_elvis_lhs;
      }
      var tmp2_elvis_lhs = tmp;
      this_.message = tmp2_elvis_lhs == null ? undefined : tmp2_elvis_lhs;
    }if (!hasOwnPrototypeProperty(this_, 'cause')) {
      this_.cause = cause;
    }this_.name = Object.getPrototypeOf(this_).constructor.name;
  }
  function hasOwnPrototypeProperty(o, name) {
    var tmp0_unsafeCast_0 = Object.getPrototypeOf(o).hasOwnProperty(name);
    return tmp0_unsafeCast_0;
  }
  function THROW_CCE() {
    throw ClassCastException_init_$Create$();
  }
  function Long() {
  }
  Long.prototype.equals = function (other) {
    var tmp;
    if (other instanceof Long) {
      tmp = equalsLong(this, other);
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  Long.prototype.hashCode = function () {
    return hashCode(this);
  };
  Long.$metadata$ = {
    simpleName: 'Long',
    kind: 'class',
    interfaces: [Comparable]
  };
  function equalsLong(_this_, other) {
    return _this_._high === other._high ? _this_._low === other._low : false;
  }
  function hashCode(l) {
    return l._low ^ l._high;
  }
  function imul(a_local, b_local) {
    var lhs = jsBitwiseAnd(a_local, 4.29490176E9) * jsBitwiseAnd(b_local, 65535);
    var rhs = jsBitwiseAnd(a_local, 65535) * b_local;
    return jsBitwiseOr(lhs + rhs, 0);
  }
  function Exception() {
    captureStack(this, Exception);
  }
  Exception.$metadata$ = {
    simpleName: 'Exception',
    kind: 'class',
    interfaces: []
  };
  function RuntimeException() {
    captureStack(this, RuntimeException);
  }
  RuntimeException.$metadata$ = {
    simpleName: 'RuntimeException',
    kind: 'class',
    interfaces: []
  };
  function ClassCastException() {
    captureStack(this, ClassCastException);
  }
  ClassCastException.$metadata$ = {
    simpleName: 'ClassCastException',
    kind: 'class',
    interfaces: []
  };
  function Exception_init_$Init$($this) {
    extendThrowable($this, null, null);
    Exception.call($this);
    return $this;
  }
  function Exception_init_$Init$_0(message, $this) {
    extendThrowable($this, message, null);
    Exception.call($this);
    return $this;
  }
  function RuntimeException_init_$Init$($this) {
    Exception_init_$Init$($this);
    RuntimeException.call($this);
    return $this;
  }
  function ClassCastException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    ClassCastException.call($this);
    return $this;
  }
  function ClassCastException_init_$Create$() {
    var tmp = ClassCastException_init_$Init$(Object.create(ClassCastException.prototype));
    captureStack(tmp, ClassCastException_init_$Create$);
    return tmp;
  }
  function jsBitwiseOr(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = lhs_hack | rhs_hack;
    return tmp0_unsafeCast_0;
  }
  function jsBitwiseAnd(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = lhs_hack & rhs_hack;
    return tmp0_unsafeCast_0;
  }
  var AlertSeverityWarning;
  var AlertSeverityInfo;
  var AlertSeveritySuccess;
  var AlertSeverityError;
  function Alert_0() {
  }
  Alert_0.$metadata$ = {
    simpleName: 'Alert',
    kind: 'interface',
    interfaces: []
  };
  function AlertSeverity_0(severity) {
    this._severity = severity;
  }
  AlertSeverity_0.prototype._get_severity__1 = function () {
    return this._severity;
  };
  AlertSeverity_0.$metadata$ = {
    simpleName: 'AlertSeverity',
    kind: 'class',
    interfaces: []
  };
  Object.defineProperty(AlertSeverity_0.prototype, 'severity', {
    configurable: true,
    get: AlertSeverity_0.prototype._get_severity__1
  });
  function Attachment_0() {
  }
  Attachment_0.$metadata$ = {
    simpleName: 'Attachment',
    kind: 'interface',
    interfaces: []
  };
  function Command_0() {
  }
  Command_0.$metadata$ = {
    simpleName: 'Command',
    kind: 'interface',
    interfaces: []
  };
  function DateInterval_0() {
  }
  DateInterval_0.$metadata$ = {
    simpleName: 'DateInterval',
    kind: 'interface',
    interfaces: []
  };
  function Event_0() {
  }
  Event_0.$metadata$ = {
    simpleName: 'Event',
    kind: 'interface',
    interfaces: []
  };
  function GeoLocation_0() {
  }
  GeoLocation_0.$metadata$ = {
    simpleName: 'GeoLocation',
    kind: 'interface',
    interfaces: []
  };
  function GeoStamp_0() {
  }
  GeoStamp_0.$metadata$ = {
    simpleName: 'GeoStamp',
    kind: 'interface',
    interfaces: []
  };
  function Page_0() {
  }
  Page_0.$metadata$ = {
    simpleName: 'Page',
    kind: 'interface',
    interfaces: []
  };
  function PageRequest_0() {
  }
  PageRequest_0.$metadata$ = {
    simpleName: 'PageRequest',
    kind: 'interface',
    interfaces: []
  };
  function AlertBase_0(type, description, date, payload, severity) {
    this._type = type;
    this._description = description;
    this._date = date;
    this._payload = payload;
    this._severity_0 = severity;
  }
  AlertBase_0.prototype._get_type__2 = function () {
    return this._type;
  };
  AlertBase_0.prototype._get_description__0 = function () {
    return this._description;
  };
  AlertBase_0.prototype._get_date__2 = function () {
    return this._date;
  };
  AlertBase_0.prototype._get_payload__2 = function () {
    return this._payload;
  };
  AlertBase_0.prototype._get_severity__1 = function () {
    return this._severity_0;
  };
  AlertBase_0.prototype.toString = function () {
    return '' + "AlertBase(type='" + this._get_type__2() + "', description='" + this._get_description__0() + "', date='" + this._get_date__2() + "', payload=" + this._get_payload__2() + ', severity=' + this._get_severity__1() + ')';
  };
  AlertBase_0.$metadata$ = {
    simpleName: 'AlertBase',
    kind: 'class',
    interfaces: [Alert_0]
  };
  Object.defineProperty(AlertBase_0.prototype, 'type', {
    configurable: true,
    get: AlertBase_0.prototype._get_type__2
  });
  Object.defineProperty(AlertBase_0.prototype, 'description', {
    configurable: true,
    get: AlertBase_0.prototype._get_description__0
  });
  Object.defineProperty(AlertBase_0.prototype, 'date', {
    configurable: true,
    get: AlertBase_0.prototype._get_date__2
  });
  Object.defineProperty(AlertBase_0.prototype, 'payload', {
    configurable: true,
    get: AlertBase_0.prototype._get_payload__2
  });
  Object.defineProperty(AlertBase_0.prototype, 'severity', {
    configurable: true,
    get: AlertBase_0.prototype._get_severity__1
  });
  function DateIntervalBase_0(startDate, endDate) {
    this._startDate = startDate;
    this._endDate = endDate;
  }
  DateIntervalBase_0.prototype._get_startDate__0 = function () {
    return this._startDate;
  };
  DateIntervalBase_0.prototype._get_endDate__0 = function () {
    return this._endDate;
  };
  DateIntervalBase_0.prototype.component1 = function () {
    return this._startDate;
  };
  DateIntervalBase_0.prototype.component2 = function () {
    return this._endDate;
  };
  DateIntervalBase_0.prototype.copy = function (startDate, endDate) {
    return new DateIntervalBase_0(startDate, endDate);
  };
  DateIntervalBase_0.prototype.copy$default = function (startDate, endDate, $mask0, $handler) {
    var startDate_0 = !(($mask0 & 1) === 0) ? this._startDate : startDate;
    var endDate_0 = !(($mask0 & 2) === 0) ? this._endDate : endDate;
    return this.copy(startDate_0, endDate_0);
  };
  DateIntervalBase_0.prototype.toString = function () {
    return '' + 'DateIntervalBase(startDate=' + this._startDate + ', endDate=' + this._endDate + ')';
  };
  DateIntervalBase_0.prototype.hashCode = function () {
    return imul(this._startDate.hashCode(), 31) + this._endDate.hashCode() | 0;
  };
  DateIntervalBase_0.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof DateIntervalBase_0))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof DateIntervalBase_0 ? other : THROW_CCE();
    if (!this._startDate.equals(tmp0_other_with_cast._startDate))
      return false;
    if (!this._endDate.equals(tmp0_other_with_cast._endDate))
      return false;
    return true;
  };
  DateIntervalBase_0.$metadata$ = {
    simpleName: 'DateIntervalBase',
    kind: 'class',
    interfaces: [DateInterval_0]
  };
  Object.defineProperty(DateIntervalBase_0.prototype, 'startDate', {
    configurable: true,
    get: DateIntervalBase_0.prototype._get_startDate__0
  });
  Object.defineProperty(DateIntervalBase_0.prototype, 'endDate', {
    configurable: true,
    get: DateIntervalBase_0.prototype._get_endDate__0
  });
  function GeoLocationBase_0(lon, lat) {
    this._lon = lon;
    this._lat = lat;
  }
  GeoLocationBase_0.prototype._get_lon__0 = function () {
    return this._lon;
  };
  GeoLocationBase_0.prototype._get_lat__0 = function () {
    return this._lat;
  };
  GeoLocationBase_0.$metadata$ = {
    simpleName: 'GeoLocationBase',
    kind: 'class',
    interfaces: [GeoLocation_0]
  };
  Object.defineProperty(GeoLocationBase_0.prototype, 'lon', {
    configurable: true,
    get: GeoLocationBase_0.prototype._get_lon__0
  });
  Object.defineProperty(GeoLocationBase_0.prototype, 'lat', {
    configurable: true,
    get: GeoLocationBase_0.prototype._get_lat__0
  });
  function GeoStampBase_0(date, location) {
    this._date_0 = date;
    this._location = location;
  }
  GeoStampBase_0.prototype._get_date__2 = function () {
    return this._date_0;
  };
  GeoStampBase_0.prototype._get_location__0 = function () {
    return this._location;
  };
  GeoStampBase_0.$metadata$ = {
    simpleName: 'GeoStampBase',
    kind: 'class',
    interfaces: [GeoStamp_0]
  };
  Object.defineProperty(GeoStampBase_0.prototype, 'date', {
    configurable: true,
    get: GeoStampBase_0.prototype._get_date__2
  });
  Object.defineProperty(GeoStampBase_0.prototype, 'location', {
    configurable: true,
    get: GeoStampBase_0.prototype._get_location__0
  });
  function PageBase_0(page, size, total, list) {
    this._page = page;
    this._size = size;
    this._total = total;
    this._list = list;
  }
  PageBase_0.prototype._get_page__2 = function () {
    return this._page;
  };
  PageBase_0.prototype._get_size__2 = function () {
    return this._size;
  };
  PageBase_0.prototype._get_total__0 = function () {
    return this._total;
  };
  PageBase_0.prototype._get_list__0 = function () {
    return this._list;
  };
  PageBase_0.$metadata$ = {
    simpleName: 'PageBase',
    kind: 'class',
    interfaces: [Page_0]
  };
  Object.defineProperty(PageBase_0.prototype, 'page', {
    configurable: true,
    get: PageBase_0.prototype._get_page__2
  });
  Object.defineProperty(PageBase_0.prototype, 'size', {
    configurable: true,
    get: PageBase_0.prototype._get_size__2
  });
  Object.defineProperty(PageBase_0.prototype, 'total', {
    configurable: true,
    get: PageBase_0.prototype._get_total__0
  });
  Object.defineProperty(PageBase_0.prototype, 'list', {
    configurable: true,
    get: PageBase_0.prototype._get_list__0
  });
  function PageRequestBase_0(page, size) {
    this._page_0 = page;
    this._size_0 = size;
  }
  PageRequestBase_0.prototype._get_page__2 = function () {
    return this._page_0;
  };
  PageRequestBase_0.prototype._get_size__2 = function () {
    return this._size_0;
  };
  PageRequestBase_0.$metadata$ = {
    simpleName: 'PageRequestBase',
    kind: 'class',
    interfaces: [PageRequest_0]
  };
  Object.defineProperty(PageRequestBase_0.prototype, 'page', {
    configurable: true,
    get: PageRequestBase_0.prototype._get_page__2
  });
  Object.defineProperty(PageRequestBase_0.prototype, 'size', {
    configurable: true,
    get: PageRequestBase_0.prototype._get_size__2
  });
  function _get_ROLE_ADMIN_() {
    return ROLE_ADMIN;
  }
  var ROLE_ADMIN;
  function _get_ROLE_SUPER_ADMIN_() {
    return ROLE_SUPER_ADMIN;
  }
  var ROLE_SUPER_ADMIN;
  function _get_ROLE_OPERATOR_ADMIN_() {
    return ROLE_OPERATOR_ADMIN;
  }
  var ROLE_OPERATOR_ADMIN;
  function _get_ROLE_OPERATOR_USER_() {
    return ROLE_OPERATOR_USER;
  }
  var ROLE_OPERATOR_USER;
  function _get_ROLE_FUNDER_ADMIN_() {
    return ROLE_FUNDER_ADMIN;
  }
  var ROLE_FUNDER_ADMIN;
  function _get_ROLE_FUNDER_USER_() {
    return ROLE_FUNDER_USER;
  }
  var ROLE_FUNDER_USER;
  function _get_ROLE_CARRIER_ADMIN_() {
    return ROLE_CARRIER_ADMIN;
  }
  var ROLE_CARRIER_ADMIN;
  function _get_ROLE_CARRIER_USER_() {
    return ROLE_CARRIER_USER;
  }
  var ROLE_CARRIER_USER;
  function NotFoundException_0(message) {
    Exception_init_$Init$_0(message, this);
    captureStack(this, NotFoundException_0);
  }
  NotFoundException_0.$metadata$ = {
    simpleName: 'NotFoundException',
    kind: 'class',
    interfaces: []
  };
  AlertSeverityWarning = new AlertSeverity_0('warning');
  AlertSeverityInfo = new AlertSeverity_0('info');
  AlertSeveritySuccess = new AlertSeverity_0('success');
  AlertSeverityError = new AlertSeverity_0('error');
  ROLE_ADMIN = 'admin';
  ROLE_SUPER_ADMIN = 'super_admin';
  ROLE_OPERATOR_ADMIN = 'operator_admin';
  ROLE_OPERATOR_USER = 'operator_user';
  ROLE_FUNDER_ADMIN = 'funder_admin';
  ROLE_FUNDER_USER = 'funder_user';
  ROLE_CARRIER_ADMIN = 'carrier_admin';
  ROLE_CARRIER_USER = 'carrier_user';
  var $city = _.city || (_.city = {});
  var $city$colisactiv = $city.colisactiv || ($city.colisactiv = {});
  var $city$colisactiv$platform = $city$colisactiv.platform || ($city$colisactiv.platform = {});
  var $city$colisactiv$platform$commons = $city$colisactiv$platform.commons || ($city$colisactiv$platform.commons = {});
  $city$colisactiv$platform$commons.AlertSeverity = AlertSeverity_0;
  var $city$colisactiv$platform$commons$base = $city$colisactiv$platform$commons.base || ($city$colisactiv$platform$commons.base = {});
  $city$colisactiv$platform$commons$base.AlertBase = AlertBase_0;
  $city$colisactiv$platform$commons$base.DateIntervalBase = DateIntervalBase_0;
  $city$colisactiv$platform$commons$base.GeoLocationBase = GeoLocationBase_0;
  $city$colisactiv$platform$commons$base.GeoStampBase = GeoStampBase_0;
  $city$colisactiv$platform$commons$base.PageBase = PageBase_0;
  $city$colisactiv$platform$commons$base.PageRequestBase = PageRequestBase_0;
  var $city$colisactiv$platform$commons$constants = $city$colisactiv$platform$commons.constants || ($city$colisactiv$platform$commons.constants = {});
  Object.defineProperty($city$colisactiv$platform$commons$constants, 'ROLE_ADMIN', {
    configurable: true,
    get: _get_ROLE_ADMIN_
  });
  Object.defineProperty($city$colisactiv$platform$commons$constants, 'ROLE_SUPER_ADMIN', {
    configurable: true,
    get: _get_ROLE_SUPER_ADMIN_
  });
  Object.defineProperty($city$colisactiv$platform$commons$constants, 'ROLE_OPERATOR_ADMIN', {
    configurable: true,
    get: _get_ROLE_OPERATOR_ADMIN_
  });
  Object.defineProperty($city$colisactiv$platform$commons$constants, 'ROLE_OPERATOR_USER', {
    configurable: true,
    get: _get_ROLE_OPERATOR_USER_
  });
  Object.defineProperty($city$colisactiv$platform$commons$constants, 'ROLE_FUNDER_ADMIN', {
    configurable: true,
    get: _get_ROLE_FUNDER_ADMIN_
  });
  Object.defineProperty($city$colisactiv$platform$commons$constants, 'ROLE_FUNDER_USER', {
    configurable: true,
    get: _get_ROLE_FUNDER_USER_
  });
  Object.defineProperty($city$colisactiv$platform$commons$constants, 'ROLE_CARRIER_ADMIN', {
    configurable: true,
    get: _get_ROLE_CARRIER_ADMIN_
  });
  Object.defineProperty($city$colisactiv$platform$commons$constants, 'ROLE_CARRIER_USER', {
    configurable: true,
    get: _get_ROLE_CARRIER_USER_
  });
  var $city$colisactiv$platform$commons$exception = $city$colisactiv$platform$commons.exception || ($city$colisactiv$platform$commons.exception = {});
  $city$colisactiv$platform$commons$exception.NotFoundException = NotFoundException_0;
  return _;
}));
