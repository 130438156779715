import { connect } from "react-redux";
import { State } from "store";
import { global } from "store/global";
import { router } from "store/router";

const mapStateToProps = (state: State) => ({
  organizationRefs: global.selectors.organizationRefs(state),
  operatorRefs: global.selectors.operatorRefs(state),
});

const mapDispatchToProps = {
  gotoCampaignDelivery: router.goto.campaignDelivery,
  gotoOrganizationView: router.goto.organization.organizationView,
  gotoBonusView: router.goto.bonusView,
};

export default connect(mapStateToProps, mapDispatchToProps);
