import React from 'react'
import { Container, makeStyles } from '@material-ui/core'
import { Section, SectionProps } from './Section'

const useStyles = makeStyles(() => ({
  container: {
    width: 'calc(100% - 40px)',
    padding: '30px 20px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '5px',
    overflow: 'auto',
    marginLeft: 0
  }
}))

export interface SectionFormProps extends SectionProps {}

export const SectionForm = (props: SectionFormProps) => {
  const { children } = props
  const classes = useStyles()
  return (
    <Section {...props}>
      <Container className={classes.container}>
        <React.Fragment>{children}</React.Fragment>
      </Container>
    </Section>
  )
}
