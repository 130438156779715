import { buildProjectApiUrl } from "../../configuration";
import { ProjectStatsPage } from "api/project/model";
import { http } from "../../http";

export const fetchProjectsPage = (
  page: number,
  size: number,
  name?: string,
  status?: string
): Promise<ProjectStatsPage> => {
  return http.get(
    buildProjectApiUrl(
      `/projectStats?page=${page}&size=${size}${
        name ? "&projectName=" + name : ""
      }${status ? "&projectStatus=" + status : ""}`
    ),
    "la récupération des projets n'a pas pu être effectuée"
  );
};
