import { Box } from "@material-ui/core";
import { ProjectStats } from "api/project/model";
import { ProjectStatusUtils } from "api/project/model/ProjectStatus";
import { HeadBarProps, HeadBarView, SectionForm } from "components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditProjectDetails from "./Details";
import withConnect from "./withConnect";
interface ProjectEditPageProps {
  setHeadBar: (title: HeadBarProps) => void;

  project: ProjectStats | null;
  fetchProject: (projectId: string) => void;
}
interface EditState {
  readonly: boolean;
  allowAddFunder: boolean;
}
const ProjectEditPage = (props: ProjectEditPageProps) => {
  const { setHeadBar, project, fetchProject } = props;
  const { projectId } = useParams();
  const [editState, setEditState] = useState<EditState>({
    readonly: true,
    allowAddFunder: false,
  });

  useEffect(() => {
    fetchProject(projectId);
  }, [projectId, fetchProject]);

  useEffect(() => {
    if (project !== null) {
      setEditState({
        readonly: !ProjectStatusUtils.isCreated(project),
        allowAddFunder: ProjectStatusUtils.isStarted(project),
      });
      setHeadBar({
        title: project.details.name,
        component: (
          <Box display="flex" alignItems="center">
            <HeadBarView
              title={`Modification du ${project.details.name}`}
              status={ProjectStatusUtils.getLabelStyleStatusTags(
                project.status
              )}
            />
          </Box>
        ),
      });
    }
  }, [project]);

  return (
    <SectionForm withGoBack>
      <EditProjectDetails
        projectId={projectId}
        project={project}
        readOnly={editState.readonly}
        allowAddFunder={editState.allowAddFunder}
      />
    </SectionForm>
  );
};

export default withConnect(ProjectEditPage);
