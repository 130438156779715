import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  image: {
    borderRadius: '4px'
  }
}))

interface ProfileLogoProps {
  src?: string
  className?: string
  style?: React.CSSProperties
  defaultLogo?: string
  alt?: string
}

export const ProfileLogo = (props: ProfileLogoProps) => {
  const { src = '', className = '', style, defaultLogo = '', alt = '' } = props
  const [logo, setLogo] = useState(src)
  useEffect(() => {
    setLogo(src)
  }, [src])
  const classes = useStyles()
  return (
    <img
      //data-tag for the onCLick propagation in the table component
      data-tag="___react-data-table-allow-propagation___"
      src={logo !== '' ? logo : defaultLogo}
      alt={alt}
      className={`${classes.image} ${className}`}
      style={style}
      onError={() => setLogo('')}
    />
  )
}
