import { connect } from "react-redux";
import { State } from "../../../../../store";
import { router } from "../../../../../store/router";
import { organization } from "../../../../../store/organization";

const mapStateToProps = (state: State) => ({
  organization: organization.selectors.organization(state),
});

const mapDispatchToProps = {
  gotoOrganizationEditGeneralInformations:
    router.goto.organization.organizationEditGeneralInformations,
  gotoOrganizationEditPaymentInformations:
    router.goto.organization.organizationEditPayementInformations,
  gotoOrganizationsView: router.goto.organization.organizationView,
  updateOrganization: organization.commands.updateOrganization,
  deleteOrganization: organization.commands.deleteOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps);
