import React from "react";
import OrganizationUsersPage from "./List";
import AddUser from "./Add";
import { useParams } from "react-router-dom";
import withConnect from "./withConnect";
import GeneralUserInformations from "./GeneralUserInformations";
import { UsersSectionMode } from "../../../../../store/router/router.user.goto";

interface OrganizationUsersSectionProps {}

const OrganizationUsersSectionPage = (props: OrganizationUsersSectionProps) => {
  const { mode } = useParams();
  const {} = props;
  const userMode = mode as UsersSectionMode;
  return (
    <>
      {userMode === undefined && <OrganizationUsersPage />}
      {userMode === "add" && <AddUser />}
      {userMode === "edit" && <GeneralUserInformations />}
      {userMode === "view" && <GeneralUserInformations />}
    </>
  );
};

export default withConnect(OrganizationUsersSectionPage);
