import { createSelector } from "reselect";
import { State } from "..";

const getNotificationState = (state: State) => state.notification;

export const getUnSeenNumber = createSelector(
  [getNotificationState],
  (state) => state.unSeenNumber
);

export const selectors = {
  unSeenNumber: getUnSeenNumber,
};
