import { GeoZone } from "api/geoZone";
import { DateInterval } from "api/report";
import { city as citybonus } from "plateform-bonus-domain";
import { createOrUpdateGroupOfBonus } from "./command/createOrUpdateGroupOfBonus";

export interface Bonus
  extends citybonus.colisactiv.plateform.bonus.model.Bonus {
  id: number;
  area: GeoZone;
  period: DateInterval;
  activeDeliveryTours: BonusDeliveryTour[];
  nonActiveDeliveryTours: BonusDeliveryTour[];
}

export interface BonusDeliveryTour
  extends citybonus.colisactiv.plateform.bonus.model.BonusDeliveryTour {}

export interface BonusManualData
  extends citybonus.colisactiv.plateform.bonus.model.BonusManualData {
  deliveryTours: BonusDeliveryTour[];
}

export interface BonusGroupStatus
  extends citybonus.colisactiv.plateform.bonus.model.BonusGroupStatus {}

export const BonusGroupStatusValues = new citybonus.colisactiv.plateform.bonus.model.BonusGroupStatusValues();

export const bonusClient = {
  createOrUpdateGroupOfBonus: createOrUpdateGroupOfBonus,
  query: {},
};
